export function isObjectEmpty(value) {
    for (let prop in value) {
        if (value.hasOwnProperty(prop)) return false
    }
    return true
}

export const upperCaseInitLetter = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : ""
}
