import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const useSlider = (settings, dataArray, SlideComponent) => {
    const FinalSlider = () => {
        return (
            <Slider {...settings}>
                {dataArray.map((v, i) => (
                    <div key={i}>
                        <SlideComponent data={v} />
                    </div>
                ))}
            </Slider>
        )
    }
    return { FinalSlider, sliderLength: dataArray.length }
}
