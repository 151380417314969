import { useState, useEffect } from 'react'
import FormWrapper from './FormWrapper'
import { Input, CheckBox } from '../../../atoms'

const WhatKindOfPractiotioner = ({ kindofPractitioner = [], setKindOfPractitioner }) => {
    const [tell1, setTell1] = useState('')
    const [tell2, setTell2] = useState('')
    const [tell3, setTell3] = useState('')
    const [tell4, setTell4] = useState('')
    const [others, setOthers] = useState('')

    useEffect(() => {
        let finalArr = []
        let arr = [tell1, tell2, tell3, tell4, others]

        arr.forEach((str) => {
            if (str !== null && str.length !== 0) {
                finalArr.push(str)
            }
        })

        setKindOfPractitioner(finalArr)
    }, [tell1, tell2, tell3, tell4, others, setKindOfPractitioner])

    return (
        <div>
            <FormWrapper title="what kind of practitioner You’re?" subtitle="Let Us know about you!">
                <CheckBox
                    name="tell-us-practitioner-1"
                    type="checkbox"
                    label="Dentist with a practice"
                    value="Dentist with a practice"
                    selectedState={
                        kindofPractitioner.includes('Dentist with a practice')
                            ? kindofPractitioner[kindofPractitioner.indexOf('Dentist with a practice')]
                            : ''
                    }
                    setValue={setTell1}
                    wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                    inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                    labelClass="cursor-pointer"
                />
                <CheckBox
                    name="tell-us-practitioner-2"
                    type="checkbox"
                    label="Associate Dentist"
                    value="Associate Dentist"
                    selectedState={
                        kindofPractitioner.includes('Associate Dentist')
                            ? kindofPractitioner[kindofPractitioner.indexOf('Associate Dentist')]
                            : ''
                    }
                    setValue={setTell2}
                    wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                    inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                    labelClass="cursor-pointer"
                />
                <CheckBox
                    name="tell-us-practitioner-3"
                    type="checkbox"
                    label="Oral Hygienist"
                    value="Oral Hygienist"
                    selectedState={
                        kindofPractitioner.includes('Oral Hygienist')
                            ? kindofPractitioner[kindofPractitioner.indexOf('Oral Hygienist')]
                            : ''
                    }
                    setValue={setTell3}
                    wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                    inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                    labelClass="cursor-pointer"
                />
                <CheckBox
                    name="tell-us-practitioner-4"
                    type="checkbox"
                    label="Student"
                    value="Student"
                    selectedState={
                        kindofPractitioner.includes('Student')
                            ? kindofPractitioner[kindofPractitioner.indexOf('Student')]
                            : ''
                    }
                    setValue={setTell4}
                    wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                    inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                    labelClass="cursor-pointer"
                />
                <Input
                    name="tell-us-practitioner-other"
                    label="Others"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={others}
                    setValue={setOthers}
                />
            </FormWrapper>
        </div>
    )
}

export default WhatKindOfPractiotioner
