import axios from 'axios'
import { resolve } from './resolve'
import { settings } from '@utils/index'

export const getProfileAPI = async (token, user_id) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/profile/${user_id}`, { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((res) => res.data),
    )
}
