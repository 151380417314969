import React, { useState } from 'react'
import FormWrapper from './FormWrapper'
import { DropDownWithField, Input } from '@components/atoms'
import { CitySelect, CountrySelect, StateSelect } from 'react-country-state-city/dist/cjs'
import { dentalCategoryOptions } from '@utils/variables'

export default function DentalStudentSpecificInfo({
    fullName,
    setFullName,
    email,
    setEmail,
    phone,
    setPhone,
    country,
    setCountry,
    stateProvince,
    setStateProvince,
    city,
    setCity,
    dentalSchoolName,
    setDentalSchoolName,
    yearInProgram,
    setYearInProgram,
    expectedGraduationDate,
    setExpectedGraduationDate,
    dentalCategory,
    setDentalCategory,
    desiredCareerPath,
    setDesiredCareerPath,
    knowledgeAreasToDevelop,
    setKnowledgeAreasToDevelop,
    preferredLearningFormat,
    setPreferredLearningFormat,
    weeklyTimeAvailablility,
    setWeeklyTimeAvailablility,
    financialBudgetForCourses,
    setFinancialBudgetForCourses,
}) {
    const [stateid, setstateid] = useState(0)
    const [countryEmoji, setCountryEmoji] = useState('🇨🇦')
    const [countryid, setCountryid] = useState(39)

    const yearInProgramOptions = [
        { text: '1st Year', value: '1st Year' },
        { text: '2nd Year', value: '2nd Year' },
        { text: '3rd Year', value: '3rd Year' },
        { text: '4th Year', value: '4th Year' },
        { text: '5th Year', value: '5th Year' },
        { text: '6th Year', value: '6th Year' },
    ]
    const desiredCareerPathOptions = [
        { text: 'Private Practice', value: 'Private Practice' },
        { text: 'Academic', value: 'Academic' },
        { text: 'Research', value: 'Research' },
    ]
    const preferredLearningFormatsOptions = [
        { text: 'Lectures', value: 'Lectures' },
        { text: 'Workshops', value: 'Workshops' },
        { text: 'Hands-On Training', value: 'Hands-On Training' },
    ]

    return (
        <div>
            <FormWrapper
                title="Tell us About Your Preferences"
                subtitle="Let’s Know your preferences to make it better">
                <p className="text-2xl font-bold py-4">Personal Information:</p>

                <Input
                    name="fullName"
                    label="Full Name"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={fullName}
                    setValue={setFullName}
                />

                <Input
                    name="email"
                    label="Email"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={email}
                    setValue={setEmail}
                />

                <Input
                    name="phone"
                    label="Phone"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={phone}
                    setValue={setPhone}
                />

                <CountrySelect
                    defaultValue={{
                        emoji: countryEmoji,
                        id: countryid,
                        name: country,
                    }}
                    onChange={(e) => {
                        setCountryEmoji(e.emoji)
                        setCountryid(e.id)
                        setCountry(e?.name || '')
                    }}
                    placeHolder="Select Country"
                />
                <h6>State</h6>
                <StateSelect
                    countryid={countryid}
                    onChange={(e) => {
                        setstateid(e.id)
                        setStateProvince(e?.name || '')
                    }}
                    placeHolder="Select State"
                />
                <h6>City</h6>
                <CitySelect
                    countryid={countryid}
                    stateid={stateid}
                    onChange={(e) => {
                        setCity(e?.name || '')
                    }}
                    placeHolder="Select City"
                />

                <p className="text-2xl font-bold py-4">Educational Details:</p>

                <Input
                    name="dentalSchoolName"
                    label="Dental School Name"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={dentalSchoolName}
                    setValue={setDentalSchoolName}
                />

                <DropDownWithField
                    data={yearInProgramOptions}
                    label="Years in Program"
                    labelClass="text-lg font-semibold"
                    setValue={setYearInProgram}
                />

                <Input
                    name="expectedGraduationDate"
                    label="Expected Graduation Date"
                    type="date"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={expectedGraduationDate}
                    setValue={setExpectedGraduationDate}
                />

                <DropDownWithField
                    data={dentalCategoryOptions}
                    label="Area of Interest"
                    labelClass="text-lg font-semibold"
                    setValue={setDentalCategory}
                />

                <p className="text-2xl font-bold py-4">Professional Aspirations:</p>

                <DropDownWithField
                    data={desiredCareerPathOptions}
                    label="Area of Interest"
                    labelClass="text-lg font-semibold"
                    setValue={setDesiredCareerPath}
                />
                <Input
                    name="knowledgeAreasToDevelop"
                    label="Specific Skills/Knowledge Areas to Develop"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={knowledgeAreasToDevelop}
                    setValue={setKnowledgeAreasToDevelop}
                />

                <p className="text-2xl font-bold py-4">Learning Preferences:</p>

                <DropDownWithField
                    data={preferredLearningFormatsOptions}
                    label="Preferred Learning Formats"
                    labelClass="text-lg font-semibold"
                    setValue={setPreferredLearningFormat}
                />

                <p className="text-2xl font-bold py-4">Learning Preferences:</p>

                <Input
                    name="weeklyTimeAvailablility"
                    label="Weekly Time Availability for Extra-Curricular Learning"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={weeklyTimeAvailablility}
                    setValue={setWeeklyTimeAvailablility}
                />
                <Input
                    name="financialBudgetForCourses"
                    label="Financial Budget for Courses/Events"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={financialBudgetForCourses}
                    setValue={setFinancialBudgetForCourses}
                />
            </FormWrapper>
        </div>
    )
}
