import { RatingStars } from '@components/atoms/events/index'

const RatingPercent = ({ star, percent }) => {
    return (
        <div>
            <span className="relative">
                <span className="inline-block py-1 mr-2">
                    <RatingStars rate={star} />
                </span>
                <span className="inline-block w-[80%] pr-[10%]">
                    <span
                        className={`inline-block bg-orange-300 py-2 rounded-l-sm`}
                        style={{ width: `${percent}%` }}></span>
                    <span
                        className={`inline-block bg-slate-200 py-2 rounded-r-sm`}
                        style={{ width: `${100 - percent}%` }}></span>
                </span>
                <span className="absolute right-0 items-center">{percent.toFixed(2)}%</span>
            </span>
        </div>
    )
}

const RatingDetails = ({ rate }) => {
    let total_ratings = rate.all_ratings.reduce((accumulator, currentValue) => {
        return accumulator + currentValue
    }, 0)

    const star5 = (rate.all_ratings[4] * 100) / total_ratings
    const star4 = (rate.all_ratings[3] * 100) / total_ratings
    const star3 = (rate.all_ratings[2] * 100) / total_ratings
    const star2 = (rate.all_ratings[1] * 100) / total_ratings
    const star1 = (rate.all_ratings[0] * 100) / total_ratings

    return (
        <div className="bg-orange-50 p-8 rounded-sm">
            <h2 className="text-center">{rate.ratings}</h2>
            <div className="flex justify-center py-2">
                <RatingStars rate={rate.ratings} />
            </div>
            <p className="text-center text-sm">Overall Ratings</p>
            <div className="mb-2">
                <RatingPercent star={5} percent={star5 || 0} />
                <RatingPercent star={4} percent={star4 || 0} />
                <RatingPercent star={3} percent={star3 || 0} />
                <RatingPercent star={2} percent={star2 || 0} />
                <RatingPercent star={1} percent={star1 || 0} />
            </div>
            {rate.user_count > 0 ? (
                <span className="text-gray-400">
                    {rate.user_count} {rate.user_count > 1 ? 'people' : 'person'} rate this event
                </span>
            ) : (
                <span className="text-gray-400">No ratings yet</span>
            )}
        </div>
    )
}

export default RatingDetails
