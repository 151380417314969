import {
    faGear,
    faCalendarAlt,
    faThumbsUp,
    faChalkboardUser,
    faHomeAlt,
    faBars,
    faCalendarCheck,
    faChartBar,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import ProfileNav from './ProfileNav'
import { ProjectVar, ProfileVar } from '../../../utils'
import { Auth } from '../../../context'

const SideBar = ({ children }) => {
    const [side, setSide] = useState(true)

    const { state } = useContext(Auth)

    const menuData = [
        { to: '/home', icon: faHomeAlt, txt: 'Home' },
        { to: '/profile', icon: faChalkboardUser, txt: 'Profile' },
        { to: '/events', icon: faCalendarAlt, txt: 'Find Events (CEs)' },
        { to: '/favorite-events', icon: faThumbsUp, txt: 'Favorite events' },
    ]

    if (state.auth.role === ProfileVar.ADMIN) {
        menuData.push({ to: '/analytics', icon: faChartBar, txt: 'Analytics' })
    }
    if (state.auth.role === ProfileVar.ADMIN || state.auth.role === ProfileVar.ORGANIZER) {
        menuData.splice(3, 0, { to: '/event-add', icon: faCalendarCheck, txt: 'Add event' })
    }

    if (state.auth.role === ProfileVar.ADMIN || state.auth.role === ProfileVar.ORGANIZER) {
        menuData[0].txt = 'Your Events'
    }

    useEffect(() => {
        if (window.innerWidth > 400) {
            setSide(false)
        }
    }, [setSide])

    const pathLocation = useLocation()
    const path = pathLocation.pathname

    return (
        <div>
            <div
                className={
                    side
                        ? 'w-[60px] md:w-[300px] fixed top-0 left-0 h-full z-10 bg-white shadow-lg transition-all duration-300'
                        : 'w-[300px] fixed top-0 left-0 h-full z-10 bg-white shadow-lg transition-all duration-300 overflow-hidden text-ellipsis whitespace-nowrap'
                }>
                <span className="absolute right-5 top-4 md:hidden" onClick={() => setSide(!side)}>
                    <FontAwesomeIcon icon={faBars} className="text-primary-green-1 text-2xl cursor-pointer" />
                </span>
                <div>
                    <Link to="/">
                        <img
                            src={ProjectVar.PROJECT_LOGO_GREEN}
                            alt={ProjectVar.PROJECT_NAME}
                            className={
                                !side
                                    ? 'mt-8 max-w-[180px] md:max-w-[200px] pl-4 md:pl-12 pt-10 block'
                                    : 'mt-8 max-w-[200px] pl-4 md:pl-12 pt-10 hidden'
                            }
                        />
                    </Link>
                </div>
                <div className="ml-5 md:ml-12 mt-16 md:mt-12">
                    {menuData.map((v, i) => {
                        return (
                            <div key={i} className="mb-6">
                                <Link
                                    to={v.to}
                                    className={
                                        v.to === path
                                            ? 'text-primary-golden-5 font-medium'
                                            : 'text-gray-500 hover:text-primary-golden-5 font-medium'
                                    }>
                                    <FontAwesomeIcon icon={v.icon} className="mr-2" />
                                    {!side ? v.txt : null}
                                </Link>
                            </div>
                        )
                    })}
                </div>
                <div className="absolute bottom-12 left-6 md:left-12">
                    <Link
                        to="/settings"
                        className={
                            '/settings' === path
                                ? 'text-primary-golden-5 font-medium'
                                : 'text-gray-500 hover:text-primary-golden-5 font-medium'
                        }>
                        <FontAwesomeIcon icon={faGear} className="mr-2" />
                        {!side ? 'Settings' : ''}
                    </Link>
                </div>
            </div>

            <div className="absolute left-[60px] md:left-[300px] w-[calc(100%-60px)] md:w-[calc(100%-300px)] min-h-screen bg-secondary-light">
                <div>
                    <div className="w-full inline-block relative">
                        <div className="absolute top-0 right-5 md:top-2 md:right-8">
                            <ProfileNav />
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default SideBar
