export const formatDateTime = (dateString) => {
    const date = new Date(dateString)

    // Array of weekday names
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    // Array of month names
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    // Get the day of the week, month, and day of the month
    const dayOfWeek = weekdays[date.getDay()]
    const month = months[date.getMonth()]
    const dayOfMonth = date.getDate()

    // Format the date
    let formattedDate = `${dayOfWeek} ${dayOfMonth} ${month} ${date.getFullYear()}`

    // Get the time
    let hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()

    // Determine AM or PM
    const amOrPm = hours >= 12 ? 'PM' : 'AM'

    // Convert hours to 12-hour format
    hours = hours % 12 || 12

    // Format the time
    let formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')} ${amOrPm}`
    let splitedTimeWithZone = dateString.toString().includes('T')
        ? dateString.toString().split('T')[1]
        : '12:00:00+00:00'
    let splitedTimeOnly = splitedTimeWithZone.includes('+') ? splitedTimeWithZone.split('+')[0] : '12:00:00'
    let splitedTime = splitedTimeOnly.includes(':') ? splitedTimeOnly.split(':') : ['12', '00', '00']
    let preparedHour = splitedTime[0] % 12 || 12
    let amPm = Number(splitedTime[0]) >= 12 ? 'PM' : 'AM'
    let preparedTime = `${preparedHour}:${splitedTime[1]}:${splitedTime[2]} ${amPm}`

    // Add the time to the formatted date
    // formattedDate += ` ${formattedTime}`

    // return { date: formattedDate, time: formattedTime }
    return { date: formattedDate, time: preparedTime }
}
