import { faCalendarAlt, faProjectDiagram, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AdminDashboardNav = ({ dashNav, setDashNav = () => {} }) => {
    const dashNavData = [
        { txt: 'Events', value: 'events', icon: faCalendarAlt },
        { txt: 'All users', value: 'users', icon: faUsers },
        { txt: 'Organizers', value: 'organizers', icon: faUsers },
        { txt: 'Sponsor contents', value: 'sponsor_contents', icon: faProjectDiagram },
        // { txt: 'Sponsors', value: 'sponsors', icon: faUserCheck },
        // { txt: 'Mentors', value: 'mentors', icon: faUserGraduate },
        // { txt: 'Dental Products', value: 'dental', icon: faUserDoctor },
    ]
    return (
        <div className="inline-block rounded-md shadow-sm">
            <div className="flex flex-wrap">
                {dashNavData.map((v, i) => {
                    return (
                        <div
                            key={i}
                            onClick={() => setDashNav(v.value)}
                            className={
                                v.value === dashNav
                                    ? 'border-2 border-gray-200 bg-gray-100 hover:cursor-pointer'
                                    : 'border-2 border-gray-200 hover:bg-gray-100 hover:cursor-pointer bg-white'
                            }>
                            <div className="pt-4 pb-2 px-4 min-w-[150px] max-w-[150px] md:min-w-[160px] md:max-w-[180px]">
                                <FontAwesomeIcon
                                    icon={v.icon}
                                    className="inline-block md:block mx-auto text-xl text-primary-green-1"
                                />
                                <p className="inline-block md:block ml-2 md:ml-0 text-center text-xs py-2 font-semibold text-primary-green-1 select-none">
                                    {v.txt}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default AdminDashboardNav
