import {
    ProfileOrganizerCreateTemplate,
    ProfilePractitionerCreateTemplate,
    ProfileSponsorCreateTemplate,
} from '@components/templates/index'
import { Header } from '@components/organisms/index'
import { ProfileVar } from '@utils/variables'
import { useContext } from 'react'
import { Auth } from '@context/authContext'
import ProfileSpeakerCreateTemplate from '@components/templates/ProfileSpeakerCreateTemplate'

const CreateProfile = () => {
    const { state } = useContext(Auth)
    const role = state.auth.role

    const PcView = {
        [ProfileVar.PRACTITIONER]: ProfilePractitionerCreateTemplate,
        [ProfileVar.ORGANIZER]: ProfileOrganizerCreateTemplate,
        [ProfileVar.SPONSOR]: ProfileSponsorCreateTemplate,
        [ProfileVar.SPEAKER]: ProfileSpeakerCreateTemplate,
    }

    const CreateView = PcView[role]

    return (
        <div>
            <Header title="Complete profile" description="Complete your profile." />
            <CreateView />
        </div>
    )
}

export default CreateProfile
