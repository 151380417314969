import * as React from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { SideBar } from '@components/molecules'

export default function UserActivityTemplate({ data }) {
    return (
        <div className="h-screen">
            <SideBar>
                <div className="pt-6 px-4">
                    <h2 className="text-primary-green-1">User Logins At</h2>
                </div>
                <Timeline
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                        },
                    }}>
                    {!!data?.data &&
                        data?.data.length > 0 &&
                        data?.data.map((item, index) => (
                            <TimelineItem key={index}>
                                <TimelineSeparator>
                                    <TimelineDot color="success" />
                                    {index !== data?.data?.length - 1 && <TimelineConnector />}
                                </TimelineSeparator>
                                <TimelineContent>{`${item?.login_date.split('T')[0] || ''} at ${
                                    item?.login_date.split('T')[1].split('.')[0] || ''
                                }`}</TimelineContent>
                            </TimelineItem>
                        ))}
                </Timeline>
            </SideBar>
        </div>
    )
}
