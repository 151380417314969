import FormWrapper from './FormWrapper'
import { Input, DropDownWithField } from '@components/atoms/index'

const RoleEducation = ({ setYourRole, education, setEducation }) => {
    const roleOptions = [
        { text: 'Dentist', value: 'Dentist' },
        { text: 'International Dentist', value: 'International Dentist' },
        { text: 'Predental Student', value: 'Predental Student' },
        { text: 'Dental Student', value: 'Dental Student' },
        { text: 'Dental Assistant', value: 'Dental Assistant' },
        { text: 'Dental Hygenist', value: 'Dental Hygenist' },
        { text: 'Dental Admin', value: 'Dental Admin' },
        { text: 'Associate Dentist', value: 'Associate Dentist' },
    ]

    return (
        <div>
            <FormWrapper
                title="Tell us About Your Experience"
                subtitle="Let’s Know your years of experience and certifications.">
                <DropDownWithField
                    data={roleOptions}
                    label="Select your subrole"
                    labelClass="text-lg font-semibold"
                    setValue={setYourRole}
                />

                <Input
                    name="education"
                    label="Education"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={education}
                    setValue={setEducation}
                />
            </FormWrapper>
        </div>
    )
}

export default RoleEducation
