import { UsersListTableTemplate } from '@components/templates'
import React, { useEffect, useState, useContext } from 'react'
import { getUsersDataAPI } from '@api/index'
import { LocalVar, ProfileVar, formatDateTime } from '@utils/index'
import { Auth } from '@context/authContext'
import { NotFound } from '..'
import { getUsersDataByDateAPI } from '@api/analyticsApi'
import { Input } from '@components/atoms'

export default function UsersListTable() {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const [dateRangeddata, setDateRangedData] = useState({})
    const [page, setPage] = useState(0)
    const [activityPage, setActivityPage] = useState(0)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

    const { state } = useContext(Auth)

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const eventDetails = async (page) => {
            let usersData = await getUsersDataAPI(token, page, 10)
            let sDate = new Date(startDate)
            let eDate = new Date(endDate)
            let psDate = `${sDate.getFullYear()}-${
                sDate.getMonth() + 1
            }-${sDate.getDate()} ${sDate.getHours()}:${sDate.getMinutes()}:${sDate.getSeconds()}`
            let peDate = `${eDate.getFullYear()}-${
                eDate.getMonth() + 1
            }-${eDate.getDate()} ${eDate.getHours()}:${eDate.getMinutes()}:${eDate.getSeconds()}`
            let usersDateRangeData = await getUsersDataByDateAPI(token, psDate, peDate, activityPage, 10)
            if (usersDateRangeData.error === null) {
                setDateRangedData(usersDateRangeData.data.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
            if (usersData.error === null) {
                setData(usersData.data.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }

        eventDetails(page)
    }, [loading, page, activityPage, startDate, endDate])

    if (state.auth.role !== ProfileVar.ADMIN) return <NotFound />
    return (
        <div>
            <UsersListTableTemplate
                data={data}
                dateRangeddata={dateRangeddata}
                setPage={setPage}
                setActivityPage={setActivityPage}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
            />
        </div>
    )
}
