import React, { useState } from 'react'
import FormWrapper from './FormWrapper'
import { DropDownWithField, Input } from '@components/atoms'
import { CitySelect, CountrySelect, StateSelect } from 'react-country-state-city/dist/cjs'

export default function InternationalDentistRoleSpecificInfo({
    fullName,
    setFullName,
    contactInformation,
    setContactInformation,
    country,
    setCountry,
    stateProvince,
    setStateProvince,
    city,
    setCity,
    countryOfLicensure,
    setCountryOfLicensure,
    yearsOfExperience,
    setYearsOfExperience,
    areasOfSpecialty,
    setAreasOfSpecialty,
    currentStageInAccreditationProcess,
    setCurrentStageInAccreditationProcess,
    destinationCountry,
    setDestinationCountry,
    examRequirements,
    setExamRequirements,
    desiredCareerPath,
    setDesiredCareerPath,
    preferredLearningFormat,
    setPreferredLearningFormat,
    timeAvailabilityForStudyAndPreparation,
    setTimeAvailabilityForStudyAndPreparation,
    budgetForCourses,
    setBudgetForCourses,
}) {
    const [stateid, setstateid] = useState(0)
    const [countryEmoji, setCountryEmoji] = useState('🇨🇦')
    const [countryid, setCountryid] = useState(39)
    const currentStageInAccreditationProcessOptions = [
        { text: 'Studying for Board Exams', value: 'Studying for Board Exams' },
        { text: 'Waiting for exam results', value: 'Waiting for exam results' },
    ]
    const destinationCountryOptions = [
        { text: 'Canada ', value: 'Canada ' },
        { text: 'U.S.', value: 'U.S.' },
    ]
    const examRequirementsOptions = [
        { text: 'National Board Dental Examination Part I', value: 'National Board Dental Examination Part I' },
        { text: 'National Board Dental Examination Part II', value: 'National Board Dental Examination Part II' },
        { text: 'AFK', value: 'AFK' },
    ]
    const desiredCareerPathOptions = [
        { text: 'Clinical ', value: 'Clinical ' },
        { text: 'Academic', value: 'Academic' },
        { text: 'Research', value: 'Research' },
    ]
    const preferredLearningFormatsOptions = [
        { text: 'Online', value: 'Online' },
        { text: 'In-Person', value: 'In-Person' },
        { text: 'Workshops', value: 'Workshops' },
    ]

    return (
        <div>
            <FormWrapper
                title="Tell us About Your Preferences"
                subtitle="Let’s Know your preferences to make it better">
                <p className="text-2xl font-bold py-4">Personal Information:</p>
                <Input
                    name="fullName"
                    label="Full Name"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={fullName}
                    setValue={setFullName}
                />
                <Input
                    name="contactInformation"
                    label="Contact Information"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={contactInformation}
                    setValue={setContactInformation}
                />
                <h6>Current Location- Country</h6>
                <CountrySelect
                    defaultValue={{
                        emoji: countryEmoji,
                        id: countryid,
                        name: country,
                    }}
                    onChange={(e) => {
                        setCountryEmoji(e.emoji)
                        setCountryid(e.id)
                        setCountry(e?.name || '')
                    }}
                    placeHolder="Select Country"
                />
                <h6>State</h6>
                <StateSelect
                    countryid={countryid}
                    onChange={(e) => {
                        setstateid(e.id)
                        setStateProvince(e?.name || '')
                    }}
                    placeHolder="Select State"
                />
                <h6>City</h6>
                <CitySelect
                    countryid={countryid}
                    stateid={stateid}
                    onChange={(e) => {
                        setCity(e?.name || '')
                    }}
                    placeHolder="Select City"
                />

                <p className="text-2xl font-bold py-4">Professional Background:</p>

                <Input
                    name="countryOfLicensure"
                    label="Country of Licensure"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={countryOfLicensure}
                    setValue={setCountryOfLicensure}
                />
                <Input
                    name="yearsOfExperience"
                    label="Years of Experience"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={yearsOfExperience}
                    setValue={setYearsOfExperience}
                />
                <Input
                    name="areasOfSpecialty"
                    label="Areas of Specialty"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={areasOfSpecialty}
                    setValue={setAreasOfSpecialty}
                />

                <p className="text-2xl font-bold py-4">Current Status & Goals:</p>
                <DropDownWithField
                    data={currentStageInAccreditationProcessOptions}
                    label="Current Stage in Accreditation Process"
                    labelClass="text-lg font-semibold"
                    setValue={setCurrentStageInAccreditationProcess}
                />
                <DropDownWithField
                    data={destinationCountryOptions}
                    label="Destination Country"
                    labelClass="text-lg font-semibold"
                    setValue={setDestinationCountry}
                />
                <DropDownWithField
                    data={examRequirementsOptions}
                    label="Exam Requirements"
                    labelClass="text-lg font-semibold"
                    setValue={setExamRequirements}
                />

                <p className="text-2xl font-bold py-4">Professional Goals:</p>
                <DropDownWithField
                    data={desiredCareerPathOptions}
                    label="Desired Career Path"
                    labelClass="text-lg font-semibold"
                    setValue={setDesiredCareerPath}
                />

                <p className="text-2xl font-bold py-4">Learning Preferences:</p>
                <DropDownWithField
                    data={preferredLearningFormatsOptions}
                    label="Your preffered learning format"
                    labelClass="text-lg font-semibold"
                    setValue={setPreferredLearningFormat}
                />

                <p className="text-2xl font-bold py-4">Availability & Constraints:</p>
                <Input
                    name="timeAvailabilityForStudyAndPreparation"
                    label="Time Availability for Study and Preparation"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={timeAvailabilityForStudyAndPreparation}
                    setValue={setTimeAvailabilityForStudyAndPreparation}
                />
                <Input
                    name="budgetForCourses"
                    label="Budget for Courses/Resources"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={budgetForCourses}
                    setValue={setBudgetForCourses}
                />
            </FormWrapper>
        </div>
    )
}
