import { useEffect, useState } from 'react'
import { Loading } from '@components/atoms/index'
import { MainNav } from '@components/molecules/index'
import { Header, Footer } from '@components/organisms/index'
import { EventDetailsViewTemplate } from '@components/templates/index'
import { useParams } from 'react-router-dom'
import { getEventDetailsAPI } from '@api/index'
import { LocalVar, isObjectEmpty } from '@utils/index'

const EventDetailsPage = () => {
    const { eventId } = useParams()

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const eventDetails = async () => {
            let eventData = await getEventDetailsAPI(token, eventId)
            if (eventData.error === null) {
                setData(eventData.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }

        eventDetails()
    }, [eventId])

    return (
        <div>
            <Header title="Event Detail" />
            <MainNav />
            {isObjectEmpty(data) || loading ? (
                <div className="h-screen flex justify-center items-center">
                    <Loading />
                </div>
            ) : (
                <EventDetailsViewTemplate data={data} />
            )}

            <Footer />
        </div>
    )
}

export default EventDetailsPage
