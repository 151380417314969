import { useState, useEffect, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faMultiply } from '@fortawesome/free-solid-svg-icons'
import { ProjectVar, isObjectEmpty } from '../../../utils'
import { Auth } from '../../../context'
import ProfileNav from './ProfileNav'

const MainNav = () => {
    const [menu, setMenu] = useState(false)
    const [showBar, setShowBar] = useState(true)

    const { state } = useContext(Auth)

    useEffect(() => {
        if (window.visualViewport.width > 450) {
            setMenu(true)
            setShowBar(false)
        }
    }, [setMenu])

    const menuData = [
        { txt: 'Find Events (CEs)', lnk: '/events' },
        { txt: 'Virtual CE', lnk: '/virtualce' },
        {
            txt: 'Mentorship',
            lnk: '/mentorship',
            submenu: [
                { txt: 'Mentors', lnk: '/' },
                { txt: 'Mentees', lnk: '/' },
            ],
        },
        { txt: 'Dental Deals', lnk: '/dentaldeals' },
    ]

    const pathLocation = useLocation()
    const path = pathLocation.pathname

    return (
        <div className="py-6 lg:px-4 xl:px-20 grid grid-cols-12 shadow-md bg-gradient-to-bl from-primary-green-1 to-dark-green backdrop-blur-md">
            <div className="inline col-span-12 md:col-span-3 relative pl-8 md:pl-0">
                <Link to="/" className="inline-block">
                    <img src={ProjectVar.PROJECT_LOGO_WHITE} alt={ProjectVar.PROJECT_NAME} className="max-w-[140px]" />
                </Link>

                {showBar ? (
                    <span>
                        <span className=" absolute right-14 top-2">
                            {isObjectEmpty(state.auth) === false ? <ProfileNav /> : null}
                        </span>
                        <span className="absolute right-6 top-2" onClick={() => setMenu(!menu)}>
                            {menu ? (
                                <FontAwesomeIcon icon={faMultiply} className="text-white scale-125" />
                            ) : (
                                <FontAwesomeIcon icon={faBars} className="text-white scale-125" />
                            )}
                        </span>
                    </span>
                ) : null}
            </div>
            {menu ? (
                <>
                    <div className="h-100 col-span-12 md:col-span-6 text-center pt-4 md:pt-1">
                        {menuData.map((v, i) => {
                            return (
                                <Link
                                    reloadDocument
                                    key={i}
                                    to={v.lnk}
                                    className={
                                        path === v.lnk
                                            ? 'golden-txt font-medium hover:golden-txt px-8 md:px-4 text-base lg:text-sm xl:text-lg text-left md:text-center block md:inline mb-2'
                                            : 'text-white font-medium hover:golden-txt px-8 md:px-4 text-base lg:text-sm xl:text-lg text-left md:text-center block md:inline mb-2'
                                    }>
                                    {v.txt}
                                </Link>
                            )
                        })}
                    </div>
                    <div className="h-100 col-span-12 md:col-span-3 relative">
                        {isObjectEmpty(state.auth) === true ? (
                            <div className="float-left md:float-right pl-6 md:pl-0 mt-2 md:mt-0">
                                <Link
                                    to="/login"
                                    className="border-2 border-primary-golden-4 text-sm lg:text-sm xl:text-lg text-primary-golden-4 font-medium px-6 md:px-4 lg:px-4 xl:px-8 py-2 md:py-3 rounded-lg mr-2">
                                    Log in
                                </Link>
                                <Link
                                    to="/signup"
                                    className="golden border-2 border-primary-golden-5 text-sm lg:text-sm xl:text-lg text-primary-green-1 font-medium px-6 md:px-4 lg:px-4 xl:px-8 py-2 md:py-3 rounded-lg ml-2">
                                    Sign up
                                </Link>
                            </div>
                        ) : (
                            <div className="w-full inline-block">
                                <div className="absolute top-0 md:top-0 md:right-16 pl-8 hidden md:inline-block">
                                    <ProfileNav />
                                </div>
                            </div>
                        )}
                    </div>
                </>
            ) : null}
        </div>
    )
}

export default MainNav
