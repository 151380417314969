import { useState } from 'react'
import { Modal } from '@components/atoms/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faInfo } from '@fortawesome/free-solid-svg-icons'

const HowItWorks = () => {
    const [open, setOpen] = useState(false)
    return (
        <div>
            <div className="md:container mx-auto">
                <span onClick={() => setOpen(true)} className="text-primary-golden-5 cursor-pointer text-2xl">
                    <FontAwesomeIcon icon={faInfo} />
                </span>{' '}
                {open && (
                    <Modal title="How it works" setValue={setOpen}>
                        <div className="max-w-[650px] mx-auto mt-4">
                            <p className="text-gray-600 p-2 rounded-md mb-3">
                                <FontAwesomeIcon icon={faCheckSquare} className="text-primary-green-1" /> Create Your
                                Profile Start your journey by creating a profile on DentiCE, where you can detail your
                                background, expertise, and career objectives within the dental community.
                            </p>
                            <p className="text-gray-600 p-2 rounded-md mb-3">
                                <FontAwesomeIcon icon={faCheckSquare} className="text-primary-green-1" /> Let DentiCE
                                Guide You Once your profile is complete, let DentiCE be your trusted guide towards
                                achieving your career goals effectively and efficiently.
                            </p>
                            <p className="text-gray-600 p-2 rounded-md mb-3">
                                <FontAwesomeIcon icon={faCheckSquare} className="text-primary-green-1" /> Access Events
                                Across North America Gain access to an extensive catalog of events taking place
                                throughout North America, ensuring you stay informed about all opportunities, whether
                                big or small.
                            </p>
                            <p className="text-gray-600 p-2 rounded-md mb-3">
                                <FontAwesomeIcon icon={faCheckSquare} className="text-primary-green-1" /> Show Interest
                                in Preferred Events Browse through the array of events and courses available on DentiCE,
                                showing interest in those that align with your aspirations and interests within
                                dentistry.
                            </p>
                            <p className="text-gray-600 p-2 rounded-md mb-3">
                                <FontAwesomeIcon icon={faCheckSquare} className="text-primary-green-1" /> Direct
                                Communication with Organizers Engage in direct communication with event organizers
                                through DentiCE, facilitating seamless interaction and registration for events that
                                capture your attention.
                            </p>
                            <p className="text-gray-600 p-2 rounded-md mb-3">
                                <FontAwesomeIcon icon={faCheckSquare} className="text-primary-green-1" /> Review Ratings
                                and Feedback Before making your final decisions, take advantage of ratings and reviews
                                from fellow participants to ensure that the events meet your expectations and
                                objectives.
                            </p>
                            <p className="text-gray-600 p-2 rounded-md mb-3">
                                <FontAwesomeIcon icon={faCheckSquare} className="text-primary-green-1" /> AI-Powered
                                Recommendations Benefit from our advanced AI algorithms that analyze your profile to
                                provide personalized recommendations for events that best match your goals.
                            </p>
                            <h4 className="text-primary-green-1 mt-8 font-bold">Take the Next Step in Your Career</h4>
                            <p className="text-gray-700">
                                Unlock new opportunities for growth and success by leveraging the power of DentiCE CE
                                Finder. Sign up today and start your journey towards professional excellence
                            </p>
                        </div>
                    </Modal>
                )}
            </div>
        </div>
    )
}

export default HowItWorks
