import { NoEventYet, GetStartedCard } from '../../atoms/organizer'
import VideoCreationPng from '../../../assets/img/VideoCreation.png'
import MeetingTabPng from '../../../assets/img/MeetingWithTab.png'

const OrgGetStarted = () => {
    return (
        <div className="mt-6">
            <NoEventYet />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-4">
                <GetStartedCard
                    img={VideoCreationPng}
                    title="Invite Sponsors to An Event"
                    description="Whether you've been teaching for years or are teaching for the first time, you can make an engaging course."
                    getLink="/event-add"
                />
                <GetStartedCard
                    img={MeetingTabPng}
                    title="Learn How to Organize an Event"
                    description="Whether you've been teaching for years or are teaching for the first time, you can make an engaging course."
                    getLink="/event-add"
                />
            </div>
        </div>
    )
}

export default OrgGetStarted
