import {
    faCaretUp,
    faCircleUser,
    faSignOut,
    faHomeAlt,
    faUserAlt,
    faGear,
    faUserFriends,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useRef, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Auth } from '../../../context'
import { logout, LocalVar } from '@utils/index'
// import { getUserRolesAPI } from '@api/index'

const ProfileNav = () => {
    const { state, dispatch } = useContext(Auth)

    const [openMenu, setOpenMenu] = useState(false)

    // const [yourRoles, setYourRoles] = useState([])

    const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

    const ref = useRef(null)

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpenMenu(false)
        }
    }

    // useEffect(() => {
    //     const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

    //     ;(async () => {
    //         const getRoles = await getUserRolesAPI(token)
    //         if (getRoles.error === null) {
    //             setYourRoles(getRoles.data.role)
    //         }
    //     })()
    // }, [])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [])

    const navigate = useNavigate()

    const submitLogout = (token) => {
        logout(token)
        dispatch({ type: 'remove' })
        localStorage.setItem(LocalVar.AUTH_DATA, null)
        localStorage.removeItem('seenCEFModal')
        navigate('/login')
    }

    const checkDev = process.env.NODE_ENV !== 'development' ? '/v1' : ''
    const fullImgPath =
        process.env.REACT_APP_BASE_URL + ':' + process.env.REACT_APP_BASE_PORT + checkDev + state?.auth?.profile_pic

    return (
        <div>
            <div className="relative cursor-pointer golden-txt" onClick={() => setOpenMenu(!openMenu)}>
                {/* {state?.auth?.profile_pic ? (
                    <img src={fullImgPath} alt="" className="absolute top-0 -left-6 w-[25px] rounded-full" />
                ) : (
                    <FontAwesomeIcon
                        icon={faCircleUser}
                        className="absolute top-0 -left-6 text-2xl text-primary-golden-5"
                    />
                )} */}

                <img
                    src={state?.auth?.profile_pic ? fullImgPath : '/assets/img/default-pro.jpg'}
                    alt="profile_pic"
                    className="absolute top-0 -left-6 w-[25px] rounded-full"
                />
                <span className="text-sm font-semibold ml-2 hidden md:inline-block">{state?.auth?.full_name}</span>
            </div>
            {openMenu === true ? (
                <div
                    className="absolute -right-2 top-10 md:right-10 md:top-12 shadow-md border-2 bg-white border-gray-200 px-6 py-4 rounded-md min-w-48 z-50"
                    ref={ref}>
                    <ul className="list-none">
                        <li className="text-sm font-medium my-4 hover:text-primary-green-2">
                            <Link to="/home">
                                <FontAwesomeIcon icon={faHomeAlt} className="mr-2 text-primary-green-1" /> Home
                            </Link>
                        </li>
                        <li className="text-sm font-medium my-4 hover:text-primary-green-2">
                            <Link to="/profile">
                                <FontAwesomeIcon icon={faUserAlt} className="mr-2 text-primary-green-1" /> Profile
                            </Link>
                        </li>
                        {state?.auth?.roles && state?.auth?.roles.length > 1 && (
                            <li className="text-sm font-medium my-4 hover:text-primary-green-2">
                                <Link to="/switch-profile">
                                    <FontAwesomeIcon icon={faUserFriends} className="mr-2 text-primary-green-1" />{' '}
                                    Switch profile
                                </Link>
                            </li>
                        )}
                        <li className="text-sm font-medium my-4 hover:text-primary-green-2">
                            <Link to="/settings">
                                <FontAwesomeIcon icon={faGear} className="mr-2 text-primary-green-1" /> Settings
                            </Link>
                        </li>
                        <li className="text-sm font-medium my-4 hover:text-primary-green-2">
                            <p onClick={() => submitLogout(token)} className="cursor-pointer">
                                <FontAwesomeIcon icon={faSignOut} className="mr-2 text-primary-green-1" /> Logout
                            </p>
                        </li>
                    </ul>
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        className="absolute text-white text-4xl -top-4 right-2 md:right-6 -z-10"
                    />
                </div>
            ) : null}
        </div>
    )
}

export default ProfileNav
