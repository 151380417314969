import axios from 'axios'
import { resolve } from './resolve'
import { settings } from '@utils/index'

export const createPractitionerProfileAPI = async (token, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .post('/profile-practitioners/', { ...body }, { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((res) => res.data),
    )
}

export const getPractitionerProfileAPI = async (token) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get('/profile-practitioners/', { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((res) => res.data),
    )
}

export const updatePractitionerProfileAPI = async (token, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .patch('/profile-practitioners/', { ...body }, { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((res) => res.data),
    )
}
