import { useState } from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import { useMultiStepForm } from '../../hooks'
import {
    DentalStudentSpecificInfo,
    DentistRoleSpecificInfo,
    RoleEducation,
    SetupProfile,
    WhatKindOfPractitioner,
    YourGoals,
} from '../molecules/Forms/PracttionerCreate'
import { LocalVar, settings } from '../../utils'
import GoalImage from '../../assets/img/goal.png'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { createPractitionerProfileAPI } from '@api/index'
import PredentalStudentSpecificInfo from '@components/molecules/Forms/PracttionerCreate/PredentalStudentSpecificInfo'
import InternationalDentistRoleSpecificInfo from '@components/molecules/Forms/PracttionerCreate/InternationalDentistSpecificInfo'

const ProfilePractitionerCreateTemplate = () => {
    const [yourRole, setYourRole] = useState('')
    const [education, setEducation] = useState('')
    const [kindOfPractitioner, setKindOfPractitioner] = useState([])
    const [yourGoals, setYourGoals] = useState([])
    const [cePassed, setCePassed] = useState(0)
    const [ceLeft, setCeLeft] = useState(0)
    const [startYear, setStartYear] = useState(2024)
    const [yearsOfCompletion, setYearOfCompletion] = useState(2024)
    const [leftYear, setLeftYear] = useState(0)
    const [categoryOne, setCategoryOne] = useState(0)
    const [categoryTwo, setCategoryTwo] = useState(0)
    const [categoryThree, setCategoryThree] = useState(0)

    //Dentist Role Specific Data
    const [preferredLearningFormat, setPreferredLearningFormat] = useState('')
    const [preferredTopics, setPreferredTopics] = useState('')
    const [preferredGeoLocation, setPreferredGeoLocation] = useState('')
    const [idealEventScale, setIdealEventScale] = useState('')
    const [preferredDaysAttendEvent, setPreferredDaysAttendEvent] = useState('')
    const [willingnessToTravel, setWillingnessToTravel] = useState('')

    //Dental Student Role Specific Data
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [country, setCountry] = useState('')
    const [stateProvince, setStateProvince] = useState('')
    const [city, setCity] = useState('')
    const [dentalSchoolName, setDentalSchoolName] = useState('')
    const [yearInProgram, setYearInProgram] = useState('')
    const [expectedGraduationDate, setExpectedGraduationDate] = useState('')
    const [dentalCategory, setDentalCategory] = useState('')
    const [desiredCareerPath, setDesiredCareerPath] = useState('')
    const [knowledgeAreasToDevelop, setKnowledgeAreasToDevelop] = useState('')
    // preferredLearningFormats resued from Dentist Role
    const [weeklyTimeAvailablility, setWeeklyTimeAvailablility] = useState('')
    const [financialBudgetForCourses, setFinancialBudgetForCourses] = useState('')

    //Internal Dentist Role Specific Data
    //fullName reused from Dental Student Role
    const [contactInformation, setContactInformation] = useState('')
    //country, stateProvince, city reused from Dental Student Role
    const [countryOfLicensure, setCountryOfLicensure] = useState('')
    const [yearsOfExperience, setYearsOfExperience] = useState('')
    const [areasOfSpecialty, setAreasOfSpecialty] = useState('')
    const [currentStageInAccreditationProcess, setCurrentStageInAccreditationProcess] = useState('')
    const [destinationCountry, setDestinationCountry] = useState('')
    const [examRequirements, setExamRequirements] = useState('')
    //desiredCareerPath reused from Dental Student Role
    //preferredLearningFormats resued from Dentist Role
    const [timeAvailabilityForStudyAndPreparation, setTimeAvailabilityForStudyAndPreparation] = useState('')
    const [budgetForCourses, setBudgetForCourses] = useState('')

    //Predental Student Role Specific Data
    const [currentStudyLevel, setCurrentStudyLevel] = useState('')
    const [majorFieldOfStudy, setMajorFieldOfStudy] = useState('')
    const [relevantCoursesTaken, setRelevantCoursesTaken] = useState('')
    const [intendedAppYearDentalSchool, setIntendedAppYearDentalSchool] = useState('')
    const [preparednessLevelAdmissionTest, setPreparednessLevelAdmissionTest] = useState('')
    const [extracurricularActivities, setExtracurricularActivities] = useState('')
    //dentalCategory reused from Dental Student Role
    const [careerInsightsAndGoals, setCareerInsightsAndGoals] = useState('')
    // preferredLearningFormats resued from Dentist Role
    const [timeAvailabilityForEnrichmentActivities, setTimeAvailabilityForEnrichmentActivities] = useState('')
    const [budgetForTestPrepAndCollegeVisits, setBudgetForTestPrepAndCollegeVisits] = useState('')

    const { step, steps, currentStepIndex, isFirstStep, isLastStep, back, next } = useMultiStepForm([
        <RoleEducation setYourRole={setYourRole} education={education} setEducation={setEducation} />,
        <WhatKindOfPractitioner setKindOfPractitioner={setKindOfPractitioner} />,
        <YourGoals setYourGoals={setYourGoals} />,
        <SetupProfile
            cePassed={cePassed}
            setCePassed={setCePassed}
            ceLeft={ceLeft}
            setCeLeft={setCeLeft}
            startYear={startYear}
            setStartYear={setStartYear}
            yearsOfCompletion={yearsOfCompletion}
            setYearOfCompletion={setYearOfCompletion}
            leftYear={leftYear}
            setLeftYear={setLeftYear}
            yourRole={yourRole}
            categoryOne={categoryOne}
            categoryTwo={categoryTwo}
            categoryThree={categoryThree}
            setCategoryOne={setCategoryOne}
            setCategoryTwo={setCategoryTwo}
            setCategoryThree={setCategoryThree}
        />,
        yourRole === 'Dentist' ? (
            <DentistRoleSpecificInfo
                preferredLearningFormat={preferredLearningFormat}
                setPreferredLearningFormat={setPreferredLearningFormat}
                preferredTopics={preferredTopics}
                setPreferredTopics={setPreferredTopics}
                preferredGeoLocation={preferredGeoLocation}
                setPreferredGeoLocation={setPreferredGeoLocation}
                idealEventScale={idealEventScale}
                setIdealEventScale={setIdealEventScale}
                preferredDaysAttendEvent={preferredDaysAttendEvent}
                setPreferredDaysAttendEvent={setPreferredDaysAttendEvent}
                willingnessToTravel={willingnessToTravel}
                setWillingnessToTravel={setWillingnessToTravel}
            />
        ) : yourRole === 'Dental Student' ? (
            <DentalStudentSpecificInfo
                fullName={fullName}
                setFullName={setFullName}
                email={email}
                setEmail={setEmail}
                phone={phone}
                setPhone={setPhone}
                country={country}
                setCountry={setCountry}
                stateProvince={stateProvince}
                setStateProvince={setStateProvince}
                city={city}
                setCity={setCity}
                dentalSchoolName={dentalSchoolName}
                setDentalSchoolName={setDentalSchoolName}
                yearInProgram={yearInProgram}
                setYearInProgram={setYearInProgram}
                expectedGraduationDate={expectedGraduationDate}
                setExpectedGraduationDate={setExpectedGraduationDate}
                dentalCategory={dentalCategory}
                setDentalCategory={setDentalCategory}
                desiredCareerPath={desiredCareerPath}
                setDesiredCareerPath={setDesiredCareerPath}
                knowledgeAreasToDevelop={knowledgeAreasToDevelop}
                setKnowledgeAreasToDevelop={setKnowledgeAreasToDevelop}
                preferredLearningFormat={preferredLearningFormat}
                setPreferredLearningFormat={setPreferredLearningFormat}
                weeklyTimeAvailablility={weeklyTimeAvailablility}
                setWeeklyTimeAvailablility={setWeeklyTimeAvailablility}
                financialBudgetForCourses={financialBudgetForCourses}
                setFinancialBudgetForCourses={setFinancialBudgetForCourses}
            />
        ) : yourRole === 'Predental Student' ? (
            <PredentalStudentSpecificInfo
                currentStudyLevel={currentStudyLevel}
                setCurrentStudyLevel={setCurrentStudyLevel}
                majorFieldOfStudy={majorFieldOfStudy}
                setMajorFieldOfStudy={setMajorFieldOfStudy}
                relevantCoursesTaken={relevantCoursesTaken}
                setRelevantCoursesTaken={setRelevantCoursesTaken}
                intendedAppYearDentalSchool={intendedAppYearDentalSchool}
                setIntendedAppYearDentalSchool={setIntendedAppYearDentalSchool}
                preparednessLevelAdmissionTest={preparednessLevelAdmissionTest}
                setPreparednessLevelAdmissionTest={setPreparednessLevelAdmissionTest}
                extracurricularActivities={extracurricularActivities}
                setExtracurricularActivities={setExtracurricularActivities}
                dentalCategory={dentalCategory}
                setDentalCategory={setDentalCategory}
                careerInsightsAndGoals={careerInsightsAndGoals}
                setCareerInsightsAndGoals={setCareerInsightsAndGoals}
                preferredLearningFormat={preferredLearningFormat}
                setPreferredLearningFormat={setPreferredLearningFormat}
                timeAvailabilityForEnrichmentActivities={timeAvailabilityForEnrichmentActivities}
                setTimeAvailabilityForEnrichmentActivities={setTimeAvailabilityForEnrichmentActivities}
                budgetForTestPrepAndCollegeVisits={budgetForTestPrepAndCollegeVisits}
                setBudgetForTestPrepAndCollegeVisits={setBudgetForTestPrepAndCollegeVisits}
            />
        ) : yourRole === 'International Dentist' ? (
            <InternationalDentistRoleSpecificInfo
                fullName={fullName}
                setFullName={setFullName}
                contactInformation={contactInformation}
                setContactInformation={setContactInformation}
                country={country}
                setCountry={setCountry}
                stateProvince={stateProvince}
                setStateProvince={setStateProvince}
                city={city}
                setCity={setCity}
                countryOfLicensure={countryOfLicensure}
                setCountryOfLicensure={setCountryOfLicensure}
                yearsOfExperience={yearsOfExperience}
                setYearsOfExperience={setYearsOfExperience}
                areasOfSpecialty={areasOfSpecialty}
                setAreasOfSpecialty={setAreasOfSpecialty}
                currentStageInAccreditationProcess={currentStageInAccreditationProcess}
                setCurrentStageInAccreditationProcess={setCurrentStageInAccreditationProcess}
                destinationCountry={destinationCountry}
                setDestinationCountry={setDestinationCountry}
                examRequirements={examRequirements}
                setExamRequirements={setExamRequirements}
                desiredCareerPath={desiredCareerPath}
                setDesiredCareerPath={setDesiredCareerPath}
                preferredLearningFormat={preferredLearningFormat}
                setPreferredLearningFormat={setPreferredLearningFormat}
                timeAvailabilityForStudyAndPreparation={timeAvailabilityForStudyAndPreparation}
                setTimeAvailabilityForStudyAndPreparation={setTimeAvailabilityForStudyAndPreparation}
                budgetForCourses={budgetForCourses}
                setBudgetForCourses={setBudgetForCourses}
            />
        ) : null,
    ])

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!isLastStep) return next()

        // here start my code
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        let body = {
            your_role: yourRole,
            education: education,
            kind_of_practitioner: kindOfPractitioner,
            your_goals: yourGoals,
            start_year: startYear,
            years_of_completion: yearsOfCompletion,
            ce_credit_passed: cePassed,
            ce_credit_left: ceLeft,
            left_years_for_target_ce: leftYear,
            // ce_category_value: 0,
            ce_category_1: categoryOne,
            ce_category_2: categoryTwo,
            ce_category_3: categoryThree,
            preferred_learning_format: [preferredLearningFormat],
            preferred_topics: [preferredTopics],
            preferred_geo_location_for_events: [preferredGeoLocation],
            ideal_event_scale: [idealEventScale],
            preferred_days_for_attending_events: [preferredDaysAttendEvent],
            willingness_to_travel: willingnessToTravel || '',
            meta: {
                fullName,
                email,
                phone,
                country,
                stateProvince,
                city,
                dentalSchoolName,
                yearInProgram,
                expectedGraduationDate,
                dentalCategory,
                desiredCareerPath,
                knowledgeAreasToDevelop,
                weeklyTimeAvailablility,
                financialBudgetForCourses,
                contactInformation,
                countryOfLicensure,
                yearsOfExperience,
                areasOfSpecialty,
                currentStageInAccreditationProcess,
                destinationCountry,
                examRequirements,
                timeAvailabilityForStudyAndPreparation,
                budgetForCourses,
                currentStudyLevel,
                majorFieldOfStudy,
                relevantCoursesTaken,
                intendedAppYearDentalSchool,
                preparednessLevelAdmissionTest,
                extracurricularActivities,
                careerInsightsAndGoals,
                timeAvailabilityForEnrichmentActivities,
                budgetForTestPrepAndCollegeVisits,
            },
        }

        const getCreatedPractitioner = await createPractitionerProfileAPI(token, body)
        if (getCreatedPractitioner.error === null) {
            toast('Profile successfully created!', {
                icon: '👋',
            })
            window.location.reload()
        } else {
            toast.error('Failed to create profile.')
        }
    }

    return (
        <div className="relative">
            <div className="w-full md:w-7/12 min-h-screen flex justify-center items-center">
                <form
                    onSubmit={onSubmit}
                    className="w-[600px] bg-gray-100 py-12 px-6 md:py-20 md:px-12 rounded-lg m-4 relative">
                    <p className="absolute top-4 left-6 md:top-10 md:left-12 text-sm text-primary-green-1 font-semibold">
                        {currentStepIndex + 1} /{' '}
                        {['Dentist', 'International Dentist', 'Predental Student', 'Dental Student'].indexOf(
                            yourRole,
                        ) === -1
                            ? steps.length - 1
                            : steps.length}
                    </p>
                    {step}
                    <div>
                        <div className="">
                            {!isFirstStep && (
                                <button
                                    type="button"
                                    onClick={back}
                                    className="text-primary-green-1 font-semibold text-xl bg-slate-200 py-2 px-12 block w-full rounded-md mt-12">
                                    Back
                                </button>
                            )}
                            <button
                                type="submit"
                                className="text-white font-semibold text-xl bg-primary-green-1 py-2 px-12 block w-full rounded-md mt-8">
                                {isLastStep ||
                                (['Dentist', 'International Dentist', 'Predental Student', 'Dental Student'].indexOf(
                                    yourRole,
                                ) === -1 &&
                                    steps.length - 2 === currentStepIndex)
                                    ? 'Submit'
                                    : 'Next'}
                            </button>
                            {isLastStep ||
                            (['Dentist', 'International Dentist', 'Predental Student', 'Dental Student'].indexOf(
                                yourRole,
                            ) === -1 &&
                                steps.length - 2 === currentStepIndex) ? null : (
                                <button
                                    type="submit"
                                    className="text-primary-green-1 text-xl font-semibold mx-auto block mt-6">
                                    Skip for now
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
            <div className="fixed w-full md:w-5/12 h-screen right-0 top-0 bg-green-100 md:flex justify-center items-center p-4 hidden">
                <Link to="/" className="absolute left-8 top-8 text-primary-green-1 text-lg font-semibold">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to Main page
                </Link>
                <img src={GoalImage} alt="goal_image" />
            </div>
        </div>
    )
}

export default ProfilePractitionerCreateTemplate
