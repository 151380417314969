import FormWrapper from './FormWrapper'
import { NumberMinMax } from '../../../atoms'

const SetupProfile = ({
    cePassed,
    setCePassed,
    ceLeft,
    setCeLeft,
    startYear,
    setStartYear,
    yearsOfCompletion,
    setYearOfCompletion,
    leftYear,
    setLeftYear,
    yourRole,
    categoryOne,
    categoryTwo,
    categoryThree,
    setCategoryOne,
    setCategoryTwo,
    setCategoryThree,
}) => {
    return (
        <div>
            <FormWrapper title="Setup Your Profile" subtitle="Complete your profile for better experience">
                {yourRole !== 'Dentist' && (
                    <>
                        <NumberMinMax
                            name="passed-ce"
                            label="How many CE credit You have passed?"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                            value={cePassed}
                            setValue={setCePassed}
                            min={0}
                            max={10000}
                        />
                        <NumberMinMax
                            name="left-ce"
                            label="How many CE credit left?"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                            value={ceLeft}
                            setValue={setCeLeft}
                            min={0}
                            max={10000}
                        />
                    </>
                )}
                {yourRole === 'Dentist' && (
                    <>
                        <NumberMinMax
                            name="categoryOne"
                            label="Category One"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                            value={categoryOne}
                            setValue={setCategoryOne}
                            min={0}
                            max={15}
                        />
                        <NumberMinMax
                            name="categoryTwo"
                            label="Category Two"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                            value={categoryTwo}
                            setValue={setCategoryTwo}
                            min={0}
                            max={45}
                        />
                        <NumberMinMax
                            name="categoryThree"
                            label="Category Three"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                            value={categoryThree}
                            setValue={setCategoryThree}
                            min={0}
                            max={30}
                        />
                    </>
                )}
                <NumberMinMax
                    name="start-year"
                    label="Start year"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    min={1900}
                    max={3000}
                    value={startYear}
                    setValue={setStartYear}
                />
                <NumberMinMax
                    name="year-completion"
                    label="Year of Completion"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    min={1900}
                    max={3000}
                    value={yearsOfCompletion}
                    setValue={setYearOfCompletion}
                />
                <NumberMinMax
                    name="target-ce-year"
                    label="How many Years Left to get the Targeted CE?"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={leftYear}
                    setValue={setLeftYear}
                    min={0}
                    max={10000}
                />
            </FormWrapper>
        </div>
    )
}

export default SetupProfile
