import { Link } from 'react-router-dom'
import { AuthTemplate } from '@components/templates/index'
import { SelectRole } from '@components/molecules/index'
import { Header } from '@components/organisms/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const SelectRolePage = () => {
    return (
        <div>
            <Header title="Select Role" description="DentiCE Select role page" />
            <AuthTemplate>
                <Link to="/" className="absolute right-8 top-8 text-primary-green-1 text-sm font-semibold">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to Main page
                </Link>
                <div className="flex justify-center mt-[35%]">
                    <SelectRole />
                </div>
            </AuthTemplate>
        </div>
    )
}

export default SelectRolePage
