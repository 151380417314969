import { getEventInterestedUsersAPI } from '@api/index'
import { useEffect, useState, useRef } from 'react'
import { LocalVar } from '@utils/index'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const InterestedUsers = ({ event_name, event_id }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const eventUsers = async (event_id) => {
            const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

            const usersData = await getEventInterestedUsersAPI(token, event_id)
            if (usersData.error === null) {
                setData(usersData.data.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }

        eventUsers(event_id)
    }, [event_id])

    const tableRef = useRef(null)

    if (data.total_data_count === 0 || loading) return <div></div>

    return (
        <div>
            <h4 className="text-primary-green-2 mb-4">
                People who interested your this event: {data.total_data_count}
            </h4>
            <div className="overflow-y-auto max-h-[300px]">
                <DownloadTableExcel
                    filename={event_name + ' participants data'}
                    sheet="users"
                    currentTableRef={tableRef.current}>
                    <button className="my-4 bg-primary-green-1 text-primary-golden-5 px-4 py-2 rounded-md active:text-primary-green-1 active:bg-white border-2 border-primary-green-1">
                        <FontAwesomeIcon icon={faDownload} /> Download participants data
                    </button>
                </DownloadTableExcel>
                <table className="rounded-sm" ref={tableRef}>
                    <thead className="bg-slate-100">
                        <tr>
                            <th className="border-2 border-slate-200 text-sm p-2 text-primary-green-1">User name</th>
                            <th className="border-2 border-slate-200 text-sm p-2 text-primary-green-1">Email</th>
                            <th className="border-2 border-slate-200 text-sm p-2 text-primary-green-1">Phone</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.data?.map((v, i) => {
                            return (
                                <tr key={i}>
                                    <td className="border-2 border-slate-200 text-sm p-2 min-w-[280px]">
                                        {v.full_name}
                                    </td>
                                    <td className="border-2 border-slate-200 text-sm p-2 min-w-[280px]">
                                        {v.email_address}
                                    </td>
                                    <td className="border-2 border-slate-200 text-sm p-2 min-w-[280px]">
                                        {v.phone_number_prefix}
                                        {v.phone_number}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default InterestedUsers
