import { useState } from 'react'
import toast from 'react-hot-toast'
import { v4 as uuidv4 } from 'uuid'
import { Input, Button } from '@components/atoms/index'
import { updateUserPasswordAPI } from '@api/index'
import { LocalVar } from '@utils/index'

const ChangePassword = () => {
    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [confirmPass, setConfirmPass] = useState('')

    const [loading, setLoadig] = useState(false)

    const [key1, setKey1] = useState(uuidv4())
    const [key2, setKey2] = useState(uuidv4())
    const [key3, setKey3] = useState(uuidv4())

    const clearInput = () => {
        setOldPass('')
        setNewPass('')
        setConfirmPass('')
        setKey1(uuidv4())
        setKey2(uuidv4())
        setKey3(uuidv4())
    }

    const submit = async (e) => {
        e.preventDefault()
        setLoadig(true)

        if (newPass !== confirmPass) {
            toast.error('Password not confirmed!')
            return
        }

        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const changePassword = await updateUserPasswordAPI(token, {
            old_secret: oldPass,
            new_secret: newPass,
        })

        if (changePassword.error === null) {
            toast.success('Your password successfully changed!')
            setLoadig(false)
        } else {
            toast.error('Unexpected error occured!')
            setLoadig(false)
        }

        clearInput()
    }

    return (
        <div>
            <form onSubmit={submit}>
                <Input
                    name="old-password"
                    label="Old Password"
                    type="password"
                    eyeIconClass="cursor-pointer absolute right-4 top-12"
                    placeHolder="Your password"
                    required={true}
                    setValue={setOldPass}
                    key={key1}
                />
                <Input
                    name="new-password"
                    label="New Password"
                    type="password"
                    eyeIconClass="cursor-pointer absolute right-4 top-12"
                    placeHolder="Your new password"
                    required={true}
                    setValue={setNewPass}
                    key={key2}
                />
                <Input
                    name="cnf-password"
                    label="Confirm Password"
                    type="password"
                    eyeIconClass="cursor-pointer absolute right-4 top-12"
                    placeHolder="Confirm password"
                    required={true}
                    setValue={setConfirmPass}
                    key={key3}
                />

                <Button loading={loading}>Change</Button>
            </form>
        </div>
    )
}

export default ChangePassword
