import toast from 'react-hot-toast'
import { useState, useEffect } from 'react'
import { AuthTemplate } from '@components/templates/index'
import { ProjectVar, ProfileVar, LocalVar } from '@utils/index'
import { setRoleAPI } from '@api/index'
import { Link } from 'react-router-dom'
import { Header } from '@components/organisms/index'
import { getUserRolesAPI } from '@api/index'
import { Loading } from '@components/atoms/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { SelectRolePage } from '.'

const SetRole = () => {
    const [haveRoles, setHaveRoles] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)

        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        ;(async () => {
            const getRoles = await getUserRolesAPI(token)
            if (getRoles.error === null) {
                if (getRoles.data.role.length !== 0) {
                    setHaveRoles(true)
                }
                setLoading(false)
            } else {
                setLoading(false)
            }
        })()
    }, [])

    const handleRole = async (role) => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        let body = {
            role: [role],
        }

        const getRole = await setRoleAPI(token, body)
        if (getRole.error === null) {
            toast('Role successfully created!', {
                icon: '👋',
            })
            window.location.reload()
        } else {
            toast.error('Failed to create role.')
        }
    }

    if (loading) return <Loading />

    if (haveRoles) return <SelectRolePage />

    return (
        <div className="relative">
            <Header title="Set Role" description="DentiCE Set role page" />
            <AuthTemplate>
                <Link to="/" className="absolute right-8 top-8 text-primary-green-1 text-sm font-semibold">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to Main page
                </Link>
                <div className="flex justify-center mt-[35%]">
                    <div>
                        <h1 className="text-center">Join {ProjectVar.PROJECT_NAME} as</h1>
                        <div className="block bg-slate-300 rounded-lg md:rounded-full p-4 md:p-0 mt-8">
                            <span
                                onClick={() => handleRole(ProfileVar.PRACTITIONER)}
                                className=" block md:inline-block text-center cursor-pointer text-gray-600 mx-2 rounded-full px-6 py-2 hover:bg-primary-green-1 hover:text-white text-lg font-semibold">
                                Practitioner
                            </span>
                            <span
                                onClick={() => handleRole(ProfileVar.ORGANIZER)}
                                className=" block md:inline-block text-center cursor-pointer text-gray-600 mx-2 rounded-full px-6 py-2 hover:bg-primary-green-1 hover:text-white text-lg font-semibold">
                                Organizer
                            </span>
                            <span
                                onClick={() => handleRole(ProfileVar.SPONSOR)}
                                className=" block md:inline-block text-center cursor-pointer text-gray-600 mx-2 rounded-full px-6 py-2 hover:bg-primary-green-1 hover:text-white text-lg font-semibold">
                                Sponsor
                            </span>
                            <span
                                onClick={() => handleRole(ProfileVar.SPEAKER)}
                                className=" block md:inline-block text-center cursor-pointer text-gray-600 mx-2 rounded-full px-6 py-2 hover:bg-primary-green-1 hover:text-white text-lg font-semibold">
                                Speaker
                            </span>
                            {/* <span className=" block md:inline-block text-center cursor-not-allowed text-gray-600 mx-2 rounded-full px-6 py-2 hover:bg-primary-green-1 hover:text-white text-lg font-semibold">
                                Educator
                            </span> */}
                        </div>
                    </div>
                </div>
            </AuthTemplate>
        </div>
    )
}

export default SetRole
