import { SideBar } from '@components/molecules/index'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserProfile } from '@components/molecules/index'
import { useContext, useEffect, useState } from 'react'
import { Button, DropDownWithField, Input, NumberMinMax } from '@components/atoms'
import { v4 as uuidv4 } from 'uuid'
import { getPractitionerProfileAPI, updatePractitionerProfileAPI } from '@api/practitionerApi'
import { dentalCategoryOptions, LocalVar } from '@utils/variables'
import toast from 'react-hot-toast'
import { Auth } from '@context/authContext'
import WhatKindOfPractiotioner from '@components/molecules/Forms/PracttionerCreate/WhatKindOfPractitioner'
import { YourGoals } from '@components/molecules/Forms/PracttionerCreate'
import { CitySelect, CountrySelect, StateSelect } from 'react-country-state-city/dist/cjs'

const roleOptions = [
    { text: 'Dentist', value: 'Dentist' },
    { text: 'International Dentist', value: 'International Dentist' },
    { text: 'Predental Student', value: 'Predental Student' },
    { text: 'Dental Student', value: 'Dental Student' },
    { text: 'Dental Assistant', value: 'Dental Assistant' },
    { text: 'Dental Hygenist', value: 'Dental Hygenist' },
    { text: 'Dental Admin', value: 'Dental Admin' },
    { text: 'Associate Dentist', value: 'Associate Dentist' },
]
const preferredLearningFormatsOptions = [
    { text: 'Online', value: 'Online' },
    { text: 'In-Person', value: 'In-Person' },
    { text: 'Workshops', value: 'Workshops' },
]
const idealEventScaleOptions = [
    { text: 'Local', value: 'Local' },
    { text: 'National', value: 'National' },
    { text: 'International', value: 'International' },
]

const currentStageInAccreditationProcessOptions = [
    { text: 'Studying for Board Exams', value: 'Studying for Board Exams' },
    { text: 'Waiting for exam results', value: 'Waiting for exam results' },
]
const destinationCountryOptions = [
    { text: 'Canada ', value: 'Canada ' },
    { text: 'U.S.', value: 'U.S.' },
]
const examRequirementsOptions = [
    { text: 'National Board Dental Examination Part I', value: 'National Board Dental Examination Part I' },
    { text: 'National Board Dental Examination Part II', value: 'National Board Dental Examination Part II' },
    { text: 'AFK', value: 'AFK' },
]
const yearInProgramOptions = [
    { text: '1st Year', value: '1st Year' },
    { text: '2nd Year', value: '2nd Year' },
    { text: '3rd Year', value: '3rd Year' },
    { text: '4th Year', value: '4th Year' },
    { text: '5th Year', value: '5th Year' },
    { text: '6th Year', value: '6th Year' },
]
const desiredCareerPathOptions = [
    { text: 'Private Practice', value: 'Private Practice' },
    { text: 'Academic', value: 'Academic' },
    { text: 'Research', value: 'Research' },
]
const currentStudyLevelOptions = [
    { text: ' High School', value: ' High School' },
    { text: 'Undergraduate', value: 'Undergraduate' },
]
const relevantCoursesTakenOptions = [
    { text: 'Private Practice', value: 'Private Practice' },
    { text: 'Academic', value: 'Academic' },
    { text: 'Research', value: 'Research' },
]
const preparednessLevelForAdmissionTestsOptions = [{ text: 'DAT in the U.S.', value: 'DAT in the U.S.' }]

const extracurricularActivitiesOptions = [{ text: 'Pre-Dental Society Member', value: 'Pre-Dental Society Member' }]

const PractitionerProfileTemplate = ({ userData, profileData }) => {
    const { state } = useContext(Auth)
    const [loading, setLoading] = useState(false)
    const [isEditable, setIsEditable] = useState(false)
    const [practitionerData, setPractitionerData] = useState({})
    const [education, setEducation] = useState('')
    const [startYear, setStartYear] = useState('')
    const [yearsOfCompletion, setYearsOfCompletion] = useState('')
    const [yourRole, setYourRole] = useState('')
    const [ceCreditPassed, setCeCreditPassed] = useState('')
    const [ceCreditLeft, setCeCreditLeft] = useState('')
    const [leftYearsForTargetCe, setLeftYearsForTargetCe] = useState('')
    const [categoryOne, setCategoryOne] = useState('')
    const [categoryTwo, setCategoryTwo] = useState('')
    const [categoryThree, setCategoryThree] = useState('')
    const [CECreditValue, setCECreditValue] = useState('')
    const [kindOfPractitioner, setKindOfPractitioner] = useState('')
    const [yourGoals, setYourGoals] = useState('')
    const [key1, setKey1] = useState(uuidv4())
    const [key2, setKey2] = useState(uuidv4())
    const [key3, setKey3] = useState(uuidv4())
    const [key4, setKey4] = useState(uuidv4())
    const [key5, setKey5] = useState(uuidv4())
    const [key6, setKey6] = useState(uuidv4())
    //Dentist Role Specific Data
    const [preferredLearningFormat, setPreferredLearningFormat] = useState([''])
    const [preferredTopics, setPreferredTopics] = useState([''])
    const [preferredGeoLocation, setPreferredGeoLocation] = useState([''])
    const [idealEventScale, setIdealEventScale] = useState([''])
    const [preferredDaysAttendEvent, setPreferredDaysAttendEvent] = useState([''])
    const [willingnessToTravel, setWillingnessToTravel] = useState('')

    //Dental Student Role Specific Data
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [country, setCountry] = useState('')
    const [stateProvince, setStateProvince] = useState('')
    const [city, setCity] = useState('')
    const [dentalSchoolName, setDentalSchoolName] = useState('')
    const [yearInProgram, setYearInProgram] = useState('')
    const [expectedGraduationDate, setExpectedGraduationDate] = useState('')
    const [dentalCategory, setDentalCategory] = useState('')
    const [desiredCareerPath, setDesiredCareerPath] = useState('')
    const [knowledgeAreasToDevelop, setKnowledgeAreasToDevelop] = useState('')
    // preferredLearningFormats resued from Dentist Role
    const [weeklyTimeAvailablility, setWeeklyTimeAvailablility] = useState('')
    const [financialBudgetForCourses, setFinancialBudgetForCourses] = useState('')

    //Internal Dentist Role Specific Data
    //fullName reused from Dental Student Role
    const [contactInformation, setContactInformation] = useState('')
    //country, stateProvince, city reused from Dental Student Role
    const [countryOfLicensure, setCountryOfLicensure] = useState('')
    const [yearsOfExperience, setYearsOfExperience] = useState('')
    const [areasOfSpecialty, setAreasOfSpecialty] = useState('')
    const [currentStageInAccreditationProcess, setCurrentStageInAccreditationProcess] = useState('')
    const [destinationCountry, setDestinationCountry] = useState('')
    const [examRequirements, setExamRequirements] = useState('')
    //desiredCareerPath reused from Dental Student Role
    //preferredLearningFormats resued from Dentist Role
    const [timeAvailabilityForStudyAndPreparation, setTimeAvailabilityForStudyAndPreparation] = useState('')
    const [budgetForCourses, setBudgetForCourses] = useState('')

    //Predental Student Role Specific Data
    const [currentStudyLevel, setCurrentStudyLevel] = useState('')
    const [majorFieldOfStudy, setMajorFieldOfStudy] = useState('')
    const [relevantCoursesTaken, setRelevantCoursesTaken] = useState('')
    const [intendedAppYearDentalSchool, setIntendedAppYearDentalSchool] = useState('')
    const [preparednessLevelAdmissionTest, setPreparednessLevelAdmissionTest] = useState('')
    const [extracurricularActivities, setExtracurricularActivities] = useState('')
    //dentalCategory reused from Dental Student Role
    const [careerInsightsAndGoals, setCareerInsightsAndGoals] = useState('')
    // preferredLearningFormats resued from Dentist Role
    const [timeAvailabilityForEnrichmentActivities, setTimeAvailabilityForEnrichmentActivities] = useState('')
    const [budgetForTestPrepAndCollegeVisits, setBudgetForTestPrepAndCollegeVisits] = useState('')
    const [stateid, setstateid] = useState(0)
    const [countryEmoji, setCountryEmoji] = useState('🇨🇦')
    const [countryid, setCountryid] = useState(39)

    const clearField = () => {
        setEducation('')
        setStartYear('')
        setYearsOfCompletion('')
        setCeCreditPassed('')
        setCeCreditLeft('')
        setLeftYearsForTargetCe('')
        setKey1(uuidv4())
        setKey2(uuidv4())
        setKey3(uuidv4())
        setKey4(uuidv4())
        setKey5(uuidv4())
        setKey6(uuidv4())
    }

    useEffect(() => {
        ;(async () => {
            const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
            let getPractitionerData = await getPractitionerProfileAPI(token, state?.auth?.entity_id)
            if (getPractitionerData.error === null) {
                setPractitionerData(getPractitionerData?.data?.data)
                setEducation(getPractitionerData?.data?.data?.education)
                setStartYear(getPractitionerData?.data?.data?.start_year)
                setYearsOfCompletion(getPractitionerData?.data?.data?.years_of_completion)
                setYourRole(getPractitionerData?.data?.data?.your_role)
                setCeCreditPassed(getPractitionerData?.data?.data?.ce_credit_passed)
                setCeCreditLeft(getPractitionerData?.data?.data?.ce_credit_left)
                setLeftYearsForTargetCe(getPractitionerData?.data?.data?.left_years_for_target_ce)
                setYourGoals(getPractitionerData?.data?.data?.your_goals)
                setKindOfPractitioner(getPractitionerData?.data?.data?.kind_of_practitioner)
                setCategoryOne(getPractitionerData?.data?.data?.ce_category_1 || 0)
                setCategoryTwo(getPractitionerData?.data?.data?.ce_category_2 || 0)
                setCategoryThree(getPractitionerData?.data?.data?.ce_category_3 || 0)

                setPreferredLearningFormat(getPractitionerData?.data?.data?.preferred_learning_format || '')
                setPreferredTopics(getPractitionerData?.data?.data?.preferred_topics || '')
                setPreferredGeoLocation(getPractitionerData?.data?.data?.preferred_geo_location_for_events || '')
                setIdealEventScale(getPractitionerData?.data?.data?.ideal_event_scale || '')
                setPreferredDaysAttendEvent(getPractitionerData?.data?.data?.preferred_days_for_attending_events || '')
                setWillingnessToTravel(getPractitionerData?.data?.data?.willingness_to_travel || '')

                setFullName(getPractitionerData?.data?.data?.meta?.fullName || '')
                setEmail(getPractitionerData?.data?.data?.meta?.email || '')
                setPhone(getPractitionerData?.data?.data?.meta?.phone || '')
                setCountry(getPractitionerData?.data?.data?.meta?.country || '')
                setStateProvince(getPractitionerData?.data?.data?.meta?.stateProvince || '')
                setCity(getPractitionerData?.data?.data?.meta?.city || '')
                setDentalSchoolName(getPractitionerData?.data?.data?.meta?.dentalSchoolName || '')
                setYearInProgram(getPractitionerData?.data?.data?.meta?.yearInProgram || '')
                setExpectedGraduationDate(getPractitionerData?.data?.data?.meta?.expectedGraduationDate || '')
                setDentalCategory(getPractitionerData?.data?.data?.meta?.dentalCategory || '')
                setDesiredCareerPath(getPractitionerData?.data?.data?.meta?.desiredCareerPath || '')
                setKnowledgeAreasToDevelop(getPractitionerData?.data?.data?.meta?.knowledgeAreasToDevelop || '')
                setWeeklyTimeAvailablility(getPractitionerData?.data?.data?.meta?.weeklyTimeAvailablility || '')
                setFinancialBudgetForCourses(getPractitionerData?.data?.data?.meta?.financialBudgetForCourses || '')

                setContactInformation(getPractitionerData?.data?.data?.meta?.contactInformation || '')
                setCountryOfLicensure(getPractitionerData?.data?.data?.meta?.countryOfLicensure || '')
                setYearsOfExperience(getPractitionerData?.data?.data?.meta?.yearsOfExperience || '')
                setAreasOfSpecialty(getPractitionerData?.data?.data?.meta?.areasOfSpecialty || '')
                setCurrentStageInAccreditationProcess(
                    getPractitionerData?.data?.data?.meta?.currentStageInAccreditationProcess || '',
                )
                setDestinationCountry(getPractitionerData?.data?.data?.meta?.destinationCountry || '')
                setExamRequirements(getPractitionerData?.data?.data?.meta?.examRequirements || '')
                setTimeAvailabilityForStudyAndPreparation(
                    getPractitionerData?.data?.data?.meta?.timeAvailabilityForStudyAndPreparation || '',
                )
                setBudgetForCourses(getPractitionerData?.data?.data?.meta?.budgetForCourses || '')

                setCurrentStudyLevel(getPractitionerData?.data?.data?.meta?.currentStudyLevel || '')
                setMajorFieldOfStudy(getPractitionerData?.data?.data?.meta?.majorFieldOfStudy || '')
                setRelevantCoursesTaken(getPractitionerData?.data?.data?.meta?.relevantCoursesTaken || '')
                setIntendedAppYearDentalSchool(getPractitionerData?.data?.data?.meta?.intendedAppYearDentalSchool || '')
                setPreparednessLevelAdmissionTest(
                    getPractitionerData?.data?.data?.meta?.preparednessLevelAdmissionTest || '',
                )
                setExtracurricularActivities(getPractitionerData?.data?.data?.meta?.extracurricularActivities || '')
                setCareerInsightsAndGoals(getPractitionerData?.data?.data?.meta?.careerInsightsAndGoals || '')
                setTimeAvailabilityForEnrichmentActivities(
                    getPractitionerData?.data?.data?.meta?.timeAvailabilityForEnrichmentActivities || '',
                )
                setBudgetForTestPrepAndCollegeVisits(
                    getPractitionerData?.data?.data?.meta?.budgetForTestPrepAndCollegeVisits || '',
                )
            }
        })()
    }, [state])

    const submit = async (e) => {
        e.preventDefault()

        setLoading(true)

        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        let body = {
            your_role: practitionerData?.your_role,
            education: practitionerData?.education,
            kind_of_practitioner: practitionerData?.kind_of_practitioner,
            your_goals: practitionerData?.your_goals,
            start_year: practitionerData?.start_year,
            years_of_completion: practitionerData?.years_of_completion,
            ce_credit_passed: practitionerData?.ce_credit_passed,
            ce_credit_left: practitionerData?.ce_credit_left,
            left_years_for_target_ce: practitionerData?.left_years_for_target_ce,
            // ce_category_value: practitionerData?.ce_category_value || 0,
            ce_category_1: practitionerData?.ce_category_1 || 0,
            ce_category_2: practitionerData?.ce_category_2 || 0,
            ce_category_3: practitionerData?.ce_category_3 || 0,
            preferred_learning_format: practitionerData?.preferred_learning_format || [''],
            preferred_topics: practitionerData?.preferred_topics || 0,
            preferred_geo_location_for_events: practitionerData?.preferred_geo_location_for_events || [''],
            ideal_event_scale: practitionerData?.ideal_event_scale || [''],
            preferred_days_for_attending_events: practitionerData?.preferred_days_for_attending_events || [''],
            willingness_to_travel: practitionerData?.willingness_to_travel || '',
            meta: {
                fullName: practitionerData?.meta?.fullName || '',
                email: practitionerData?.meta?.email || '',
                phone: practitionerData?.meta?.phone || '',
                country: practitionerData?.meta?.country || '',
                stateProvince: practitionerData?.meta?.stateProvince || '',
                city: practitionerData?.meta?.city || '',
                dentalSchoolName: practitionerData?.meta?.dentalSchoolName || '',
                yearInProgram: practitionerData?.meta?.yearInProgram || '',
                expectedGraduationDate: practitionerData?.meta?.expectedGraduationDate || '',
                dentalCategory: practitionerData?.meta?.dentalCategory || '',
                desiredCareerPath: practitionerData?.meta?.desiredCareerPath || '',
                knowledgeAreasToDevelop: practitionerData?.meta?.knowledgeAreasToDevelop || '',
                weeklyTimeAvailablility: practitionerData?.meta?.weeklyTimeAvailablility || '',
                financialBudgetForCourses: practitionerData?.meta?.financialBudgetForCourses || '',
                contactInformation: practitionerData?.meta?.contactInformation || '',
                countryOfLicensure: practitionerData?.meta?.countryOfLicensure || '',
                yearsOfExperience: practitionerData?.meta?.yearsOfExperience || '',
                areasOfSpecialty: practitionerData?.meta?.areasOfSpecialty || '',
                currentStageInAccreditationProcess: practitionerData?.meta?.currentStageInAccreditationProcess || '',
                destinationCountry: practitionerData?.meta?.destinationCountry || '',
                examRequirements: practitionerData?.meta?.examRequirements || '',
                timeAvailabilityForStudyAndPreparation:
                    practitionerData?.meta?.timeAvailabilityForStudyAndPreparation || '',
                budgetForCourses: practitionerData?.meta?.budgetForCourses || '',
                currentStudyLevel: practitionerData?.meta?.currentStudyLevel || '',
                majorFieldOfStudy: practitionerData?.meta?.majorFieldOfStudy || '',
                relevantCoursesTaken: practitionerData?.meta?.relevantCoursesTaken || '',
                intendedAppYearDentalSchool: practitionerData?.meta?.intendedAppYearDentalSchool || '',
                preparednessLevelAdmissionTest: practitionerData?.meta?.preparednessLevelAdmissionTest || '',
                extracurricularActivities: practitionerData?.meta?.extracurricularActivities || '',
                careerInsightsAndGoals: practitionerData?.meta?.careerInsightsAndGoals || '',
                timeAvailabilityForEnrichmentActivities:
                    practitionerData?.meta?.timeAvailabilityForEnrichmentActivities || '',
                budgetForTestPrepAndCollegeVisits: practitionerData?.meta?.budgetForTestPrepAndCollegeVisits || '',
            },
        }

        if (yourRole.length !== 0) {
            body['your_role'] = yourRole
        }

        if (education.length !== 0) {
            body['education'] = education
        }

        if (startYear.length !== 0) {
            body['start_year'] = startYear
        }

        if (yearsOfCompletion.length !== 0) {
            body['years_of_completion'] = yearsOfCompletion
        }

        if (ceCreditPassed.length !== 0) {
            body['ce_credit_passed'] = Number(ceCreditPassed)
        }

        if (ceCreditLeft.length !== 0) {
            body['ce_credit_left'] = ceCreditLeft
        }

        if (leftYearsForTargetCe.length !== 0) {
            body['left_years_for_target_ce'] = leftYearsForTargetCe
        }

        if (categoryOne.length !== 0) {
            body['ce_category_1'] = Number(categoryOne || 0)
        }

        if (categoryTwo.length !== 0) {
            body['ce_category_2'] = Number(categoryTwo || 0)
        }

        if (categoryThree.length !== 0) {
            body['ce_category_3'] = Number(categoryThree || 0)
        }

        if (kindOfPractitioner.length !== 0) {
            body['kind_of_practitioner'] = kindOfPractitioner
        }

        if (yourGoals.length !== 0) {
            body['your_goals'] = yourGoals
        }
        // New Blc St
        if (preferredLearningFormat.length !== 0) {
            body['preferred_learning_format'] = Array.isArray(preferredLearningFormat)
                ? [...preferredLearningFormat]
                : [preferredLearningFormat]
        }
        if (preferredTopics.length !== 0) {
            body['preferred_topics'] = Array.isArray(preferredTopics) ? [...preferredTopics] : [preferredTopics]
        }
        if (preferredGeoLocation.length !== 0) {
            body['preferred_geo_location_for_events'] = Array.isArray(preferredGeoLocation)
                ? [...preferredGeoLocation]
                : [preferredGeoLocation]
        }
        if (idealEventScale.length !== 0) {
            body['ideal_event_scale'] = Array.isArray(idealEventScale) ? [...idealEventScale] : [idealEventScale]
        }
        if (preferredDaysAttendEvent.length !== 0) {
            body['preferred_days_for_attending_events'] = Array.isArray(preferredDaysAttendEvent)
                ? [...preferredDaysAttendEvent]
                : [preferredDaysAttendEvent]
        }
        if (willingnessToTravel.length !== 0) {
            body['willingness_to_travel'] = willingnessToTravel
        }

        if (fullName.length !== 0) {
            body['meta']['fullName'] = fullName
        }
        if (contactInformation.length !== 0) {
            body['meta']['contactInformation'] = contactInformation
        }
        if (country.length !== 0) {
            body['meta']['country'] = country
        }
        if (stateProvince.length !== 0) {
            body['meta']['stateProvince'] = stateProvince
        }
        if (city.length !== 0) {
            body['meta']['city'] = city
        }
        if (countryOfLicensure.length !== 0) {
            body['meta']['countryOfLicensure'] = countryOfLicensure
        }
        if (yearsOfExperience.length !== 0) {
            body['meta']['yearsOfExperience'] = yearsOfExperience
        }
        if (areasOfSpecialty.length !== 0) {
            body['meta']['areasOfSpecialty'] = areasOfSpecialty
        }
        if (currentStageInAccreditationProcess.length !== 0) {
            body['meta']['currentStageInAccreditationProcess'] = currentStageInAccreditationProcess
        }
        if (destinationCountry.length !== 0) {
            body['meta']['destinationCountry'] = destinationCountry
        }
        if (examRequirements.length !== 0) {
            body['meta']['examRequirements'] = examRequirements
        }
        if (desiredCareerPath.length !== 0) {
            body['meta']['desiredCareerPath'] = desiredCareerPath
        }
        if (timeAvailabilityForStudyAndPreparation.length !== 0) {
            body['meta']['timeAvailabilityForStudyAndPreparation'] = timeAvailabilityForStudyAndPreparation
        }
        if (budgetForCourses.length !== 0) {
            body['meta']['budgetForCourses'] = budgetForCourses
        }

        if (currentStudyLevel.length !== 0) {
            body['meta']['currentStudyLevel'] = currentStudyLevel
        }
        if (majorFieldOfStudy.length !== 0) {
            body['meta']['majorFieldOfStudy'] = majorFieldOfStudy
        }
        if (relevantCoursesTaken.length !== 0) {
            body['meta']['relevantCoursesTaken'] = relevantCoursesTaken
        }
        if (intendedAppYearDentalSchool.length !== 0) {
            body['meta']['intendedAppYearDentalSchool'] = intendedAppYearDentalSchool
        }
        if (preparednessLevelAdmissionTest.length !== 0) {
            body['meta']['preparednessLevelAdmissionTest'] = preparednessLevelAdmissionTest
        }
        if (extracurricularActivities.length !== 0) {
            body['meta']['extracurricularActivities'] = extracurricularActivities
        }
        if (dentalCategory.length !== 0) {
            body['meta']['dentalCategory'] = dentalCategory
        }
        if (careerInsightsAndGoals.length !== 0) {
            body['meta']['careerInsightsAndGoals'] = careerInsightsAndGoals
        }
        if (timeAvailabilityForEnrichmentActivities.length !== 0) {
            body['meta']['timeAvailabilityForEnrichmentActivities'] = timeAvailabilityForEnrichmentActivities
        }
        if (budgetForTestPrepAndCollegeVisits.length !== 0) {
            body['meta']['budgetForTestPrepAndCollegeVisits'] = budgetForTestPrepAndCollegeVisits
        }

        if (fullName.length !== 0) {
            body['meta']['fullName'] = fullName
        }
        if (email.length !== 0) {
            body['meta']['email'] = email
        }
        if (phone.length !== 0) {
            body['meta']['phone'] = phone
        }
        if (country.length !== 0) {
            body['meta']['country'] = country
        }
        if (stateProvince.length !== 0) {
            body['meta']['stateProvince'] = stateProvince
        }
        if (city.length !== 0) {
            body['meta']['city'] = city
        }
        if (dentalSchoolName.length !== 0) {
            body['meta']['dentalSchoolName'] = dentalSchoolName
        }
        if (yearInProgram.length !== 0) {
            body['meta']['yearInProgram'] = yearInProgram
        }
        if (expectedGraduationDate.length !== 0) {
            body['meta']['expectedGraduationDate'] = expectedGraduationDate
        }
        if (dentalCategory.length !== 0) {
            body['meta']['dentalCategory'] = dentalCategory
        }
        if (desiredCareerPath.length !== 0) {
            body['meta']['desiredCareerPath'] = desiredCareerPath
        }
        if (knowledgeAreasToDevelop.length !== 0) {
            body['meta']['knowledgeAreasToDevelop'] = knowledgeAreasToDevelop
        }
        if (weeklyTimeAvailablility.length !== 0) {
            body['meta']['weeklyTimeAvailablility'] = weeklyTimeAvailablility
        }
        if (financialBudgetForCourses.length !== 0) {
            body['meta']['financialBudgetForCourses'] = financialBudgetForCourses
        }
        // New Blc Ed

        const updateData = await updatePractitionerProfileAPI(token, body)

        if (updateData.error === null) {
            toast.success('Practitioner data updated!')
            setLoading(false)
            window.location.reload()
        } else {
            toast.error('Unexpected error occured!')
            setLoading(false)
        }

        clearField()
    }

    return (
        <div>
            <SideBar>
                <div className="pt-12 px-4">
                    <h2 className="mb-6">Profile</h2>
                    <div className="bg-white rounded-md p-8 shadow-sm">
                        <UserProfile
                            data={{ ...userData, completeness: profileData.completeness }}
                            isEditable={isEditable}
                            setIsEditable={setIsEditable}
                        />
                    </div>
                    {!isEditable && (
                        <div className="bg-white rounded-md p-8 shadow-sm mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <p className="text-gray-600 font-semibold py-2">
                                    Education: <span className="font-medium">{profileData.profile.education}</span>
                                </p>
                                <p className="text-gray-600 font-semibold py-2">
                                    Start year: <span className="font-medium">{profileData.profile.start_year}</span>
                                </p>
                                <p className="text-gray-600 font-semibold py-2">
                                    Years of completion:{' '}
                                    <span className="font-medium">{profileData.profile.years_of_completion}</span>
                                </p>
                            </div>
                            <div>
                                <p className="text-gray-600 font-semibold py-2">
                                    Your role: <span className="font-medium">{profileData.profile.your_role}</span>
                                </p>
                                {yourRole.toLowerCase() !== 'dentist' && (
                                    <>
                                        <p className="text-gray-600 font-semibold py-2">
                                            CE credit left:{' '}
                                            <span className="font-medium">{profileData.profile?.ce_credit_left}</span>
                                        </p>
                                        <p className="text-gray-600 font-semibold py-2">
                                            CE credit passed:{' '}
                                            <span className="font-medium">{profileData.profile?.ce_credit_passed}</span>
                                        </p>
                                        <p className="text-gray-600 font-semibold py-2">
                                            Left years for target CE:{' '}
                                            <span className="font-medium">
                                                {profileData.profile.left_years_for_target_ce}
                                            </span>
                                        </p>
                                    </>
                                )}
                                {yourRole.toLowerCase() === 'dentist' && (
                                    <>
                                        <p className="text-gray-600 font-semibold py-2">
                                            Category 1:{' '}
                                            <span className="font-medium">
                                                {profileData?.profile?.ce_category_1 || 0}/15 points
                                            </span>
                                        </p>
                                        <p className="text-gray-600 font-semibold py-2">
                                            Category 2:{' '}
                                            <span className="font-medium">
                                                {profileData?.profile?.ce_category_2 || 0}/45 points
                                            </span>
                                        </p>
                                        <p className="text-gray-600 font-semibold py-2">
                                            Category 3:{' '}
                                            <span className="font-medium">
                                                {profileData?.profile.ce_category_3 || 0}/30 points
                                            </span>
                                        </p>
                                        <p className="text-gray-600 font-semibold py-2">
                                            Total:{' '}
                                            <span className="font-medium">
                                                {profileData?.profile?.ce_category_1 +
                                                    profileData?.profile?.ce_category_2 +
                                                    profileData?.profile?.ce_category_3}
                                                /90 points
                                            </span>
                                        </p>
                                    </>
                                )}
                            </div>
                            {/* Dentist role new fields - 2 Cols */}
                            {yourRole.toLowerCase() === 'dentist' && (
                                <div>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Preferred Learning Formats :{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.preferred_learning_format || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Preferred Topics/Fields of Interest :{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.preferred_topics || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Preferred Geo Location :{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.preferred_geo_location_for_events || ''}
                                        </span>
                                    </p>
                                </div>
                            )}
                            {yourRole.toLowerCase() === 'dentist' && (
                                <div>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Ideal event scale:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.ideal_event_scale || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Preferred days for attending events:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.preferred_days_for_attending_events || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Willingness to Travel:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.willingness_to_travel || ''}
                                        </span>
                                    </p>
                                </div>
                            )}
                            {/* International Dentist role new fields - 2 Cols */}
                            {yourRole.toLowerCase() === 'international dentist' && (
                                <div>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Full Name :{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.fullName || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Contact Information:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.contactInformation || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Current Location :{' '}
                                        <span className="font-medium">
                                            {`${
                                                (profileData?.profile?.meta?.city || '',
                                                profileData?.profile?.meta?.stateProvince || '',
                                                profileData?.profile?.meta?.country || '')
                                            }`}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Country of Licensure:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.countryOfLicensure || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Years of Experience :{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.yearsOfExperience || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Areas of Specialty:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.areasOfSpecialty || ''}
                                        </span>
                                    </p>
                                </div>
                            )}
                            {yourRole.toLowerCase() === 'international dentist' && (
                                <div>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Current Stage in Accreditation Process:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.currentStageInAccreditationProcess || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Destination Country:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.destinationCountry || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Exam Requirements:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.examRequirements || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Desired Career Path:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.desiredCareerPath || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Preferred Learning Formats:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.preferred_learning_format || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Time Availability for Study and Preparation:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.timeAvailabilityForStudyAndPreparation || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Budget for Courses/Resources:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.budgetForCourses || ''}
                                        </span>
                                    </p>
                                </div>
                            )}
                            {/* Predental Student role new fields - 2 Cols */}
                            {yourRole.toLowerCase() === 'predental student' && (
                                <div>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Current Study Level :{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.currentStudyLevel || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Major/Field of Study:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.majorFieldOfStudy || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Relevant Courses Taken :{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.relevantCoursesTaken || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Intended Application Year for Dental School:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.intendedAppYearDentalSchool || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Preparedness Level for Admission Tests :{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.preparednessLevelAdmissionTest || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Extracurricular Activities:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.extracurricularActivities || ''}
                                        </span>
                                    </p>
                                </div>
                            )}
                            {yourRole.toLowerCase() === 'predental student' && (
                                <div>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Areas of Interest in Dentistry:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.dentalCategory || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Career Insights and Goals:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.careerInsightsAndGoals || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Preferred Learning Formats:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.preferred_learning_format || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Time Availability for Enrichment Activities:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.timeAvailabilityForEnrichmentActivities || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Budget for Test Prep and College Visits:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.budgetForTestPrepAndCollegeVisits || ''}
                                        </span>
                                    </p>
                                </div>
                            )}
                            {/* Dental Student role new fields - 2 Cols */}
                            {yourRole.toLowerCase() === 'dental student' && (
                                <div>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Full Name :{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.fullName || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Contact Information:{' '}
                                        <span className="font-medium">
                                            {`${
                                                (profileData?.profile?.meta?.email || '',
                                                profileData?.profile?.meta?.phone || '')
                                            }`}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Location :{' '}
                                        <span className="font-medium">
                                            {`${
                                                (profileData?.profile?.meta?.city || '',
                                                profileData?.profile?.meta?.stateProvince || '',
                                                profileData?.profile?.meta?.country || '')
                                            }`}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Dental School Name:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.dentalSchoolName || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Year in Program :{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.yearInProgram || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Expected Graduation Date:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.expectedGraduationDate || ''}
                                        </span>
                                    </p>
                                </div>
                            )}
                            {yourRole.toLowerCase() === 'dental student' && (
                                <div>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Areas of Interest:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.dentalCategory || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Desired Career Path:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.desiredCareerPath || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Specific Skills or Knowledge Areas to Develop:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.knowledgeAreasToDevelop || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Preferred Learning Formats:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.preferred_learning_format || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Weekly Time Availability for Extra-Curricular Learning:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.weeklyTimeAvailablility || ''}
                                        </span>
                                    </p>
                                    <p className="text-gray-600 font-semibold py-2">
                                        Financial Budget for Courses/Events:{' '}
                                        <span className="font-medium">
                                            {profileData?.profile?.meta?.financialBudgetForCourses || ''}
                                        </span>
                                    </p>
                                </div>
                            )}

                            <div>
                                <div className="bg-gray-100 p-2 rounded-md">
                                    <p className="text-primary-green-1 font-semibold">Your goals:</p>
                                    {profileData.profile.your_goals.map((v, i) => {
                                        return (
                                            <p className="text-sm" key={i}>
                                                <FontAwesomeIcon icon={faCheck} className="text-primary-green-1 mr-2" />
                                                {v}
                                            </p>
                                        )
                                    })}
                                </div>
                            </div>
                            <div>
                                <div className="bg-gray-100 p-2 rounded-md">
                                    <p className="text-primary-green-1 font-semibold">
                                        What kind of practitioner you are:
                                    </p>
                                    {profileData.profile.kind_of_practitioner.map((v, i) => {
                                        return (
                                            <p key={i} className="text-sm">
                                                <FontAwesomeIcon icon={faCheck} className="text-primary-green-1 mr-2" />
                                                {v}
                                            </p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                    {isEditable && (
                        <form onSubmit={submit}>
                            <div className="bg-white rounded-md p-8 shadow-sm mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    {/* Education, Start year, years of completion */}
                                    <Input
                                        name="education"
                                        label="Education"
                                        placeHolder="Education"
                                        value={education}
                                        setValue={setEducation}
                                        key={key1}
                                    />
                                    <Input
                                        name="startYear"
                                        label="Start Year"
                                        placeHolder="Start Year"
                                        value={startYear}
                                        setValue={setStartYear}
                                        key={key2}
                                    />
                                    <Input
                                        name="yearsOfCompletion"
                                        label="Years Of Completion"
                                        placeHolder="Years Of Completion"
                                        value={yearsOfCompletion}
                                        setValue={setYearsOfCompletion}
                                        key={key3}
                                    />
                                </div>
                                {yourRole.toLowerCase() !== 'dentist' && (
                                    <div>
                                        {/* your role, ce credit left, ce credit passed,left years for ce target */}
                                        <DropDownWithField
                                            data={roleOptions}
                                            label="Select your subrole"
                                            labelClass="text-lg font-semibold"
                                            value={yourRole}
                                            setValue={setYourRole}
                                        />
                                        <Input
                                            name="ceCreditPassed"
                                            label="CE Credit Passed"
                                            placeHolder="CE Credit Passed"
                                            value={ceCreditPassed}
                                            setValue={setCeCreditPassed}
                                            key={key4}
                                        />
                                        <Input
                                            name="ceCreditLeft"
                                            label="CE Credit Left"
                                            placeHolder="CE Credit Left"
                                            value={ceCreditLeft}
                                            setValue={setCeCreditLeft}
                                            key={key5}
                                        />
                                        <Input
                                            name="leftYearsForTargetCe"
                                            label="Left Years For Target CE"
                                            placeHolder="Left Years For Target CE"
                                            value={leftYearsForTargetCe}
                                            setValue={setLeftYearsForTargetCe}
                                            key={key6}
                                        />
                                    </div>
                                )}
                                {yourRole.toLowerCase() === 'dentist' && (
                                    <div>
                                        {/* your role, ce credit left, ce credit passed,left years for ce target */}
                                        <DropDownWithField
                                            data={roleOptions}
                                            label="Select your subrole"
                                            labelClass="text-lg font-semibold"
                                            value={yourRole}
                                            setValue={setYourRole}
                                        />
                                        <NumberMinMax
                                            name="categoryOne"
                                            label="Category One"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={categoryOne}
                                            setValue={setCategoryOne}
                                            min={0}
                                            max={15}
                                            key={key4}
                                        />
                                        <NumberMinMax
                                            name="categoryTwo"
                                            label="Category Two"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={categoryTwo}
                                            setValue={setCategoryTwo}
                                            min={0}
                                            max={45}
                                            key={key5}
                                        />
                                        <NumberMinMax
                                            name="categoryThree"
                                            label="Category Three"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={categoryThree}
                                            setValue={setCategoryThree}
                                            min={0}
                                            max={30}
                                            key={key6}
                                        />
                                    </div>
                                )}
                                {/* Dentist role new fields - 2 cols */}
                                {yourRole.toLowerCase() === 'dentist' && (
                                    <div>
                                        <DropDownWithField
                                            data={preferredLearningFormatsOptions}
                                            label="Your preffered learning format"
                                            labelClass="text-lg font-semibold"
                                            value={preferredLearningFormat}
                                            setValue={setPreferredLearningFormat}
                                        />

                                        <p className="text-2xl font-bold py-4">Interest and Preferences:</p>

                                        <Input
                                            name="prefTopics"
                                            label="Preferred Topics/Fields of Interest"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={preferredTopics}
                                            setValue={setPreferredTopics}
                                        />

                                        <Input
                                            name="prefGeoLoc"
                                            label="Geographic Locations for Events"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={preferredGeoLocation}
                                            setValue={setPreferredGeoLocation}
                                        />
                                    </div>
                                )}
                                {yourRole.toLowerCase() === 'dentist' && (
                                    <div>
                                        <DropDownWithField
                                            data={idealEventScaleOptions}
                                            label="Ideal Event Scale"
                                            labelClass="text-lg font-semibold"
                                            value={idealEventScale}
                                            setValue={setIdealEventScale}
                                        />

                                        <Input
                                            name="prefDaysAttEvent"
                                            label="Preferred Days for Attending Events"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={preferredDaysAttendEvent}
                                            setValue={setPreferredDaysAttendEvent}
                                        />

                                        <Input
                                            name="willingnessToTravel"
                                            label="Willingness to Travel"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={willingnessToTravel}
                                            setValue={setWillingnessToTravel}
                                        />
                                    </div>
                                )}
                                {/* International Dentist role new fields - 2 cols */}
                                {yourRole.toLowerCase() === 'international dentist' && (
                                    <div>
                                        <Input
                                            name="fullName"
                                            label="Full Name"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={fullName}
                                            setValue={setFullName}
                                        />
                                        <Input
                                            name="contactInformation"
                                            label="Contact Information"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={contactInformation}
                                            setValue={setContactInformation}
                                        />
                                        <h6>Current Location- Country</h6>
                                        <CountrySelect
                                            defaultValue={{
                                                emoji: countryEmoji,
                                                id: countryid,
                                                name: country,
                                            }}
                                            onChange={(e) => {
                                                setCountryEmoji(e.emoji)
                                                setCountryid(e.id)
                                                setCountry(e?.name || '')
                                            }}
                                            placeHolder="Select Country"
                                        />
                                        <h6>State</h6>
                                        <StateSelect
                                            countryid={countryid}
                                            onChange={(e) => {
                                                setstateid(e.id)
                                                setStateProvince(e?.name || '')
                                            }}
                                            placeHolder="Select State"
                                        />
                                        <h6>City</h6>
                                        <CitySelect
                                            countryid={countryid}
                                            stateid={stateid}
                                            onChange={(e) => {
                                                setCity(e?.name || '')
                                            }}
                                            placeHolder="Select City"
                                        />

                                        <Input
                                            name="countryOfLicensure"
                                            label="Country of Licensure"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={countryOfLicensure}
                                            setValue={setCountryOfLicensure}
                                        />
                                        <Input
                                            name="yearsOfExperience"
                                            label="Years of Experience"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={yearsOfExperience}
                                            setValue={setYearsOfExperience}
                                        />
                                    </div>
                                )}
                                {yourRole.toLowerCase() === 'international dentist' && (
                                    <div>
                                        <Input
                                            name="areasOfSpecialty"
                                            label="Areas of Specialty"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={areasOfSpecialty}
                                            setValue={setAreasOfSpecialty}
                                        />

                                        <DropDownWithField
                                            data={currentStageInAccreditationProcessOptions}
                                            label="Current Stage in Accreditation Process"
                                            labelClass="text-lg font-semibold"
                                            value={currentStageInAccreditationProcess}
                                            setValue={setCurrentStageInAccreditationProcess}
                                        />
                                        <DropDownWithField
                                            data={destinationCountryOptions}
                                            label="Destination Country"
                                            labelClass="text-lg font-semibold"
                                            value={destinationCountry}
                                            setValue={setDestinationCountry}
                                        />
                                        <DropDownWithField
                                            data={examRequirementsOptions}
                                            label="Exam Requirements"
                                            labelClass="text-lg font-semibold"
                                            value={examRequirements}
                                            setValue={setExamRequirements}
                                        />

                                        <DropDownWithField
                                            data={desiredCareerPathOptions}
                                            label="Desired Career Path"
                                            labelClass="text-lg font-semibold"
                                            value={desiredCareerPath}
                                            setValue={setDesiredCareerPath}
                                        />

                                        <DropDownWithField
                                            data={preferredLearningFormatsOptions}
                                            label="Your preffered learning format"
                                            labelClass="text-lg font-semibold"
                                            value={preferredLearningFormat}
                                            setValue={setPreferredLearningFormat}
                                        />

                                        <Input
                                            name="timeAvailabilityForStudyAndPreparation"
                                            label="Time Availability for Study and Preparation"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={timeAvailabilityForStudyAndPreparation}
                                            setValue={setTimeAvailabilityForStudyAndPreparation}
                                        />
                                        <Input
                                            name="budgetForCourses"
                                            label="Budget for Courses/Resources"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={budgetForCourses}
                                            setValue={setBudgetForCourses}
                                        />
                                    </div>
                                )}
                                {/* Predental Student role new fields - 2 cols */}
                                {yourRole.toLowerCase() === 'predental student' && (
                                    <div>
                                        <DropDownWithField
                                            data={currentStudyLevelOptions}
                                            label="Current Study Level"
                                            labelClass="text-lg font-semibold"
                                            value={currentStudyLevel}
                                            setValue={setCurrentStudyLevel}
                                        />
                                        <Input
                                            name="majorFieldOfStudy"
                                            label="Major/Field of Study (if applicable)"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={majorFieldOfStudy}
                                            setValue={setMajorFieldOfStudy}
                                        />
                                        <DropDownWithField
                                            data={relevantCoursesTakenOptions}
                                            label="Relevant Courses Taken"
                                            labelClass="text-lg font-semibold"
                                            value={relevantCoursesTaken}
                                            setValue={setRelevantCoursesTaken}
                                        />

                                        <Input
                                            name="intendedAppYearDentalSchool"
                                            label="Intended Application Year for Dental School"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={intendedAppYearDentalSchool}
                                            setValue={setIntendedAppYearDentalSchool}
                                        />
                                        <DropDownWithField
                                            data={preparednessLevelForAdmissionTestsOptions}
                                            label="Preparedness Level for Admission Tests"
                                            labelClass="text-lg font-semibold"
                                            value={preparednessLevelAdmissionTest}
                                            setValue={setPreparednessLevelAdmissionTest}
                                        />
                                        <DropDownWithField
                                            data={extracurricularActivitiesOptions}
                                            label="Extracurricular Activities"
                                            labelClass="text-lg font-semibold"
                                            value={extracurricularActivities}
                                            setValue={setExtracurricularActivities}
                                        />
                                    </div>
                                )}
                                {yourRole.toLowerCase() === 'predental student' && (
                                    <div>
                                        <DropDownWithField
                                            data={dentalCategoryOptions}
                                            label="Areas of Interest in Dentistry"
                                            labelClass="text-lg font-semibold"
                                            value={dentalCategory}
                                            setValue={setDentalCategory}
                                        />

                                        <Input
                                            name="careerInsightsAndGoals"
                                            label="Career Insights and Goals"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={careerInsightsAndGoals}
                                            setValue={setCareerInsightsAndGoals}
                                        />
                                        <DropDownWithField
                                            data={preferredLearningFormatsOptions}
                                            label="Preferred Learning Formats"
                                            labelClass="text-lg font-semibold"
                                            value={preferredLearningFormat}
                                            setValue={setPreferredLearningFormat}
                                        />
                                        <Input
                                            name="timeAvailabilityForEnrichmentActivities"
                                            label="Time Availability for Enrichment Activities"
                                            type="date"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={timeAvailabilityForEnrichmentActivities}
                                            setValue={setTimeAvailabilityForEnrichmentActivities}
                                        />
                                        <Input
                                            name="budgetForTestPrepAndCollegeVisits"
                                            label="Budget for Test Prep and College Visits"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={budgetForTestPrepAndCollegeVisits}
                                            setValue={setBudgetForTestPrepAndCollegeVisits}
                                        />
                                    </div>
                                )}
                                {/* Dental Student role new fields - 2 cols */}
                                {yourRole.toLowerCase() === 'dental student' && (
                                    <div>
                                        <Input
                                            name="fullName"
                                            label="Full Name"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={fullName}
                                            setValue={setFullName}
                                        />

                                        <Input
                                            name="email"
                                            label="Email"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={email}
                                            setValue={setEmail}
                                        />

                                        <Input
                                            name="phone"
                                            label="Phone"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={phone}
                                            setValue={setPhone}
                                        />
                                        <h6>Country</h6>
                                        <CountrySelect
                                            defaultValue={{
                                                emoji: countryEmoji,
                                                id: countryid,
                                                name: country,
                                            }}
                                            onChange={(e) => {
                                                setCountryEmoji(e.emoji)
                                                setCountryid(e.id)
                                                setCountry(e?.name || '')
                                            }}
                                            placeHolder="Select Country"
                                        />
                                        <h6>State</h6>
                                        <StateSelect
                                            countryid={countryid}
                                            onChange={(e) => {
                                                setstateid(e.id)
                                                setStateProvince(e?.name || '')
                                            }}
                                            placeHolder="Select State"
                                        />
                                        <h6>City</h6>
                                        <CitySelect
                                            countryid={countryid}
                                            stateid={stateid}
                                            onChange={(e) => {
                                                setCity(e?.name || '')
                                            }}
                                            placeHolder="Select City"
                                        />

                                        <Input
                                            name="dentalSchoolName"
                                            label="Dental School Name"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={dentalSchoolName}
                                            setValue={setDentalSchoolName}
                                        />
                                    </div>
                                )}
                                {yourRole.toLowerCase() === 'dental student' && (
                                    <div>
                                        <DropDownWithField
                                            data={yearInProgramOptions}
                                            label="Years in Program"
                                            labelClass="text-lg font-semibold"
                                            value={yearInProgram}
                                            setValue={setYearInProgram}
                                        />

                                        <Input
                                            name="expectedGraduationDate"
                                            label="Expected Graduation Date"
                                            type="date"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={expectedGraduationDate}
                                            setValue={setExpectedGraduationDate}
                                        />

                                        <DropDownWithField
                                            data={dentalCategoryOptions}
                                            label="Area of Interest"
                                            labelClass="text-lg font-semibold"
                                            value={dentalCategory}
                                            setValue={setDentalCategory}
                                        />

                                        <DropDownWithField
                                            data={desiredCareerPathOptions}
                                            label="Area of Interest"
                                            labelClass="text-lg font-semibold"
                                            value={desiredCareerPath}
                                            setValue={setDesiredCareerPath}
                                        />
                                        <Input
                                            name="knowledgeAreasToDevelop"
                                            label="Specific Skills/Knowledge Areas to Develop"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={knowledgeAreasToDevelop}
                                            setValue={setKnowledgeAreasToDevelop}
                                        />

                                        <DropDownWithField
                                            data={preferredLearningFormatsOptions}
                                            label="Preferred Learning Formats"
                                            labelClass="text-lg font-semibold"
                                            value={preferredLearningFormat}
                                            setValue={setPreferredLearningFormat}
                                        />

                                        <Input
                                            name="weeklyTimeAvailablility"
                                            label="Weekly Time Availability for Extra-Curricular Learning"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={weeklyTimeAvailablility}
                                            setValue={setWeeklyTimeAvailablility}
                                        />
                                        <Input
                                            name="financialBudgetForCourses"
                                            label="Financial Budget for Courses/Events"
                                            labelClass="text-md font-semibold"
                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                                            value={financialBudgetForCourses}
                                            setValue={setFinancialBudgetForCourses}
                                        />
                                    </div>
                                )}
                                <div>
                                    <YourGoals yourGoals={yourGoals} setYourGoals={setYourGoals} />
                                </div>
                                <div>
                                    <WhatKindOfPractiotioner
                                        kindofPractitioner={kindOfPractitioner}
                                        setKindOfPractitioner={setKindOfPractitioner}
                                    />
                                </div>
                                <Button loading={loading}>Update</Button>
                            </div>
                        </form>
                    )}
                </div>
            </SideBar>
        </div>
    )
}

export default PractitionerProfileTemplate
