import axios from 'axios'
import { resolve } from './resolve'
import { settings } from '@utils/index'

export const createAdmin = async (body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(fetchAPI.post('/admin/', { ...body }).then((res) => res.data))
}

export const getOrganizersAdminAPI = async (token, active) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/admin/get-organizers/?active=${active}`, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const updateStatusOrganizerAdminAPI = async (token, organizer_profile_id, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .patch(
                `/admin/activate-organizer/${organizer_profile_id}`,
                { ...body },
                {
                    headers: { Authorization: `Bearer ${token.session_id}` },
                },
            )
            .then((res) => res.data),
    )
}

export const getUsersAdminAPI = async (token, page = 0, limit = 10, verified = true, active = true) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/admin/get-users?page=${page}&limit=${limit}&verified=${verified}&active=${active}`, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const updateFeatureEventAPI = async (token, eventId, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .patch(
                `/events/feature/${eventId}/`,
                { ...body },
                { headers: { Authorization: `Bearer ${token.session_id}` } },
            )
            .then((res) => res.data),
    )
}

export const updateArchiveEventAPI = async (token, eventId, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .patch(
                `/events/archive/${eventId}/`,
                { ...body },
                { headers: { Authorization: `Bearer ${token.session_id}` } },
            )
            .then((res) => res.data),
    )
}

export const updateUserVerifyAPI = async (token, account_id, status) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .patch(
                `/admin/verify-user/${account_id}/`,
                { verified: status },
                { headers: { Authorization: `Bearer ${token.session_id}` } },
            )
            .then((res) => res.data),
    )
}

export const getFullUsersListAPI = async (token) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/admin/get-users?verified=null`, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}
