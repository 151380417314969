class Settings {
    BaseApiUrlV1 = process.env.REACT_APP_BASE_URL
    PORT = process.env.NODE_ENV === 'development' ? `:${process.env.REACT_APP_BASE_PORT}` : ''
    // VERSION_1 = process.env.NODE_ENV === 'development' ? '/v1' : ''
    VERSION_1 = '/v1'

    api_url_v1() {
        return `${this.BaseApiUrlV1}` + `${this.PORT}` + `${this.VERSION_1}`
    }
}

export const settings = new Settings()
