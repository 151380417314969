import { useState, useEffect } from 'react'
import { CheckBox, Input } from '../../../atoms'
import FormWrapper from './FormWrapper'

const KindOfEvents = ({ setKindsOfEventHold }) => {
    const [kind1, setKind1] = useState('')
    const [kind2, setKind2] = useState('')
    const [kind3, setKind3] = useState('')
    const [kind4, setKind4] = useState('')
    const [kind5, setKind5] = useState('')
    const [kind6, setKind6] = useState('')
    const [kind7, setKind7] = useState('')
    const [kind8, setKind8] = useState('')
    const [other, setOther] = useState('')

    useEffect(() => {
        let finalArr = []
        let arr = [kind1, kind2, kind3, kind4, kind5, kind6, kind7, kind8, other]

        arr.forEach((str) => {
            if (str !== null && str.length !== 0) {
                finalArr.push(str)
            }
        })

        setKindsOfEventHold(finalArr)
    }, [kind1, kind2, kind3, kind4, kind5, kind6, kind7, kind8, other, setKindsOfEventHold])

    return (
        <div>
            <FormWrapper title="Kind of events you hold" subtitle="Which kind of events do you hold?">
                <div className="grid grid-cols-2 gap-2">
                    <CheckBox
                        name="kind-of-event-organizer-webinar"
                        type="checkbox"
                        label="Webinar"
                        value="Webinar"
                        selectedState={kind1}
                        wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                        inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                        labelClass="cursor-pointer font-medium"
                        setValue={setKind1}
                    />
                    <CheckBox
                        name="kind-of-event-organizer-congres"
                        type="checkbox"
                        label="Congres"
                        value="Congres"
                        wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                        inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                        labelClass="cursor-pointer font-medium"
                        setValue={setKind2}
                    />
                </div>
                <div className="grid grid-cols-2 gap-2">
                    <CheckBox
                        name="kind-of-event-organizer-training"
                        type="checkbox"
                        label="Training"
                        value="Training"
                        wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                        inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                        labelClass="cursor-pointer font-medium"
                        setValue={setKind3}
                    />
                    <CheckBox
                        name="kind-of-event-organizer-symposium"
                        type="checkbox"
                        label="Symposium"
                        value="Symposium"
                        wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                        inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                        labelClass="cursor-pointer font-medium"
                        setValue={setKind4}
                    />
                </div>
                <CheckBox
                    name="kind-of-event-organizer-seminar"
                    type="checkbox"
                    label="Seminar"
                    value="Seminar"
                    wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                    inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                    labelClass="cursor-pointer font-medium"
                    setValue={setKind5}
                />
                <CheckBox
                    name="kind-of-event-organizer-conference"
                    type="checkbox"
                    label="Conference"
                    value="Conference"
                    wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                    inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                    labelClass="cursor-pointer font-medium"
                    setValue={setKind6}
                />
                <div className="border-2 border-primary-gray-1 mb-4 rounded-md">
                    <p className="p-4 font-medium">In Person</p>
                    <div className="grid grid-cols-2 gap-6 px-4">
                        <CheckBox
                            name="kind-of-event-organizer-course"
                            type="checkbox"
                            label="Course"
                            value="Course"
                            wrapperClass="border-2 border-primary-gray-1 px-4 py-2 rounded-full relative mb-4"
                            inputClass="accent-primary-green-1 w-4 h-4 absolute right-4 mt-1 cursor-pointer"
                            labelClass="cursor-pointer font-medium"
                            setValue={setKind7}
                        />
                        <CheckBox
                            name="kind-of-event-organizer-hands"
                            type="checkbox"
                            label="Hand's on"
                            value="Hand's on"
                            wrapperClass="border-2 border-primary-gray-1 px-4 py-2 rounded-full relative mb-4"
                            inputClass="accent-primary-green-1 w-4 h-4 absolute right-4 mt-1 cursor-pointer"
                            labelClass="cursor-pointer font-medium"
                            setValue={setKind8}
                        />
                    </div>
                </div>
                <Input
                    name="tell-us-practitioner-other"
                    label="Add others if you want to"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    setValue={setOther}
                />
            </FormWrapper>
        </div>
    )
}

export default KindOfEvents
