import { useState, useEffect } from 'react'
import { PrefixDropDown } from './index'

const PhoneNumber = ({
    name,
    type = 'text',
    prefixValue = null,
    value = '',
    setValue = () => {},
    setPrefixValue = () => {},
    label = '',
    labelClass = 'text-md font-semibold',
    inputClass = 'block w-full p-2 pl-20 my-4 border-2 border-gray-300 rounded-md focus:outline-0 rounded-md bg-gray-100',
    placeHolder = '',
    required = false,
}) => {
    const [localValue, setLocalValue] = useState(value)

    useEffect(() => {
        setLocalValue(value)
    }, [value])

    const handleChange = (e) => {
        const newValue = e.target.value
        setLocalValue(newValue)
        setValue(newValue)
    }
    return (
        <div className="relative">
            {label.length !== 0 ? (
                <label className={labelClass} htmlFor={name}>
                    {label}
                </label>
            ) : null}
            <div className="absolute left-2 top-[42px] border-r-2 border-gray-300">
                <PrefixDropDown prefixValue={prefixValue} setSelectValue={setPrefixValue} />
            </div>
            <input
                className={inputClass}
                type={type}
                id={name}
                value={localValue}
                onChange={handleChange}
                placeholder={placeHolder}
                required={required}
            />
        </div>
    )
}
export default PhoneNumber
