import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useEffect } from 'react'

const Input = ({
    name,
    type = 'text',
    value = '',
    setValue = () => {},
    label = '',
    wrapperClass = 'relative',
    labelClass = 'text-md font-semibold',
    inputClass = 'block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100',
    eyeIconClass = '',
    placeHolder = '',
    required = false,
}) => {
    const [localValue, setLocalValue] = useState(value)
    const [localType, setLocalType] = useState(false)

    useEffect(() => {
        if (value && value.length !== 0) {
            setLocalValue(value)
        }
    }, [value])

    const handleChange = (e) => {
        const newValue = e.target.value
        setLocalValue(newValue)
        setValue(newValue)
    }

    return (
        <div className={wrapperClass}>
            {label.length !== 0 ? (
                <label className={labelClass} htmlFor={name} title={required ? 'This field is required' : ''}>
                    {label} {required ? <span className="text-red-500">*</span> : null}
                </label>
            ) : null}
            <input
                className={inputClass}
                type={localType ? localType : type}
                id={name}
                value={localValue}
                onChange={handleChange}
                placeholder={placeHolder}
                required={required}
            />
            {type === 'password' ? (
                <span className={eyeIconClass} onClick={() => setLocalType(!localType)}>
                    <FontAwesomeIcon icon={localType ? faEye : faEyeSlash} className="text-gray-400 text-sm" />
                </span>
            ) : null}
        </div>
    )
}

export default Input
