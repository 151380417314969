import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@components/atoms/index'
import { removeEventAPI, updateFeatureEventAPI, updateArchiveEventAPI } from '@api/index'
import { LocalVar } from '@utils/index'
import toast from 'react-hot-toast'

const AdminEventControlBtns = ({ data }) => {
    const [featureLoading, setFeaturetLoading] = useState(false)
    const [archiveLoading, setArchiveLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)

    const navigate = useNavigate()

    const featureEvent = async (eventId, value) => {
        setFeaturetLoading(true)
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const ft = await updateFeatureEventAPI(token, eventId, { featured: value })
        if (ft.error === null) {
            toast.success('Event successfully updated!')
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        } else {
            setFeaturetLoading(false)
            toast.error('Unexpected error occurred!')
        }
    }

    const archiveEvent = async (eventId, value) => {
        setArchiveLoading(true)
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const ar = await updateArchiveEventAPI(token, eventId, { archived: value })
        if (ar.error === null) {
            toast.success('Event successfully updated!')
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        } else {
            setArchiveLoading(false)
            toast.error('Unexpected error occurred!')
        }
    }

    const removeEvent = async (event_id) => {
        setDeleteLoading(true)

        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const rm = await removeEventAPI(token, event_id)
        if (rm.error === null) {
            setDeleteLoading(false)
            toast.success('Event successfully removed!')
            navigate('/events')
        } else {
            toast.error('Unexpected error occurred!')
            setDeleteLoading(false)
        }
    }

    return (
        <div className="grid grid-cols-12 gap-4">
            <div className="col-span-6">
                {data.archived ? (
                    <p
                        onClick={() => archiveEvent(data.id, false)}
                        className="block w-full py-2 rounded-sm border-2 text-center border-blue-400 bg-white text-blue-400 cursor-pointer">
                        Unarchive event
                    </p>
                ) : (
                    <Button
                        onClick={() => archiveEvent(data.id, true)}
                        loading={archiveLoading}
                        btnClass="block text-white bg-blue-400 w-full py-2 rounded-sm border-2 border-blue-400 active:bg-white active:text-blue-400">
                        Archive event
                    </Button>
                )}
            </div>
            <div className="col-span-6">
                {data.featured ? (
                    <p
                        onClick={() => featureEvent(data.id, false)}
                        className="block w-full py-2 rounded-sm border-2 text-center border-orange-400 bg-white text-orange-400 cursor-pointer">
                        Remove from feature
                    </p>
                ) : (
                    <Button
                        onClick={() => featureEvent(data.id, true)}
                        loading={featureLoading}
                        btnClass="block text-white bg-orange-400 w-full py-2 rounded-sm border-2 border-orange-400 active:bg-white active:text-orange-400">
                        Feature event
                    </Button>
                )}
            </div>
            <div className="col-span-12">
                <Button
                    onClick={() => removeEvent(data.id)}
                    loading={deleteLoading}
                    btnClass="block text-white bg-red-500 w-full py-2 rounded-sm border-2 border-red-500 active:bg-white active:text-red-500">
                    Delete event
                </Button>
            </div>
        </div>
    )
}

export default AdminEventControlBtns
