import { Link } from 'react-router-dom'

const GetStartedCard = ({ img, title, description, getLink }) => {
    return (
        <div className="bg-white rounded-lg py-8">
            <div className="max-w-[400px] mx-auto">
                <img src={img} alt="get started" className="mx-auto" />
                <h3 className="text-center text-primary-green-1 p-4">{title}</h3>
                <p className="text-center text-sm max-w-[350px] mx-auto text-gray-500">{description}</p>
                <div className="flex justify-center p-4">
                    <Link to={getLink} className="font-semibold text-indigo-500">
                        Get Started
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default GetStartedCard
