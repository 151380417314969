import { Input } from '../../../atoms'
import FormWrapper from './FormWrapper'

const TellUs = ({ orgName, setOrgName, orgEmail, setOrgEmail, orgAddress, setOrgAddress }) => {
    return (
        <div>
            <FormWrapper title="Tell us about you" subtitle="Complete your profile for better experience">
                <Input
                    name="org-name"
                    label="Organization Name"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                    placeHolder="Write here"
                    value={orgName}
                    setValue={setOrgName}
                />
                <Input
                    name="org-email"
                    type="email"
                    label="Organization Email"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                    placeHolder="Write here"
                    value={orgEmail}
                    setValue={setOrgEmail}
                />
                <Input
                    name="address"
                    label="Address"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                    placeHolder="Write here"
                    value={orgAddress}
                    setValue={setOrgAddress}
                />
            </FormWrapper>
        </div>
    )
}

export default TellUs
