import { useState } from 'react'
import { useMultiStepForm } from '@hooks/index'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons'
import GoalImage from '../../assets/img/goal.png'
import { LocalVar } from '@utils/variables'
import { createSponsorProfileAPI } from '@api/index'
import { CompanyForm, RepresentativeForm, LocationForm } from '@components/molecules/Forms/SponsorCreate/index'
import toast from 'react-hot-toast'

const ProfileSponsorCreateTemplate = () => {
    const [companyName, setCompanyName] = useState('')
    const [companyContactName, setCompanyContactName] = useState('')
    const [companyContactTel, setCompanyContactTel] = useState('')
    const [companyEmail, setCompanyEmail] = useState('')
    const [representativeName, setRepresentativeName] = useState('')
    const [representativeContactTel, setRepresentativeContactTel] = useState('')
    const [representativeEmail, setRepresentativeEmail] = useState('')
    const [country, setCountry] = useState('')
    const [provinceState, setProvinceState] = useState('')
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [loading, setLoading] = useState(false)

    const { step, steps, currentStepIndex, isFirstStep, isLastStep, back, next } = useMultiStepForm([
        <CompanyForm
            companyName={companyName}
            setCompanyName={setCompanyName}
            companyContactName={companyContactName}
            setCompanyContactName={setCompanyContactName}
            companyContactTel={companyContactTel}
            setCompanyContactTel={setCompanyContactTel}
            companyEmail={companyEmail}
            setCompanyEmail={setCompanyEmail}
        />,
        <RepresentativeForm
            representativeName={representativeName}
            setRepresentativeName={setRepresentativeName}
            representativeContactTel={representativeContactTel}
            setRepresentativeContactTel={setRepresentativeContactTel}
            representativeEmail={representativeEmail}
            setRepresentativeEmail={setRepresentativeEmail}
        />,
        <LocationForm
            country={country}
            setCountry={setCountry}
            provinceState={provinceState}
            setProvinceState={setProvinceState}
            city={city}
            setCity={setCity}
            address={address}
            setAddress={setAddress}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
        />,
    ])

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!isLastStep) return next()
        setLoading(true)

        let body = {
            company_name: companyName,
            company_contact_name: companyContactName,
            company_contact_tel: companyContactTel,
            company_email: companyEmail,
            representative_name: representativeName,
            representative_contact_tel: representativeContactTel,
            representative_email: representativeEmail,
            country: country,
            province_state: provinceState,
            city: city,
            address: address,
            postal_code: postalCode,
        }

        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const createProfileData = await createSponsorProfileAPI(token, body)
        if (createProfileData.error === null) {
            toast.success('Your profile created successfully!')
            window.location.reload()
        } else {
            toast.error('Failed to create your profile!')
        }

        setLoading(false)
    }

    return (
        <div className="relative">
            <div className="w-full md:w-7/12 min-h-screen flex justify-center items-center">
                <form
                    onSubmit={onSubmit}
                    className="w-[600px] bg-gray-100 py-12 px-6 md:py-20 md:px-12 rounded-lg m-4 relative">
                    <p className="absolute top-4 left-6 md:top-10 md:left-12 text-sm text-primary-green-1 font-semibold">
                        {currentStepIndex + 1} / {steps.length}
                    </p>
                    {step}
                    <div>
                        <div className="">
                            {!isFirstStep && (
                                <button
                                    type="button"
                                    onClick={back}
                                    className="text-primary-green-1 font-semibold text-xl bg-slate-200 py-2 px-12 block w-full rounded-md mt-12">
                                    Back
                                </button>
                            )}
                            <button
                                type="submit"
                                className="text-white font-semibold text-xl bg-primary-green-1 py-2 px-12 block w-full rounded-md mt-8">
                                {loading ? (
                                    <FontAwesomeIcon icon={faSpinner} className="motion-safe:animate-spin" />
                                ) : isLastStep ? (
                                    'Submit'
                                ) : (
                                    'Next'
                                )}
                            </button>
                            {isLastStep ? null : (
                                <button
                                    type="submit"
                                    className="text-primary-green-1 text-xl font-semibold mx-auto block mt-6">
                                    Skip for now
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
            <div className="fixed w-full md:w-5/12 h-screen right-0 top-0 bg-green-100 md:flex justify-center items-center p-4 hidden">
                <Link to="/" className="absolute left-8 top-8 text-primary-green-1 text-lg font-semibold">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to Main page
                </Link>
                <img src={GoalImage} alt="goal_image" />
            </div>
        </div>
    )
}

export default ProfileSponsorCreateTemplate
