import { Header, Footer } from '@components/organisms/index'
import { MainNav } from '@components/molecules/index'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const NotFound = ({ statusCode = 404, warningTxt = 'Not found' }) => {
    const navigate = useNavigate()

    return (
        <div>
            <Header title={`DentiCE | ${statusCode} ${warningTxt}`} description="Error found" />
            <MainNav />
            <div className="flex justify-center items-center min-h-[50vh]">
                <div className="px-4">
                    <h1 className="text-[80px] text-gray-300 mb-8">{statusCode}</h1>
                    <h1 className="text-primary-green-2 text-[50px]">{warningTxt}</h1>
                    <button
                        className="bg-primary-green-1 text-white px-6 py-2 rounded-md mt-8"
                        onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Go Back
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NotFound
