import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Input, Button } from '@components/atoms/index'
import { useNavigate, Link } from 'react-router-dom'
import { forgotPasswordAPI } from '@api/index'

const ForgotPassForm = () => {
    const [email, setEmail] = useState('')
    const [done, setDone] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (done === true) {
            navigate('/reset-password')
        }
    }, [done, navigate])

    const submit = async (e) => {
        e.preventDefault()

        if (loading === true) return
        setLoading(true)

        let body = {
            email_address: email,
        }

        const fPass = await forgotPasswordAPI(body)
        if (fPass.error === null) {
            toast.success('Email send')
            setLoading(false)
            setDone(true)
        } else {
            const pop = fPass.error.response.data.errors[0].message || 'Something went wrong'
            toast.error(pop)
            setLoading(false)
        }
    }

    return (
        <div className="h-full w-full flex justify-center overflow-auto">
            <div className="w-[400px] m-auto py-8">
                <h2 className="font-w6">Forgot password?</h2>
                <p className="text-gray-700 py-4">Enter you email for reset password.</p>
                <form onSubmit={submit}>
                    <Input
                        name="email"
                        label="Email"
                        type="email"
                        setValue={setEmail}
                        labelClass="text-md font-semibold"
                        inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md bg-gray-100"
                        placeHolder="Your Email"
                        required={true}
                    />

                    <Button
                        btnClass="bg-primary-green-1 text-white p-4 w-full block mt-6 cursor-pointer rounded-md active:bg-primary-green-10 active:text-primary-green-1 border-2 border-primary-green-1 hover:bg-primary-green-2"
                        loading={loading}>
                        Send
                    </Button>
                </form>
                <p className="font-semibold text-gray-400 text-center my-8">OR</p>
                <p className="font-semibold text-gray-400 text-center mt-8">
                    Remember password?{' '}
                    <Link to="/login" className="text-primary-green-1">
                        Login
                    </Link>
                </p>
            </div>
        </div>
    )
}
export default ForgotPassForm
