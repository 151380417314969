import { SideBar } from '../molecules'
import { OwnEvents } from '@components/molecules/index'

const OrganizerHomeTemplate = () => {
    return (
        <div className="h-screen">
            <SideBar>
                <div className="px-8">
                    <OwnEvents />
                </div>
            </SideBar>
        </div>
    )
}

export default OrganizerHomeTemplate
