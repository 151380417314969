import { FormWrapper } from '.'
import { Input } from '@components/atoms/index'

const CompanyForm = ({
    companyName,
    setCompanyName = () => {},
    companyContactName,
    setCompanyContactName = () => {},
    companyContactTel,
    setCompanyContactTel = () => {},
    companyEmail,
    setCompanyEmail = () => {},
}) => {
    return (
        <div>
            <FormWrapper title={'Company profile'} subtitle={'Tell me about your company'}>
                <Input
                    name="company"
                    label="Company name"
                    placeHolder="Your company name"
                    value={companyName}
                    setValue={setCompanyName}
                />
                <Input
                    name="company-contact"
                    label="Company contact name"
                    placeHolder="Company contact name"
                    value={companyContactName}
                    setValue={setCompanyContactName}
                />
                <Input
                    name="company-contact-tel"
                    label="Company contact phone number"
                    placeHolder="Company contact phone number"
                    value={companyContactTel}
                    setValue={setCompanyContactTel}
                />
                <Input
                    type="email"
                    name="company-email"
                    label="Company email"
                    placeHolder="Company email"
                    value={companyEmail}
                    setValue={setCompanyEmail}
                />
            </FormWrapper>
        </div>
    )
}

export default CompanyForm
