import { Loading } from '@components/atoms/index'
import { SideBar, EventCards, NearByEvents } from '@components/molecules/index'
import { CeCard } from '../atoms/practitioner'
import { getProfileAPI } from '@api/index'
import { useContext, useEffect, useState } from 'react'
import { LocalVar, ProfileVar } from '@utils/index'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { Auth } from '@context/authContext'

const PractitionerHomeTemplate = () => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    const { state } = useContext(Auth)

    useEffect(() => {
        const getPro = async (state) => {
            setLoading(true)
            const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

            const getData = await getProfileAPI(token, state.auth.entity_id)
            if (getData.error === null) {
                setData(getData.data.data[ProfileVar.PRACTITIONER])
                setLoading(false)
            } else {
                toast.error('Unexpected error occured!')
                setLoading(false)
            }
        }
        getPro(state)
    }, [state])

    if (loading) return <Loading />

    return (
        <div className="h-screen">
            <SideBar>
                <div className="pt-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
                        <CeCard
                            title="CE You Have completed"
                            ceClass="text-green-600 text-center scale-125"
                            ceWrapperClass="p-8 rounded-full bg-green-100 w-24 h-24 mr-4"
                            txt={
                                data.profile.ce_credit_passed +
                                ' credits have been passed out of ' +
                                (data.profile?.ce_credit_left + data.profile.ce_credit_passed) +
                                ' credits'
                            }
                        />
                        <CeCard
                            title="CE Left"
                            ceClass="text-blue-600 text-center scale-125"
                            ceWrapperClass="p-8 rounded-full bg-blue-100 w-24 h-24 mr-4"
                            txt={
                                data.profile?.ce_credit_left +
                                ' Credits Left for next ' +
                                data.profile?.left_years_for_target_ce +
                                ' Year' +
                                (data.profile?.left_years_for_target_ce > 1 ? 's' : '')
                            }
                        />
                    </div>
                </div>
                <div className="bg-white rounded-md p-4 py-8 my-4 mx-4">
                    <h3 className="text-gray-600">
                        Begin your journey by searching{' '}
                        <Link to="/events" className="underline text-primary-green-1">
                            Events
                        </Link>
                    </h3>
                </div>
                <EventCards
                    title="Featured events"
                    container={false}
                    by="event_name"
                    keyword=""
                    upcoming={true}
                    featured={true}
                />
                <NearByEvents container={false} upcoming={true} />
            </SideBar>
        </div>
    )
}

export default PractitionerHomeTemplate
