import { useState } from 'react'
import { HeroSearchBar } from '@components/atoms/index'
import { HowItWorks } from '..'

const EventSearch = ({ filter, setSearch, data = [] }) => {
    const dataArrtoStr = (data) => {
        if (data.length === 0) {
            return []
        }
        let arr = []
        data.map((v, i) => {
            let filterName = filter
            if (filter === 'all') filterName = 'event_name'
            return arr.push(v[filterName])
        })
        return arr
    }

    return (
        <div className="bg-dark-green flex justify-center py-8">
            <div className="max-w-[900px]">
                <h1 className="golden-txt text-center py-2">Discover Your Path to Continued Success with DentiCE</h1>
                <p className="text-lg block text-white text-center mb-10">
                    DentiCE Find Events (CEs) is your go-to for customized Continuing Education (CE) opportunities in dentistry.
                    Whether you're a student, staff, hygienist, assistant, dentist, or surgeon, our platform simplifies
                    finding events and courses tailored to your goals.
                </p>

                <div className="px-4 md:px-0 relative">
                    <HeroSearchBar
                        setValue={setSearch}
                        placeHolder={`Search by ${filter.replaceAll('_', ' ')}`}
                        data={dataArrtoStr(data)}
                    />
                    <div className="absolute -right-5 top-3">
                        <HowItWorks />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventSearch
