const FormWrapper = ({ title, subtitle, children }) => {
    return (
        <div>
            <h1>{title}</h1>
            <p className="pt-4">{subtitle}</p>
            <div className="mt-10">{children}</div>
        </div>
    )
}

export default FormWrapper
