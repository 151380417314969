import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Pagination = ({ prevPageCount = 0, currentPageProp = 0, nextPageCount = 0, setPage = () => {} }) => {
    let currentPage = currentPageProp + 1

    const handlePage = (page) => {
        setPage(page)
    }

    return (
        <div className="flex justify-center py-8">
            <div className="flex">
                {/* prev page  */}
                {prevPageCount !== 0 ? (
                    <button
                        className="py-1 px-4 text-sm rounded-md mx-1 bg-primary-green-1 text-white border-2 border-primary-green-1"
                        onClick={() => handlePage(currentPageProp - 1)}>
                        <FontAwesomeIcon icon={faAngleLeft} /> Back
                    </button>
                ) : (
                    <button className="py-1 px-4 text-sm rounded-md mx-1 bg-white text-gray-600 border-2 border-gray-300 cursor-not-allowed">
                        <FontAwesomeIcon icon={faAngleLeft} /> Back
                    </button>
                )}

                {/* 2 prev page  */}
                {prevPageCount >= 2 && (
                    <span
                        className="py-1 px-2 text-sm rounded-md mx-1 text-gray-600 border-2 border-gray-300 hover:bg-indigo-100 cursor-pointer"
                        onClick={() => handlePage(currentPageProp - 2)}>
                        {currentPage - 2}
                    </span>
                )}

                {/* 1 prev page  */}
                {prevPageCount >= 1 && (
                    <span
                        className="py-1 px-2 text-sm rounded-md mx-1 text-gray-600 border-2 border-gray-300 hover:bg-indigo-100 cursor-pointer"
                        onClick={() => handlePage(currentPageProp - 1)}>
                        {currentPage - 1}
                    </span>
                )}

                {/* show current page  */}
                <span className="py-1 px-2 text-sm rounded-md mx-1 text-gray-600 border-2 border-gray-300 bg-indigo-100">
                    {currentPage}
                </span>

                {/* 1 next page  */}
                {nextPageCount >= 1 && (
                    <span
                        className="py-1 px-2 text-sm rounded-md mx-1 text-gray-600 border-2 border-gray-300 hover:bg-indigo-100 cursor-pointer"
                        onClick={() => handlePage(currentPageProp + 1)}>
                        {currentPage + 1}
                    </span>
                )}

                {/* 1 next page  */}
                {nextPageCount >= 2 && (
                    <span
                        className="py-1 px-2 text-sm rounded-md mx-1 text-gray-600 border-2 border-gray-300 hover:bg-indigo-100 cursor-pointer"
                        onClick={() => handlePage(currentPageProp + 2)}>
                        {currentPage + 2}
                    </span>
                )}

                {/* next page  */}
                {nextPageCount !== 0 ? (
                    <button
                        className="py-1 px-4 text-sm rounded-md mx-1 bg-primary-green-1 text-white border-2 border-primary-green-1"
                        onClick={() => handlePage(currentPageProp + 1)}>
                        Next <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                ) : (
                    <button className="py-1 px-4 text-sm rounded-md mx-1 bg-white text-gray-600 border-2 border-gray-300 cursor-not-allowed">
                        Next <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                )}
            </div>
        </div>
    )
}

export default Pagination
