import React from 'react'
import FormWrapper from './FormWrapper'
import { DropDownWithField, Input } from '@components/atoms'
import { dentalCategoryOptions } from '@utils/variables'

export default function PredentalStudentSpecificInfo({
    currentStudyLevel,
    setCurrentStudyLevel,
    majorFieldOfStudy,
    setMajorFieldOfStudy,
    relevantCoursesTaken,
    setRelevantCoursesTaken,
    intendedAppYearDentalSchool,
    setIntendedAppYearDentalSchool,
    preparednessLevelAdmissionTest,
    setPreparednessLevelAdmissionTest,
    extracurricularActivities,
    setExtracurricularActivities,
    dentalCategory,
    setDentalCategory,
    careerInsightsAndGoals,
    setCareerInsightsAndGoals,
    preferredLearningFormat,
    setPreferredLearningFormat,
    timeAvailabilityForEnrichmentActivities,
    setTimeAvailabilityForEnrichmentActivities,
    budgetForTestPrepAndCollegeVisits,
    setBudgetForTestPrepAndCollegeVisits,
}) {
    const currentStudyLevelOptions = [
        { text: ' High School', value: ' High School' },
        { text: 'Undergraduate', value: 'Undergraduate' },
    ]
    const relevantCoursesTakenOptions = [
        { text: 'Private Practice', value: 'Private Practice' },
        { text: 'Academic', value: 'Academic' },
        { text: 'Research', value: 'Research' },
    ]
    const preferredLearningFormatsOptions = [
        { text: 'Lectures', value: 'Lectures' },
        { text: 'Workshops', value: 'Workshops' },
        { text: 'Hands-On Training', value: 'Hands-On Training' },
    ]

    const preparednessLevelForAdmissionTestsOptions = [{ text: 'DAT in the U.S.', value: 'DAT in the U.S.' }]

    const extracurricularActivitiesOptions = [{ text: 'Pre-Dental Society Member', value: 'Pre-Dental Society Member' }]

    return (
        <div>
            <FormWrapper
                title="Tell us About Your Preferences"
                subtitle="Let’s Know your preferences to make it better">
                <p className="text-2xl font-bold py-4">Academic Background:</p>
                <DropDownWithField
                    data={currentStudyLevelOptions}
                    label="Current Study Level"
                    labelClass="text-lg font-semibold"
                    setValue={setCurrentStudyLevel}
                />
                <Input
                    name="majorFieldOfStudy"
                    label="Major/Field of Study (if applicable)"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={majorFieldOfStudy}
                    setValue={setMajorFieldOfStudy}
                />
                <DropDownWithField
                    data={relevantCoursesTakenOptions}
                    label="Relevant Courses Taken"
                    labelClass="text-lg font-semibold"
                    setValue={setRelevantCoursesTaken}
                />

                <p className="text-2xl font-bold py-4">Dental School Preparation:</p>
                <Input
                    name="intendedAppYearDentalSchool"
                    label="Intended Application Year for Dental School"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={intendedAppYearDentalSchool}
                    setValue={setIntendedAppYearDentalSchool}
                />
                <DropDownWithField
                    data={preparednessLevelForAdmissionTestsOptions}
                    label="Preparedness Level for Admission Tests"
                    labelClass="text-lg font-semibold"
                    setValue={setPreparednessLevelAdmissionTest}
                />
                <DropDownWithField
                    data={extracurricularActivitiesOptions}
                    label="Extracurricular Activities"
                    labelClass="text-lg font-semibold"
                    setValue={setExtracurricularActivities}
                />

                <p className="text-2xl font-bold py-4">Professional Interests:</p>
                <DropDownWithField
                    data={dentalCategoryOptions}
                    label="Areas of Interest in Dentistry"
                    labelClass="text-lg font-semibold"
                    setValue={setDentalCategory}
                />

                <Input
                    name="careerInsightsAndGoals"
                    label="Career Insights and Goals"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={careerInsightsAndGoals}
                    setValue={setCareerInsightsAndGoals}
                />

                <p className="text-2xl font-bold py-4">Learning Preferences:</p>
                <DropDownWithField
                    data={preferredLearningFormatsOptions}
                    label="Preferred Learning Formats"
                    labelClass="text-lg font-semibold"
                    setValue={setPreferredLearningFormat}
                />

                <p className="text-2xl font-bold py-4">Availability & Constraints:</p>
                <Input
                    name="timeAvailabilityForEnrichmentActivities"
                    label="Time Availability for Enrichment Activities"
                    type="date"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={timeAvailabilityForEnrichmentActivities}
                    setValue={setTimeAvailabilityForEnrichmentActivities}
                />
                <Input
                    name="budgetForTestPrepAndCollegeVisits"
                    label="Budget for Test Prep and College Visits"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={budgetForTestPrepAndCollegeVisits}
                    setValue={setBudgetForTestPrepAndCollegeVisits}
                />
            </FormWrapper>
        </div>
    )
}
