import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

const DropDown = ({ data, value = 'Select', setValue = () => {}, label, labelClass }) => {
    const [localValue, setLocalValue] = useState('Select')
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (!value) {
            setLocalValue('Select')
        } else {
            data.forEach((v) => {
                if (v.value === value) {
                    setLocalValue(v.text)
                }
            })
        }
    }, [value, data])

    const handleChange = (text, value) => {
        setLocalValue(text)
        setValue(value)
        setIsActive(false)
    }
    return (
        <div>
            <label className={labelClass}>{label}</label>
            <div className="relative mt-2">
                <p
                    className="py-2 px-4 border-2 bg-white border-gray-200 rounded-md mb-1 cursor-pointer text-gray-600 font-medium relative"
                    onClick={() => setIsActive(!isActive)}>
                    {localValue}
                    <span className="absolute right-4">
                        {isActive ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
                    </span>
                </p>
                {isActive ? (
                    <div className="py-2 border-2 border-gray-200 rounded-md bg-white absolute top-12 block w-full shadow-sm z-10 max-h-[300px] overflow-y-auto">
                        {data.map((v, i) => {
                            return (
                                <span
                                    key={i}
                                    className={
                                        v.value === value
                                            ? 'block cursor-pointer bg-gray-100 py-1 px-4 text-gray-600 text-sm'
                                            : 'block cursor-pointer hover:bg-gray-100 py-1 px-4 text-gray-600 text-sm'
                                    }
                                    onClick={() => {
                                        handleChange(v.text, v.value)
                                    }}>
                                    {v.text}
                                </span>
                            )
                        })}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default DropDown
