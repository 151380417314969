import { useState, useEffect } from 'react'
import { MainNav, EventSearch } from '../molecules'
import FindEventsSearchedTemplate from './FindEventsSearchedTemplate'
import { getEventFilterAPI } from '@api/index'
import { useLocation } from 'react-router-dom'

const FindEeventsTemplates = () => {
    const location = useLocation()
    const homeCeSpcParam = location?.state?.ceSpecialty || ''
    const [data, setData] = useState({ data: [] })
    const [loading, setLoading] = useState(false)
    const [by, setBy] = useState('all')
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(100)
    const [upcoming, setUpcoming] = useState('all')
    const [featured, setFeatured] = useState(null)
    const [category, setCategory] = useState('all')
    const [ceSpeciality, setCeSpeciality] = useState(homeCeSpcParam || 'all')
    const [priceValue, setPriceValue] = useState([null, null])
    const [country, setCountry] = useState('')
    const [stateProvince, setStateProvince] = useState('')
    const [city, setCity] = useState('')
    const [nationality, setNationality] = useState('')
    const [eventsBy, setEventsBy] = useState('upcoming')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [sortBy, setSortBy] = useState('created_at')

    useEffect(() => {
        let preparedCES = ceSpeciality.toString().split(',')
        let ces = preparedCES.includes('all') ? 'all' : ceSpeciality
        ;(async () => {
            if (search.length === 0) {
                setData([])
            }
            let preparedUpcomming = eventsBy === 'upcoming' ? true : eventsBy === 'previous' ? false : 'all'
            if (startDate && endDate) {
                const eventData = await getEventFilterAPI(
                    by,
                    search,
                    limit,
                    page,
                    preparedUpcomming,
                    featured,
                    category,
                    ces,
                    priceValue[0],
                    priceValue[1],
                    country,
                    stateProvince,
                    city,
                    nationality,
                    startDate,
                    endDate,
                    sortBy
                )
                if (eventData.error === null) {
                    setData({ ...eventData.data.data, data: [...eventData.data.data.data] })
                } else {
                    setData([])
                }
            } else {
                const eventData = await getEventFilterAPI(
                    by,
                    search,
                    limit,
                    page,
                    preparedUpcomming,
                    featured,
                    category,
                    ces,
                    priceValue[0],
                    priceValue[1],
                    country,
                    stateProvince,
                    city,
                    nationality,
                    '',
                    '',
                    sortBy
                )
                if (eventData.error === null) {
                    setData({ ...eventData.data.data, data: [...eventData.data.data.data] })
                } else {
                    setData([])
                }
            }
        })()
    }, [
        by,
        search,
        page,
        limit,
        upcoming,
        featured,
        category,
        ceSpeciality,
        priceValue,
        country,
        stateProvince,
        city,
        nationality,
        eventsBy,
        startDate,
        endDate,
        sortBy
    ])

    return (
        <div className="bg-light-main-green">
            <MainNav />
            <EventSearch filter={by} setSearch={setSearch} data={data.data} />
            <FindEventsSearchedTemplate
                searchBy={by}
                setSearchBy={setBy}
                page={page}
                setPage={setPage}
                data={data}
                search={search}
                upcoming={upcoming}
                setUpcoming={setUpcoming}
                category={category}
                setCategory={setCategory}
                ceSpecialities={ceSpeciality}
                setCeSpecialities={setCeSpeciality}
                priceValue={priceValue}
                setPriceValue={setPriceValue}
                country={country}
                setCountry={setCountry}
                state={stateProvince}
                setState={setStateProvince}
                city={city}
                setCity={setCity}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                eventsBy={eventsBy}
                setEventsBy={setEventsBy}
                sortBy={sortBy}
                setSortBy={setSortBy}
            />
        </div>
    )
}

export default FindEeventsTemplates
