import { Header, Footer } from '@components/organisms'
import { MainNav } from '@components/molecules/index'
import { Modal } from '@components/atoms/index'
import { useState } from 'react'
import { MentorshipPageSlider } from '@components/molecules/index'

const MentorshipPage = () => {
    const [open, setOpen] = useState(5)
    return (
        <div className="bg-light-main-green">
            <Header title="Mentorship" description="DentiCE | A Roadmap to Career Growth and Personal Fulfillment." />
            <MainNav />
            <div className="p-4 py-10 bg-dark-green">
                <div className="md:container mx-auto">
                    <p className="text-white leading-8 text-center text-xl">
                        <b className="golden-txt">DentiCE:</b> Mentorship for Dental Community. Experienced?{' '}
                        <b className="golden-txt">Share Wisdom</b>. Student? <b className="golden-txt">Seek Guidance</b>
                        . Join Mentor & Mentee Program.
                    </p>
                </div>
            </div>
            <div className="md:container mx-auto py-10">
                <MentorshipPageSlider />
            </div>

            {open === 5 && (
                <Modal title="Update" setValue={setOpen}>
                    <h4 className="text-primary-green-1">New Features for this page is coming soon!</h4>
                </Modal>
            )}

            <Footer />
        </div>
    )
}

export default MentorshipPage
