import { getEventFilterAPI } from '@api/eventsApi'
import { HeroSearchBar, Pagination } from '@components/atoms'
import { EventCard } from '@components/atoms/events'
import { EventCards, EventSearch, MainNav } from '@components/molecules'
import { faBan, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EventCategoryArray, dentalCategoryOptions } from '@utils/variables'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GroupsIcon from '@mui/icons-material/Groups'
import LocationOnIcon from '@mui/icons-material/LocationOn'

export default function SearchedPageTemplate() {
    const container = true
    const by = 'event_name'
    const keyword = ''
    const limitPerPage = 3
    const featured = false
    const navigate = useNavigate()
    const { state } = useLocation()
    const [data, setData] = useState({ data: [] })
    const [loading, setLoading] = useState(true)
    const [view, setView] = useState(false)
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(limitPerPage)
    const [expanded, setExpanded] = React.useState('panel1')
    const [search, setSearch] = useState('')
    const [upcoming, setUpcoming] = useState(true)
    const [category, setCategory] = useState('all')
    const [filter, setFilter] = useState('all')
    const [ceSpeciality, setCeSpeciality] = useState('all')

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    // useEffect(() => {
    //     const updateLimitBasedOnWidth = () => {
    //         if (window.innerWidth < 1280) {
    //             setLimit(4)
    //         } else {
    //             setLimit(6)
    //         }
    //     }
    //     updateLimitBasedOnWidth()

    //     window.addEventListener('resize', updateLimitBasedOnWidth)
    //     return () => window.removeEventListener('resize', updateLimitBasedOnWidth)
    // }, [])

    useEffect(() => {
        // const eventFilter = async (by, keyword, limit, page, upcoming, featured, category) => {
        //     let eventData = await getEventFilterAPI(by, keyword, limit, page, upcoming, featured, category)
        //     if (eventData.error === null) {
        //         setData({ ...eventData.data.data, data: [...eventData.data.data.data] })
        //         setLoading(false)
        //     } else {
        //         setLoading(false)
        //     }
        // }

        // eventFilter(by, keyword, limit, page, upcoming, featured, category)
        if (search.length === 0) {
            setData({ data: state?.data || [] })
        }
        const eventFilter = async (by, keyword, limit, page, upcoming, category = 'all', ceSpeciality = 'all') => {
            let eventData = await getEventFilterAPI(by, keyword, limit, page, upcoming, null, category, ceSpeciality)
            if (eventData.error === null) {
                setData({ ...eventData.data.data, data: [...eventData.data.data.data] })
                setLoading(false)
            } else {
                setData([])
                setLoading(false)
            }
        }

        eventFilter(filter, search, 9, page, upcoming, category, ceSpeciality)
    }, [
        page,
        limit,
        by,
        keyword,
        upcoming,
        view,
        featured,
        category,
        search,
        setData,
        filter,
        page,
        upcoming,
        loading,
        ceSpeciality,
    ])

    const handleView = (value) => {
        // setView(value)
        // if (value === true) {
        //     setLimit(3)
        // }
        navigate(`/all-events/${upcoming === 'all' ? 'all' : upcoming ? 'upcomming' : 'previous'}`, {
            state: { data, page, limit, by, keyword, upcoming, view, featured, category },
        })
    }

    const dataArrtoStr = (data) => {
        if (data?.length === 0) {
            return []
        } else {
            let arr = []
            data?.map((v, i) => {
                let filterName = filter
                if (filter === 'all') filterName = 'event_name'
                return arr.push(v[filterName])
            })
            return arr
        }
    }

    const handleCheckboxValueChange = (cType, val) => {
        if (cType === 'category') {
            setCategory(val)
            setCeSpeciality('all')
        } else if (cType === 'ce_speciality') {
            setCeSpeciality(val)
            setCategory('all')
        }
    }

    return (
        <div className="bg-light-main-green">
            <MainNav />
            <div className="px-8 pt-8">
                <HeroSearchBar
                    setValue={setSearch}
                    filterBtn={false}
                    filterList={[]}
                    filter={filter}
                    setFilter={setFilter}
                    placeHolder={`Search by ${filter.replaceAll('_', ' ')}`}
                    data={dataArrtoStr(data?.data || [])}
                />
            </div>
            <div className="flex p-4">
                {/* Sidebar */}
                <div className="w-3/12 p-4 bg-light-main-green">
                    <div className="bg-white p-4 h-full">
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header">
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Event Type</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    {EventCategoryArray.map((cat) => (
                                        <div className="mt-2 flex gap-0">
                                            <input
                                                type="checkbox"
                                                checked={category === cat?.key}
                                                onChange={() => handleCheckboxValueChange('category', cat?.key)}
                                                className="mr-2"
                                            />
                                            <p>{cat?.key || ''}</p>
                                        </div>
                                    ))}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header">
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>Types</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    {dentalCategoryOptions.map((cat) => (
                                        <div className="mt-2 flex gap-0">
                                            <input
                                                type="checkbox"
                                                checked={ceSpeciality === cat?.value}
                                                onChange={() => handleCheckboxValueChange('ce_speciality', cat?.value)}
                                                className="mr-2"
                                            />
                                            <p>{cat?.value || ''}</p>
                                        </div>
                                    ))}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>

                {/* Main Content */}
                <div className="flex-1 p-4">
                    <div className="">
                        {loading ? (
                            <div className="flex justify-center items-center min-h-[200px]">
                                <FontAwesomeIcon
                                    icon={faSpinner}
                                    className="motion-safe:animate-spin text-4xl text-primary-green-2"
                                />
                            </div>
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                                {data?.data?.length !== 0
                                    ? data?.data?.map((v, i) => {
                                          return (
                                              <div key={i} className="col-span-12">
                                                  <EventCard data={v} />
                                              </div>
                                          )
                                      })
                                    : null}
                            </div>
                        )}
                        {data?.data?.length === 0 && !loading && (
                            <div className="flex justify-center items-center bg-white rounded-md border-2 border-slate-200 py-8">
                                <h3 className="text-gray-400 text-center font-medium">
                                    <FontAwesomeIcon icon={faBan} /> No events found
                                </h3>
                            </div>
                        )}
                        {view && data.total_data_count > 3 ? (
                            <Pagination
                                prevPageCount={data.prev_page_count}
                                currentPageProp={data.current_page}
                                nextPageCount={data.next_page_count}
                                setPage={setPage}
                            />
                        ) : null}
                    </div>
                </div>

                {/* Right Sidebar */}
                <div className="w-4/12 p-4">
                    <div className="flex flex-col gap-6 border p-4 rounded shadow-sm mb-4 bg-gradient-to-r from-indigo-50 to-indigo-200">
                        <div className="flex justify-between items-center">
                            <div>
                                <div className="font-semibold">Wed, 03 - Fri, 05 Jul 2024</div>
                                <div className="text-xl text-primary-green-1 font-bold hover:text-red-600 cursor-pointer">
                                    Aluminum China Expo
                                </div>
                                <div className="flex gap-1 text-gray-500">
                                    <LocationOnIcon />
                                    <p className="font-semibold">Shanghai, China</p>
                                </div>
                                <div className="">Industrial Engineering</div>
                            </div>
                            <div className="bg-primary-green-1 h-[80px] w-[80px] rounded-md flex justify-center items-center">
                                <img src="/assets/logo/dent-logo-white.png" height={80} width={80} alt="" />
                            </div>
                        </div>
                        <div className="flex gap-1 text-gray-500">
                            <GroupsIcon />
                            <p className="font-semibold">2558 Members</p>
                        </div>
                    </div>

                    <div className="border p-4 rounded shadow-sm mb-4 bg-gradient-to-r from-amber-50 to-amber-200">
                        <div className="text-lg font-semibold">Wed, 03 - Fri, 05 Jul 2024</div>
                        <div className="mt-2 text-xl font-bold  hover:text-red-600 cursor-pointer">Copper China</div>
                        <div className="text-gray-500">Shanghai, China</div>
                        <div className="mt-2">Industrial Engineering</div>
                        <div className="mt-2 text-gray-500">2558 Members</div>
                    </div>

                    <div className="border p-4 rounded shadow-sm bg-gradient-to-r from-lime-50 to-lime-200">
                        <div className="text-lg font-semibold">Wed, 03 - Fri, 05 Jul 2024</div>
                        <div className="mt-2 text-xl font-bold  hover:text-red-600 cursor-pointer">Copper China</div>
                        <div className="text-gray-500">Shanghai, China</div>
                        <div className="mt-2">Industrial Engineering</div>
                        <div className="mt-2 text-gray-500">2558 Members</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
