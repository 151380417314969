import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Footer, Header } from '@components/organisms/index'
import { getEventDetailsAPI } from '@api/index'
import { LocalVar } from '@utils/variables'
import { Loading } from '@components/atoms/index'
import { EventEditTemplate } from '@components/templates/index'
import { MainNav } from '@components/molecules/index'

const EventEditPage = () => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const { eventId } = useParams()

    useEffect(() => {
        ;(async () => {
            setLoading(true)
            const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
            const eve = await getEventDetailsAPI(token, eventId)
            if (eve.error === null) {
                setData(eve.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        })()
    }, [eventId])

    return (
        <div>
            <Header title="Edit Event" />
            <MainNav />
            {loading ? <Loading /> : null}
            <div className="md:container mx-auto px-4 py-8">
                <EventEditTemplate data={data} />
            </div>
            <Footer />
        </div>
    )
}

export default EventEditPage
