import FormWrapper from './FormWrapper'
import { Input, CheckBox } from '../../../atoms'
import { useState, useEffect } from 'react'

const YourGoals = ({ yourGoals = [], setYourGoals }) => {
    const [tell1, setTell1] = useState('')
    const [tell2, setTell2] = useState('')
    const [tell3, setTell3] = useState('')
    const [tell4, setTell4] = useState('')
    const [tell5, setTell5] = useState('')
    const [tell6, setTell6] = useState('')
    const [others, setOthers] = useState('')

    useEffect(() => {
        let finalArr = []
        let arr = [tell1, tell2, tell3, tell4, tell5, tell6, others]

        arr.forEach((str) => {
            if (str !== null && str.length !== 0) {
                finalArr.push(str)
            }
        })

        setYourGoals(finalArr)
    }, [tell1, tell2, tell3, tell4, tell5, tell6, others, setYourGoals])

    return (
        <div>
            <FormWrapper
                title="Tell us About Your Goals!"
                subtitle="Let’s Know your years of experience and certifications">
                <CheckBox
                    name="tell-us-goal-practitioner-1"
                    type="checkbox"
                    label="Career Growth and Personal Fulfillment."
                    value="Career Growth and Personal Fulfillment."
                    selectedState={
                        yourGoals.includes('Career Growth and Personal Fulfillment.')
                            ? yourGoals[yourGoals.indexOf('Career Growth and Personal Fulfillment.')]
                            : ''
                    }
                    setValue={setTell1}
                    wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                    inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                    labelClass="cursor-pointer"
                />
                <CheckBox
                    name="tell-us-goal-practitioner-2"
                    type="checkbox"
                    label="Looking for a mentor"
                    value="Looking for a mentor"
                    selectedState={
                        yourGoals.includes('Looking for a mentor')
                            ? yourGoals[yourGoals.indexOf('Looking for a mentor')]
                            : ''
                    }
                    setValue={setTell2}
                    wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                    inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                    labelClass="cursor-pointer"
                />
                <CheckBox
                    name="tell-us-goal-practitioner-2"
                    type="checkbox"
                    label="I would like to be aware of the events in dentistry"
                    value="I would like to be aware of the events in dentistry"
                    selectedState={
                        yourGoals.includes('I would like to be aware of the events in dentistry')
                            ? yourGoals[yourGoals.indexOf('I would like to be aware of the events in dentistry')]
                            : ''
                    }
                    setValue={setTell3}
                    wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                    inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                    labelClass="cursor-pointer"
                />
                <CheckBox
                    name="tell-us-goal-practitioner-3"
                    type="checkbox"
                    label="Increase my knowledge in Dentistry"
                    value="Increase my knowledge in Dentistry"
                    selectedState={
                        yourGoals.includes('Increase my knowledge in Dentistry')
                            ? yourGoals[yourGoals.indexOf('Increase my knowledge in Dentistry')]
                            : ''
                    }
                    setValue={setTell4}
                    wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                    inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                    labelClass="cursor-pointer"
                />
                <CheckBox
                    name="tell-us-goal-practitioner-4"
                    type="checkbox"
                    label="Socializing"
                    value="Socializing"
                    selectedState={yourGoals.includes('Socializing') ? yourGoals[yourGoals.indexOf('Socializing')] : ''}
                    setValue={setTell5}
                    wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                    inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                    labelClass="cursor-pointer"
                />
                <CheckBox
                    name="tell-us-goal-practitioner-5"
                    type="checkbox"
                    label="I want Courses with CE Credit"
                    value="I want Courses with CE Credit"
                    selectedState={
                        yourGoals.includes('I want Courses with CE Credit')
                            ? yourGoals[yourGoals.indexOf('I want Courses with CE Credit')]
                            : ''
                    }
                    setValue={setTell6}
                    wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                    inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                    labelClass="cursor-pointer"
                />
                <Input
                    name="other"
                    label="Others"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={others}
                    setValue={setOthers}
                />
            </FormWrapper>
        </div>
    )
}

export default YourGoals
