import axios from 'axios'
import { resolve } from './resolve'
import { settings } from '@utils/index'

export const createEventReview = async (token, event_id, review) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .post(
                `/reviews/review-event/`,
                { event_id: event_id, review: review },
                {
                    headers: { Authorization: `Bearer ${token.session_id}` },
                },
            )
            .then((res) => res.data),
    )
}

export const getEventReviews = async (token, event_id, page, limit) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/reviews/get-reviews/${event_id}?page=${page}&limit=${limit}`, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const removeEventReviews = async (token, rev_id) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .delete(`/reviews/review-event/${rev_id}`, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}
