import { Helmet } from 'react-helmet-async'
import { ProjectVar } from '../../utils'

const Header = ({ title = ProjectVar.PROJECT_NAME, description = 'Dentist knowledge Optimizer' }) => {
    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
        </div>
    )
}
export default Header
