import { AuthTemplate } from '@components/templates'
import { SignupForm } from '@components/molecules'
import { Header } from '@components/organisms'

const SignupPage = () => {
    return (
        <div>
            <Header title="Signup" description="DentiCE signup page" />
            <AuthTemplate>
                <SignupForm />
            </AuthTemplate>
        </div>
    )
}

export default SignupPage
