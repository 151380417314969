import { useState } from 'react'
import { CalendarLogoLink } from '../../atoms/forSections'
import { EventCards } from '..'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMultiply } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

const SecondaryHeroSection = () => {
    const navigate = useNavigate()
    const [keyword, setKeyWord] = useState('')
    const handleCeSpecialtyClick = (val) => {
        navigate('/events', {
            state: {
                ceSpecialty: val,
            },
        })
    }

    return (
        <div>
            <div className="bg-light-main-green py-12">
                <div className="md:container mx-auto">
                    <h1 className="golden-txt text-center text-2xl md:text-4xl leading-10 md:leading-[60px] pb-8 md:pb-16 max-w-[850px] mx-auto">
                        Find Top Dental Continuing Education Events by Specialty
                    </h1>
                    <div className="hidden md:block">
                        <div className="flex justify-center">
                            <div className="flex flex-wrap gap-4 md:gap-10 lg:gap-16">
                                {/* <CalendarLogoLink setValue={setKeyWord}>Orthodontics</CalendarLogoLink>
                                <CalendarLogoLink setValue={setKeyWord}>Endodontics</CalendarLogoLink>
                                <CalendarLogoLink setValue={setKeyWord}>Prosthodontics</CalendarLogoLink> */}
                                <p onClick={() => handleCeSpecialtyClick('Endodontics')}>
                                    <CalendarLogoLink setValue={setKeyWord}>Endodontics</CalendarLogoLink>
                                </p>
                                <p onClick={() => handleCeSpecialtyClick('Periodontics')}>
                                    <CalendarLogoLink setValue={setKeyWord}>Periodontics</CalendarLogoLink>
                                </p>
                                <p onClick={() => handleCeSpecialtyClick('Orthodontics')}>
                                    <CalendarLogoLink setValue={setKeyWord}>Orthodontics</CalendarLogoLink>
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-center mt-4 md:mt-12">
                            <div className="flex flex-wrap gap-4 md:gap-10 lg:gap-16">
                                {/* <CalendarLogoLink setValue={setKeyWord}>Dental braces</CalendarLogoLink>
                                <CalendarLogoLink setValue={setKeyWord}>Scaling and root planing</CalendarLogoLink>
                                <CalendarLogoLink setValue={setKeyWord}>Veneer</CalendarLogoLink>
                                <CalendarLogoLink setValue={setKeyWord}>Cosmetic dentistry</CalendarLogoLink> */}
                                <p onClick={() => handleCeSpecialtyClick('Pediatric')}>
                                    <CalendarLogoLink setValue={setKeyWord}>Pediatric</CalendarLogoLink>
                                </p>
                                <p onClick={() => handleCeSpecialtyClick('Prosthodontics')}>
                                    <CalendarLogoLink setValue={setKeyWord}>Prosthodontics</CalendarLogoLink>
                                </p>
                                <p onClick={() => handleCeSpecialtyClick('Implant')}>
                                    <CalendarLogoLink setValue={setKeyWord}>Implant</CalendarLogoLink>
                                </p>
                                <p onClick={() => handleCeSpecialtyClick('Digital Dentistry')}>
                                    <CalendarLogoLink setValue={setKeyWord}>Digital Dentistry</CalendarLogoLink>
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-center mt-4 md:mt-12">
                            <div className="flex flex-wrap gap-4 md:gap-10 lg:gap-16">
                                {/* <CalendarLogoLink setValue={setKeyWord}>Orthodontics</CalendarLogoLink>
                                <CalendarLogoLink setValue={setKeyWord}>Endodontics</CalendarLogoLink>
                                <CalendarLogoLink setValue={setKeyWord}>Prosthodontics</CalendarLogoLink> */}
                                <p onClick={() => handleCeSpecialtyClick('Esthetic Dentistry')}>
                                    <CalendarLogoLink setValue={setKeyWord}>Esthetic Dentistry</CalendarLogoLink>
                                </p>
                                <p onClick={() => handleCeSpecialtyClick('Oral Medicine')}>
                                    <CalendarLogoLink setValue={setKeyWord}>Oral Medicine</CalendarLogoLink>
                                </p>
                                <p onClick={() => handleCeSpecialtyClick('Oral pathology')}>
                                    <CalendarLogoLink setValue={setKeyWord}>Orar pathology</CalendarLogoLink>
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-center mt-4 md:mt-12">
                            <div className="flex flex-wrap gap-4 md:gap-10 lg:gap-16">
                                {/* <CalendarLogoLink setValue={setKeyWord}>Restorative dentistry</CalendarLogoLink> */}
                                <p onClick={() => handleCeSpecialtyClick('Oral Radiology')}>
                                    <CalendarLogoLink setValue={setKeyWord}>Oral Radiology</CalendarLogoLink>
                                </p>
                                <p onClick={() => handleCeSpecialtyClick('Oral surgery')}>
                                    <CalendarLogoLink setValue={setKeyWord}>Oral surgery</CalendarLogoLink>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-4 md:gap-10 lg:gap-16 md:hidden">
                        <p onClick={() => handleCeSpecialtyClick('')}>
                            <CalendarLogoLink setValue={setKeyWord}>Cosmetic dentistry</CalendarLogoLink>
                        </p>
                        <p onClick={() => handleCeSpecialtyClick('')}>
                            <CalendarLogoLink setValue={setKeyWord}>Orthodontics</CalendarLogoLink>
                        </p>
                        <p onClick={() => handleCeSpecialtyClick('')}>
                            <CalendarLogoLink setValue={setKeyWord}>Endodontics</CalendarLogoLink>
                        </p>
                        <p onClick={() => handleCeSpecialtyClick('')}>
                            <CalendarLogoLink setValue={setKeyWord}>Prosthodontics</CalendarLogoLink>
                        </p>
                        <p onClick={() => handleCeSpecialtyClick('')}>
                            <CalendarLogoLink setValue={setKeyWord}>Dental braces</CalendarLogoLink>
                        </p>
                        <p onClick={() => handleCeSpecialtyClick('')}>
                            <CalendarLogoLink setValue={setKeyWord}>Restorative dentistry</CalendarLogoLink>
                        </p>
                        <p onClick={() => handleCeSpecialtyClick('')}>
                            <CalendarLogoLink setValue={setKeyWord}>Veneer</CalendarLogoLink>
                        </p>
                        <p onClick={() => handleCeSpecialtyClick('')}>
                            <CalendarLogoLink setValue={setKeyWord}>Scaling and root planing</CalendarLogoLink>
                        </p>
                    </div>
                </div>
            </div>
            <div className="md:container mx-auto">
                <div className="px-10 mt-16">
                    {keyword.length > 1 ? (
                        <span className="bg-primary-green-1 p-2 ml-4 rounded-md">
                            <span className="golden-txt">
                                {keyword}{' '}
                                <FontAwesomeIcon
                                    icon={faMultiply}
                                    className="text-primary-golden-5 pl-4 cursor-pointer"
                                    onClick={() => setKeyWord('')}
                                />
                            </span>
                        </span>
                    ) : null}
                    {keyword.length > 0 ? (
                        <EventCards
                            title={'Search based on - ' + keyword}
                            by="event_name"
                            keyword={keyword}
                            upcoming={true}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default SecondaryHeroSection
