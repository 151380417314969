import { FormWrapper } from '.'
import { Input } from '@components/atoms/index'

const RepresentativeForm = ({
    representativeName,
    setRepresentativeName = () => {},
    representativeContactTel,
    setRepresentativeContactTel = () => {},
    representativeEmail,
    setRepresentativeEmail = () => {},
}) => {
    return (
        <div>
            <FormWrapper title={'Representative profile'} subtitle={'Tell me about your representative'}>
                <Input
                    name="represent"
                    label="Representative name"
                    placeHolder="Representative name"
                    value={representativeName}
                    setValue={setRepresentativeName}
                />
                <Input
                    name="representative-contact"
                    label="Representative contact number"
                    placeHolder="Representative contact number"
                    value={representativeContactTel}
                    setValue={setRepresentativeContactTel}
                />
                <Input
                    name="representative-email"
                    label="Representative email"
                    placeHolder="Representative email"
                    value={representativeEmail}
                    setValue={setRepresentativeEmail}
                />
            </FormWrapper>
        </div>
    )
}

export default RepresentativeForm
