import { Outlet, Navigate } from 'react-router-dom'
// import { useNavigate, useLocation } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import { Auth } from '@context/index'
import { Loading } from '@components/atoms'
import { SetRole, NotFound, CreateProfile, LoginPage } from '@components/pages'
import { ProfileVar, LocalVar } from './variables'
import { getAuthSessionAPI } from '@api/index'

const ProtectedRoutes = () => {
    const { dispatch } = useContext(Auth)

    // const location = useLocation()

    const [loading, setLoading] = useState(true)
    const [authenticated, setAuthenticated] = useState(null)
    const [role, setRole] = useState(null)
    const [profile, setProfile] = useState(null)
    const [err, setErr] = useState(null)

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const getAuthSession = async (token) => {
            let data = await getAuthSessionAPI(token)
            if (data.error === null) {
                setAuthenticated(true)
                dispatch({ type: 'set', payload: data.data })
                setRole(data.data.role)
                setProfile(data.data.profile)
                setLoading(false)
            } else {
                setErr(data.error)
                setAuthenticated(false)
                setLoading(false)
            }
        }

        if (token !== null) {
            getAuthSession(token)
        } else {
            setLoading(false)
        }
    }, [dispatch])

    if (loading) {
        return <Loading />
    } else if (err !== null && err.response.status === 400) {
        return <NotFound warningTxt="Unexpected error occurred!" />
    } else if (!loading & (authenticated === true) & (role === null)) {
        return <SetRole />
    } else if (!loading & (authenticated === true) & (role === ProfileVar.ADMIN)) {
        return authenticated === true ? <Outlet /> : <Navigate to="/login" />
    } else if (!loading & (authenticated === true) & (role !== null) & !profile) {
        if (role === ProfileVar.PRACTITIONER || role === ProfileVar.ORGANIZER || role === ProfileVar.SPONSOR || role === ProfileVar.SPEAKER)
            return <CreateProfile />
        if (role === ProfileVar.EDUCATOR) return <NotFound />
    } else {
        // return authenticated === true ? <Outlet /> : <Navigate to="/login" />
        if (authenticated === true) {
            return <Outlet />
        } else {
            return <LoginPage />
        }
        // return authenticated === true ? <Outlet /> : <NavigateWithProps loc="/login" st={{ state: { loc: '/' } }} />
    }
}

export default ProtectedRoutes

// const NavigateWithProps = ({ loc, st }) => {
//     const navigate = useNavigate()
//     useEffect(() => {
//         navigate(loc, { ...st })
//     }, [loc, st])
//     return <div></div>
// }
