import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
// import { HeroSearchBar } from '../../atoms'

const HeroSection = () => {
    return (
        <div className="bg-dark-green">
            <div className="w-[100%] xl:w-[100%] 2xl:w-[75%] mx-auto px-4 md:px-20 pt-10 grid grid-cols-12 gap-6">
                <div className="py-4 md:py-8 col-span-12 md:col-span-8">
                    <h1 className="font-bold golden-txt text-4xl xl:text-5xl">Welcome to DentiCE</h1>
                    <h2 className="font-semibold text-xl md:text-3xl text-white py-4 mb-2">
                        Your AI-powered Continuing Education Platform for Dental Community
                    </h2>
                    <p className="mb-4 leading-8 text-white text-md text-left tracking-wide">
                        <b className="golden-txt">AI-Powered Matchmaking</b> Say goodbye to endless searching. DentiCE
                        utilizes cutting-edge machine learning algorithms to match your profile with the most suitable
                        events and courses, saving you time and effort.
                    </p>
                    {/* <HeroSearchBar /> */}
                    <div className="float-left md:hidden pl-0 mt-2">
                        <Link
                            to="/login"
                            className="border-2 border-primary-golden-4 text-sm lg:text-sm xl:text-lg text-primary-golden-4 font-medium px-6 md:px-4 lg:px-4 xl:px-8 py-2 md:py-3 rounded-lg mr-2">
                            Log in
                        </Link>
                        <Link
                            to="/signup"
                            className="golden border-2 border-primary-golden-5 text-sm lg:text-sm xl:text-lg text-primary-green-1 font-medium px-6 md:px-4 lg:px-4 xl:px-8 py-2 md:py-3 rounded-lg ml-2">
                            Sign up
                        </Link>
                    </div>
                    <Link className="text-2xl golden-txt font-semibold md:flex items-center hidden" to="/signup">
                        <p className="flex justify-center border-2 border-primary-golden-4 text-sm lg:text-sm xl:text-lg text-primary-golden-4 font-medium px-6 md:px-4 lg:px-4 xl:px-8 py-2 md:py-3 rounded-lg mr-2">
                            Get started now{' '}
                            <FontAwesomeIcon icon={faArrowRight} className="ml-2 text-xl text-primary-golden-5" />
                        </p>
                    </Link>
                </div>
                <div className="col-span-12 md:col-span-4 flex justify-center items-center">
                    <span className="p-2 mb-6 md:mb-0 golden rounded-sm">
                        <img src="/assets/img/dentice_hero.png" alt="hero" className="rounded-sm" />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default HeroSection
