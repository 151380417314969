import { Input } from '../../../atoms'
import FormWrapper from './FormWrapper'

const AddDetails = ({
    companyName,
    setCompanyName,
    yourRole,
    setYourRole,
    howManyEventsOrganize,
    setHowManyEventsOrganize,
}) => {
    return (
        <div>
            <FormWrapper title="Add Details" subtitle="Let’s Know your years of experience and certifications.">
                <Input
                    name="company"
                    label="Company name"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                    placeHolder="Your company name here"
                    value={companyName}
                    setValue={setCompanyName}
                />
                <Input
                    name="role"
                    label="Your role"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                    placeHolder="Write here your role"
                    value={yourRole}
                    setValue={setYourRole}
                />
                <Input
                    name="how-many-events"
                    type="number"
                    label="How many events per year You organize approximately"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                    value={howManyEventsOrganize}
                    setValue={setHowManyEventsOrganize}
                />
            </FormWrapper>
        </div>
    )
}

export default AddDetails
