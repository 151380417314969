import { faCalendarAlt, faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FormWrapper = ({ children, title = '', number = 1 }) => {
    return (
        <div>
            <div className="mb-4 hidden md:block">
                <span
                    className={
                        number === 1
                            ? 'font-semibold px-20 py-2 border-gray-600 border-b-2'
                            : 'font-semibold px-20 py-2 text-gray-600 border-gray-400 border-b-2'
                    }>
                    <FontAwesomeIcon icon={faLayerGroup} className="text-gray-600" /> Basic information
                </span>
                <span
                    className={
                        number === 2
                            ? 'font-semibold px-20 py-2 border-gray-600 border-b-2'
                            : 'font-semibold px-20 py-2 text-gray-600 border-gray-400 border-b-2'
                    }>
                    <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-600" /> Event information
                </span>
            </div>
            <div className="py-4 px-4 md:px-8">
                <h3 className="w-[400px] border-b-2 mb-4 py-4 text-gray-700">{title}</h3>
                {children}
            </div>
        </div>
    )
}

export default FormWrapper
