import { getEventReviews, createEventReview, removeEventReviews } from '@api/index'
import { useState, useEffect, useContext } from 'react'
import { LocalVar, ProfileVar } from '@utils/index'
import { Pagination, Button } from '@components/atoms/index'
import toast from 'react-hot-toast'
import { Auth } from '@context/authContext'

const EventReviews = ({ event_id }) => {
    const [data, setData] = useState({ data: [] })
    const [page, setPage] = useState(0)
    const [review, setReview] = useState('')
    const [newRev, setNewRev] = useState({})
    const [delLoading, setDelLoading] = useState(false)

    const { state } = useContext(Auth)

    useEffect(() => {
        ;(async () => {
            const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
            const getData = await getEventReviews(token, event_id, page, 10)
            if (getData.error === null) {
                setData(getData.data)
            }
        })()
    }, [event_id, page, newRev])

    const reviewSubmit = async (event_id) => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
        const postReview = await createEventReview(token, event_id, review)
        if (postReview.error === null) {
            setReview('')
            setNewRev(postReview.data)
        } else {
            let errStr = postReview.error.response.data.errors[0].code.split('.')[1].replaceAll('_', ' ')
            toast.error(errStr.charAt(0).toUpperCase() + errStr.slice(1))
        }
    }

    const removeRev = async (rev_id) => {
        setDelLoading(true)
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
        const remRev = await removeEventReviews(token, rev_id)
        if (remRev.error === null) {
            setDelLoading(false)
            window.location.reload()
        } else {
            setDelLoading(false)
        }
    }

    return (
        <div>
            <h4 className="text-primary-green-1 py-2">Reviews</h4>

            <div>
                <textarea
                    onChange={(e) => setReview(e.target.value)}
                    value={review}
                    className="border-2 border-slate-200 rounded-md w-full outline-none p-2"
                    rows={3}
                    placeholder="Write your review here..."></textarea>
                <button
                    onClick={() => reviewSubmit(event_id)}
                    className="mt-2 bg-primary-green-1 text-white py-2 px-8 rounded-md border-2 border-primary-green-1 active:text-primary-green-1 active:bg-white">
                    Post
                </button>
            </div>

            {data.data.total_data_count !== 0 && (
                <div className="bg-slate-100 p-4 rounded-md my-4">
                    {data?.data?.data?.map((v, i) => {
                        return (
                            <div key={i} className="px-4 py-6 bg-white my-4 rounded-md shadow-sm">
                                <p className="font-semibold text-primary-green-1">{v.full_name}</p>
                                <p className="text-sm">{v.review}</p>
                                {v.user_id === state.auth.entity_id || state.auth.role === ProfileVar.ADMIN ? (
                                    <div className="w-[80px]">
                                        <Button
                                            onClick={() => removeRev(v.id)}
                                            loading={delLoading}
                                            btnClass="text-sm text-center w-full px-4 py-1 bg-red-500 text-white rounded-sm hover:bg-red-400 border-2 border-red-500 active:text-red-500 active:bg-white mt-4">
                                            Delete
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        )
                    })}
                </div>
            )}

            {data.data.total_data_count > 10 ? (
                <Pagination
                    prevPageCount={data.data.prev_page_count}
                    currentPageProp={data.data.current_page}
                    nextPageCount={data.data.next_page_count}
                    setPage={setPage}
                />
            ) : null}
        </div>
    )
}

export default EventReviews
