import { useState } from 'react'

const PrefixDropDown = ({ prefixValue = '+1', setSelectValue = () => {} }) => {
    const [selected, setSelected] = useState(prefixValue)

    const handleSelected = (e) => {
        setSelected(e.target.value)
        setSelectValue(e.target.value)
    }

    const mobilePrefixes = [
        '+44', // United Kingdom
        '+86', // China
        '+91', // India
        '+81', // Japan
        '+61', // Australia
        '+49', // Germany
        '+33', // France
        '+7', // Russia
        '+55', // Brazil
        '+39', // Italy
        '+82', // South Korea
        '+34', // Spain
        '+52', // Mexico
        '+65', // Singapore
        '+971', // United Arab Emirates
        '+966', // Saudi Arabia
        '+852', // Hong Kong
        '+86', // Macau
        '+88', // Bangladesh
        '+886', // Taiwan
        '+63', // Philippines
        '+64', // New Zealand
        '+27', // South Africa
        '+46', // Sweden
        '+41', // Switzerland
        '+31', // Netherlands
        '+358', // Finland
        '+972', // Israel
        '+370', // Lithuania
        '+371', // Latvia
        '+372', // Estonia
        '+48', // Poland
        '+420', // Czech Republic
        '+421', // Slovakia
        '+386', // Slovenia
        '+385', // Croatia
        '+359', // Bulgaria
        '+36', // Hungary
        '+353', // Ireland
        '+354', // Iceland
        '+352', // Luxembourg
        '+377', // Monaco
        '+373', // Moldova
        '+382', // Montenegro
        '+356', // Malta
        '+377', // San Marino
        '+47', // Norway
        '+421', // Slovakia
        '+421', // Liechtenstein
        '+378', // Vatican City
    ]

    return (
        <div className="relative inline">
            <select
                name=""
                id=""
                value={selected}
                onChange={handleSelected}
                className="appearance-none outline-none cursor-pointer p-2 bg-transparent">
                <option value={'+1'} defaultValue={true}>
                    +1
                </option>
                {mobilePrefixes.map((v, i) => {
                    return (
                        <option key={i} value={v}>
                            {v}
                        </option>
                    )
                })}
            </select>
        </div>
    )
}
export default PrefixDropDown
