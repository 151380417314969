import { useState, useEffect } from 'react'
import { getUserAPI, getProfileAPI } from '@api/index'
import { LocalVar } from '@utils/variables'
import { NotFound } from '@components/pages/index'
import { Loading } from '@components/atoms/index'
import { UserProfile } from '..'

const AdminSeeUserDetail = ({ entity_id = '' }) => {
    const [userData, setUserData] = useState(null)
    const [profileData, setProfileData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [err, setErr] = useState(null)

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const getUser = async (token, user_id) => {
            let getUserData = await getUserAPI(token, user_id)
            if (getUserData.error === null) {
                setUserData(getUserData.data.data)
            } else {
                setErr(getUserData.error)
            }
        }

        const getProfile = async (token, user_id) => {
            let data = await getProfileAPI(token, user_id)
            if (data.error === null) {
                setProfileData(data.data.data)
                setLoading(false)
            } else {
                setErr(data.error)
                setLoading(false)
            }
        }

        getUser(token, entity_id)

        if (token !== null) {
            if (err === null) {
                getProfile(token, entity_id)
            } else {
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }, [entity_id, err])

    if (loading) return <Loading />

    if (err !== null) return <NotFound warningTxt="Unexpected error occurred!" />

    return (
        <div>
            <UserProfile data={userData.data} completeness={null} />
            {profileData}
        </div>
    )
}

export default AdminSeeUserDetail
