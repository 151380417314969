import axios from 'axios'
import { resolve } from './resolve'
import { settings } from '@utils/index'

export const createEventInterestAPI = async (token, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .post(`/events/interest/`, { ...body }, { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((res) => res.data),
    )
}

export const getIsEventInterestAPI = async (token, event_id) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/events/is-interested/?event_id=${event_id}`, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const getEventInterestedUsersAPI = async (token, event_id) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/events/event-interested/?event_id=${event_id}`, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const getFavoriteEventsAPI = async (token, page, limit, upcoming) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/events/favourites?page=${page}&limit=${limit}&upcoming=${upcoming}`, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}
