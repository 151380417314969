import { useState, useEffect } from 'react'
import { getEventDetailsAPI } from '@api/index'
import { LocalVar, isObjectEmpty, formatDateTime } from '@utils/index'
import { Loading } from '@components/atoms/index'
import { EventReviews, RatingDetails } from '@components/molecules/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faClock, faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const EventData = ({ event_id }) => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        ;(async () => {
            const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

            let eventData = await getEventDetailsAPI(token, event_id)
            if (eventData.error === null) {
                setData(eventData.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        })()
    }, [event_id])

    if (isObjectEmpty(data) || loading) {
        return (
            <div className="min-w-[60vw]">
                <Loading />
            </div>
        )
    }

    return (
        <div className="min-w-[60vw] max-h-[80vh] overflow-y-auto">
            <h3 className="text-primary-green-1">{data.event_name}</h3>
            <div className="grid grid-cols-12">
                <div className="col-span-6">
                    <p className="mt-4 ml-8 text-gray-600 relative text-sm">
                        <FontAwesomeIcon
                            icon={faLocationDot}
                            className="text-primary-green-1 py-[6px] px-[8px] bg-primary-green-8 rounded-full text-sm absolute -left-10 -top-1"
                        />
                        {data.address}, {data.city}, {data.country}
                    </p>
                    <div className="grid grid-cols-2 pt-8 px-8">
                        <div>
                            <h6 className="text-primary-green-1 mb-4 -ml-10">Start date & time</h6>
                            <p className="text-sm relative mb-6">
                                <FontAwesomeIcon
                                    icon={faCalendarAlt}
                                    className="text-primary-green-1 py-[6px] px-[8px] bg-primary-green-8 rounded-full text-sm absolute -left-10 -top-1"
                                />
                                {formatDateTime(data.event_start_date).date}
                            </p>
                            <p className="text-sm relative">
                                <FontAwesomeIcon
                                    icon={faClock}
                                    className="text-primary-green-1 py-[6px] px-[8px] bg-primary-green-8 rounded-full text-sm absolute -left-10 -top-1"
                                />
                                {formatDateTime(data.event_start_date).time}
                            </p>
                        </div>
                        <div>
                            <h6 className="text-primary-green-1 mb-4 -ml-10">End date & time</h6>

                            <p className="text-sm relative mb-6">
                                <FontAwesomeIcon
                                    icon={faCalendarAlt}
                                    className="text-primary-green-1 py-[6px] px-[8px] bg-primary-green-8 rounded-full text-sm absolute -left-10 -top-1"
                                />
                                {formatDateTime(data.event_end_date).date}
                            </p>
                            <p className="text-sm relative">
                                <FontAwesomeIcon
                                    icon={faClock}
                                    className="text-primary-green-1 py-[6px] px-[8px] bg-primary-green-8 rounded-full text-sm absolute -left-10 -top-1"
                                />
                                {formatDateTime(data.event_end_date).time}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-span-6">
                    {data.early_birds_price === data.regular_price && data.regular_price === 0 ? (
                        <p className="flex items-center">
                            <span className="text-primary-green-1 py-2 px-8 text-xl font-bold bg-gray-200 rounded-full">
                                Free
                            </span>
                        </p>
                    ) : (
                        <div className="col-span-12 md:col-span-4 flex gap-4">
                            <p className="flex items-center">
                                <span className="text-primary-green-1 py-2 px-8 text-xl font-bold bg-gray-200 rounded-full">
                                    {'$'}
                                    {data.early_birds_price}
                                </span>
                                <span className="ml-2">Early birds price</span>
                            </p>
                            <p className="flex items-center">
                                <span className="text-primary-green-1 py-2 px-8 text-xl font-bold bg-gray-200 rounded-full">
                                    {'$'}
                                    {data.regular_price}
                                </span>
                                <span className="ml-2">Regular price</span>
                            </p>
                        </div>
                    )}

                    <div className="col-span-12 md:col-span-4">
                        <div>
                            <p className="text-md font-medium relative pt-6">
                                Organizer Name:{' '}
                                <span className="absolute left-[150px] font-semibold">{data.organizer_name}</span>
                            </p>
                            <p className="text-md font-medium relative pt-2">
                                Event Type:{' '}
                                <span className="absolute left-[150px] font-semibold">
                                    {data.virtual ? 'Virtual' : 'In-Person'}
                                </span>
                            </p>
                        </div>
                        <div className="flex gap-16 pl-8 mt-4 py-6">
                            <p className="text-sm relative">
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    className="text-primary-green-1 py-[6px] px-[8px] bg-primary-green-8 rounded-full text-sm absolute -left-10 -top-1"
                                />
                                {data.event_enquiries}
                            </p>
                            <p className="text-sm relative">
                                <FontAwesomeIcon
                                    icon={faPhone}
                                    className="text-primary-green-1 py-[6px] px-[8px] bg-primary-green-8 rounded-full text-sm absolute -left-10 -top-1"
                                />
                                {data.organizer_contact}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 py-8">
                    <div className="grid grid-cols-2 gap-4">
                        <RatingDetails rate={data.rate} />
                        <div>
                            <Link
                                to={`/events/${data.id}`}
                                className="text-xl bg-primary-green-1 text-white inline-block px-4 py-2 rounded-md shadow-sm">
                                Click here for more detail
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 py-8">
                    <div className="w-[50%]">
                        <EventReviews event_id={data.id} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventData
