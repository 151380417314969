import axios from 'axios'
import { resolve } from './resolve'
import { settings } from '@utils/index'

export const createEventAPI = async (token, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .post(
                `/events/`,
                { ...body, ce_speciality: 'Prosthodontics' },
                { headers: { Authorization: `Bearer ${token.session_id}` } },
            )
            .then((res) => res.data),
    )
}

export const createEventsArrayAPI = async (token, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .post(`/events/admin/`, body, { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((res) => res.data),
    )
}

export const getOwnEventsAPI = async (token, page, limit) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/events/?page=${page}&limit=${limit}`, { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((res) => res.data),
    )
}

export const getEventFilterAPI = async (
    by,
    keyword,
    limit,
    page,
    upcoming = 'all',
    featured = null,
    category = 'all',
    ce_speciality = 'all',
    start_price = null,
    end_price = null,
    country = '',
    state_province = '',
    city = '',
    nationality = '',
    start_date = '',
    end_date = '',
    sort_by = 'created_at'
) => {
    let queryStr = `/events/filter?by=${by}&keyword=${keyword}&limit=${limit}&page=${page}&upcoming=${upcoming}&featured=${featured}&category=${category}&ce_speciality=${ce_speciality}&start_price=${start_price}&end_price=${end_price}&start_date=${start_date}&end_date=${end_date}&order_by=${sort_by}`

    if (country !== null && country.length > 0) queryStr += `&country=${country}`
    if (state_province !== null && state_province.length !== 0) queryStr += `&state_province=${state_province}`
    if (city !== null && city.length !== 0) queryStr += `&city=${city}`
    if (nationality !== null && nationality.length !== 0) queryStr += `&nationality=${nationality}`

    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(fetchAPI.get(queryStr).then((res) => res.data))
}

export const getEventDetailsAPI = async (token, eventId) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/events/${eventId}/`, { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((res) => res.data),
    )
}

export const updateEventAPI = async (token, eventId, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .patch(
                `/events/${eventId}/`,
                { ...body, ce_speciality: 'Prosthodontics' },
                { headers: { Authorization: `Bearer ${token.session_id}` } },
            )
            .then((res) => res.data),
    )
}

export const removeEventAPI = async (token, eventId) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .delete(`/events/${eventId}/`, { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((res) => res.data),
    )
}
