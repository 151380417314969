import { faCameraAlt, faCancel, faEdit, faEnvelope, faPhoneAlt, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState, useRef } from 'react'
import { Auth } from '@context/authContext'
import { Button, Modal } from '@components/atoms/index'
import { upperCaseInitLetter } from '@utils/customFunction'
import { ProfileVar, LocalVar } from '@utils/variables'
import { uploadProPicAPI } from '@api/index'
import toast from 'react-hot-toast'

const ViewProPic = () => {
    const defaultImg = '/assets/img/default-pro.jpg'

    const [imgFile, setImgFile] = useState(defaultImg)

    const fileUploadRef = useRef(null)

    // const handleImageUpload = (event) => {
    //     event.preventDefault()
    //     fileUploadRef.current.click()
    // }

    const uploadImageDisplay = () => {
        const uploadedFile = fileUploadRef.current.files[0]
        setImgFile(URL.createObjectURL(uploadedFile))
    }

    const uploadProPic = async (e) => {
        e.preventDefault()

        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const formData = new FormData()
        formData.append('file', fileUploadRef.current.files[0])

        const uploaded = await uploadProPicAPI(token, formData)
        if (uploaded.error === null) {
            toast.success('Profile picture successfully updated!')
            window.location.reload()
        } else {
            toast.error('Failed to upload this photo!')
        }
    }

    return (
        <div>
            <div className="flex justify-center ">
                <img src={imgFile} alt="uploaded image" className="rounded-full w-32 h-32" />
            </div>
            <form id="form" encType="multipart/form-data" className="mt-8">
                <label htmlFor="file" className="text-white bg-primary-green-1 rounded-md px-4 py-2 cursor-pointer">
                    <FontAwesomeIcon icon={faUpload} /> Browse photo
                </label>
                <input
                    type="file"
                    id="file"
                    accept="image/*"
                    ref={fileUploadRef}
                    onChange={uploadImageDisplay}
                    hidden
                />
                {imgFile !== defaultImg ? (
                    <Button onClick={uploadProPic} btnClass="bg-primary-green-1 text-white rounded-md px-4 py-1 mx-2">
                        Upload
                    </Button>
                ) : null}
            </form>
        </div>
    )
}

const UserProfile = ({ data, isEditable = false, setIsEditable = () => {} }) => {
    const { state } = useContext(Auth)

    const [openUpload, setOpenUpload] = useState(false)

    const checkDev = process.env.NODE_ENV !== 'development' ? '/v1' : ''
    const fullImgPath =
        process.env.REACT_APP_BASE_URL + ':' + process.env.REACT_APP_BASE_PORT + checkDev + state?.auth?.profile_pic

    return (
        <div>
            <div className="md:flex md:items-center">
                <span className="relative">
                    <img
                        src={state?.auth?.profile_pic ? fullImgPath : '/assets/img/default-pro.jpg'}
                        alt=""
                        className="bg-gray-300 w-20 h-20 md:w-16 md:h-16 rounded-full shadow-md"
                    />
                    <FontAwesomeIcon
                        icon={faCameraAlt}
                        className="text-primary-green-3 text-lg cursor-pointer absolute right-0 bottom-0 bg-green-100 p-1 rounded-full shadow-md"
                        onClick={() => setOpenUpload(true)}
                    />
                </span>

                {openUpload ? (
                    <Modal setValue={setOpenUpload} title="Upload profile picture">
                        <ViewProPic />
                    </Modal>
                ) : null}

                <h3 className="text-primary-green-1 text-lg md:text-2xl md:ml-6 md:flex md:items-center py-6 md:py-0">
                    {data.full_name}
                    <span className="text-sm font-medium text-gray-400 pl-2">
                        ({upperCaseInitLetter(state.auth.role)})
                    </span>
                </h3>
                {ProfileVar.ADMIN !== state.auth.role ? (
                    <div
                        className="text-primary-green-3 border-primary-green-3 font-semibold text-lg inline-block px-4 py-2 cursor-pointer"
                        onClick={() => setIsEditable((prev) => !prev)}>
                        {!isEditable && (
                            <>
                                <FontAwesomeIcon icon={faEdit} /> Edit
                            </>
                        )}
                        {isEditable && (
                            <>
                                <FontAwesomeIcon icon={faCancel} /> Discard
                            </>
                        )}
                    </div>
                ) : null}
            </div>
            <div className="md:mt-8">
                <p className="text-gray-600 font-semibold text-sm md:text-base">
                    <FontAwesomeIcon icon={faEnvelope} />{' '}
                    <span className="font-medium">{data.identifier || data.email_address}</span>
                </p>
                <p className="text-gray-600 font-semibold text-sm md:text-sm mt-4">
                    <FontAwesomeIcon icon={faPhoneAlt} />{' '}
                    <span className="font-medium">
                        {data.phone_country_prefix}
                        {data.phone_number}
                    </span>
                </p>
                {data.completeness !== null && (
                    <div>
                        <p className="text-sm text-gray-600 mt-4">Profile completed: {data.completeness}%</p>
                        <div className="border-2 border-gray-200 h-2 w-full mt-2 relative rounded-full">
                            <span
                                className={
                                    'h-2 bg-primary-green-3 absolute left-0 top-0 rounded-full' +
                                    ' w-[' +
                                    data.completeness.toString() +
                                    '%]'
                                }></span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default UserProfile
