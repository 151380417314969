import { AuthTemplate } from '../templates'
import { ResetPassForm } from '../molecules'
import { ProjectVar } from '@utils/index'
import { Header } from '@components/organisms/index'
const ResetPasswordPage = () => {
    return (
        <div>
            <Header title="Reset password" description={ProjectVar.PROJECT_NAME} />
            <AuthTemplate>
                <ResetPassForm />
            </AuthTemplate>
        </div>
    )
}

export default ResetPasswordPage
