import { Link } from 'react-router-dom'
import { ProjectVar } from '@utils/index'

const AuthTemplate = ({ children }) => {
    return (
        <div>
            <div className="bg-primary-green-1 h-full w-5/12 fixed md:flex items-center hidden">
                <Link to="/">
                    <img
                        src={ProjectVar.PROJECT_LOGO_WHITE}
                        alt={ProjectVar.PROJECT_NAME}
                        className="w-[100px] md:w-[180px] absolute left-16 top-6"
                    />
                </Link>
                <h1 className="font-bold text-white absolute left-16 scale-100 xl:scale-125 xl:left-24">
                    A Roadmap to
                    <br /> <span className="golden-txt">Career Growth</span> and
                    <br /> Personal Fulfillment.
                </h1>
            </div>
            <div className="md:absolute right-0 top-0 md:w-7/12 h-full p-4 md:p-0">
                <Link to="/" className="md:hidden sm:block">
                    <img src={ProjectVar.PROJECT_LOGO_GREEN} alt={ProjectVar.PROJECT_NAME} className="w-[180px]" />
                </Link>
                {children}
            </div>
        </div>
    )
}
export default AuthTemplate
