import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'

const root = ReactDOM.createRoot(document.getElementById('root'))

const googleClientId = process.env.REACT_APP_GA_CLIENT

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <GoogleOAuthProvider clientId={googleClientId}>
                <App />
            </GoogleOAuthProvider>
        </BrowserRouter>
    </React.StrictMode>,
)
