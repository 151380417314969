import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Input, Button } from '@components/atoms/index'
import { useNavigate, Link } from 'react-router-dom'
import { resetPasswordAPI } from '@api/index'

const ResetPassForm = () => {
    const [token, setToken] = useState('')
    const [newPass, setNewPass] = useState('')
    const [cnfPass, setCnfPass] = useState('')
    const [done, setDone] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (done === true) {
            navigate('/login')
        }
    }, [done, navigate])

    const submit = async (e) => {
        e.preventDefault()

        if (loading === true) return
        setLoading(true)

        if (newPass !== cnfPass) {
            toast.error('Password not confirmed')
            return
        }

        let body = {
            token,
            new_secret: newPass,
        }

        const resetPass = await resetPasswordAPI(body)
        if (resetPass.error === null) {
            toast.success('Password Reset Successful')
            setLoading(false)
            setDone(true)
        } else {
            const pop = resetPass.error.response.data.errors[0].message || 'Something went wrong'
            toast.error(pop)
            setLoading(false)
        }
    }

    return (
        <div className="h-full w-full flex justify-center overflow-auto">
            <div className="w-[400px] m-auto py-8">
                <h2 className="font-w6 py-4">Reset password?</h2>
                <form onSubmit={submit}>
                    <Input name="token" label="Token" setValue={setToken} placeHolder="Your token" required={true} />
                    <p className="text-gray-700 py-4">Enter your new password.</p>
                    <Input
                        name="new-password"
                        label="New password"
                        type="password"
                        wrapperClass="relative"
                        eyeIconClass="cursor-pointer absolute right-4 top-12"
                        setValue={setNewPass}
                        placeHolder="Your new password"
                        required={true}
                    />
                    <Input
                        name="cnf-password"
                        label="Confirm password"
                        type="password"
                        wrapperClass="relative"
                        eyeIconClass="cursor-pointer absolute right-4 top-12"
                        setValue={setCnfPass}
                        placeHolder="Confirm your password"
                        required={true}
                    />

                    <Button
                        btnClass="bg-primary-green-1 text-white p-4 w-full block mt-6 cursor-pointer rounded-md active:bg-primary-green-10 active:text-primary-green-1 border-2 border-primary-green-1 hover:bg-primary-green-2"
                        loading={loading}>
                        Submit
                    </Button>
                </form>
                <p className="font-semibold text-gray-400 text-center my-8">OR</p>
                <p className="font-semibold text-gray-400 text-center mt-8">
                    Remember password?{' '}
                    <Link to="/login" className="text-primary-green-1">
                        Login
                    </Link>
                </p>
            </div>
        </div>
    )
}
export default ResetPassForm
