import React, { useEffect, useState } from 'react'
import { Header, Footer } from '@components/organisms'
import { MainNav, HowItWorks } from '@components/molecules/index'
import { Button, HeroSearchBar, Input, Pagination } from '@components/atoms/index'
import SponsorsBanner from '../../assets/img/sponsors-banner.png'
import { dentalCategoryOptions, EventCategoryArray, eventsByOptions } from '@utils/variables'
import Slider from '@mui/material/Slider'
import { CitySelect, CountrySelect, StateSelect } from 'react-country-state-city/dist/cjs'
import { useLocation } from 'react-router-dom'
import { Accordion, AccordionDetails, AccordionSummary, Box, Hidden, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { EventCard } from '@components/atoms/events'
import { getEventFilterAPI } from '@api/eventsApi'
import FilterListIcon from '@mui/icons-material/FilterList'

const VirtualCEPage = () => {
    const [data, setData] = useState({ data: [] })
    const [loading, setLoading] = useState(false)
    const [searchBy, setSearchBy] = useState('all')
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(100)
    const [upcoming, setUpcoming] = useState('all')
    const [featured, setFeatured] = useState(null)
    const [category, setCategory] = useState('all')
    const [priceValue, setPriceValue] = useState([null, null])
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [nationality, setNationality] = useState('')
    const [eventsBy, setEventsBy] = useState('upcoming')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [expanded, setExpanded] = useState('panel0')
    const [mobFilterExpanded, setMobFilterExpanded] = useState('')
    const [ceSpecialities, setCeSpecialities] = useState('all')
    const [speakerRating, setSpeakerRating] = useState('all')
    const [priceRange, setPriceRange] = useState('all')
    const [stateid, setstateid] = useState('')
    const [countryEmoji, setCountryEmoji] = useState('')
    const [countryid, setCountryid] = useState('')
    const [selectedFilters, setSelectedFilters] = useState([])
    const [catLocal, setCatLocal] = useState('')
    

    useEffect(() => {
        let preparedCES = ceSpecialities.toString().split(',')
        let ces = preparedCES.includes('all') ? 'all' : ceSpecialities
        ;(async () => {
            if (search.length === 0) {
                setData([])
            }
            let preparedUpcomming = eventsBy === 'upcoming' ? true : eventsBy === 'previous' ? false : 'all'
            if (startDate && endDate) {
                const eventData = await getEventFilterAPI(
                    searchBy,
                    search,
                    limit,
                    page,
                    preparedUpcomming,
                    featured,
                    category,
                    ces,
                    priceValue[0],
                    priceValue[1],
                    country,
                    state,
                    city,
                    nationality,
                    startDate,
                    endDate,
                )
                if (eventData.error === null) {
                    setData({ ...eventData.data.data, data: [...eventData.data.data.data] })
                } else {
                    setData([])
                }
            } else {
                const eventData = await getEventFilterAPI(
                    searchBy,
                    search,
                    limit,
                    page,
                    preparedUpcomming,
                    featured,
                    category,
                    ces,
                    priceValue[0],
                    priceValue[1],
                    country,
                    state,
                    city,
                    nationality,
                    '',
                    '',
                )
                if (eventData.error === null) {
                    setData({ ...eventData.data.data, data: [...eventData.data.data.data] })
                } else {
                    setData([])
                }
            }
        })()
    }, [
        searchBy,
        search,
        page,
        limit,
        upcoming,
        featured,
        category,
        ceSpecialities,
        priceValue,
        country,
        state,
        city,
        nationality,
        eventsBy,
        startDate,
        endDate,
    ])

    const handleFilterResetReset = () => {
        setSearchBy('all')
        setPage(0)
        setCategory('all')
        setCeSpecialities('all')
        setPriceValue([null, null])
        setCountry('')
        setCountryid('')
        setCountryEmoji('')
        setstateid('')
        setState('')
        setCity('')
        setStartDate('')
        setEndDate('')
        setEventsBy('all')
        window.location.reload()
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const handleEventsByChange = (evntBy) => {
        if (evntBy === 'thisWeek') {
            const startOfWeek = dayjs().startOf('week').add(1, 'days') // Monday
            const endOfWeek = dayjs().startOf('week').add(7, 'days') // Sunday
            setStartDate(startOfWeek.format('YYYY-MM-DD'))
            setEndDate(endOfWeek.format('YYYY-MM-DD'))
        } else if (evntBy === 'thisWeekend') {
            const saturday = dayjs().startOf('week').add(6, 'day') // Saturday
            const sunday = dayjs().startOf('week').add(7, 'day') // Sunday
            setStartDate(saturday.format('YYYY-MM-DD'))
            setEndDate(sunday.format('YYYY-MM-DD'))
        } else if (evntBy === 'thisMonth') {
            const startOfMonth = dayjs().startOf('month') // First day of the month
            const endOfMonth = dayjs().endOf('month') // Last day of the month
            setStartDate(startOfMonth.format('YYYY-MM-DD'))
            setEndDate(endOfMonth.format('YYYY-MM-DD'))
        } else if (evntBy === 'upcoming') {
            setStartDate('')
            setEndDate('')
        } else if (eventsBy === 'previous') {
            setStartDate('')
            setEndDate('')
        }
        setEventsBy(evntBy)
    }

    const handleCeSpecilities = (param) => {
        setCeSpecialities((prevCeSpecilities) => {
            // Split the string into an array
            const ceSpecialitiesArray = prevCeSpecilities ? prevCeSpecilities.split(',') : []

            let newCeSpecialitiesArray
            if (ceSpecialitiesArray.includes(param)) {
                newCeSpecialitiesArray = ceSpecialitiesArray.filter((n) => n !== param)
            } else {
                newCeSpecialitiesArray = [...ceSpecialitiesArray, param]
            }

            // Join the array elements back into a string
            return newCeSpecialitiesArray.join(',')
        })
    }

    const priceHandleChange = (event, newValue) => {
        setPriceValue(newValue)
    }

    function valuetext(value) {
        return `$${value}`
    }

    const dataArrtoStr = (dta) => {
        if (dta?.length === 0) {
            return []
        }
        let arr = []
        dta?.map((v, i) => {
            let filterName = searchBy
            if (searchBy === 'all') filterName = 'event_name'
            return arr.push(v[filterName])
        })
        return arr
    }

    const handleMobParentAccordionChange = (panel) => (event, isExpanded) => {
        setMobFilterExpanded(isExpanded ? panel : false)
    }

    return (
        <div className="bg-light-main-green">
            <Header title="VirtualCE" description="DentiCE | A Roadmap to Career Growth and Personal Fulfillment." />
            <MainNav />
            <div className="md:px-8">
                <div className="h-[200px] flex justify-center rounded">
                    <img src={SponsorsBanner} alt="" />
                </div>
            </div>
            <div className="md:px-8 py-4">
                <div className="relative">
                    <HeroSearchBar
                        setValue={setSearch}
                        placeHolder={`Search by ${searchBy.replaceAll('_', ' ')}`}
                        data={dataArrtoStr(data?.data)}
                    />
                    <div className="absolute -right-5 top-3">
                        <HowItWorks />
                    </div>
                </div>
            </div>
            {/* <div className="md:container mx-auto p-4"> */}
            <Hidden smDown>
                <div className="flex">
                    <div className="w-3/12 p-4 bg-light-main-green">
                        <div className="bg-white p-4 h-full">
                            {/* <div onSubmit={handleFilterSubmit}> */}
                            <Button onClick={() => handleFilterResetReset()}>Reset Filters</Button>
                            <div>
                                <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel0bh-content"
                                        id="panel0bh-header">
                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>Date</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            {eventsByOptions.map((cat, i) => (
                                                <>
                                                    <div className="mt-2 flex gap-0" key={i}>
                                                        <input
                                                            type="checkbox"
                                                            checked={eventsBy === cat?.value}
                                                            onChange={() => handleEventsByChange(cat?.value)}
                                                            className="mr-2"
                                                            id={'spec' + i.toString()}
                                                        />
                                                        <label for={'spec' + i.toString()} className="cursor-pointer">
                                                            {cat?.text || ''}
                                                        </label>
                                                    </div>
                                                </>
                                            ))}
                                            {eventsBy === 'custom' && (
                                                <>
                                                    <div className="col-span-12 md:col-span-6">
                                                        <Input
                                                            type="date"
                                                            name="event-start-date"
                                                            label="Start date"
                                                            labelClass="text-md font-semibold"
                                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                                                            placeHolder=""
                                                            value={startDate}
                                                            setValue={setStartDate}
                                                            required={true}
                                                        />
                                                    </div>
                                                    <div className="col-span-12 md:col-span-6">
                                                        <Input
                                                            type="date"
                                                            name="event-end-date"
                                                            label="End date"
                                                            labelClass="text-md font-semibold"
                                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                                                            placeHolder=""
                                                            value={endDate}
                                                            setValue={setEndDate}
                                                            required={true}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header">
                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>Events Type</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            {[{ key: 'ALL', value: { value: 'all' } }]
                                                .concat(EventCategoryArray)
                                                .map((cat, i) => (
                                                    <div className="mt-2 flex gap-0" key={i}>
                                                        <input
                                                            type="checkbox"
                                                            checked={category === cat?.value?.value}
                                                            onChange={() => setCategory(cat?.value?.value)}
                                                            className="mr-2"
                                                            id={'cat' + i.toString()}
                                                        />
                                                        <label for={'cat' + i.toString()} className="cursor-pointer">
                                                            {cat?.key || ''}
                                                        </label>
                                                    </div>
                                                ))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header">
                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>CE Speciality</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            {[{ text: 'All', value: 'all' }]
                                                .concat(dentalCategoryOptions)
                                                .map((cat, i) => (
                                                    <div className="mt-2 flex gap-0" key={i}>
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                ceSpecialities.includes(cat?.value) ||
                                                                ceSpecialities.includes('all')
                                                            }
                                                            onChange={() => handleCeSpecilities(cat?.value)}
                                                            className="mr-2"
                                                            id={'spec' + i.toString()}
                                                        />
                                                        <label for={'spec' + i.toString()} className="cursor-pointer">
                                                            {cat?.text || ''}
                                                        </label>
                                                    </div>
                                                ))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel5bh-content"
                                        id="panel5bh-header">
                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>Price</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <Box sx={{ width: '100%' }}>
                                                <Slider
                                                    getAriaLabel={() => 'Price range'}
                                                    value={priceValue}
                                                    onChange={priceHandleChange}
                                                    valueLabelDisplay="auto"
                                                    getAriaValueText={valuetext}
                                                    min={0}
                                                    max={1200}
                                                />
                                            </Box>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel6bh-content"
                                        id="panel6bh-header">
                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>Country/State/City</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <h6>Country</h6>
                                            <CountrySelect
                                                onChange={(e) => {
                                                    setCountryEmoji(e.emoji)
                                                    setCountryid(e.id)
                                                    setCountry(e?.name || '')
                                                }}
                                                placeHolder="Select Country"
                                            />
                                            <h6>State</h6>
                                            <StateSelect
                                                countryid={countryid}
                                                onChange={(e) => {
                                                    setstateid(e.id)
                                                    setState(e?.name || '')
                                                }}
                                                placeHolder="Select State"
                                            />
                                            <h6>City</h6>
                                            <CitySelect
                                                countryid={countryid}
                                                stateid={stateid}
                                                onChange={(e) => {
                                                    setCity(e?.name || '')
                                                }}
                                                placeHolder="Select City"
                                            />
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    <div className="w-9/12 p-4">
                        <div className="md:container">
                            {loading ? (
                                <div className="flex justify-center items-center min-h-[200px]">
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        className="motion-safe:animate-spin text-4xl text-primary-green-2"
                                    />
                                </div>
                            ) : (
                                <>
                                    <p className="font-bold mb-3 golden-txt text-xl">
                                        Total Events : <span>{data?.total_data_count || 0}</span>
                                    </p>
                                    <div className="grid grid-cols-1 md:grid-cols-12 gap-4 overflow-y-scroll max-h-[1000px] pb-4">
                                        {data?.data?.length !== 0
                                            ? data?.data?.map((v, i) => {
                                                  return (
                                                      <div key={i} className="col-span-12 md:col-span-6 xl:col-span-4">
                                                          <EventCard data={v} />
                                                      </div>
                                                  )
                                              })
                                            : null}
                                    </div>
                                </>
                            )}
                            {data?.data?.length === 0 && !loading && (
                                <div className="flex justify-center items-center bg-white rounded-md border-2 border-slate-200 py-8">
                                    <h3 className="text-gray-400 text-center font-medium">
                                        <FontAwesomeIcon icon={faBan} /> No events found
                                    </h3>
                                </div>
                            )}
                            {data.total_data_count > 20 ? (
                                <Pagination
                                    prevPageCount={data.prev_page_count}
                                    currentPageProp={data.current_page}
                                    nextPageCount={data.next_page_count}
                                    setPage={setPage}
                                />
                            ) : null}
                        </div>
                        {/* <EventCards
                        title="VirtualCE Events"
                        by="all"
                        keyword="virtual"
                        // upcoming={true}
                        upcoming={'all'}
                        featured={null}
                        limitPerPage={6}
                    /> */}
                    </div>
                </div>
            </Hidden>
            <Hidden smUp>
                <div className="w-full p-4 bg-light-main-green">
                    <div className="h-full w-full">
                        <div className="pt-4">
                            <Accordion
                                expanded={mobFilterExpanded === 'parent'}
                                onChange={handleMobParentAccordionChange('parent')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panelParentbh-content"
                                    id="panelParentbh-header">
                                    <Typography className="flex items-center" sx={{ width: '75%', flexShrink: 0 }}>
                                        <FilterListIcon /> Filter by
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        <div>
                                            <Button onClick={() => handleFilterResetReset()}>Reset Filters</Button>
                                            <div>
                                                <Accordion
                                                    expanded={expanded === 'panel0'}
                                                    onChange={handleChange('panel0')}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel0bh-content"
                                                        id="panel0bh-header">
                                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>
                                                            Date
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div>
                                                            {eventsByOptions.map((cat, i) => (
                                                                <>
                                                                    <div className="mt-2 flex gap-0" key={i}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={eventsBy === cat?.value}
                                                                            onChange={() =>
                                                                                handleEventsByChange(cat?.value)
                                                                            }
                                                                            className="mr-2"
                                                                            id={'spec' + i.toString()}
                                                                        />
                                                                        <label
                                                                            for={'spec' + i.toString()}
                                                                            className="cursor-pointer">
                                                                            {cat?.text || ''}
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            ))}
                                                            {eventsBy === 'custom' && (
                                                                <>
                                                                    <div className="col-span-12 md:col-span-6">
                                                                        <Input
                                                                            type="date"
                                                                            name="event-start-date"
                                                                            label="Start date"
                                                                            labelClass="text-md font-semibold"
                                                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                                                                            placeHolder=""
                                                                            value={startDate}
                                                                            setValue={setStartDate}
                                                                            required={true}
                                                                        />
                                                                    </div>
                                                                    <div className="col-span-12 md:col-span-6">
                                                                        <Input
                                                                            type="date"
                                                                            name="event-end-date"
                                                                            label="End date"
                                                                            labelClass="text-md font-semibold"
                                                                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                                                                            placeHolder=""
                                                                            value={endDate}
                                                                            setValue={setEndDate}
                                                                            required={true}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion
                                                    expanded={expanded === 'panel1'}
                                                    onChange={handleChange('panel1')}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header">
                                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>
                                                            Events Type
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div>
                                                            {[{ key: 'ALL', value: { value: 'all' } }]
                                                                .concat(EventCategoryArray)
                                                                .map((cat, i) => (
                                                                    <div className="mt-2 flex gap-0" key={i}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={category === cat?.value?.value}
                                                                            onChange={() =>
                                                                                setCategory(cat?.value?.value)
                                                                            }
                                                                            className="mr-2"
                                                                            id={'cat' + i.toString()}
                                                                        />
                                                                        <label
                                                                            for={'cat' + i.toString()}
                                                                            className="cursor-pointer">
                                                                            {cat?.key || ''}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion
                                                    expanded={expanded === 'panel2'}
                                                    onChange={handleChange('panel2')}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel2bh-content"
                                                        id="panel2bh-header">
                                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>
                                                            CE Speciality
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div>
                                                            {[{ text: 'All', value: 'all' }]
                                                                .concat(dentalCategoryOptions)
                                                                .map((cat, i) => (
                                                                    <div className="mt-2 flex gap-0" key={i}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                ceSpecialities.includes(cat?.value) ||
                                                                                ceSpecialities.includes('all')
                                                                            }
                                                                            onChange={() =>
                                                                                handleCeSpecilities(cat?.value)
                                                                            }
                                                                            className="mr-2"
                                                                            id={'spec' + i.toString()}
                                                                        />
                                                                        <label
                                                                            for={'spec' + i.toString()}
                                                                            className="cursor-pointer">
                                                                            {cat?.text || ''}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion
                                                    expanded={expanded === 'panel5'}
                                                    onChange={handleChange('panel5')}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel5bh-content"
                                                        id="panel5bh-header">
                                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>
                                                            Price
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div>
                                                            <Box sx={{ width: '100%' }}>
                                                                <Slider
                                                                    getAriaLabel={() => 'Price range'}
                                                                    value={priceValue}
                                                                    onChange={priceHandleChange}
                                                                    valueLabelDisplay="auto"
                                                                    getAriaValueText={valuetext}
                                                                    min={0}
                                                                    max={1200}
                                                                />
                                                            </Box>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion
                                                    expanded={expanded === 'panel6'}
                                                    onChange={handleChange('panel6')}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel6bh-content"
                                                        id="panel6bh-header">
                                                        <Typography sx={{ width: '75%', flexShrink: 0 }}>
                                                            Country/State/City
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div>
                                                            <h6>Country</h6>
                                                            <CountrySelect
                                                                onChange={(e) => {
                                                                    setCountryEmoji(e.emoji)
                                                                    setCountryid(e.id)
                                                                    setCountry(e?.name || '')
                                                                }}
                                                                placeHolder="Select Country"
                                                            />
                                                            <h6>State</h6>
                                                            <StateSelect
                                                                countryid={countryid}
                                                                onChange={(e) => {
                                                                    setstateid(e.id)
                                                                    setState(e?.name || '')
                                                                }}
                                                                placeHolder="Select State"
                                                            />
                                                            <h6>City</h6>
                                                            <CitySelect
                                                                countryid={countryid}
                                                                stateid={stateid}
                                                                onChange={(e) => {
                                                                    setCity(e?.name || '')
                                                                }}
                                                                placeHolder="Select City"
                                                            />
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>

                        {/* Events */}
                        <div className="w-full mb-4">
                            <div className="pt-4">
                                {/* {!search && (
                                        <EventCards
                                            title={upcoming ? 'Upcoming events' : 'Previous events'}
                                            by="event_name"
                                            keyword=""
                                            upcoming={upcoming}
                                            featured={null}
                                            limitPerPage={20}
                                            isMiddleSection={true}
                                        />
                                    )} */}
                                {loading ? (
                                    <div className="flex justify-center items-center min-h-[200px]">
                                        <FontAwesomeIcon
                                            icon={faSpinner}
                                            className="motion-safe:animate-spin text-4xl text-primary-green-2"
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <p className="font-bold mb-3 golden-txt text-xl">
                                            Total Events : <span>{data?.total_data_count || 0}</span>
                                        </p>
                                        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                                            {data?.data?.length !== 0
                                                ? data?.data?.map((v, i) => {
                                                      return (
                                                          <div key={i} className="col-span-12">
                                                              <EventCard data={v} />
                                                          </div>
                                                      )
                                                  })
                                                : null}
                                        </div>
                                    </>
                                )}
                                {data?.data?.length === 0 && !loading && (
                                    <div className="flex justify-center items-center bg-white rounded-md border-2 border-slate-200 py-8">
                                        <h3 className="text-gray-400 text-center font-medium">
                                            <FontAwesomeIcon icon={faBan} /> No events found
                                        </h3>
                                    </div>
                                )}
                                {data?.data?.total_data_count > 20 ? (
                                    <Pagination
                                        prevPageCount={data?.data?.dataprev_page_count}
                                        currentPageProp={data?.data?.current_page}
                                        nextPageCount={data?.data?.next_page_count}
                                        setPage={setPage}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </Hidden>
            {/* {open === 5 && (
                <Modal title="Update" setValue={setOpen}>
                    <h4 className="text-primary-green-1">New Features for this page is coming soon!</h4>
                </Modal>
            )} */}

            <Footer />
        </div>
    )
}

export default VirtualCEPage
