import { useState } from 'react'
import { useMultiStepForm } from '../../hooks'
import { TellUs, KindOfEvents, LoveToKnow, AddDetails } from '../molecules/Forms/OrganizerCreate'
import KeyLogin from '../../assets/img/key_login.png'
import axios from 'axios'
import { LocalVar, settings } from '../../utils'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const ProfileOrganizerCreateTemplate = () => {
    const [companyName, setCompanyName] = useState('')
    const [yourRole, setYourRole] = useState('')
    const [howManyEventsOrganize, setHowManyEventsOrganize] = useState(0)
    const [orgName, setOrgName] = useState('')
    const [orgEmail, setOrgEmail] = useState('')
    const [orgAddress, setOrgAddress] = useState('')
    const [kindsOfEventsHold, setKindsOfEventHold] = useState([])
    const [needSponsor, setNeedSponsor] = useState(false)
    const [provideCeCredits, setProvideCeCredits] = useState(false)
    const [budget, setBudget] = useState('')

    const { step, steps, currentStepIndex, isFirstStep, isLastStep, back, next } = useMultiStepForm([
        <AddDetails
            companyName={companyName}
            setCompanyName={setCompanyName}
            yourRole={yourRole}
            setYourRole={setYourRole}
            howManyEventsOrganize={howManyEventsOrganize}
            setHowManyEventsOrganize={setHowManyEventsOrganize}
        />,
        <TellUs
            orgName={orgName}
            setOrgName={setOrgName}
            orgEmail={orgEmail}
            setOrgEmail={setOrgEmail}
            orgAddress={orgAddress}
            setOrgAddress={setOrgAddress}
        />,
        <KindOfEvents setKindsOfEventHold={setKindsOfEventHold} />,
        <LoveToKnow
            needSponsor={needSponsor}
            setNeedSponsor={setNeedSponsor}
            provideCeCredits={provideCeCredits}
            setProvideCeCredits={setProvideCeCredits}
            setBudget={setBudget}
        />,
    ])

    const onSubmit = (e) => {
        e.preventDefault()
        if (!isLastStep) return next()

        // here start my code
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        let body = {
            company_name: companyName,
            your_role: yourRole,
            organization_name: orgName,
            organization_email: orgEmail,
            address: orgAddress,
            per_year_organize_events: howManyEventsOrganize,
            do_you_provide_ce_credits: provideCeCredits,
            kinds_of_events_you_hold: kindsOfEventsHold,
            do_you_need_sponser: needSponsor,
            how_much_budget_you_need: needSponsor ? budget : "0",
        }

        const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
        fetchAPI
            .post('/profile-organizers/', { ...body }, { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((data) => {
                toast('Profile successfully created!', {
                    icon: '👋',
                })
                window.location.reload()
            })
            .catch((err) => toast.error('Failed to create profile.'))
    }

    return (
        <div className="relative">
            <div className="w-full md:w-7/12 min-h-screen flex justify-center items-center">
                <form
                    onSubmit={onSubmit}
                    className="w-[600px] bg-gray-100 py-12 px-6 md:py-20 md:px-12 rounded-lg m-4 relative">
                    <p className="absolute top-4 left-6 md:top-10 md:left-12 text-sm text-primary-green-1 font-semibold">
                        {currentStepIndex + 1} / {steps.length}
                    </p>
                    {step}
                    <div>
                        <div className="">
                            {!isFirstStep && (
                                <button
                                    type="button"
                                    onClick={back}
                                    className="text-primary-green-1 font-semibold text-xl bg-slate-200 py-2 px-12 block w-full rounded-md mt-12">
                                    Back
                                </button>
                            )}
                            <button
                                type="submit"
                                className="text-white font-semibold text-xl bg-primary-green-1 py-2 px-12 block w-full rounded-md mt-8">
                                {currentStepIndex === 0 ? 'Get started' : isLastStep ? 'Submit' : 'Next'}
                            </button>
                            {isLastStep ? null : (
                                <button
                                    type="submit"
                                    className="text-primary-green-1 text-xl font-semibold mx-auto block mt-6">
                                    Skip for now
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
            <div className="fixed w-full md:w-5/12 h-screen right-0 top-0 bg-green-100 md:flex justify-center items-center p-4 hidden">
                <Link to="/" className="absolute right-8 top-8 text-primary-green-1 text-lg font-semibold">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to Main page
                </Link>
                <img src={KeyLogin} alt="goal_image" />
            </div>
        </div>
    )
}

export default ProfileOrganizerCreateTemplate
