import { faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

const Modal = ({
    children,
    title,
    setValue = () => {},
    popupCrossClass = 'text-xs absolute right-3 top-3 text-white bg-red-500 py-[5px] px-[6px] rounded-full cursor-pointer',
    popupTitleClass = 'text-center mt-2 mb-4 golden-txt',
}) => {
    const [isOpen, setIsOpen] = useState(true)

    const setLocalValue = () => {
        setIsOpen(false)
        setValue(false)
    }

    if (isOpen) {
        return (
            <div className="fixed left-0 top-0 h-full w-full z-20 bg-gray-800 bg-opacity-50 flex justify-center items-center shadow-md">
                <div
                    className="bg-gray-600 bg-opacity-50 fixed left-0 top-0 h-full w-full z-30"
                    onClick={setLocalValue}></div>
                <div className="bg-light-main-green border-2 border-primary-golden-5 inline-block rounded-md p-8 z-50 relative max-h-[80vh] mx-4 overflow-y-auto">
                    <FontAwesomeIcon icon={faX} className={popupCrossClass} onClick={setLocalValue} />
                    <h3 className={popupTitleClass}>{title}</h3>
                    <div>{children}</div>
                </div>
            </div>
        )
    }
}

export default Modal
