import { useEffect, useState } from 'react'
import { Header, Footer } from '../organisms'
import { FindEeventsTemplates } from '../templates'
import { Modal } from '@components/atoms/index'

const EventFinderPage = () => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const seenModal = localStorage.getItem('seenCEFModal')
        if (!seenModal) {
            setOpen(true)
            localStorage.setItem('seenCEFModal', true)
        }
    }, [])

    return (
        <div>
            <Header title="Find Event" description="DentiCE Event page" />
            <FindEeventsTemplates />

            {/* {open && (
                <Modal title="CE Finder" setValue={setOpen}>
                    <h4 className="text-primary-green-1">
                        Explore All Events! Unlock a World of CE Opportunities Tailored to Your Interests. Create Your
                        Profile to Get Started.
                    </h4>
                </Modal>
            )} */}
            <Footer />
        </div>
    )
}

export default EventFinderPage
