import { AuthTemplate } from '@components/templates'
import { SignupAdminForm } from '@components/molecules'
import { Header } from '@components/organisms'

const SignupAdminPage = () => {
    return (
        <div>
            <Header title="Signup as Admin" description="DentiCE signup page" />
            <AuthTemplate>
                <SignupAdminForm />
            </AuthTemplate>
        </div>
    )
}

export default SignupAdminPage
