import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { useSlider } from '@hooks/index'

const PrevBtn = ({ onClick }) => {
    return (
        <button
            className="absolute bottom-[-50px] left-[calc(50%-40px)] cursor-pointer py-2 px-4 bg-primary-green-2 text-primary-golden-5 rounded-full"
            onClick={onClick}>
            <FontAwesomeIcon icon={faAngleLeft} />
        </button>
    )
}

const NextBtn = ({ onClick }) => {
    return (
        <button
            className="absolute bottom-[-50px] left-[calc(50%+40px)] cursor-pointer py-2 px-4 bg-primary-green-2 text-primary-golden-5 rounded-full"
            onClick={onClick}>
            <FontAwesomeIcon icon={faAngleRight} />
        </button>
    )
}

const SingleSlide = ({ data }) => {
    return (
        <div className="border-2 border-primary-golden-5 bg-dark-green rounded-md p-6 active:cursor-grabbing mx-2 min-h-[220px]">
            <h3 className="golden-txt mb-2">{data.title}</h3>
            <p className="text-white leading-8 tracking-wide">{data.detail}</p>
        </div>
    )
}

const MentorshipPageSlider = () => {
    const data = [
        {
            title: 'Guidance and Support',
            detail: 'Gain valuable insights and advice from experienced mentors who have excelled in their respective fields of dentistry.',
        },
        {
            title: 'Networking Opportunities',
            detail: 'Build professional relationships and expand your network within the dental community by connecting with mentors and mentees who share your interests and goals.',
        },
        {
            title: 'Personalized Matches',
            detail: 'Our AI-powered matchmaking technology pairs mentors and mentees based on their profiles, ensuring meaningful and productive mentorship experiences.',
        },
        {
            title: 'Continuous Learning',
            detail: "Whether you're a mentor or a mentee, participating in our program offers opportunities for continuous learning and professional development.",
        },
        {
            title: 'Get Involved Today!',
            detail: 'Become a mentor or mentee with DentiCE and embark on a rewarding journey of growth, collaboration, and success in the field of dentistry.',
        },
    ]
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <PrevBtn />,
        nextArrow: <NextBtn />,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    const { FinalSlider } = useSlider(settings, data, SingleSlide)
    return (
        <div className="py-4 my-8">
            <h1 className="golden-txt py-6 text-center">Why Join DentiCE Mentor & Mentee Program?</h1>
            <div className="md:container mx-auto p-4 relative">
                <FinalSlider />
            </div>
        </div>
    )
}

export default MentorshipPageSlider
