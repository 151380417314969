import { useEffect, useState } from 'react'
import { LocalVar } from '@utils/index'
import { updateEventAPI } from '@api/index'
import { Input, Button, TextArea, DropDown } from '@components/atoms/index'
import toast from 'react-hot-toast'
import { EventCategoryArray, virtualOptions, timeZones } from '@utils/index'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const EventEditTemplate = ({ data }) => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [timeZone, setTimeZone] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [organizer, setOrganizer] = useState('')
    const [orgPhone, setOrgPhone] = useState('')
    const [countryName, setCountryName] = useState('')
    const [cityName, setCityName] = useState('')
    const [address, setAddress] = useState('')
    const [earlyBirdsPrice, setEarlyBirdsPrice] = useState(0)
    const [regularPrice, setRegularPrice] = useState(0)
    const [regLink, setRegLink] = useState('')
    const [enq, setEnq] = useState('')
    const [virtual, setVirtual] = useState('')
    const [category, setCategory] = useState('')
    const [ce, setCe] = useState('')
    const [nat, setNat] = useState('')
    const [speaker, setSpeaker] = useState('')
    const [speakers, setSpeakers] = useState([])
    const [loading, setLoading] = useState(false)

    const tz = []
    timeZones.forEach((v) => {
        tz.push({ text: `${v.offset} ${v.cities}`, value: v.offset })
    })

    useEffect(() => {
        setTitle(data.event_name)
        setDescription(data.description)
        setStartDate(data.event_start_date?.split(':')[0] + ':' + data.event_start_date?.split(':')[1])
        setEndDate(data.event_end_date?.split(':')[0] + ':' + data.event_end_date?.split(':')[1])
        setTimeZone(data.time_zone)
        setOrganizer(data.organizer_name)
        setOrgPhone(data.organizer_contact)
        setCountryName(data.country)
        setCityName(data.city)
        setAddress(data.address)
        setEarlyBirdsPrice(data.early_birds_price)
        setRegularPrice(data.regular_price)
        setRegLink(data.registration_link)
        setEnq(data.event_enquiries)
        setVirtual(data.virtual)
        setCategory(data.category)
        setCe(data.number_of_ce)
        setNat(data.nationality)
        setSpeakers(data.speakers_with_cv)
    }, [data])

    let categories = EventCategoryArray.map((v, i) => v.value)

    const addSpeaker = (e) => {
        e.preventDefault()
        if (speaker.length === 0) return
        setSpeakers([...speakers, speaker])
        setSpeaker('')
    }

    const removeSpeaker = (po) => {
        let arr = [...speakers]
        arr.splice(po, 1)
        setSpeakers([...arr])
    }

    let nav = useNavigate()
    const updateEvent = async () => {
        setLoading(true)
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
        const body = {
            event_name: title,
            description,
            event_start_date: startDate + timeZone,
            event_end_date: endDate + timeZone,
            time_zone: timeZone,
            organizer_name: organizer,
            organizer_contact: orgPhone,
            country: countryName,
            city: cityName,
            address: address,
            early_birds_price: earlyBirdsPrice,
            regular_price: regularPrice,
            registration_link: regLink,
            event_enquiries: enq,
            category: category,
            number_of_ce: ce,
            nationality: nat,
            speakers_with_cv: [...speakers],
            virtual,
            speaker_ids: []
        }

        const upE = await updateEventAPI(token, data.id, body)
        if (upE.error === null) {
            setLoading(false)
            toast.success('Event updated!')
            nav(`/events/${data.id}`)
        } else {
            setLoading(false)
            toast.error('Error! Something went wrong...!')
        }
    }

    return (
        <div className="grid grid-cols-12 gap-8">
            <div className="col-span-12">
                <h2 className="text-primary-green-1 mb-4">Edit your event</h2>
                <Link to={`/events/${data.id}`} className="text-lg text-primary-green-1 font-semibold py-8">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to your event
                </Link>
            </div>
            <div className="col-span-6">
                <Input name="title" value={title} setValue={setTitle} label="Event name" placeHolder={title} />
                <TextArea
                    label="Description"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                    value={description}
                    setValue={setDescription}
                />
                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12">
                        <DropDown
                            data={tz}
                            value={timeZone}
                            label="Set timezone"
                            labelClass="text-lg font-semibold"
                            setValue={setTimeZone}
                        />
                    </div>
                    <div className="col-span-12 md:col-span-6">
                        <Input
                            type="datetime-local"
                            name="event-start-date"
                            label="Event start date & time"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                            placeHolder="Your event name here"
                            value={startDate}
                            setValue={setStartDate}
                        />
                    </div>
                    <div className="col-span-12 md:col-span-6">
                        <Input
                            type="datetime-local"
                            name="event-end-date"
                            label="Event end date & time"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                            placeHolder="Your event name here"
                            value={endDate}
                            setValue={setEndDate}
                        />
                    </div>
                </div>

                <div className="relative">
                    <Input
                        type="number"
                        name="early_price"
                        label="Early birds price"
                        labelClass="text-md font-semibold"
                        inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 pl-6 focus:outline-0 rounded-md "
                        placeHolder="Write here price in USD"
                        value={earlyBirdsPrice}
                        setValue={setEarlyBirdsPrice}
                    />
                    <i className="absolute top-[41px] md:top-[42px] left-3 text-gray-600">$</i>
                </div>
                <div className="relative">
                    <Input
                        type="number"
                        name="regular_price"
                        label="Regular price"
                        labelClass="text-md font-semibold"
                        inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 pl-6 focus:outline-0 rounded-md "
                        placeHolder="Write here price in USD"
                        value={regularPrice}
                        setValue={setRegularPrice}
                    />
                    <i className="absolute top-[41px] md:top-[42px] left-3 text-gray-600">$</i>
                </div>
                <TextArea
                    name="add-speaker"
                    label="Add speaker with CV"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={speaker}
                    setValue={setSpeaker}
                />
                <button
                    onClick={(e) => addSpeaker(e)}
                    className="text-white bg-primary-green-1 border-2 border-primary-green-1 active:text-primary-green-1 active:bg-white py-2 px-6 rounded-md">
                    Add
                </button>
                <div className="flex flex-wrap mt-4">
                    {speakers?.map((v, i) => {
                        return (
                            <pre
                                className="text-sm text-gray-600 bg-gray-300 py-1 px-2 m-1 rounded-md pr-6 relative"
                                key={i}>
                                {v}
                                <span
                                    onClick={() => removeSpeaker(i)}
                                    className="absolute right-2 top-1 text-gray-600 font-semibold cursor-pointer hover:text-red-400">
                                    x
                                </span>
                            </pre>
                        )
                    })}
                </div>
            </div>
            <div className="col-span-6">
                <Input
                    name="org"
                    value={organizer}
                    setValue={setOrganizer}
                    label="Organizer name"
                    placeHolder={organizer}
                />
                <Input
                    name="orgph"
                    value={orgPhone}
                    setValue={setOrgPhone}
                    label="Organizer contact"
                    placeHolder={orgPhone}
                />
                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 md:col-span-4">
                        <Input
                            name="country"
                            label="Country name"
                            placeHolder={countryName}
                            value={countryName}
                            setValue={setCountryName}
                        />
                    </div>
                    <div className="col-span-12 md:col-span-4">
                        <Input
                            name="city"
                            label="City name"
                            placeHolder={cityName}
                            value={cityName}
                            setValue={setCityName}
                        />
                    </div>
                    <div className="col-span-12 md:col-span-4">
                        <Input
                            name="address"
                            label="Address"
                            placeHolder={address}
                            value={address}
                            setValue={setAddress}
                        />
                    </div>
                </div>
                <Input
                    name="reg"
                    value={regLink}
                    setValue={setRegLink}
                    label="Registration link"
                    placeHolder={regLink}
                />
                <Input name="enq" value={enq} setValue={setEnq} label="Event enquiries (email)" placeHolder={enq} />
                <Input name="ce" type="number" label="Number of CE" placeHolder={ce} value={ce} setValue={setCe} />
                <span className="mb-2"></span>
                <DropDown
                    data={virtualOptions}
                    value={virtual}
                    label="Virtual or In-person"
                    labelClass="text-lg font-semibold"
                    setValue={setVirtual}
                />
                <span className="mb-2"></span>
                <DropDown
                    data={categories}
                    value={category}
                    label="Select category"
                    labelClass="text-lg font-semibold"
                    setValue={setCategory}
                />
                <Input name="nat" value={nat} setValue={setNat} label="Nationality" placeHolder={nat} />
                <Button loading={loading} onClick={updateEvent}>
                    Update
                </Button>
            </div>
        </div>
    )
}

export default EventEditTemplate
