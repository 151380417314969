import { faCopy, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatDateTime, isObjectEmpty, upperCaseInitLetter } from '@utils/index'
import { Modal } from '@components/atoms/index'
import RatingStarts from './RatingStars'
import { Link } from 'react-router-dom'
import { useState, useContext } from 'react'
import EventData from './EventData'
import { Auth } from '@context/authContext'

const EventCard = ({ data }) => {
    const [openEvent, setOpenEvent] = useState(null)

    const { state } = useContext(Auth)

    return (
        <div className="p-3 md:p-6 bg-white rounded-lg shadow-sm h-full relative">
            {/* <Link to={state?.auth?.session_id ? `/events/${data.id}` : `/events/${data.id}`}> */}
            <Link to={`/events/${data.id}`}>
                <h5 className="text-primary-green-1 pl-8 relative  text-base md:text-lg flex items-center">
                    <img src="/assets/logo/calendar.png" alt="calendar" className="w-6 inline absolute left-0 top-0" />
                    {data.event_name}
                    {data.featured && (
                        <span className="text-sm ml-2 text-primary-green-4 border-2 border-primary-green-4 px-3 py-1 rounded-full">
                            Featured
                        </span>
                    )}
                    {data.archived && (
                        <span className="text-sm ml-2 text-gray-600 border-2 border-gray-600 px-3 py-1 rounded-full">
                            Archived
                        </span>
                    )}
                </h5>
            </Link>
            <p className="text-gray-500 text-xs md:text-sm py-2 md:py-4 pl-8">
                {formatDateTime(data.event_start_date).date}
            </p>
            <p className="pl-8 text-xs md:text-sm text-gray-600 mb-4 relative">
                <span className="text-primary-green-1 bg-primary-green-8 absolute -left-0 -top-1 md:-left-1 md:-top-2 px-[5px] pt-[4px] pb-[2px] md:px-[10px] md:pt-[8px] md:pb-[4px] rounded-full">
                    <FontAwesomeIcon icon={faLocationDot} />
                </span>
                {data.address}, {data.city}, {data.country}
            </p>
            <span
                className={
                    data.virtual === 'virtual'
                        ? 'ml-8 text-xs md:text-sm py-1 px-2 md:py-2 md:px-4 bg-green-100 rounded-full'
                        : data.virtual === 'person'
                          ? 'ml-8 text-xs md:text-sm py-1 px-2 md:py-2 md:px-4 bg-red-100 rounded-full'
                          : 'ml-8 text-xs md:text-sm py-1 px-2 md:py-2 md:px-4 bg-blue-100 rounded-full'
                }>
                {upperCaseInitLetter(data.virtual)}
            </span>
            <div className="pl-8 mt-4">
                <span className="inline-block">
                    <RatingStarts rate={data.rate.ratings} />
                </span>
                {data.rate.user_count > 0 ? (
                    <span className="text-gray-400 text-sm ml-4">
                        {data.rate.user_count} {data.rate.user_count > 1 ? 'people' : 'person'} rate this event
                    </span>
                ) : (
                    <span className="text-gray-400 text-sm ml-4">No ratings yet</span>
                )}

                {!isObjectEmpty(state.auth) && (
                    <FontAwesomeIcon
                        icon={faCopy}
                        className="text-gray-300 text-2xl absolute bottom-6 right-8 cursor-pointer"
                        onClick={() => setOpenEvent(true)}
                    />
                )}

                {openEvent !== null && openEvent !== false && (
                    <Modal title="Event details" setValue={setOpenEvent}>
                        <EventData event_id={data.id} />
                    </Modal>
                )}
            </div>
        </div>
    )
}

export default EventCard
