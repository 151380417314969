import { Outlet } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import { Loading } from '../components/atoms'
import { Auth } from '../context'
import { LocalVar } from './variables'
import { getAuthSessionAPI } from '@api/index'

const GetAuthDataRoute = () => {
    const { dispatch } = useContext(Auth)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const getAuthSession = async (token) => {
            let data = await getAuthSessionAPI(token)
            if (data.error === null) {
                dispatch({ type: 'set', payload: data.data })
                setLoading(false)
            } else {
                setLoading(false)
            }
        }

        if (token !== null) {
            getAuthSession(token)
        } else {
            setLoading(false)
        }
    }, [dispatch])

    // if (loading) {
    //     return <Loading />
    // } else {
    //     return <Outlet />
    // }
    return <Outlet />
}

export default GetAuthDataRoute
