import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

const RadioBtn = ({ options, onChange, wrapperClass = 'grid grid-cols-2 gap-4', defaultValue = false }) => {
    const [selectedOption, setSelectedOption] = useState(defaultValue)

    useEffect(() => {
        setSelectedOption(defaultValue)
    }, [defaultValue])

    const handleOptionChange = (value) => {
        setSelectedOption(value)
        onChange(value)
    }
    return (
        <div className={wrapperClass}>
            {options.map((option) => (
                <label
                    key={option.value}
                    className="py-2 px-4 border-2 border-gray-200 rounded-full relative cursor-pointer">
                    <input
                        type="radio"
                        value={option.value}
                        checked={selectedOption === option.value}
                        onChange={() => handleOptionChange(option.value)}
                        className="hidden"
                    />
                    {option.label}
                    <span className="text-md text-primary-green-1 absolute right-4">
                        {selectedOption === option.value ? (
                            <FontAwesomeIcon icon={faCheckSquare} />
                        ) : (
                            <FontAwesomeIcon icon={faSquare} className="text-transparent border-2 rounded-sm" />
                        )}
                    </span>
                </label>
            ))}
        </div>
    )
}

export default RadioBtn
