import axios from 'axios'
import { resolve } from './resolve'
import { settings } from '@utils/index'

export const getUsersDataAPI = async (token, page, limit) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/admin/get-users?page=${page}&limit=${limit}`, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const getUsersDataByDateAPI = async (token, startDate, endDate, page, limit, isAll = false) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    let url = isAll ? `/analytics/?start_date=${startDate}&end_date=${endDate}&all=True` : `/analytics/?start_date=${startDate}&end_date=${endDate}&page=${page}&limit=${limit}`
    return await resolve(
        fetchAPI
            .get(url, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const getUserActivityDataAPI = async (token, accountId) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/analytics/${accountId}/all/`, { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((res) => res.data),
    )
}
