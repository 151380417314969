export const initialAuth = { auth: {} }

export const authtReducer = (state, action) => {
    switch (action.type) {
        case 'set':
            return { auth: action.payload }
        case 'remove':
            return { auth: {} }
        default:
            return state
    }
}
