import { UserProfile } from '@components/molecules/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHashtag } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'

const SponsorProfile = ({ userData, profileData }) => {
    const [isEditable, setIsEditable] = useState(false)
    return (
        <div className="pt-12 px-4">
            <h2 className="mb-6">Profile</h2>
            <div className="bg-white rounded-md p-8 shadow-sm">
                <UserProfile
                    data={{ ...userData, completeness: profileData.completeness }}
                    isEditable={isEditable}
                    setIsEditable={setIsEditable}
                />
            </div>

            {/* {isEditable && (
                <div className="bg-white rounded-md p-8 shadow-sm mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <OrganizerSettings userData={userData} profileData={profileData} />
                </div>
            )} */}
            <h4 className="text-gray-500 px-2 py-8">Coming soon more profile related options</h4>
        </div>
    )
}

export default SponsorProfile
