import { Link } from 'react-router-dom'
// import { Button } from '../../atoms'

const MainPageFindMentors = () => {
    return (
        <div>
            <div className="bg-dark-green py-14 border-b-2">
                <h1 className="golden-txt text-center leading-[50px] max-w-[850px] mx-auto">
                    Welcome to Mentorship on DentiCE!
                </h1>
                <p className="text-white text-center my-6 max-w-[600px] mx-auto leading-8 text-base tracking-wider">
                    DentiCE connects dentists, students, and dental professionals with tailored mentorship
                    opportunities. Whether you're seeking guidance as a dentist, student, or other dental professional,
                    our platform matches you with the right mentors to enhance your skills and career prospects.{' '}
                </p>
                <div className="flex justify-center">
                    <Link
                        className="bg-primary-green-1 rounded-md py-3 px-8 border-2 border-primary-golden-5"
                        to="/signup">
                        <span className="golden-txt">Signup</span>
                    </Link>
                </div>
            </div>
            {/* <div className="hidden md:block md:container mx-auto mt-[80px] mb-[80px]">
                <div className="md:grid md:grid-cols-2">
                    <div>
                        <img src="/assets/img/top-mentor-5.png" alt="5" className="ml-[5%]" />
                        <img src="/assets/img/top-mentor-7.png" alt="7" className="ml-[50%]" />
                        <img src="/assets/img/top-mentor-8.png" alt="8" className="ml-[80%]" />
                        <img src="/assets/img/top-mentor-6.png" alt="6" className="ml-[20%]" />
                    </div>
                    <div>
                        <img src="/assets/img/top-mentor-11.png" alt="9" className="ml-[90%]" />
                        <img src="/assets/img/top-mentor-4.png" alt="9" className="ml-[30%]" />
                        <img src="/assets/img/top-mentor-10.png" alt="10" className="ml-[70%]" />
                        <img src="/assets/img/top-mentor-9.png" alt="9" className="ml-[15%]" />
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default MainPageFindMentors
