import axios from 'axios'
import { resolve } from './resolve'
import { settings } from '@utils/index'

export const filterSponsorContentsAPI = async (token, page, limit) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/sponsor-contents/filter?page=${page}&limit=${limit}`, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const getSponsorContentsAPI = async (id) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(fetchAPI.get(`/sponsor-contents/${id}`).then((res) => res.data))
}

export const createSponsorContentsAPI = async (token, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .post(
                `/sponsor-contents/`,
                { ...body },
                {
                    headers: { Authorization: `Bearer ${token.session_id}` },
                },
            )
            .then((res) => res.data),
    )
}

export const updateSponsorContentsAPI = async (token, id, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .patch(
                `/sponsor-contents/${id}`,
                { ...body },
                {
                    headers: { Authorization: `Bearer ${token.session_id}` },
                },
            )
            .then((res) => res.data),
    )
}

export const removeSponsorContentsAPI = async (token, id) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .delete(`/sponsor-contents/${id}`, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}
