import { FormWrapper } from '.'
import { Input } from '@components/atoms/index'

const LocationForm = ({
    country,
    setCountry = () => {},
    provinceState,
    setProvinceState = () => {},
    city,
    setCity = () => {},
    address,
    setAddress = () => {},
    postalCode,
    setPostalCode = () => {},
}) => {
    return (
        <div>
            <FormWrapper title={'Location'} subtitle={'Where your company located?'}>
                <Input name="country" label="Country" placeHolder="Country" value={country} setValue={setCountry} />
                <Input
                    name="province"
                    label="Province/State"
                    placeHolder="Province or State"
                    value={provinceState}
                    setValue={setProvinceState}
                />
                <Input name="city" label="City" placeHolder="City" value={city} setValue={setCity} />
                <Input name="address" label="Address" placeHolder="Address" value={address} setValue={setAddress} />
                <Input
                    name="postal"
                    label="Postal Code"
                    placeHolder="Postal Code"
                    value={postalCode}
                    setValue={setPostalCode}
                />
            </FormWrapper>
        </div>
    )
}

export default LocationForm
