import * as XLSX from 'xlsx'
import { useState } from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LocalVar } from '@utils/index'
import { createEventsArrayAPI } from '@api/index'

const AdminEvents = () => {
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const tableHead = [
        'Event name',
        'Speakers with CV',
        'Event start date',
        'Event start time',
        'Event end date',
        'Event end time',
        'Description',
        'Organizer name',
        'Organizer contact',
        'Country',
        'City',
        'Address',
        'Early birds price',
        'Regular price',
        'Number of CE credits',
        'Meeting link',
        'Registration Link',
        'Event enquiries (Email)',
        'Virtual event?',
        'Category',
    ]

    const handleFile = (file) => {
        const reader = new FileReader()

        reader.onload = (e) => {
            const data = e.target.result
            try {
                const workbook = XLSX.read(data, { type: 'binary' })
                const sheetName = workbook.SheetNames[0] // Assuming we are reading the first sheet
                const sheet = workbook.Sheets[sheetName]
                const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 })

                excelData.shift()
                let resData = []
                excelData.forEach((item, index) => {
                    if (item.length !== 0) {
                        resData.push(item)
                    }
                })
                setData(resData)
            } catch (error) {
                setError('Error reading Excel file.')
            }
        }

        reader.onerror = (e) => {
            setError('Error reading file.')
        }

        reader.readAsArrayBuffer(file)
    }

    // const virtualCheck = (str) => {
    //     if (str === 'y' || str === 'Y' || str === 'virtual' || str === 'Virtual') return true
    //     else return false
    // }

    const speakersArray = (str) => {
        if (str === '-') return []
        if (typeof str === 'string') {
            return str
                .split('\n')
                .flatMap((line) =>
                    line
                        .trim()
                        .split(',')
                        .map((speaker) => speaker.trim()),
                )
                .filter(Boolean)
        } else {
            return []
        }
    }

    const reformatDate = (dateString) => {
        // const parts = dateString.trim().split('-')
        let dateArr = dateString.toString().split('/')
        // console.log('dateArr : ', dateArr)

        let month = ''
        let date = ''
        if (dateArr[1].length === 1) {
            date = `0${dateArr[1]}`
        } else {
            date = `${dateArr[1]}`
        }
        if (dateArr[0].length === 1) {
            month = `0${dateArr[0]}`
        } else {
            month = `${dateArr[0]}`
        }
        // return `${parts[0]}-${month}-${date}`

        return `${dateArr[2]}-${month}-${date}`
    }

    const submitEvents = async () => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        let filteredData = []

        data.forEach((v, i) => {
            filteredData.push({
                event_name: v[0],
                speakers_with_cv: speakersArray(v[1]),
                event_start_date: `${reformatDate(v[2])}T${v[3]}`,
                event_end_date: `${reformatDate(v[4])}T${v[5]}`,
                description: v[6],
                organizer_name: v[7] === '-' ? v[7] : '',
                organizer_contact: v[8] === '-' ? '' : String(v[8]),
                country: v[9] === '-' ? '-' : v[9],
                city: v[10] === '-' ? '-' : v[10],
                address: v[11] === '-' ? '-' : v[11]?.toString(),
                early_birds_price: parseFloat(v[12]),
                regular_price: parseFloat(v[13]),
                number_of_ce: parseInt(v[14]),
                meeting_link: v[15],
                registration_link: v[16] === '-' ? 'https://dentice.ai/' : v[16],
                event_enquiries: v[17] === '-' ? '' : v[17],
                virtual: v[18],
                category: v[19],
            })
        })

        setLoading(true)
        let createEvents = await createEventsArrayAPI(token, filteredData)
        if (createEvents.error === null) {
            setLoading(false)
            window.location.reload()
        } else {
            setLoading(false)
        }
    }

    return (
        <div className="bg-white  rounded-md">
            <div className="py-8 px-8">
                <label
                    htmlFor="up"
                    className="bg-primary-green-1 text-white px-8 py-2 rounded-md shadow-sm cursor-pointer active:bg-gray-200 active:text-primary-green-1">
                    Browse Events
                    <input
                        id="up"
                        type="file"
                        accept=".xlsx"
                        onChange={(e) => {
                            const file = e.target.files[0]
                            handleFile(file)
                        }}
                        className="hidden"
                    />
                </label>
                {data.length !== 0 && (
                    <button
                        onClick={submitEvents}
                        className="bg-primary-green-1 text-white px-8 py-2 rounded-md shadow-sm cursor-pointer ml-4 active:bg-gray-200 active:text-primary-green-1">
                        Upload
                    </button>
                )}

                {error !== null && <p className="text-red-600 py-4">{error}</p>}
                {loading && (
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="motion-safe:animate-spin text-4xl text-primary-green-2"
                    />
                )}
                {data.length !== 0 && (
                    <div>
                        <table className="h-[60vh] block overflow-y-auto my-8 rounded-sm border-2 border-gray-100">
                            <thead>
                                <tr>
                                    {tableHead.map((v, i) => {
                                        return (
                                            <th
                                                key={i}
                                                className="min-w-[350px] max-w-[400px] overflow-x-auto border-2 border-gray-200 p-2">
                                                <div className="overflow-y-auto min-h-[25px] max-h-[120px] text-sm">
                                                    {v}
                                                </div>
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((row, rowIndex) => {
                                    return (
                                        <tr key={rowIndex}>
                                            {row.map((v, i) => {
                                                return (
                                                    <td
                                                        className="min-w-[350px] max-w-[400px] overflow-x-auto border-2 border-gray-200 p-2 hover:bg-gray-100"
                                                        key={i}>
                                                        <div className="overflow-y-auto min-h-[50px] max-h-[120px] text-sm">
                                                            {v}
                                                        </div>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AdminEvents
