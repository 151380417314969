import { Footer } from '../organisms'
import {
    MainNav,
    HeroSection,
    SecondaryHeroSection,
    MainPageFindMentors,
    // MainMentorSlider,
    // MainPageCards,
    SaysAboutUsSlider,
    WhyChooseUs,
} from '../molecules'

const MainTemplate = () => {
    return (
        <div className="bg-light-main-green">
            <MainNav />
            <HeroSection />
            <SecondaryHeroSection />
            <WhyChooseUs />
            {/* <MainPageCards /> */}
            {/* <MainMentorSlider /> */}
            <SaysAboutUsSlider />
            <MainPageFindMentors />
            <Footer />
        </div>
    )
}

export default MainTemplate
