import { getEventRatings, postEventRatings, updateEventRatings } from '@api/index'
import { useEffect, useState } from 'react'
import { LocalVar } from '@utils/variables'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PostPatchRatings = ({ event_id, user_id }) => {
    const [rating, setRating] = useState(0)

    useEffect(() => {
        ;(async () => {
            const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

            const getData = await getEventRatings(token, event_id, user_id)
            if (getData.error === null) {
                setRating(getData.data.data.ratings)
            } else {
                setRating(0)
            }
        })()
    }, [event_id, user_id, rating])

    const submitRating = async (event_id, rating) => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
        const postRating = await postEventRatings(token, event_id, rating)
        if (postRating.error === null) {
            setRating(rating)
            window.location.reload()
        } else {
            const updateRating = await updateEventRatings(token, event_id, rating)
            if (updateRating.error === null) {
                setRating(rating)
                window.location.reload()
            }
        }
    }

    return (
        <div>
            <h4 className="text-primary-green-1 mt-4">Ratings:</h4>
            {Array.from(Array(5), (e, i) => {
                return (
                    <FontAwesomeIcon
                        key={i}
                        icon={faStar}
                        className={
                            i < rating ? 'text-primary-golden-7 cursor-pointer' : 'text-slate-300 cursor-pointer'
                        }
                        onClick={() => submitRating(event_id, i + 1)}
                    />
                )
            })}
        </div>
    )
}

export default PostPatchRatings
