import React from 'react'
import FormWrapper from './FormWrapper'
import { DropDownWithField, Input } from '@components/atoms'

export default function DentistRoleSpecificInfo({
    preferredLearningFormat,
    setPreferredLearningFormat,
    preferredTopics,
    setPreferredTopics,
    preferredGeoLocation,
    setPreferredGeoLocation,
    idealEventScale,
    setIdealEventScale,
    preferredDaysAttendEvent,
    setPreferredDaysAttendEvent,
    willingnessToTravel,
    setWillingnessToTravel,
}) {
    const preferredLearningFormatsOptions = [
        { text: 'Online', value: 'Online' },
        { text: 'In-Person', value: 'In-Person' },
        { text: 'Workshops', value: 'Workshops' },
    ]
    const idealEventScaleOptions = [
        { text: 'Local', value: 'Local' },
        { text: 'National', value: 'National' },
        { text: 'International', value: 'International' },
    ]

    return (
        <div>
            <FormWrapper
                title="Tell us About Your Preferences"
                subtitle="Let’s Know your preferences to make it better">
                <DropDownWithField
                    data={preferredLearningFormatsOptions}
                    label="Your preffered learning format"
                    labelClass="text-lg font-semibold"
                    setValue={setPreferredLearningFormat}
                />

                <p className="text-2xl font-bold py-4">Interest and Preferences:</p>

                <Input
                    name="prefTopics"
                    label="Preferred Topics/Fields of Interest"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={preferredTopics}
                    setValue={setPreferredTopics}
                />

                <Input
                    name="prefGeoLoc"
                    label="Geographic Locations for Events"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={preferredGeoLocation}
                    setValue={setPreferredGeoLocation}
                />

                <DropDownWithField
                    data={idealEventScaleOptions}
                    label="Ideal Event Scale"
                    labelClass="text-lg font-semibold"
                    setValue={setIdealEventScale}
                />

                <p className="text-2xl font-bold py-4">Availability:</p>

                <Input
                    name="prefDaysAttEvent"
                    label="Preferred Days for Attending Events"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={preferredDaysAttendEvent}
                    setValue={setPreferredDaysAttendEvent}
                />

                <Input
                    name="willingnessToTravel"
                    label="Willingness to Travel"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                    value={willingnessToTravel}
                    setValue={setWillingnessToTravel}
                />
            </FormWrapper>
        </div>
    )
}
