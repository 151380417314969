import { useContext } from 'react'
import {
    AdminHomeTemplate,
    OrganizerHomeTemplate,
    PractitionerHomeTemplate,
    SponsorHomeTemplate,
    SpeakerHomeTemplate,
} from '@components/templates/index'
import { Auth } from '../../context'
import { Header } from '../organisms'
import { ProfileVar, ProjectVar } from '@utils/index'

const HomePage = () => {
    const { state } = useContext(Auth)

    const role = state.auth.role

    let views = {
        [ProfileVar.ADMIN]: AdminHomeTemplate,
        [ProfileVar.PRACTITIONER]: PractitionerHomeTemplate,
        [ProfileVar.ORGANIZER]: OrganizerHomeTemplate,
        [ProfileVar.SPONSOR]: SponsorHomeTemplate,
        [ProfileVar.SPEAKER]: SpeakerHomeTemplate,
    }

    const HomeViewer = views[role]

    return (
        <div>
            <Header title="Home" description={ProjectVar.PROJECT_NAME + ' home page'} />
            <HomeViewer />
        </div>
    )
}

export default HomePage
