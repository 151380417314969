import { UserActivityTemplate } from '@components/templates'
import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { getUserActivityDataAPI } from '@api/index'
import { LocalVar, ProfileVar } from '@utils/index'
import { NotFound } from '..'
import { Auth } from '@context/authContext'
import { Loading } from '@components/atoms'

export default function UserActivity() {
    const { accountId } = useParams()

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    const { state } = useContext(Auth)

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const eventDetails = async () => {
            let userData = await getUserActivityDataAPI(token, accountId)
            if (userData.error === null) {
                setData(userData.data.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }

        eventDetails()
    }, [accountId])

    if (state.auth.role !== ProfileVar.ADMIN) return <NotFound />
    if (loading) return <Loading />
    return <UserActivityTemplate data={data} />
}
