import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faBan } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from '../../atoms'
import { EventCard } from '../../atoms/events'
import { getEventFilterAPI } from '@api/index'
import { useNavigate } from 'react-router-dom'

const EventCards = ({
    title,
    container = true,
    upcoming = true,
    by = 'event_name',
    keyword = '',
    limitPerPage = 3,
    featured = false,
    category = 'all',
    isMiddleSection = false,
}) => {
    const navigate = useNavigate()
    const [data, setData] = useState({ data: [] })
    const [loading, setLoading] = useState(true)
    const [view, setView] = useState(false)
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(limitPerPage)

    // useEffect(() => {
    //     const updateLimitBasedOnWidth = () => {
    //         if (window.innerWidth < 1280) {
    //             setLimit(4)
    //         } else {
    //             setLimit(6)
    //         }
    //     }
    //     updateLimitBasedOnWidth()

    //     window.addEventListener('resize', updateLimitBasedOnWidth)
    //     return () => window.removeEventListener('resize', updateLimitBasedOnWidth)
    // }, [])

    useEffect(() => {
        const eventFilter = async (by, keyword, limit, page, upcoming, featured, category) => {
            let eventData = await getEventFilterAPI(by, keyword, limit, page, upcoming, featured, category)
            if (eventData.error === null) {
                setData({ ...eventData.data.data, data: [...eventData.data.data.data] })
                setLoading(false)
            } else {
                setLoading(false)
            }
        }

        eventFilter(by, keyword, limit, page, upcoming, featured, category)
    }, [page, limit, by, keyword, upcoming, view, featured, category])

    const handleView = (value) => {
        // setView(value)
        // if (value === true) {
        //     setLimit(3)
        // }
        navigate(`/all-events/${upcoming === 'all' ? 'all' : upcoming ? 'upcomming' : 'previous'}`, {
            state: { data, page, limit, by, keyword, upcoming, view, featured, category },
        })
    }
    return (
        <div className={`${container ? 'md:container' : ''} mx-auto ${isMiddleSection ? '' : 'py-10 p-4'}`}>
            <h3 className="text-primary-green-1 pb-6 relative">
                {title}
                {!isMiddleSection && data?.data?.length > 0 ? (
                    <span
                        className="absolute right-0 text-base md:text-lg font-medium text-primary-green-1 cursor-pointer select-none"
                        onClick={() => handleView(!view)}>
                        {view ? 'View less' : 'View all'}
                    </span>
                ) : null}
            </h3>
            {loading ? (
                <div className="flex justify-center items-center min-h-[200px]">
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="motion-safe:animate-spin text-4xl text-primary-green-2"
                    />
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                    {data?.data?.length !== 0
                        ? data?.data?.map((v, i) => {
                              return (
                                  <div
                                      key={i}
                                      className={`col-span-12 md:col-span-${isMiddleSection ? '12' : '6'} xl:col-span-${
                                          isMiddleSection ? '12' : '4'
                                      }`}>
                                      <EventCard data={v} />
                                  </div>
                              )
                          })
                        : null}
                </div>
            )}
            {data?.data?.length === 0 && !loading && (
                <div className="flex justify-center items-center bg-white rounded-md border-2 border-slate-200 py-8">
                    <h3 className="text-gray-400 text-center font-medium">
                        <FontAwesomeIcon icon={faBan} /> No events found
                    </h3>
                </div>
            )}
            {(isMiddleSection && data.total_data_count > 20) || (view && data.total_data_count > 3) ? (
                <Pagination
                    prevPageCount={data.prev_page_count}
                    currentPageProp={data.current_page}
                    nextPageCount={data.next_page_count}
                    setPage={setPage}
                />
            ) : null}
        </div>
    )
}

export default EventCards
