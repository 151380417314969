import FormWrapper from './FormWrapper'
import { Input, TextArea, PhoneNumber, DropDown, SearchDropDown } from '@components/atoms/index'
import { timeZones } from '@utils/variables'

const FirstForm = ({
    eventName,
    setEventName,
    description,
    setDescription,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    orgName,
    setOrgName,
    orgPhone,
    setOrgPhone,
    countryName,
    setCountryName,
    cityName,
    setCityName,
    address,
    setAddress,
    timeZone,
    setTimeZone,
}) => {
    const tz = []
    timeZones.forEach((v) => {
        tz.push({ text: `${v.offset} ${v.cities}`, value: v.offset })
    })

    return (
        <div>
            <FormWrapper title="Basic information" number={1}>
                <div className="grid grid-cols-12 gap-2 md:gap-8">
                    <div className="col-span-12 md:col-span-6">
                        <Input
                            name="event-name"
                            label="Event name"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                            placeHolder="Your event name here"
                            value={eventName}
                            setValue={setEventName}
                            required={true}
                        />
                        <TextArea
                            label="Description"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                            value={description}
                            setValue={setDescription}
                        />
                        <div className="grid grid-cols-12 gap-2 md:gap-4">
                            <div className="col-span-12">
                                <SearchDropDown
                                    data={tz}
                                    value={timeZone}
                                    label="Set timezone"
                                    labelClass="text-lg font-semibold"
                                    setValue={setTimeZone}
                                />
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <Input
                                    type="datetime-local"
                                    name="event-start-date"
                                    label="Event start date & time"
                                    labelClass="text-md font-semibold"
                                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                                    placeHolder="Your event name here"
                                    value={startDate}
                                    setValue={setStartDate}
                                    required={true}
                                />
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <Input
                                    type="datetime-local"
                                    name="event-end-date"
                                    label="Event end date & time"
                                    labelClass="text-md font-semibold"
                                    inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                                    placeHolder="Your event name here"
                                    value={endDate}
                                    setValue={setEndDate}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                        <Input
                            name="org-name"
                            label="Organization name"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                            placeHolder="Your organization name"
                            value={orgName}
                            setValue={setOrgName}
                        />
                        <PhoneNumber
                            name="phone"
                            label="Organization contact number"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full p-2 pl-16 my-4 border-2 border-gray-300 rounded-md focus:outline-0 rounded-md"
                            placeHolder="Contact phone number"
                            value={orgPhone}
                            setValue={setOrgPhone}
                        />
                        <h4 className="font-semibold mt-5 mb-4 text-primary-green-1">Event location</h4>
                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12 md:col-span-4">
                                <Input
                                    name="country"
                                    label="Country name"
                                    labelClass="text-md font-semibold"
                                    inputClass="block w-full border-2 border-gray-300 p-2 mt-1 md:mt-2 mb-6 focus:outline-0 rounded-md "
                                    placeHolder="Country"
                                    value={countryName}
                                    setValue={setCountryName}
                                    required={true}
                                />
                            </div>
                            <div className="col-span-12 md:col-span-4">
                                <Input
                                    name="city"
                                    label="City name"
                                    labelClass="text-md font-semibold"
                                    inputClass="block w-full border-2 border-gray-300 p-2 mt-1 md:mt-2 mb-6 focus:outline-0 rounded-md "
                                    placeHolder="City"
                                    value={cityName}
                                    setValue={setCityName}
                                    required={true}
                                />
                            </div>
                            <div className="col-span-12 md:col-span-4">
                                <Input
                                    name="address"
                                    label="Address"
                                    labelClass="text-md font-semibold"
                                    inputClass="block w-full border-2 border-gray-300 p-2 mt-1 md:mt-2 mb-6 focus:outline-0 rounded-md "
                                    placeHolder="Write here address"
                                    value={address}
                                    setValue={setAddress}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </FormWrapper>
        </div>
    )
}

export default FirstForm
