import { useState, useEffect } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faBan, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { useGeolocated } from 'react-geolocated'
import { Modal, Pagination } from '@components/atoms/index'
import { EventCard } from '../../atoms/events'
import { getEventFilterAPI } from '@api/index'

const NearByEvents = ({ title = 'Near you', container = true, upcoming = 'true' }) => {
    const [data, setData] = useState({ data: [] })
    const [loading, setLoading] = useState(false)
    const [view, setView] = useState(false)
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(3)

    const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    })

    useEffect(() => {
        const fetchEvents = async (city, country) => {
            let getCity = await getEventFilterAPI('city', city, limit, page, upcoming)
            if (getCity.error === null) {
                if (getCity.data.data.data.length !== 0) {
                    setData({ ...getCity.data.data, data: [...getCity.data.data.data] })
                    setLoading(false)
                } else {
                    let getCountry = await getEventFilterAPI('country', country, limit, page, upcoming)
                    if (getCountry.error === null) {
                        if (getCountry.data.data.data.length !== 0) {
                            setData({ ...getCountry.data.data, data: [...getCountry.data.data.data] })
                            setLoading(false)
                        }
                    } else {
                        setLoading(false)
                    }
                }
            } else {
                setLoading(false)
            }
        }

        const fetchGeo = () => {
            setLoading(true)
            if (coords !== undefined) {
                const fetchGeoAPI = axios.create({
                    baseURL: 'https://api.bigdatacloud.net/data/reverse-geocode-client',
                })
                fetchGeoAPI
                    .get(`?latitude=${coords.latitude}&longitude=${coords.longitude}&localityLanguage=en`)
                    .then((geoData) => {
                        fetchEvents(geoData.data.city, geoData.data.countryName)
                        setLoading(false)
                    })
                    .catch((err) => {
                        setLoading(false)
                    })
            }
            setLoading(false)
        }

        // fetch geo locations
        fetchGeo()
        // set limit
        if (view === false) {
            setLimit(3)
        }
    }, [coords, page, limit, upcoming, view, loading])

    const handleView = (value) => {
        setView(value)
        if (value === true) {
            setLimit(6)
        }
    }

    return (
        <div className={container ? 'md:container mx-auto py-10 p-4' : 'mx-auto py-10 p-4'}>
            <h3 className="text-primary-green-1 pb-6 relative">
                {title}{' '}
                {data?.data?.length ? (
                    <span
                        className="absolute right-0 text-base md:text-lg font-medium text-primary-green-1 cursor-pointer select-none"
                        onClick={() => handleView(!view)}>
                        {view ? 'View less' : 'View all'}
                    </span>
                ) : null}
            </h3>
            {!isGeolocationEnabled || !isGeolocationAvailable ? <GeoError /> : null}
            {loading ? (
                <div className="flex justify-center items-center min-h-[200px]">
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="motion-safe:animate-spin text-4xl text-primary-green-2"
                    />
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                    {data?.data?.map((v, i) => {
                        return (
                            <div key={i} className="col-span-4">
                                <EventCard data={v} />
                            </div>
                        )
                    })}
                </div>
            )}
            {isGeolocationEnabled && data?.data?.length === 0 && (
                <div className="flex justify-center items-center bg-white rounded-md border-2 border-slate-200 py-8">
                    <h3 className="text-gray-400 text-center font-medium">
                        <FontAwesomeIcon icon={faBan} /> No events found near you
                    </h3>
                </div>
            )}
            {view && data?.data?.length > 3 ? (
                <Pagination
                    prevPageCount={data.prev_page_count}
                    currentPageProp={data.current_page}
                    nextPageCount={data.next_page_count}
                    setPage={setPage}
                />
            ) : null}
        </div>
    )
}

const GeoError = () => {
    const [open, setOpen] = useState(false)
    return (
        <div className="bg-slate-100 border-2 border-slate-300 rounded-md py-10 flex justify-center">
            <p className="max-w-[400px] text-center">
                <FontAwesomeIcon icon={faLocationDot} className="text-4xl text-primary-green-1 w-full mb-4" />
                Your browser's location services are not active. To activate location services,{' '}
                <span
                    className="cursor-pointer text-primary-green-1 p-1 rounded-md bg-slate-300 inline-block"
                    onClick={() => setOpen(true)}>
                    click here
                </span>{' '}
                to view the instructions.
            </p>
            <div>
                {open ? (
                    <Modal title="Instructions" setValue={setOpen}>
                        <div>
                            <h4 className="py-2 mt-4 text-primary-green-1 font-semibold">Google Chrome</h4>
                            <p>
                                <b>Menu:</b> Click on the three dots in the upper-right corner to open the menu.
                            </p>
                            <p>
                                <b>Settings:</b> Select 'Settings' from the dropdown menu.
                            </p>
                            <p>
                                <b>Privacy and Security:</b> Click on 'Privacy and security' on the left sidebar.
                            </p>
                            <p>
                                <b>Site Settings:</b> Scroll down and click on 'Site settings'.
                            </p>
                            <p>
                                <b>Location:</b> Under the 'Permissions' section, click on 'Location'.
                            </p>
                            <p>
                                <b>Ask before accessing (recommended):</b> Ensure that the toggle is turned on to be
                                asked each time a site wants to access your location.
                            </p>

                            <h4 className="py-2 mt-4 text-primary-green-1 font-semibold">Mozilla Firefox</h4>
                            <p>
                                <b>Menu:</b> Click on the three horizontal lines in the upper-right corner.
                            </p>
                            <p>
                                <b>Options:</b> Choose 'Options' from the dropdown menu.
                            </p>
                            <p>
                                <b>Privacy & Security:</b> Select 'Privacy & Security' from the left-hand options.
                            </p>
                            <p>
                                <b>Permissions:</b> Scroll down to the 'Permissions' section.
                            </p>
                            <p>
                                <b>Location:</b> Click the 'Settings...' button next to 'Location'.
                            </p>
                            <p>
                                <b>Settings for Location:</b> Ensure that the checkbox for 'Block new requests asking to
                                access your location' is unchecked.
                            </p>

                            <h4 className="py-2 mt-4 text-primary-green-1 font-semibold">Microsoft Edge</h4>
                            <p>
                                <b>Menu:</b> Click on the three dots at the top right corner.
                            </p>
                            <p>
                                <b>Settings:</b> From the dropdown, select 'Settings'.
                            </p>
                            <p>
                                <b>Cookies and site permissions:</b> Click on 'Cookies and site permissions' from the
                                left sidebar.
                            </p>
                            <p>
                                <b>Location:</b> Scroll down to find 'Location' and click on it.
                            </p>
                            <p>
                                <b>Ask before accessing:</b> Toggle on 'Ask before accessing' to receive prompts when a
                                site requests your location.
                            </p>

                            <h4 className="py-2 mt-4 text-primary-green-1 font-semibold">Safari (Mac)</h4>
                            <p>
                                <b>Safari Menu:</b> Click on 'Safari' in the top left corner of your screen, then select
                                'Preferences'.
                            </p>
                            <p>
                                <b>Websites Tab:</b> Click on the 'Websites' tab at the top of the Preferences window.
                            </p>
                            <p>
                                <b>Location:</b> On the left pane, click on 'Location'.
                            </p>
                            <p>
                                <b>Allow or Deny:</b> Here you can see which websites have requested your location. You
                                can choose to allow or deny each request, or you can select 'Deny' at the bottom of the
                                window to deny all requests by default.
                            </p>

                            <h4 className="py-2 mt-4 text-primary-green-1 font-semibold">Opera</h4>
                            <p>
                                <b>Settings:</b> Click on the 'Easy Setup' menu on the top right, then scroll down and
                                click on 'Go to full browser settings'.
                            </p>
                            <p>
                                <b>Privacy & Security:</b> Scroll down to 'Privacy and security'.
                            </p>
                            <p>
                                <b>Site Settings:</b> Click on 'Site settings'.
                            </p>
                            <p>
                                <b>Location:</b> Under 'Permissions', click on 'Location'.
                            </p>
                            <p>
                                <b>Ask before accessing:</b> Switch on 'Ask before accessing' to have Opera ask you
                                whenever a site wants to access your location.
                            </p>
                        </div>
                    </Modal>
                ) : null}
            </div>
        </div>
    )
}

export default NearByEvents
