import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = ({
    loading = false,
    type = '',
    btnClass = 'bg-primary-green-1 text-white p-4 w-full block mt-6 cursor-pointer rounded-md active:bg-primary-green-10 active:text-primary-green-1 border-2 border-primary-green-1 hover:bg-primary-green-2',
    onClick,
    children,
}) => {
    return (
        <button type={type} onClick={onClick} className={`${btnClass} ${loading ? 'hover:cursor-wait' : ''}`}>
            {loading ? <FontAwesomeIcon icon={faSpinner} className="motion-safe:animate-spin" /> : children}
        </button>
    )
}

export default Button
