export const resolve = async (promiseFunction) => {
    const resolved = {
        data: null,
        error: null,
        duration: null,
    }

    let attempts = 0
    const maxAttempts = 3

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

    const startTime = Date.now()
    while (attempts < maxAttempts) {
        try {
            resolved.data = await promiseFunction
            resolved.error = null
            break
        } catch (e) {
            resolved.error = e
            attempts++
            if (attempts < maxAttempts) {
                await delay(1000)
            }
        }
    }

    resolved.duration = Date.now() - startTime

    return resolved
}
