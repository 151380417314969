import { useState, useEffect } from 'react'

// CheckBox name props should be unique in this entire project
const CheckBox = ({
    name,
    type = 'text',
    value = '',
    selectedState = '',
    setValue = () => {},
    label = '',
    wrapperClass = '',
    labelClass = '',
    inputClass = '',
    placeHolder = '',
    required = false,
}) => {
    // const initialCheckedState = localStorage.getItem(`checkbox-${name}`) === 'true'
    const [isChecked, setIsChecked] = useState(false)

    useEffect(() => {
        if (selectedState === value) {
            setIsChecked(true)
            setValue(value)
        }
    }, [selectedState, setValue, value])

    useEffect(() => {
        // localStorage.setItem(`checkbox-${name}`, isChecked.toString())
        if (isChecked) {
            if (typeof value === 'boolean') {
                setValue(true)
            } else {
                setValue(value)
            }
        } else {
            if (typeof value === 'boolean') {
                setValue(false)
            } else {
                setValue(null)
            }
        }
    }, [isChecked, value, setValue])

    const handleChecked = (e) => {
        setIsChecked(!isChecked)
    }

    return (
        <div className={wrapperClass}>
            {label.length !== 0 ? (
                <label
                    className={`${labelClass} select-none`}
                    htmlFor={name}
                    title={required ? 'This field is required' : ''}>
                    {label}
                    {required ? <span className="text-red-500">*</span> : null}
                </label>
            ) : null}
            <input
                className={inputClass}
                type={type}
                id={name}
                checked={isChecked}
                onChange={handleChecked}
                placeholder={placeHolder}
                required={required}
            />
        </div>
    )
}

export default CheckBox
