import {
    SideBar,
    AdminDashboardNav,
    AdminEvents,
    OwnEvents,
    AdminOrganizersTable,
    AdminUsersTable,
    AdminSponsorContent,
} from '@components/molecules/index'
import { useLocalStorage } from '@hooks/index'

const AdminEventsHandler = () => {
    return (
        <div>
            <AdminEvents />
            <OwnEvents />
        </div>
    )
}

const TableComponent = ({ name }) => {
    const tableComponents = {
        events: AdminEventsHandler,
        users: AdminUsersTable,
        organizers: AdminOrganizersTable,
        sponsor_contents: AdminSponsorContent,
    }
    const TableComp = tableComponents[name]
    return <TableComp />
}

const AdminHomeTemplate = () => {
    const [dashNav, setDashNav] = useLocalStorage('adminDashMenuKey', 'events')

    return (
        <div className="h-screen">
            <SideBar>
                <div className="pt-6 px-4">
                    <h2 className="text-primary-green-1">Admin Dashboard</h2>
                    <div className="mt-4">
                        <AdminDashboardNav dashNav={dashNav} setDashNav={setDashNav} />
                    </div>
                    <div className="pt-6">
                        <div>
                            <TableComponent name={dashNav} />
                        </div>
                    </div>
                </div>
            </SideBar>
        </div>
    )
}

export default AdminHomeTemplate
