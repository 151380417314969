import FormWrapper from './FormWrapper'
import { CheckBox, DropDown, RadioBtn } from '../../../atoms'

const LoveToKnow = ({
    needSponsor,
    setNeedSponsor,
    provideCeCredits,
    setProvideCeCredits,
    budget,
    setBudget,
    isUpdate = false,
}) => {
    const budgetData = [
        { text: '$300 to $500', value: '$300 to $500' },
        { text: '$800 to $1500', value: '$800 to $1500' },
        { text: '$2000 to $2500', value: '$2000 to $2500' },
        { text: '$3000 to $4000', value: '$3000 to $4000' },
    ]

    const ceOptions = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ]

    const handleChange = (value) => {
        setProvideCeCredits(value)
    }

    return (
        <div>
            <FormWrapper
                title={`${isUpdate ? '' : 'We would love to know'}`}
                subtitle={`${isUpdate ? '' : 'Kindly let us know more about you!'}`}>
                <CheckBox
                    name="kind-of-event-organizer-conference"
                    type="checkbox"
                    label="Do you need a Sponsor?"
                    value={true}
                    selectedState={needSponsor}
                    setValue={setNeedSponsor}
                    wrapperClass="border-2 border-primary-gray-1 p-4 rounded-md relative mb-4"
                    inputClass="accent-primary-green-1 w-4 h-4 absolute right-3 mt-1 cursor-pointer"
                    labelClass="cursor-pointer font-medium"
                />
                <div className="border-2 border-primary-gray-1 mb-4 rounded-md">
                    <p className="p-4 font-medium">Can you provide CE Credits?</p>
                    <div className="p-4">
                        <RadioBtn options={ceOptions} onChange={handleChange} defaultValue={provideCeCredits} />
                    </div>
                </div>
                {needSponsor && (
                    <DropDown
                        data={budgetData}
                        value={budget}
                        setValue={setBudget}
                        label="How much budget do you need?"
                        labelClass="text-lg font-semibold"
                    />
                )}
            </FormWrapper>
        </div>
    )
}

export default LoveToKnow
