import { formatDateTime, ProfileVar } from '@utils/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCalendarAlt,
    faClock,
    faEdit,
    faEnvelope,
    faLocationDot,
    faPhone,
    faUserGraduate,
} from '@fortawesome/free-solid-svg-icons'
import {
    EventInterestMeetingBtn,
    InterestedUsers,
    EventReviews,
    RatingDetails,
    AdminEventControlBtns,
} from '@components/molecules/index'
import { Auth } from '@context/index'
import { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import { upperCaseInitLetter, EventCategoryArray } from '@utils/index'

const handleCopy = (txt) => {
    navigator.clipboard.writeText(txt)
    toast('Phone number copied', {
        icon: '👍',
    })
}

const EventDetailsViewTemplate = ({ data }) => {
    const { state } = useContext(Auth)

    let category = ''
    //TODO : Update the method to match category when category text includes comma separated multiple category
    EventCategoryArray.forEach((v) => {
        if (v.value.value === data.category) {
            category = v.value.text
        }
    })

    if (state.auth.role !== ProfileVar.ADMIN && data.archived) {
        return (
            <div className="md:container mx-auto py-20 px-4 grid grid-cols-12">
                <div className="col-span-12 md:col-span-8">
                    <h2 className="text-primary-green-1 flex items-center opacity-50">
                        {data.event_name}{' '}
                        {data.featured && (
                            <span className="text-sm ml-2 text-primary-green-4 border-2 border-primary-green-4 px-3 py-1 rounded-full">
                                Featured
                            </span>
                        )}
                        {data.archived && (
                            <span className="text-sm ml-2 text-gray-600 border-2 border-gray-600 px-3 py-1 rounded-full">
                                Archived
                            </span>
                        )}
                    </h2>
                </div>

                <div className="col-span-12 py-10">
                    <h1 className="text-primary-green-1 text-center">This event archived by Admin.</h1>

                    {
                        (state.auth.entity_id = data.user_id && (
                            <p className="text-center py-4 text-lg">
                                Contact with Admin: <span className="text-primary-green-1">admin@worldaigroup.com</span>
                            </p>
                        ))
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="md:container mx-auto py-20 px-6 grid grid-cols-12 bg-light-main-green">
            <div className="col-span-12 md:col-span-8">
                <h2 className="text-primary-green-1 flex items-center">
                    {data.event_name}{' '}
                    {data.featured && (
                        <span className="text-sm ml-2 text-primary-green-4 border-2 border-primary-green-4 px-3 py-1 rounded-full">
                            Featured
                        </span>
                    )}
                    {data.archived && (
                        <span className="text-sm ml-2 text-gray-600 border-2 border-gray-600 px-3 py-1 rounded-full">
                            Archived
                        </span>
                    )}
                </h2>
            </div>
            {data.early_birds_price === data.regular_price && data.regular_price === 0 ? (
                <p className="flex items-center">
                    <span className="text-primary-green-1 py-2 px-8 text-xl font-bold bg-gray-200 rounded-full">
                        Free
                    </span>
                </p>
            ) : (
                <div className="col-span-12 md:col-span-4 flex gap-8">
                    <div className="flex flex-col items-center">
                        <p className="text-primary-green-1 py-2 px-8 text-xl font-bold bg-gray-200 rounded-full">
                            {'$'}
                            {data.early_birds_price}
                        </p>
                        <p className="ml-2">Early birds price</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <p className="text-primary-green-1 py-2 px-8 text-xl font-bold bg-gray-200 rounded-full">
                            {'$'}
                            {data.regular_price}
                        </p>
                        <p className="ml-2">Regular price</p>
                    </div>
                </div>
            )}
            <div className="col-span-12 md:col-span-8">
                <p className="mt-4 ml-8 text-gray-600 relative text-sm">
                    <FontAwesomeIcon
                        icon={faLocationDot}
                        className="text-primary-green-1 py-[6px] px-[8px] bg-primary-green-8 rounded-full text-sm absolute -left-10 -top-1"
                    />
                    {data.address}, {data.city}, {data.country}
                </p>
                <div className="grid grid-cols-2 pt-8 px-8">
                    <div>
                        <h6 className="text-primary-green-1 mb-4 -ml-10">Start date & time</h6>
                        <p className="text-sm relative mb-6">
                            <FontAwesomeIcon
                                icon={faCalendarAlt}
                                className="text-primary-green-1 py-[6px] px-[8px] bg-primary-green-8 rounded-full text-sm absolute -left-10 -top-1"
                            />
                            {formatDateTime(data.event_start_date).date}
                        </p>
                        <p className="text-sm relative">
                            <FontAwesomeIcon
                                icon={faClock}
                                className="text-primary-green-1 py-[6px] px-[8px] bg-primary-green-8 rounded-full text-sm absolute -left-10 -top-1"
                            />
                            {formatDateTime(data.event_start_date).time}{' '}
                            <span className="text-gray-500">{data.time_zone} (General)</span>
                        </p>
                    </div>
                    <div>
                        <h6 className="text-primary-green-1 mb-4 -ml-10">End date & time</h6>

                        <p className="text-sm relative mb-6">
                            <FontAwesomeIcon
                                icon={faCalendarAlt}
                                className="text-primary-green-1 py-[6px] px-[8px] bg-primary-green-8 rounded-full text-sm absolute -left-10 -top-1"
                            />
                            {formatDateTime(data.event_end_date).date}
                        </p>
                        <p className="text-sm relative">
                            <FontAwesomeIcon
                                icon={faClock}
                                className="text-primary-green-1 py-[6px] px-[8px] bg-primary-green-8 rounded-full text-sm absolute -left-10 -top-1"
                            />
                            {formatDateTime(data.event_end_date).time}
                            <span className="text-gray-500">{data.time_zone} General</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-span-12 md:col-span-4">
                <div className="pt-6">
                    <div className="flex gap-1">
                        <p className="text-md font-medium relative">CE Type:</p>
                        <p className="font-semibold">{category || 'Unknown'}</p>
                    </div>
                    <div className="flex gap-1">
                        <p className="text-md font-medium relative">Organizer Name: </p>
                        <p className="font-semibold">{data?.organizer_name}</p>
                    </div>
                    <div className="flex gap-1">
                        <p className="text-md font-medium relative">Event Type: </p>
                        <p className="font-semibold">{upperCaseInitLetter(data?.virtual)}</p>
                    </div>
                    {(data?.user_id === state.auth.entity_id && state.auth.role === ProfileVar.ORGANIZER) ||
                    state.auth.role === ProfileVar.ADMIN ? (
                        <Link
                            to={`/event-edit/${data.id}`}
                            className="text-primary-green-3 border-2 border-primary-green-3 rounded-md font-semibold text-xl mt-4 inline-block px-10 py-2">
                            <FontAwesomeIcon icon={faEdit} /> Edit this event
                        </Link>
                    ) : null}
                </div>
                <div className="flex gap-16 pl-8 mt-4 py-6">
                    {data?.event_enquiries && data?.event_enquiries.length > 2 ? (
                        <Link
                            className="text-sm relative"
                            to="#"
                            onClick={(e) => {
                                window.location.href = 'mailto:' + data.event_enquiries
                                e.preventDefault()
                            }}>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className="text-primary-green-1 py-[6px] px-[8px] bg-primary-green-8 rounded-full text-sm absolute -left-10 -top-1"
                            />
                            {data.event_enquiries}
                        </Link>
                    ) : null}

                    {data?.organizer_contact && data?.organizer_contact.length > 2 ? (
                        <p
                            className="text-sm relative hover:cursor-pointer"
                            onClick={() => handleCopy(data.organizer_contact)}>
                            <FontAwesomeIcon
                                icon={faPhone}
                                className="text-primary-green-1 py-[6px] px-[8px] bg-primary-green-8 rounded-full text-sm absolute -left-10 -top-1"
                            />
                            {data.organizer_contact}
                        </p>
                    ) : null}
                </div>

                <div className="grid grid-cols-2 py-4 gap-4">
                    <div>
                        {data?.user_id !== state.auth.entity_id ? (
                            <div>
                                <EventInterestMeetingBtn
                                    event_id={data.id}
                                    reg_link={data.registration_link}
                                    web_link={data?.webpage_url || ''}
                                    end_date={data?.event_end_date}
                                />
                            </div>
                        ) : (
                            <div>
                                <p className="text-primary-green-3">This is your event</p>
                            </div>
                        )}
                    </div>
                    <h4 className="text-primary-green-1 font-medium">
                        CE credits: <span className="text-primary-green-3">{data.number_of_ce}</span>
                    </h4>
                </div>

                <div>{state.auth.role === ProfileVar.ADMIN && <AdminEventControlBtns data={data} />}</div>
            </div>
            <div className="col-span-12 pt-8">
                <h3 className="text-primary-green-1 mb-4">Description</h3>
                <p className="text-gray-600 leading-relaxed">{data.description}</p>
                <p className="mt-4">{data.number_of_ce_credit_zoom}</p>

                {Array.isArray(data.speakers_with_cv) && data.speakers_with_cv.length !== 0 && (
                    <h4 className="text-primary-green-1">Speakers:</h4>
                )}
                <div className="grid grid-cols-12 gap-4 mt-4">
                    {Array.isArray(data.speakers_with_cv) &&
                        data.speakers_with_cv.map((v, i) => {
                            return (
                                <div key={i} className="col-span-3 p-4 bg-slate-100 shadow-sm rounded-md">
                                    {v.split('\n').map((p, ip) => {
                                        return (
                                            <p key={ip} className="text-sm text-gray-700 font-semibold">
                                                <FontAwesomeIcon icon={faUserGraduate} /> {p}
                                            </p>
                                        )
                                    })}
                                </div>
                            )
                        })}
                </div>
            </div>
            <div className="col-span-12 py-8">
                <div className="w-[100%] md:w-[50%]">
                    <RatingDetails rate={data?.rate} />
                </div>
            </div>
            <div className="col-span-12 py-8">
                <div className="w-[50%] pb-8">
                    {data?.user_id === state.auth.entity_id && (
                        <InterestedUsers event_name={data?.event_name || ''} event_id={data.id} />
                    )}
                </div>
                <div className="w-[50%]">
                    <EventReviews event_id={data.id} />
                </div>
            </div>
        </div>
    )
}

export default EventDetailsViewTemplate
