import { Link } from 'react-router-dom'
import NoDataFolder from '../../../assets/img/NoDataFolder.png'

const NoEventYet = () => {
    return (
        <div className="pt-8 pb-4">
            <div className="bg-white rounded-lg p-10">
                <img src={NoDataFolder} alt="no data found img" className="mx-auto" />
                <h1 className="text-xl md:text-4xl text-center text-primary-green-1 py-6">
                    You don't have any event yet
                </h1>
                <p className="text-gray-500 text-center">Start adding your event</p>
                <div className="flex justify-center">
                    <Link
                        to="/event-add"
                        className="inline-block mx-auto py-4 px-8 mt-8 bg-primary-green-1 text-white rounded-lg active:bg-white active:text-primary-green-1 border-2 border-primary-green-1 shadow-lg">
                        Start now
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default NoEventYet
