import { createEventInterestAPI, getIsEventInterestAPI } from '@api/index'
import { Loading } from '@components/atoms/index'
import { LocalVar } from '@utils/index'
import { useEffect, useState, useContext } from 'react'
import { Auth } from '@context/authContext'
import PostPatchRatings from './PostPatchRatings'
import toast from 'react-hot-toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown19 } from '@fortawesome/free-solid-svg-icons'

const EventInterestMeetingBtn = ({ event_id, reg_link, web_link = '', end_date = new Date() }) => {
    const [clickedInterested, setClickedInterested] = useState(null)
    const [done, setDone] = useState(false)
    const [loading, setLoading] = useState(true)

    const { state } = useContext(Auth)

    useEffect(() => {
        const checkInterest = async () => {
            const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

            const isInterested = await getIsEventInterestAPI(token, event_id)
            if (isInterested.error === null) {
                if (isInterested.data.data) {
                    setDone(true)
                }
            } else {
                setDone(false)
            }
            setLoading(false)
        }

        checkInterest()
    }, [clickedInterested, event_id])

    const submitInterest = async () => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
        const body = { event_id: event_id }

        const interestData = await createEventInterestAPI(token, body)
        if (interestData.error === null) {
            setClickedInterested(interestData.data)
        } else if (
            interestData?.error?.response?.data?.errors &&
            interestData?.error?.response?.data?.errors[0]?.code === 'events.event_already_passed'
        ) {
            toast.error('Event Expired!')
        }
    }

    const handleRegister = async (regLink, webLink) => {
        try {
            const response = await fetch(regLink, { method: 'HEAD' })
            if (!response.ok) {
                window.open(webLink, '_blank')
            } else {
                window.open(regLink, '_blank')
            }
        } catch (error) {
            window.open(webLink, '_blank')
        }
    }

    if (loading) return <Loading />

    return (
        <div>
            {new Date(end_date) < new Date() ? (
                <div className="flex flex-col gap-2">
                    <p className="flex text-red-900 font-bold justify-center">
                        Event Expired!
                    </p>
                    <p
                        onClick={() => handleRegister(reg_link, web_link)}
                        className="text-blue-400 border-blue-400 text-lg font-semibold text-center px-4 py-2 rounded-md border-2 cursor-pointer">
                        Organizer Website
                    </p>
                </div>
            ) : (
                <div>
                    {done && !loading ? (
                        <div>
                            {reg_link.length !== 0 ? (
                                <div className="flex flex-col gap-2">
                                    {/* <Link
                                    to={reg_link}
                                    target="_blank"
                                    className="text-blue-400 border-blue-400 text-lg font-semibold text-center px-4 py-2 rounded-md border-2">
                                    Registration Link
                                </Link>
                                <Link
                                    to={web_link}
                                    target="_blank"
                                    className="text-blue-400 border-blue-400 text-lg font-semibold text-center px-4 py-2 rounded-md border-2">
                                    Web Link
                                </Link> */}
                                    <p
                                        onClick={() => handleRegister(reg_link, web_link)}
                                        className="text-blue-400 border-blue-400 text-lg font-semibold text-center px-4 py-2 rounded-md border-2 cursor-pointer">
                                        Registration Link
                                    </p>
                                </div>
                            ) : (
                                <p className="text-red-400 border-red-400 text-sm font-semibold text-center mr-4 p-2 rounded-md border-2">
                                    Registration link not available
                                </p>
                            )}
                            <span className=" block py-2"></span>
                            <PostPatchRatings event_id={event_id} user_id={state.auth.entity_id} />
                        </div>
                    ) : (
                        <div>
                            <p
                                onClick={() => submitInterest()}
                                className="text-primary-golden-5 bg-primary-green-1 border-primary-green-1 active:text-primary-green-1 active:bg-white text-lg font-semibold text-center px-8 py-2 rounded-md border-2 inline-block cursor-pointer select-none">
                                Interested ?
                            </p>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default EventInterestMeetingBtn
