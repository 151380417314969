import { useState, useEffect } from 'react'
import { getUserRolesAPI, selectRoleAPI } from '@api/index'
import { LocalVar, upperCaseInitLetter } from '@utils/index'
import toast from 'react-hot-toast'
import { Loading } from '@components/atoms/index'
import { useNavigate } from 'react-router-dom'

const Role = ({ roleName }) => {
    const navigate = useNavigate()

    const handleRole = async (role) => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        let body = {
            role: role,
        }

        const getRole = await selectRoleAPI(token, body)
        if (getRole.error === null) {
            toast('Role selected!', {
                icon: '👋',
            })
            localStorage.removeItem('seenCEFModal')
            setTimeout(() => {
                navigate('/events')
                window.location.replace('/events')
            }, 1000)
        } else {
            toast.error('Failed to set role.')
        }
    }

    return (
        <span
            onClick={() => handleRole(roleName)}
            className="block md:inline-block cursor-pointer text-gray-600 mx-2 rounded-full px-6 py-2 hover:bg-primary-green-1 hover:text-white text-lg font-medium">
            {upperCaseInitLetter(roleName)}
        </span>
    )
}

const SelectRole = () => {
    const [loading, setLoading] = useState(false)
    const [roles, setRoles] = useState([])

    useEffect(() => {
        setLoading(true)

        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        ;(async () => {
            const getRoles = await getUserRolesAPI(token)
            if (getRoles.error === null) {
                setRoles(getRoles.data.role)
                setLoading(false)
            } else {
                setLoading(false)
            }
        })()
    }, [])

    if (loading) return <Loading />

    return (
        <div>
            <h1 className="text-center text-primary-green-1">Continue as</h1>
            <p className="text-center pt-4">Select your profile</p>
            <div className="block bg-slate-300 rounded-lg md:rounded-full px-2 py-6 md:py-2 mt-8">
                {roles.map((v, i) => {
                    return (
                        <span key={i}>
                            <Role roleName={v} />
                        </span>
                    )
                })}
            </div>
        </div>
    )
}

export default SelectRole
