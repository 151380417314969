import { SettingsTemplate } from '@components/templates/index'
import { Header } from '@components/organisms/index'
import { ProjectVar } from '@utils/index'

const SettingsPage = () => {
    return (
        <div>
            <Header title="Settings" description={ProjectVar.PROJECT_NAME + ' settiings'} />
            <SettingsTemplate />
        </div>
    )
}

export default SettingsPage
