import { useState, useEffect } from 'react'
import { Pagination, Input, TextArea, Modal } from '@components/atoms'
import {
    filterSponsorContentsAPI,
    removeSponsorContentsAPI,
    createSponsorContentsAPI,
    getSponsorContentsAPI,
    updateSponsorContentsAPI,
} from '@api/sponsorContentsApi'
import { LocalVar } from '@utils/variables'
import toast from 'react-hot-toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMarker } from '@fortawesome/free-solid-svg-icons'

const AdminSponsorContent = () => {
    const [data, setData] = useState({ data: [] })
    const [page, setPage] = useState(0)
    const [openCreate, setOpemCreate] = useState(false)
    const [updateModal, setUpdateModal] = useState(null)

    useEffect(() => {
        ;(async () => {
            const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
            const getData = await filterSponsorContentsAPI(token, page, 12)
            setData(getData.data.data)
        })()
    }, [page])

    const remove = async (id) => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
        const rm = await removeSponsorContentsAPI(token, id)
        if (rm.error === null) {
            toast.success('Sponsor content removed')
            window.location.reload()
        } else {
            toast.error('Failed to remove sponsor content!')
        }
    }

    return (
        <div>
            <h3 className="text-primary-green-1 py-6">Sponsor contents</h3>
            <div>
                <span
                    className="bg-primary-green-1 text-white py-2 px-6 rounded-md cursor-pointer"
                    onClick={() => setOpemCreate(!openCreate)}>
                    Create sponsor content <FontAwesomeIcon icon={faMarker} className="pl-2" />
                </span>
                {openCreate && <AddSponsorContent />}
                <div className="grid grid-cols-12 gap-4 mt-8">
                    {data?.data?.map((v, i) => {
                        return (
                            <div key={i} className="col-span-3 bg-white rounded-md shadow-sm p-4 relative">
                                <h4 className="text-primary-green-1 font-bold mb-2 pr-4">
                                    <a href={v.link}>{v.title}</a>
                                </h4>
                                <span className="text-sm text-gray-500 absolute right-4 top-4">({v.priority})</span>
                                <p className="text-gray-700">{v.subtitle}</p>
                                <p>{v.body}</p>
                                <div className="flex gap-4 mt-4">
                                    <span
                                        className="bg-indigo-400 text-white py-1 px-3 rounded-sm cursor-pointer text-sm"
                                        onClick={() => setUpdateModal(v.id)}>
                                        Edit
                                    </span>
                                    <span
                                        className="bg-red-600 text-white py-1 px-3 rounded-sm cursor-pointer text-sm"
                                        onClick={() => remove(v.id)}>
                                        Delete
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <Pagination
                    prevPageCount={data.prev_page_count}
                    currentPageProp={data.curent_page}
                    nextPageCount={data.next_page_count}
                    setPage={setPage}
                />
                {updateModal !== null && updateModal !== false && (
                    <Modal title="Update sponsor content" setValue={setUpdateModal}>
                        <UpdateContent id={updateModal} setModalValue={setUpdateModal} />
                    </Modal>
                )}
            </div>
        </div>
    )
}

// update content component
const UpdateContent = ({ id, setModalValue }) => {
    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [link, setLink] = useState('')
    const [body, setBody] = useState('')
    const [priority, setPriority] = useState(0)

    useEffect(() => {
        ;(async () => {
            const getData = await getSponsorContentsAPI(id)
            if (getData.error === null) {
                setTitle(getData.data.data.title)
                setSubtitle(getData.data.data.subtitle)
                setLink(getData.data.data.link)
                setBody(getData.data.data.body)
                setPriority(getData.data.data.priority)
            } else {
                toast.error('Faild to get data!')
            }
        })()
    }, [id])

    const updateSpons = async (e) => {
        e.preventDefault()
        const bodyJson = {
            title,
            subtitle,
            link,
            body,
            priority,
        }

        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
        const create = await updateSponsorContentsAPI(token, id, bodyJson)
        if (create.error === null) {
            toast.success('Sponsor content updated.')
            setModalValue(false)
            window.location.reload()
        } else {
            toast.error('Failed to update sponsor content!')
        }
    }

    return (
        <div>
            <form onSubmit={updateSpons}>
                <div className="p-4 min-w-[600px]">
                    <Input
                        name="title"
                        value={title}
                        setValue={setTitle}
                        label="Title"
                        placeHolder="Sponsor content title"
                        required={true}
                    />
                    <Input
                        name="subtitle"
                        value={subtitle}
                        setValue={setSubtitle}
                        label="Subtitle"
                        placeHolder="Sponsor content subtitle"
                        required={true}
                    />
                    <Input
                        name="link"
                        value={link}
                        setValue={setLink}
                        label="Link"
                        placeHolder="Sponsor content link/url"
                        required={true}
                    />
                    <Input
                        name="priority"
                        value={priority}
                        setValue={setPriority}
                        label="Priority"
                        placeHolder="Set priority number for positioning"
                        required={true}
                        type="number"
                    />
                    <TextArea name="body" label="Details" value={body} setValue={setBody} />
                    <button type="submit" className="bg-primary-green-1 text-white py-2 px-6 rounded-md">
                        Update
                    </button>
                </div>
            </form>
        </div>
    )
}

// add content component
const AddSponsorContent = () => {
    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [link, setLink] = useState('')
    const [body, setBody] = useState('')
    const [priority, setPriority] = useState(0)

    const createSpons = async (e) => {
        e.preventDefault()
        const bodyJson = {
            title,
            subtitle,
            link,
            body,
            priority,
        }

        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
        const create = await createSponsorContentsAPI(token, bodyJson)
        if (create.error === null) {
            toast.success('New sponsor content created.')
            window.location.reload()
        } else {
            toast.error('Failed to create new sponsor content!')
        }
    }

    return (
        <div className="bg-white rounded-md my-4 p-4">
            <h4 className="text-primary-green-1 font-bold">Create sponsor contents</h4>
            <div>
                <form onSubmit={createSpons}>
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6">
                            <Input
                                name="title"
                                value={title}
                                setValue={setTitle}
                                label="Title"
                                placeHolder="Sponsor content title"
                                required={true}
                            />
                            <Input
                                name="subtitle"
                                value={subtitle}
                                setValue={setSubtitle}
                                label="Subtitle"
                                placeHolder="Sponsor content subtitle"
                                required={true}
                            />
                            <Input
                                name="link"
                                value={link}
                                setValue={setLink}
                                label="Link"
                                placeHolder="Sponsor content link/url"
                                required={true}
                            />
                            <Input
                                name="priority"
                                value={priority}
                                setValue={setPriority}
                                label="Priority"
                                placeHolder="Set priority number for positioning"
                                required={true}
                                type="number"
                            />
                            <TextArea name="body" label="Details" value={body} setValue={setBody} />
                            <button type="submit" className="bg-primary-green-1 text-white py-2 px-6 rounded-md">
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AdminSponsorContent
