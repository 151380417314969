import { useEffect, useState } from 'react'
import { getUserRolesAPI, setRoleAPI } from '@api/index'
import { LocalVar, ProfileVar, upperCaseInitLetter } from '@utils/index'
import toast from 'react-hot-toast'

const CreateRole = () => {
    const [yourRoles, setYourRoles] = useState([])

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        ;(async () => {
            const getRoles = await getUserRolesAPI(token)
            if (getRoles.error === null) {
                setYourRoles(getRoles.data.role)
            }
        })()
    }, [])

    return (
        <div className="p-4">
            <ProfileName yourRoles={yourRoles} profileName={ProfileVar.PRACTITIONER} />
            <ProfileName yourRoles={yourRoles} profileName={ProfileVar.ORGANIZER} />
            <ProfileName yourRoles={yourRoles} profileName={ProfileVar.SPONSOR} />
            <ProfileName yourRoles={yourRoles} profileName={ProfileVar.SPEAKER} />
            {/* <ProfileName yourRoles={yourRoles} profileName={ProfileVar.EDUCATOR} /> */}

            <div className="border-2 border-gray-400 bg-slate-100 rounded-md p-4 mt-8">
                <p>
                    <b>Note:</b> After creating your new role, you need to continue with it. During this time, you can
                    complete your profile.
                </p>
            </div>
        </div>
    )
}

export default CreateRole

const ProfileName = ({ yourRoles, profileName }) => {
    const submitRole = async (yourRoles, roleName) => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        if (yourRoles.includes(profileName)) return

        if (profileName === ProfileVar.EDUCATOR) return

        let finalArrayRoles = yourRoles.filter((str) => str !== ProfileVar.ADMIN)

        const roleCreate = await setRoleAPI(token, { role: [...finalArrayRoles, roleName] })
        if (roleCreate.error === null) {
            toast.success('Your role created!')
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        } else {
            toast.error('Unexpected error occured!')
        }
    }

    return (
        <h3
            onClick={() => submitRole(yourRoles, profileName)}
            className={
                yourRoles.includes(profileName)
                    ? 'bg-gray-200 text-primary-green-1 px-6 py-2 w-[260px] text-center font-medium rounded-md mb-2 cursor-pointer relative group border-2 border-primary-green-1'
                    : 'bg-primary-green-1 text-white px-6 py-2 w-[260px] text-center font-medium rounded-md mb-2 cursor-pointer relative active:bg-white active:text-primary-green-1 border-2 border-primary-green-1'
            }>
            {upperCaseInitLetter(profileName)}
            {yourRoles.includes(profileName) && (
                <span className="absolute -right-[170px] top-2 hidden group-hover:block text-white bg-black rounded-md text-sm p-1">
                    Already you have this role!
                </span>
            )}
        </h3>
    )
}
