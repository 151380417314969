import { Header } from '@components/organisms'
import { AuthTemplate } from '@components/templates/index'
import { VerifyForm } from '@components/molecules/index'

const VerifyPage = () => {
    return (
        <div>
            <Header title="Verify Email" description="DentiCE Verify page" />
            <AuthTemplate>
                <VerifyForm />
            </AuthTemplate>
        </div>
    )
}

export default VerifyPage
