import { SideBar } from '@components/molecules/index'
import { UserProfile } from '@components/molecules/index'

const AdminProfileTemplate = ({ userData }) => {
    return (
        <div>
            <SideBar>
                <div className="pt-6 px-4">
                    <h2 className="mb-6 text-red-600">Admin Profile</h2>
                    <div className="bg-white rounded-md p-8 shadow-sm">
                        <UserProfile data={{ ...userData, completeness: null }} />
                    </div>
                </div>
            </SideBar>
        </div>
    )
}

export default AdminProfileTemplate
