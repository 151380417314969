import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

const SearchDropDown = ({ data, value: propValue = 'Select', setValue = () => {}, label, labelClass }) => {
    const [localValue, setLocalValue] = useState('Select')
    const [selectedValue, setSelectedValue] = useState(propValue)
    const [isActive, setIsActive] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [filteredData, setFilteredData] = useState(data)

    useEffect(() => {
        if (!propValue) {
            setLocalValue('Select')
        } else {
            data.forEach((v) => {
                if (v.value === propValue) {
                    setLocalValue(v.text)
                }
            })
        }
    }, [propValue, data])

    const handleChange = (text, value) => {
        setLocalValue(text)
        setSelectedValue(value)
        setValue(value)
        setIsActive(false)
    }

    useEffect(() => {
        const filtered = data.filter((item) => item.text.toLowerCase().includes(searchTerm.toLowerCase()))
        setFilteredData(filtered)
    }, [searchTerm, data])

    return (
        <div>
            <label className={labelClass}>{label}</label>
            <div className="relative mt-2">
                {isActive && (
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search..."
                        className="w-full px-4 py-2 border-2 border-gray-200 rounded-md mb-1 cursor-text focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                )}
                <p
                    className="py-2 px-4 border-2 bg-white border-gray-200 rounded-md mb-1 cursor-pointer text-gray-600 font-medium relative"
                    onClick={() => setIsActive(!isActive)}>
                    {localValue}
                    <span className="absolute right-4">
                        {isActive ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
                    </span>
                </p>
                {isActive ? (
                    <div className="py-2 border-2 border-gray-200 rounded-md bg-white absolute top-12 block w-full shadow-sm z-10 max-h-[300px] overflow-y-auto">
                        {filteredData.map((v, i) => {
                            return (
                                <span
                                    key={i}
                                    className={
                                        v.value === selectedValue
                                            ? 'block cursor-pointer bg-gray-100 py-1 px-4 text-gray-600 text-sm'
                                            : 'block cursor-pointer hover:bg-gray-100 py-1 px-4 text-gray-600 text-sm'
                                    }
                                    onClick={() => {
                                        handleChange(v.text, v.value)
                                    }}>
                                    {v.text}
                                </span>
                            )
                        })}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default SearchDropDown
