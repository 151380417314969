import { Link } from 'react-router-dom'

const FooterNav = () => {
    const navList = [
        { route: '/events', txt: 'Find Events (CEs)' },
        { route: '/virtualce', txt: 'Virtual CE' },
        { route: '/mentorship', txt: 'Mentorship' },
        { route: '/dentaldeals', txt: 'Dental Deals' },
    ]
    return (
        <div className="flex flex-col">
            <ul className="pl-0 md:pl-16">
                {navList.map((v, i) => {
                    return (
                        <li className="pb-4" key={i}>
                            <Link to={v.route} className="golden-txt text-xl font-medium">
                                {v.txt}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default FooterNav
