import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGoogleLogin } from '@react-oauth/google'

const Google0AuthLogin = () => {
    const handleLoginSuccess = (credentialResponse) => {
        console.log('success', credentialResponse)
    }

    const handleLoginError = () => {
        console.log('failed')
    }

    const login = useGoogleLogin({
        onSuccess: handleLoginSuccess,
        onError: handleLoginError,
    })

    return (
        <div>
            <button
                onClick={() => login()}
                className="px-4 py-2 border-2 border-green-900 rounded-md w-full font-medium">
                <FontAwesomeIcon icon={faGoogle} className="text-primary-golden-5" /> Continue with Google
            </button>
        </div>
    )
}

export default Google0AuthLogin
