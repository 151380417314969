import { useState } from 'react'

const NumberMinMax = ({
    name,
    type = 'number',
    value = '',
    setValue = () => {},
    label = '',
    labelClass = '',
    inputClass = '',
    placeHolder = '',
    required = false,
    min = 0,
    max = 3000,
}) => {
    const [localValue, setLocalValue] = useState(value)

    const handleChange = (e) => {
        const newValue = e.target.value
        setLocalValue(newValue)
        setValue(parseInt(newValue))
    }

    return (
        <div>
            {label.length !== 0 ? (
                <label className={labelClass} htmlFor={name}>
                    {label}
                </label>
            ) : null}
            <input
                className={inputClass}
                type={type}
                id={name}
                value={localValue}
                onChange={handleChange}
                placeholder={placeHolder}
                required={required}
                min={min}
                max={max}
            />
        </div>
    )
}

export default NumberMinMax
