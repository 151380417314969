import { useState, useContext, useEffect } from 'react'
import { Input, PhoneNumber, Button } from '@components/atoms/index'
import { v4 as uuidv4 } from 'uuid'
import { updateUserAPI, getUserAPI } from '@api/index'
import { LocalVar } from '@utils/index'
import toast from 'react-hot-toast'
import { Auth } from '@context/authContext'

const UpdateUserInfo = () => {
    const { state } = useContext(Auth)

    const [fullName, setFullName] = useState(state.auth.full_name)
    const [phonePrefix, setPhonePrefix] = useState('')
    const [phone, setPhone] = useState('')
    const [country, setCountry] = useState('')
    const [province, setProvince] = useState('')
    const [city, setCity] = useState('')
    const [loading, setLoading] = useState(false)
    const [key1, setKey1] = useState(uuidv4())
    const [key2, setKey2] = useState(uuidv4())

    const clearField = () => {
        setFullName('')
        setPhonePrefix('+1')
        setPhone('')

        setKey1(uuidv4())
        setKey2(uuidv4())
    }

    useEffect(() => {
        ;(async () => {
            const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
            let getUserData = await getUserAPI(token, state?.auth?.entity_id)
            if (getUserData.error === null) {
                setPhonePrefix(getUserData?.data?.data?.phone_country_prefix)
                setPhone(getUserData?.data?.data?.phone_number)
                setCountry(getUserData?.data?.data?.country)
                setProvince(getUserData?.data?.data?.province)
                setCity(getUserData?.data?.data?.city)
            }
        })()
    }, [state])

    const submit = async (e) => {
        e.preventDefault()

        setLoading(true)

        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        let body = {}

        if (fullName.length !== 0) {
            body['full_name'] = fullName
        }

        if (phone.length !== 0) {
            body['phone_country_prefix'] = phonePrefix
            body['phone_number'] = phone
        }

        if (country.length !== 0) {
            body['country'] = country
        }

        if (province.length !== 0) {
            body['province'] = province
        }

        if (city.length !== 0) {
            body['city'] = city
        }

        const updateData = await updateUserAPI(token, body)

        if (updateData.error === null) {
            toast.success('User data updated!')
            setLoading(false)
            window.location.reload()
        } else {
            toast.error('Unexpected error occured!')
            setLoading(false)
        }

        clearField()
    }

    return (
        <div>
            <form onSubmit={submit}>
                <Input
                    name="name"
                    label="Name"
                    placeHolder="Your name"
                    value={fullName}
                    setValue={setFullName}
                    key={key1}
                />
                <PhoneNumber
                    name="phone"
                    label="Phone"
                    placeHolder="Your phone number"
                    setValue={setPhone}
                    setPrefixValue={setPhonePrefix}
                    key={key2}
                    value={phone}
                    prefixValue={phonePrefix}
                    required={false}
                />
                <Input
                    name="country"
                    label="Country"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100"
                    placeHolder="Your country"
                    required={true}
                    setValue={setCountry}
                    value={country}
                />
                <Input
                    name="province"
                    label="Province"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100"
                    placeHolder="Your province"
                    required={true}
                    setValue={setProvince}
                    value={province}
                />
                <Input
                    name="city"
                    label="City"
                    labelClass="text-md font-semibold"
                    inputClass="block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100"
                    placeHolder="Your city"
                    required={true}
                    setValue={setCity}
                    value={city}
                />
                <Button loading={loading}>Update</Button>
            </form>
        </div>
    )
}

export default UpdateUserInfo
