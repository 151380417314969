import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { useSlider } from '@hooks/index'

const PrevBtn = ({ onClick }) => {
    return (
        <button
            className="absolute bottom-[-50px] left-[calc(50%-40px)] cursor-pointer py-2 px-4 bg-primary-green-2 text-primary-golden-5 rounded-full"
            onClick={onClick}>
            <FontAwesomeIcon icon={faAngleLeft} />
        </button>
    )
}

const NextBtn = ({ onClick }) => {
    return (
        <button
            className="absolute bottom-[-50px] left-[calc(50%+40px)] cursor-pointer py-2 px-4 bg-primary-green-2 text-primary-golden-5 rounded-full"
            onClick={onClick}>
            <FontAwesomeIcon icon={faAngleRight} />
        </button>
    )
}

const SingleSlide = ({ data }) => {
    return (
        <div className="border-2 border-primary-golden-5 bg-dark-green rounded-md p-6 active:cursor-grabbing mx-2 min-h-[220px]">
            <h3 className="golden-txt mb-2">{data.title}</h3>
            <p className="text-white leading-8 tracking-wide">{data.detail}</p>
        </div>
    )
}

const DentalDealsPageSlider = () => {
    const data = [
        {
            title: 'Savings and Value',
            detail: 'Enjoy significant savings on a wide range of dental products and services, including equipment, supplies, software, and more.',
        },
        {
            title: 'Trusted Providers',
            detail: 'Access deals from reputable suppliers and service providers who meet our stringent quality standards and have a proven track record of excellence in the dental industry.',
        },
        {
            title: 'Convenience',
            detail: 'Shop online and take advantage of exclusive offers from the comfort of your home or office, saving you time and hassle.',
        },
        {
            title: 'Stay Informed',
            detail: 'Stay informed about the latest deals and promotions in the dental industry by subscribing to our newsletter and following us on social media.',
        },
        {
            title: 'Start Saving Today!',
            detail: 'Explore DentiCE Dental Deals and start enjoying exclusive discounts and offers that help you optimize your practice and enhance patient care.',
        },
    ]
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <PrevBtn />,
        nextArrow: <NextBtn />,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    const { FinalSlider } = useSlider(settings, data, SingleSlide)
    return (
        <div className="py-4 my-8">
            <h1 className="golden-txt py-6 text-center">Why Choose DentiCE Dental Deals?</h1>
            <div className="md:container mx-auto p-4 relative">
                <FinalSlider />
            </div>
        </div>
    )
}

export default DentalDealsPageSlider
