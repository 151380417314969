import { SaysAboutUs } from '../../atoms/sliderWrapper'
import { useSlider } from '../../../hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

const PrevBtn = ({ onClick }) => {
    return (
        <button
            className="absolute bottom-[-50px] left-[calc(50%-40px)] cursor-pointer py-2 px-4 bg-green-100 rounded-full"
            onClick={onClick}>
            <FontAwesomeIcon icon={faAngleLeft} />
        </button>
    )
}

const NextBtn = ({ onClick }) => {
    return (
        <button
            className="absolute bottom-[-50px] left-[calc(50%+40px)] cursor-pointer py-2 px-4 bg-green-100 rounded-full"
            onClick={onClick}>
            <FontAwesomeIcon icon={faAngleRight} />
        </button>
    )
}

const SaysAboutUsSlider = () => {
    const data = [
        {
            img: '/assets/img/dr-zamanian.png',
            personName: 'Dr. Ali Zamanian',
            title: 'DentiCE is A Time-Saver for Busy Dentists!',
            message:
                "Before DentiCE, I relied on word of mouth or aimless internet searches to find dental events. Now, with DentiCE, I can effortlessly discover a wide range of events in North America tailored to my interests. It's a time-saver that optimizes my continuing education journey, ensuring I never miss out on valuable opportunities again.",
        },
        {
            img: '/assets/img/dr-fattahian.png',
            personName: 'Dr. Reza Fattahian',
            title: 'DentiCE is a Breaking Barriers in Dental Education!',
            message:
                'DentiCE has shattered the limitations I faced in finding relevant dental events. Previously, I relied on colleagues or stumbled upon events by chance. Now, with DentiCE, I have access to a comprehensive database of seminars, webinars, and courses, empowering me to optimize my CE credits and stay ahead in my field.',
        },
        {
            img: '/assets/img/dr-alireza.png',
            personName: 'Dr. Alireza Karimi',
            title: 'DentiCE is Empowering Dentists with Knowledge!',
            message:
                "DentiCE has revolutionized how I approach dental education. Instead of relying solely on limited information from dental associations, I now have a centralized platform where I can explore a plethora of events across North America. With DentiCE, I'm empowered to take control of my learning journey and expand my knowledge base like never before.",
        },
        {
            img: '/assets/img/dr-behnam.png',
            personName: 'Dr. Behnam Bohluli, Dentist',
            title: 'DentiCE is a game-changer for us!',
            message:
                "Organizing events at our clinic, we needed a platform allowing us to publish our events for a larger audience. DentiCE offers just that, with all kinds of events in North America. As a mentor, I can easily provide guidance and keep track of what's happening in our field",
        },
    ]

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <PrevBtn />,
        nextArrow: <NextBtn />,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    const { FinalSlider } = useSlider(settings, data, SaysAboutUs)
    return (
        <div className="bg-light-main-green py-4 my-8">
            <h1 className="text-primary-green-1 text-center p-2">What other says about us</h1>
            <div className="md:container mx-auto p-4 relative">
                <FinalSlider />
            </div>
        </div>
    )
}

export default SaysAboutUsSlider
