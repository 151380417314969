import React, { useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
// import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { useNavigate } from 'react-router-dom'
import { SideBar } from '@components/molecules'
import { Input, Pagination } from '@components/atoms/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { writeFile, utils } from 'xlsx'
import { getUsersDataByDateAPI } from '@api/analyticsApi'
import { LocalVar } from '@utils/variables'

const dateRangedTableColumns = [
    { id: 'full_name', label: 'Name', minWidth: 100 },
    { id: 'email_address', label: 'Email', minWidth: 100 },
    { id: 'phone', label: 'Phone', minWidth: 100 },
    { id: 'signup_date', label: 'SignUp At', minWidth: 100 },
    {
        id: 'last_login_date',
        label: 'LS Date',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'number_of_login',
        label: 'Number of Logins',
        minWidth: 70,
        align: 'right',
    },
]

const columns = [
    { id: 'full_name', label: 'Name', minWidth: 100 },
    { id: 'email_address', label: 'Email', minWidth: 100 },
    {
        id: 'created_at',
        label: 'Sign Up At',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'last_login_date',
        label: 'LS Date',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'status',
        label: 'Status',
        minWidth: 70,
        align: 'right',
    },
    {
        id: 'role',
        label: 'Roles',
        minWidth: 170,
        align: 'right',
    },
    // {
    //     id: 'phone_number',
    //     label: 'Phone',
    //     minWidth: 170,
    //     align: 'right'
    // },
]

export default function UsersListTableTemplate({
    data,
    dateRangeddata,
    setPage,
    setActivityPage,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
}) {
    const navigate = useNavigate()
    const [isDateRangeView, setIsDateRangeView] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    // const [page, setPage] = React.useState(0)
    // const [rowsPerPage, setRowsPerPage] = React.useState(10)

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage)
    // }

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value)
    //     setPage(0)
    // }

    const handleRowClick = (accountId) => {
        navigate(`/analytics/${accountId}`)
    }

    const isUserActive = (paramDate) => {
        let date = new Date()
        let lastLoginDate = new Date(paramDate)
        let oneMonthBackDate = new Date(date)
        oneMonthBackDate.setMonth(oneMonthBackDate.getMonth() - 1)

        return lastLoginDate < oneMonthBackDate ? 'Inactive' : 'Active'
    }

    const handleDownload = async () => {
        setIsDownloading(true)
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
        let data
        let sDate = new Date(startDate)
        let eDate = new Date(endDate)
        let psDate = `${sDate.getFullYear()}-${
            sDate.getMonth() + 1
        }-${sDate.getDate()} ${sDate.getHours()}:${sDate.getMinutes()}:${sDate.getSeconds()}`
        let peDate = `${eDate.getFullYear()}-${
            eDate.getMonth() + 1
        }-${eDate.getDate()} ${eDate.getHours()}:${eDate.getMinutes()}:${eDate.getSeconds()}`
        let usersDateRangeData = await getUsersDataByDateAPI(token, psDate, peDate, 0, 10, true)
        if (usersDateRangeData.error === null) {
            data = usersDateRangeData?.data?.data?.data || []
            setIsDownloading(false)
        } else {
            setIsDownloading(false)
        }

        let preparedData = data?.map((dt) => {
            return {
                'Full name': dt?.full_name || '',
                Email: dt?.email_address || '',
                Phone: dt?.phone_country_prefix && dt?.phone_number ? dt?.phone_country_prefix + dt?.phone_number : '',
                Country: dt?.country || '',
                Province: dt?.province || '',
                City: dt?.city || '',
                'SignUp At': dt?.signup_date ? dt?.signup_date.split('T')[0] : '',
                'No. of logins': dt?.number_of_login || '',
                'Last login date': dt?.last_login_date ? dt?.last_login_date.split('T')[0] : '',
            }
        })
        let wb = utils.book_new()
        let ws = utils.json_to_sheet(preparedData || {})
        utils.book_append_sheet(wb, ws, 'Users list')
        writeFile(wb, `data.xlsx`)
        setIsDownloading(false)
    }

    return (
        <div className="h-screen">
            <SideBar>
                <div className="flex pt-6 px-6 gap-4">
                    <h2 className="text-primary-green-1">Analytics Dashboard</h2>
                    <div className="flex justify-between items-center bg-white shadow-lg rounded-xl px-1 w-[160px] h-[30px]">
                        <div
                            className={`flex items-center justify-center w-[70px] h-[25px] ${
                                !isDateRangeView ? 'bg-[#184332]' : 'bg-white'
                            } rounded-[10px] cursor-pointer`}
                            onClick={() => setIsDateRangeView((prev) => !prev)}>
                            <p
                                className={`${!isDateRangeView ? 'button-linear-text' : 'button-dashboard-tab-text'}`}
                                style={{ textTransform: 'capitalize' }}>
                                Analytics
                            </p>
                        </div>
                        <div
                            className={`flex items-center justify-center w-[70px] h-[25px] ${
                                isDateRangeView ? 'bg-[#184332]' : 'bg-white'
                            } rounded-[10px] cursor-pointer`}
                            onClick={() => setIsDateRangeView((prev) => !prev)}>
                            <p
                                className={`${isDateRangeView ? 'button-linear-text' : 'button-dashboard-tab-text'}`}
                                style={{ textTransform: 'capitalize' }}>
                                Activity
                            </p>
                        </div>
                    </div>
                </div>

                {isDateRangeView && (
                    <div className="flex justify-center gap-4 py-4">
                        <div className="">
                            <Input
                                type="datetime-local"
                                name="Start Date"
                                label="Start date"
                                labelClass="text-md font-semibold"
                                inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                                placeHolder="Start Date"
                                value={startDate}
                                setValue={setStartDate}
                                required={true}
                            />
                        </div>
                        <div className="">
                            <Input
                                type="datetime-local"
                                name="End Date"
                                label="End date"
                                labelClass="text-md font-semibold"
                                inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                                placeHolder="End Date"
                                value={endDate}
                                setValue={setEndDate}
                                required={true}
                            />
                        </div>
                    </div>
                )}
                {isDateRangeView && (
                    <div className="flex justify-end px-6">
                        <button
                            className={`py-1 px-4 text-sm rounded-md mx-1 bg-primary-green-1 text-white border-2 border-primary-green-1 ${
                                isDownloading ? 'cursor-not-allowed' : ''
                            }`}
                            disabled={isDownloading}
                            onClick={() => handleDownload()}>
                            Download <FontAwesomeIcon icon={faFileDownload} />
                        </button>
                    </div>
                )}
                <div className="flex justify-center px-6 py-2">
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            {!isDateRangeView && (
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, fontWeight: 700 }}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!!data?.data &&
                                            data?.data.length > 0 &&
                                            data?.data
                                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((rowData) => {
                                                    let role = rowData?.role.join(', ')
                                                    let userStatus = isUserActive(rowData?.last_login_date)
                                                    return {
                                                        ...rowData,
                                                        status: userStatus,
                                                        created_at: rowData?.created_at
                                                            ? rowData?.created_at.split('T')[0]
                                                            : '',
                                                        last_login_date: rowData?.last_login_date
                                                            ? rowData?.last_login_date.split('T')[0]
                                                            : '',
                                                        role,
                                                    }
                                                })
                                                .map((row) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            onClick={() => handleRowClick(row?.id)}
                                                            className="cursor-pointer">
                                                            {columns.map((column) => {
                                                                const value = row[column.id]
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === 'number'
                                                                            ? column.format(value)
                                                                            : value}
                                                                    </TableCell>
                                                                )
                                                            })}
                                                        </TableRow>
                                                    )
                                                })}
                                    </TableBody>
                                </Table>
                            )}
                            {isDateRangeView && (
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {dateRangedTableColumns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, fontWeight: 700 }}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!!dateRangeddata?.data &&
                                            dateRangeddata?.data.length > 0 &&
                                            dateRangeddata?.data
                                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((rowData) => {
                                                    return {
                                                        ...rowData,
                                                        last_login_date: rowData?.last_login_date
                                                            ? rowData?.last_login_date.split('T')[0]
                                                            : '',
                                                        signup_date: rowData?.signup_date
                                                            ? rowData?.signup_date.split('T')[0]
                                                            : '',
                                                        phone:
                                                            rowData?.phone_country_prefix && rowData?.phone_number
                                                                ? rowData?.phone_country_prefix + rowData?.phone_number
                                                                : '',
                                                    }
                                                })
                                                .map((row) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            onClick={() => handleRowClick(row?.user_id)}
                                                            className="cursor-pointer">
                                                            {dateRangedTableColumns.map((column) => {
                                                                const value = row[column.id]
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {value}
                                                                    </TableCell>
                                                                )
                                                            })}
                                                        </TableRow>
                                                    )
                                                })}
                                    </TableBody>
                                </Table>
                            )}
                        </TableContainer>
                        {/* <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={data?.data?.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        /> */}
                    </Paper>
                </div>
                {!isDateRangeView && (
                    <Pagination
                        prevPageCount={data?.prev_page_count}
                        currentPageProp={data?.current_page}
                        nextPageCount={data?.next_page_count}
                        setPage={setPage}
                    />
                )}
                {isDateRangeView && (
                    <Pagination
                        prevPageCount={dateRangeddata?.prev_page_count}
                        currentPageProp={dateRangeddata?.current_page}
                        nextPageCount={dateRangeddata?.next_page_count}
                        setPage={setActivityPage}
                    />
                )}
            </SideBar>
        </div>
    )
}
