import { AuthTemplate } from '../templates'
import { LoginForm } from '../molecules'
import { Header } from '../organisms'

const LoginPage = () => {
    return (
        <div>
            <Header title="Login" description="DentiCE login page" />
            <AuthTemplate>
                <LoginForm />
            </AuthTemplate>
        </div>
    )
}

export default LoginPage
