import axios from 'axios'
import { resolve } from './resolve'
import { settings } from '@utils/index'

export const createSpeakerProfileAPI = async (token, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .post('/profile-speakers/', { ...body }, { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((res) => res.data),
    )
}

export const getSpeakerProfileAPI = async (token) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get('/profile-speakers/', { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((res) => res.data),
    )
}

export const updateSpeakerProfileAPI = async (token, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .patch('/profile-speakers/', { ...body }, { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((res) => res.data),
    )
}
