import { Header, Footer } from '@components/organisms'
import { MainNav } from '@components/molecules/index'
import { Modal } from '@components/atoms/index'
import { useState } from 'react'
import { DentalDealsPageSlider } from '@components/molecules/index'

const DentalDealsPage = () => {
    const [open, setOpen] = useState(5)
    return (
        <div className="bg-light-main-green">
            <Header title="Dental Deals" description="DentiCE | A Roadmap to Career Growth and Personal Fulfillment." />
            <MainNav />
            <div className="p-4 py-10 bg-dark-green">
                <div className="md:container mx-auto">
                    <h2 className="golden-txt text-center py-10">
                        Unlock Exclusive Deals and Discounts for Dental Professionals with DentiCE Dental Deals
                    </h2>
                    <p className="text-white leading-8 text-center text-lg">
                        DentiCE Dental Deals is your one-stop destination for accessing exclusive discounts and offers
                        on dental products, services, and resources. Whether you're looking to upgrade your equipment,
                        stock up on supplies, or access premium services for your practice, our platform connects you
                        with unbeatable deals from trusted providers in the dental industry.
                    </p>
                </div>
            </div>
            <div className="md:container mx-auto py-16">
                <DentalDealsPageSlider />
            </div>
            {open === 5 && (
                <Modal title="Update" setValue={setOpen}>
                    <h4 className="text-primary-green-1">New Features for this page is coming soon!</h4>
                </Modal>
            )}

            <Footer />
        </div>
    )
}

export default DentalDealsPage
