import { Outlet, Navigate } from 'react-router-dom'
import { useState, useContext, useEffect } from 'react'
import { Loading } from '../components/atoms'
import { Auth } from '../context'
import { LocalVar } from './variables'
import { getAuthSessionAPI } from '@api'

const UnshelteredRoute = () => {
    const { dispatch } = useContext(Auth)

    const [loading, setLoading] = useState(true)
    const [authenticated, setAuthenticated] = useState(false)

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const getAuthSession = async (token) => {
            let data = await getAuthSessionAPI(token)
            if (data.error === null) {
                setAuthenticated(true)
                dispatch({ type: 'set', payload: data.data })
                setLoading(false)
            } else {
                setAuthenticated(false)
                setLoading(false)
            }
        }

        if (token !== null) {
            getAuthSession(token)
        } else {
            setLoading(false)
        }
    }, [dispatch])

    if (loading || loading === undefined) {
        return <Loading />
    } else {
        return authenticated !== true ? <Outlet /> : <Navigate to="/home" />
    }
}

export default UnshelteredRoute
