import { SponsorProfile } from '@components/molecules/index'
import { SideBar } from '@components/molecules/index'

const SponsorProfileTemplate = ({ userData, profileData }) => {
    return (
        <div>
            <SideBar>
                <SponsorProfile userData={userData} profileData={profileData} />
            </SideBar>
        </div>
    )
}

export default SponsorProfileTemplate
