import { useState } from 'react'
import { SideBar } from '../molecules'
import { Button } from '../atoms'
import { useMultiStepForm } from '../../hooks'
import { FirstForm, LastForm } from '../molecules/Forms/EventCreate'
import { LocalVar } from '@utils/index'
import { useNavigate } from 'react-router-dom'
import { createEventAPI } from '@api/index'
import toast from 'react-hot-toast'

const EventsAddTemplate = () => {
    const [eventName, setEventName] = useState('')
    const [description, setDescription] = useState('')
    const [timeZone, setTimeZone] = useState('-04:00')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [orgName, setOrgName] = useState('')
    const [orgPhone, setOrgPhone] = useState('')
    const [countryName, setCountryName] = useState('')
    const [cityName, setCityName] = useState('')
    const [address, setAddress] = useState('')
    const [earlyBirdsPrice, setEarlyBirdsPrice] = useState(0)
    const [regularPrice, setRegularPrice] = useState(0)
    const [eventEnqEmail, setEventEnqEmail] = useState('')
    const [regLink, setRegLink] = useState('')
    const [numberOfCE, setNumberOfCE] = useState(0)
    // const [meetingLink, setMeetingLink] = useState('')
    const [virtual, setVirtual] = useState('virtual')
    const [speakers, setSpeakers] = useState([])
    const [category, setCategory] = useState('webinar')
    const [nationality, setNationality] = useState('')

    // const [done, setDone] = useState(false)
    const [loading, setLoading] = useState(false)

    const { step, isFirstStep, isLastStep, back, next } = useMultiStepForm([
        <FirstForm
            eventName={eventName}
            setEventName={setEventName}
            descripion={description}
            setDescription={setDescription}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            orgName={orgName}
            setOrgName={setOrgName}
            orgPhone={orgPhone}
            setOrgPhone={setOrgPhone}
            countryName={countryName}
            setCountryName={setCountryName}
            cityName={cityName}
            setCityName={setCityName}
            address={address}
            setAddress={setAddress}
            timeZone={timeZone}
            setTimeZone={setTimeZone}
        />,
        <LastForm
            earlyBirdsPrice={earlyBirdsPrice}
            setEarlyBirdsPrice={setEarlyBirdsPrice}
            regularPrice={regularPrice}
            setRegularPrice={setRegularPrice}
            eventEnqEmail={eventEnqEmail}
            setEventEnqEmail={setEventEnqEmail}
            numberOfCE={numberOfCE}
            setNumberOfCE={setNumberOfCE}
            // meetingLink={meetingLink}
            // setMeetingLink={setMeetingLink}
            regLink={regLink}
            setRegLink={setRegLink}
            setVirtual={setVirtual}
            speakers={speakers}
            setSpeakers={setSpeakers}
            setCategory={setCategory}
            nationality={nationality}
            setNationality={setNationality}
        />,
    ])

    let navigate = useNavigate()

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!isLastStep) return next()

        if (loading === true) return
        setLoading(true)

        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const body = {
            event_name: eventName,
            speakers_with_cv: [...speakers],
            event_start_date: startDate + timeZone,
            event_end_date: endDate + timeZone,
            description: description,
            organizer_name: orgName,
            organizer_contact: orgPhone,
            country: countryName,
            state_province: '',
            city: cityName,
            address: address,
            early_birds_price: earlyBirdsPrice,
            regular_price: regularPrice,
            number_of_ce: numberOfCE,
            meeting_link: '',
            registration_link: regLink,
            event_enquiries: eventEnqEmail,
            virtual: virtual,
            featured: false,
            archived: false,
            category: category,
            nationality: nationality.length === 0 ? 'any' : nationality,
            time_zone: timeZone,
            speaker_ids: []
        }

        let addEvent = await createEventAPI(token, body)
        if (addEvent.error === null) {
            toast('Event successfully created!', {
                icon: '👋',
            })
            setLoading(false)
            navigate(`/events/${addEvent.data.data.id}`)
        } else {
            console.log(addEvent.error)
            const pop = 'Something went wrong'
            toast.error(pop)
            setLoading(false)
        }
    }

    return (
        <div className="h-screen">
            <SideBar>
                <div className="pt-2">
                    <div className="w-full px-2 md:px-6 py-10">
                        <form onSubmit={onSubmit} className="w-full bg-white py-6 rounded-lg relative">
                            {step}
                            <div className="py-8 mt-4">
                                <div className="flex justify-center">
                                    {!isFirstStep && (
                                        <button
                                            type="button"
                                            onClick={back}
                                            className="block text-primary-green-1 font-semibold text-xl bg-slate-200 py-2 px-12 rounded-md mr-4">
                                            Back
                                        </button>
                                    )}
                                    <Button
                                        type="submit"
                                        loading={loading}
                                        btnClass="block text-white font-semibold text-base md:text-xl bg-primary-green-1 py-2 px-8 md:px-12 rounded-md">
                                        {isLastStep ? 'Submit' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </SideBar>
        </div>
    )
}

export default EventsAddTemplate
