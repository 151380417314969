const SaysAboutUs = ({ data }) => {
    return (
        <div className="p-4">
            <div className="bg-blue-50 rounded-lg active:cursor-grabbing p-6 pt-24 relative shadow-md">
                <img src={data.img} alt={data.img} className="w-14 h-14 rounded-full absolute left-6 top-6" />
                <p className="text-primary-green-1 font-bold mb-1">{data.title}</p>
                <h4 className="text-primary-green-1 font-semibold absolute top-10 left-24">{data.personName}</h4>
                <p className="text-gray-600">{data.message}</p>
            </div>
        </div>
    )
}

export default SaysAboutUs
