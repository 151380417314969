import { useState } from 'react'
import toast from 'react-hot-toast'
import { Input, Button } from '@components/atoms/index'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { verifyEmailAPI, resendVerifyEmailAPI } from '@api/index'

const VerifyForm = () => {
    let { email } = useParams()
    const [code, setCode] = useState('')
    const [verifyLoading, setVerifyLoading] = useState(false)
    const [resendLoading, setResendLoading] = useState(false)

    const navigate = useNavigate()

    const verifySubmit = async (e) => {
        if (code.length === 0) return
        e.preventDefault()
        setVerifyLoading(true)
        const vr = await verifyEmailAPI({ token: code, email: email })
        if (vr.error === null) {
            setVerifyLoading(false)
            toast.success('Your email verified...!')
            setTimeout(() => {
                navigate('/login')
            }, 100)
        } else {
            setVerifyLoading(false)
            toast.error('Email not verified. Something went wrong!')
        }
    }

    const resendSubmit = async () => {
        setResendLoading(true)
        const vr = await resendVerifyEmailAPI({ email: email })
        if (vr.error === null) {
            setResendLoading(false)
            toast.success('Email send!')
        } else {
            setResendLoading(false)
            toast.error('Email not verified. Something went wrong!')
        }
    }

    return (
        <div className="h-full w-full flex justify-center overflow-auto">
            <div className="w-[400px] m-auto py-8">
                <h2 className="font-w6 text-center">Verify your email</h2>
                <p className="text-gray-700 py-4 text-center mb-6">
                    <b>Check your email: </b>
                    {email}
                </p>
                <form onSubmit={verifySubmit}>
                    <Input
                        name="code"
                        label="Verification Code"
                        setValue={setCode}
                        placeHolder="Put your verification code here"
                        required={true}
                    />

                    <Button loading={verifyLoading}>Submit</Button>
                </form>
                <p className="font-semibold text-gray-400 text-center my-2">OR</p>
                <Button
                    onClick={() => resendSubmit()}
                    btnClass="bg-slate-100 text-primary-green-1 p-4 w-full block cursor-pointer rounded-md active:bg-primary-green-10 active:text-white active:bg-primary-green-3 border-2 border-slate-200 hover:bg-slate-50"
                    loading={resendLoading}>
                    Resend verification code
                </Button>
                <p className="font-semibold text-gray-400 text-center mt-8">
                    Account verified?{' '}
                    <Link to="/login" className="text-primary-green-1">
                        Login
                    </Link>
                </p>
            </div>
        </div>
    )
}
export default VerifyForm
