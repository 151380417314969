import { OrganizerSettings, UserProfile } from '@components/molecules/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHashtag } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'

const OrganizersProfile = ({ userData, profileData }) => {
    const [isEditable, setIsEditable] = useState(false)
    return (
        <div className="pt-12 px-4">
            <h2 className="mb-6">Profile</h2>
            <div className="bg-white rounded-md p-8 shadow-sm">
                <UserProfile
                    data={{ ...userData, completeness: profileData.completeness }}
                    isEditable={isEditable}
                    setIsEditable={setIsEditable}
                />
            </div>
            {!isEditable && (
                <div className="bg-white rounded-md p-8 shadow-sm mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <p className="font-semibold">
                            Profile active:{' '}
                            <span className={profileData?.profile?.active ? 'text-primary-green-2' : 'text-red-500'}>
                                {profileData?.profile?.active ? 'Yes' : 'No'}
                            </span>
                        </p>
                        <p className="text-gray-600 font-semibold py-2">
                            Company name: <span className="font-medium">{profileData?.profile?.company_name}</span>
                        </p>
                        <p className="text-gray-600 font-semibold py-2">
                            Organization name:{' '}
                            <span className="font-medium">{profileData?.profile?.organization_name}</span>
                        </p>
                        <p className="text-gray-600 font-semibold py-2">
                            Organization email:{' '}
                            <span className="font-medium">{profileData?.profile?.organization_email}</span>
                        </p>
                        <p className="text-gray-600 font-semibold py-2">
                            Your role: <span className="font-medium">{profileData?.profile?.your_role}</span>
                        </p>
                        <p className="text-gray-600 font-semibold py-2">
                            Address: <span className="font-medium">{profileData?.profile?.address}</span>
                        </p>
                    </div>
                    <div>
                        <p className="text-gray-600 font-semibold py-2">
                            Per year you want to organize events:{' '}
                            <span className="font-medium">{profileData?.profile?.per_year_organize_events}</span>
                        </p>
                        <p className="text-gray-600 font-semibold py-2">
                            You need sponser:{' '}
                            <span className="font-medium">
                                {profileData?.profile?.do_you_need_sponser ? 'Yes' : 'No'}
                            </span>
                        </p>
                        {profileData?.profile?.do_you_need_sponser && (
                            <p className="text-gray-600 font-semibold py-2">
                                How much budget you need:{' '}
                                <span className="font-medium">{profileData?.profile?.how_much_budget_you_need}</span>
                            </p>
                        )}
                        <p className="text-gray-600 font-semibold py-2">
                            Do you provide CE credits:{' '}
                            <span className="font-medium">
                                {profileData?.profile?.do_you_provide_ce_credits ? 'Yes' : 'No'}
                            </span>
                        </p>
                        <div className="py-2">
                            <p className="text-primary-green-1 font-semibold mb-2">Kind of events you hold:</p>
                            <div className="flex flex-wrap">
                                {profileData?.profile?.kinds_of_events_you_hold.map((v, i) => {
                                    return (
                                        <span className="text-sm px-1 bg-gray-200 rounded-md m-1" key={i}>
                                            <FontAwesomeIcon icon={faHashtag} className="text-primary-green-1 mr-2" />
                                            {v}
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isEditable && (
                <div className="bg-white rounded-md p-8 shadow-sm mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <OrganizerSettings userData={userData} profileData={profileData} />
                </div>
            )}
        </div>
    )
}

export default OrganizersProfile
