import { useState } from 'react'
import FormWrapper from './FormWrapper'
import { DropDown, Input, TextArea } from '@components/atoms/index'
import { EventCategoryArray, virtualOptions } from '@utils/index'

const LastForm = ({
    earlyBirdsPrice,
    setEarlyBirdsPrice,
    regularPrice,
    setRegularPrice,
    eventEnqEmail,
    setEventEnqEmail,
    numberOfCE,
    setNumberOfCE,
    // meetingLink,
    // setMeetingLink,
    regLink,
    setRegLink,
    setVirtual,
    speakers,
    setSpeakers,
    setCategory,
    nationality,
    setNationality,
}) => {
    let categories = EventCategoryArray.map((v, i) => v.value)

    const [speaker, setSpeaker] = useState('')

    const addSpeaker = (e) => {
        e.preventDefault()
        if (speaker.length === 0) return
        setSpeakers([...speakers, speaker])
        setSpeaker('')
    }

    const removeSpeaker = (po) => {
        let arr = [...speakers]
        arr.splice(po, 1)
        setSpeakers([...arr])
    }

    return (
        <div>
            <FormWrapper title="Event informationn" number={2}>
                <div className="grid grid-cols-12 gap-2 md:gap-8">
                    <div className="col-span-12 md:col-span-6">
                        <div className="relative">
                            <Input
                                type="number"
                                name="early_price"
                                label="Early birds price"
                                labelClass="text-md font-semibold"
                                inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 pl-6 focus:outline-0 rounded-md "
                                placeHolder="Write here price in USD"
                                value={earlyBirdsPrice}
                                setValue={setEarlyBirdsPrice}
                            />
                            <i className="absolute top-[41px] md:top-[42px] left-3 text-gray-600">$</i>
                        </div>
                        <div className="relative">
                            <Input
                                type="number"
                                name="regular_price"
                                label="Regular price"
                                labelClass="text-md font-semibold"
                                inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 pl-6 focus:outline-0 rounded-md "
                                placeHolder="Write here price in USD"
                                value={regularPrice}
                                setValue={setRegularPrice}
                            />
                            <i className="absolute top-[41px] md:top-[42px] left-3 text-gray-600">$</i>
                        </div>
                        <Input
                            name="reg"
                            label="Registration link"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                            placeHolder="Paste here"
                            value={regLink}
                            setValue={setRegLink}
                            required={true}
                        />
                        <TextArea
                            name="add-speaker"
                            label="Add speaker with CV"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md"
                            value={speaker}
                            setValue={setSpeaker}
                        />
                        <button
                            onClick={(e) => addSpeaker(e)}
                            className="text-white bg-primary-green-1 border-2 border-primary-green-1 active:text-primary-green-1 active:bg-white py-2 px-6 rounded-md">
                            Add
                        </button>
                        <div className="flex flex-wrap mt-4">
                            {speakers.map((v, i) => {
                                return (
                                    <pre
                                        className="text-sm text-gray-600 bg-gray-300 py-1 px-2 m-1 rounded-md pr-6 relative"
                                        key={i}>
                                        {v}
                                        <span
                                            onClick={() => removeSpeaker(i)}
                                            className="absolute right-2 top-1 text-gray-600 font-semibold cursor-pointer hover:text-red-400">
                                            x
                                        </span>
                                    </pre>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                        <Input
                            type="email"
                            name="enq-email"
                            label="Event enquiries (email)"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                            placeHolder="Write here"
                            value={eventEnqEmail}
                            setValue={setEventEnqEmail}
                            required={true}
                        />
                        <Input
                            type="number"
                            name="number_ce"
                            label="Number of CE"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 pl-2 focus:outline-0 rounded-md "
                            placeHolder="Number of CE"
                            value={numberOfCE}
                            setValue={setNumberOfCE}
                        />
                        {/* <Input
                            name="meet-link"
                            label="Meeting link"
                            labelClass="text-md font-semibold"
                            inputClass="block w-full border-2 border-gray-300 p-2 mt-2 mb-6 focus:outline-0 rounded-md "
                            placeHolder="Write here"
                            value={meetingLink}
                            setValue={setMeetingLink}
                        /> */}
                        <DropDown
                            data={virtualOptions}
                            label="Virtual or In-person"
                            labelClass="text-lg font-semibold"
                            setValue={setVirtual}
                        />
                        <span className="mb-2"></span>
                        <DropDown
                            data={categories}
                            label="Select category"
                            labelClass="text-lg font-semibold"
                            setValue={setCategory}
                        />
                        <span className="mb-2"></span>
                        <Input
                            type="text"
                            name="nat"
                            label="Nationality"
                            placeHolder="Nationality"
                            value={nationality}
                            setValue={setNationality}
                        />
                    </div>
                </div>
            </FormWrapper>
        </div>
    )
}

export default LastForm
