import { useState, useEffect } from 'react'
import { Loading, Modal } from '@components/atoms/index'
import { LocalVar } from '@utils/index'
import { getOrganizersAdminAPI, updateStatusOrganizerAdminAPI } from '@api/index'
import { OrganizersProfile } from '@components/molecules/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import toast from 'react-hot-toast'

const AdminOrganizersTable = () => {
    const [data, setData] = useState({ data: [] })
    const [active, setActive] = useState(false)
    const [statusUpdate, setStatusUpdate] = useState(null)
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(null)
    const [orgModal, setOrgModal] = useState(null)

    useEffect(() => {
        setLoading(true)
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const getOrg = async (token, active) => {
            const getDtata = await getOrganizersAdminAPI(token, active)
            if (getDtata.error === null) {
                setData(getDtata.data.data)
                setLoading(false)
            } else {
                setErr(getDtata.error)
                setLoading(false)
            }
        }

        getOrg(token, active)
    }, [active, statusUpdate])

    const activeOrg = async (id, value) => {
        const token = await JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
        const body = { status: value }
        const act = await updateStatusOrganizerAdminAPI(token, id, body)
        if (act.error === null) {
            toast.success("Organizer's profile status update!")
            setStatusUpdate(act.data)
        }
    }

    if (loading) {
        return (
            <div className="h-full flex justify-center items-center bg-white">
                <Loading />
            </div>
        )
    }

    if (err !== null) {
        return (
            <div className="bg-white rounded-md p-8">
                <h3 className="text-primary-green-1">Organizers </h3>
                <h3 className="text-center text-gray-400 mt-8">
                    <FontAwesomeIcon icon={faBan} className="mr-2" />
                    Unexpected error occurred!
                </h3>
            </div>
        )
    }

    return (
        <div className="bg-white rounded-md p-8">
            <h3 className="text-primary-green-1">
                Organizers{' '}
                <span
                    onClick={() => setActive(!active)}
                    className="ml-4 text-sm font-medium rounded-full bg-slate-300 py-2 cursor-pointer">
                    <span
                        className={
                            active
                                ? 'px-4 py-2 rounded-full text-primary-golden-6 bg-primary-green-1'
                                : 'px-4 py-2 rounded-full'
                        }>
                        Active Organizers
                    </span>
                    <span
                        className={
                            active
                                ? 'px-4 py-2 rounded-full'
                                : 'px-4 py-2 rounded-full text-primary-golden-6 bg-primary-green-1'
                        }>
                        Deactive Organizers
                    </span>
                </span>
            </h3>
            <div className="mt-6 overflow-y-auto min-h-[40vh] max-h-[60vh]">
                <table>
                    <thead>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold min-w-[180px] bg-slate-100">
                            User name
                        </th>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold min-w-[180px] bg-slate-100">
                            User email
                        </th>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold min-w-[180px] bg-slate-100">
                            Organization name
                        </th>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold min-w-[180px] bg-slate-100">
                            Organization email
                        </th>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold min-w-[180px] bg-slate-100">
                            Company name
                        </th>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold bg-slate-100">
                            View profile
                        </th>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold bg-slate-100">
                            Status
                        </th>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold bg-slate-100">
                            Update
                        </th>
                    </thead>
                    <tbody>
                        {data?.data?.map((v, i) => {
                            return (
                                <tr key={i}>
                                    <td className="p-2 text-sm border-2 border-gray-200 min-w-[180px]">
                                        {v.user.full_name}
                                    </td>
                                    <td className="p-2 text-sm border-2 border-gray-200 min-w-[180px]">
                                        {v.user.email_address}
                                    </td>
                                    <td className="p-2 text-sm border-2 border-gray-200 min-w-[180px]">
                                        {v.profile.organization_name}
                                    </td>
                                    <td className="p-2 text-sm border-2 border-gray-200 min-w-[180px]">
                                        {v.profile.organization_email}
                                    </td>
                                    <td className="p-2 text-sm border-2 border-gray-200 min-w-[180px]">
                                        {v.profile.company_name}
                                    </td>
                                    <td className="p-2 text-sm border-2 border-gray-200 min-w-[180px] text-center">
                                        <span
                                            className="text-blue-500 underline cursor-pointer mx-auto"
                                            onClick={() => setOrgModal({ userData: v.user, profileData: v.profile })}>
                                            View
                                        </span>
                                    </td>
                                    <td className="p-2 text-sm border-2 border-gray-200 text-center">
                                        <p
                                            className={
                                                v.profile.active
                                                    ? 'text-green-400 font-semibold'
                                                    : 'text-red-400 font-semibold'
                                            }>
                                            <FontAwesomeIcon
                                                icon={v.profile.active ? faToggleOn : faToggleOff}
                                                className="text-base mr-2"
                                            />
                                            {v.profile.active ? 'Active' : 'Deactive'}
                                        </p>
                                    </td>
                                    <td className="p-2 text-sm border-2 border-gray-200 text-center">
                                        <span
                                            className="text-white bg-blue-400 px-2 py-1 rounded-sm shadow-sm cursor-pointer"
                                            onClick={() => activeOrg(v.profile.id, !v.profile.active)}>
                                            {v.profile.active ? 'Deactivate' : 'Activate'}
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {orgModal !== null && orgModal !== false && (
                    <Modal title="Organizer detail" setValue={setOrgModal}>
                        <OrganizersProfile
                            userData={orgModal.userData}
                            profileData={{ profile: orgModal.profileData, completeness: null }}
                        />
                    </Modal>
                )}
            </div>
        </div>
    )
}

export default AdminOrganizersTable
