import { AuthTemplate } from '../templates'
import { ForgotPassForm } from '../molecules'
import { ProjectVar } from '@utils/index'
import { Header } from '@components/organisms/index'

const ForgotPasswordPage = () => {
    return (
        <div>
            <Header title="Forgot password" description={ProjectVar.PROJECT_NAME} />
            <AuthTemplate>
                <ForgotPassForm />
            </AuthTemplate>
        </div>
    )
}

export default ForgotPasswordPage
