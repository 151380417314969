import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const RatingStars = ({ rate = 0 }) => {
    return (
        <div>
            {Array.from(Array(5), (e, i) => {
                return (
                    <FontAwesomeIcon
                        key={i}
                        icon={faStar}
                        className={i < rate ? 'text-primary-golden-7' : 'text-slate-300'}
                    />
                )
            })}
        </div>
    )
}

export default RatingStars
