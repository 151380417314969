import React from 'react'
import FormWrapper from './FromWrapper'
import { Input } from '@components/atoms'

export default function ExperienceForm({
    yearsOfExperience,
    setYearsOfExperience = () => {},
    specializeIn,
    setSpecializeIn = () => {},
}) {
    return (
        <div>
            <FormWrapper title={'Speaker profile'} subtitle={'Tell me about your experiece and speciality.'}>
                <Input
                    name="experience"
                    label="Years Of Experience"
                    placeHolder="Years Of Experience"
                    value={yearsOfExperience}
                    setValue={setYearsOfExperience}
                />
                <Input
                    name="specialize"
                    label="Specialize In"
                    placeHolder="Specialize In"
                    value={specializeIn}
                    setValue={setSpecializeIn}
                />
            </FormWrapper>
        </div>
    )
}
