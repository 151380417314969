import { getOrganizerProfileAPI, updateOrganizerProfileAPI } from '@api/organizerApi'
import { Button, Input } from '@components/atoms'
import { Auth } from '@context/authContext'
import { LocalVar } from '@utils/variables'
import { useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import toast from 'react-hot-toast'
import { LoveToKnow } from '../OrganizerCreate'

const OrganizerSettings = () => {
    const { state } = useContext(Auth)
    const [loading, setLoading] = useState(false)
    const [companyName, setCompanyName] = useState('')
    const [orgName, setOrgName] = useState('')
    const [orgEmail, setOrgEmail] = useState('')
    const [perYearOrganizeEvents, setPerYearOrganizeEvents] = useState('')
    const [needSponsor, setNeedSponsor] = useState(false)
    const [budgetNeed, setBudgetNeed] = useState('$300 to $500')
    const [needCECredit, setNeedCECredit] = useState('')
    const [orgData, setOrgData] = useState({})
    const [key1, setKey1] = useState(uuidv4())
    const [key2, setKey2] = useState(uuidv4())
    const [key3, setKey3] = useState(uuidv4())
    const [key4, setKey4] = useState(uuidv4())
    const [key5, setKey5] = useState(uuidv4())
    const [key6, setKey6] = useState(uuidv4())
    const [key7, setKey7] = useState(uuidv4())

    const clearField = () => {
        setCompanyName('')
        setOrgName('')
        setOrgEmail('')
        setPerYearOrganizeEvents('')
        setNeedSponsor('')
        setBudgetNeed('')
        setNeedCECredit('')
        setKey1(uuidv4())
        setKey2(uuidv4())
        setKey3(uuidv4())
        setKey4(uuidv4())
        setKey5(uuidv4())
        setKey6(uuidv4())
        setKey7(uuidv4())
    }

    useEffect(() => {
        ;(async () => {
            const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
            let getOrganizerData = await getOrganizerProfileAPI(token, state?.auth?.entity_id)
            if (getOrganizerData.error === null) {
                setOrgData(getOrganizerData?.data?.data)
                setCompanyName(getOrganizerData?.data?.data?.company_name)
                setOrgName(getOrganizerData?.data?.data?.organization_name)
                setOrgEmail(getOrganizerData?.data?.data?.organization_email)
                setPerYearOrganizeEvents(getOrganizerData?.data?.data?.per_year_organize_events)
                setNeedSponsor(getOrganizerData?.data?.data?.do_you_need_sponser)
                setBudgetNeed(getOrganizerData?.data?.data?.how_much_budget_you_need)
                setNeedCECredit(getOrganizerData?.data?.data?.do_you_provide_ce_credits)
            }
        })()
    }, [state])

    const submit = async (e) => {
        e.preventDefault()

        setLoading(true)

        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        let body = {
            company_name: orgData?.company_name,
            your_role: orgData?.your_role,
            organization_name: orgData?.organization_name,
            organization_email: orgData?.organization_email,
            address: orgData?.address,
            per_year_organize_events: orgData?.per_year_organize_events,
            do_you_provide_ce_credits: orgData?.do_you_provide_ce_credits,
            kinds_of_events_you_hold: [...orgData?.kinds_of_events_you_hold],
            do_you_need_sponser: orgData?.do_you_need_sponser,
            how_much_budget_you_need: orgData?.how_much_budget_you_need,
        }

        if (companyName.length !== 0) {
            body['company_name'] = companyName
        }

        if (orgName.length !== 0) {
            body['organization_name'] = orgName
        }

        if (orgEmail.length !== 0) {
            body['organization_email'] = orgEmail
        }

        if (perYearOrganizeEvents.length !== 0) {
            body['per_year_organize_events'] = Number(perYearOrganizeEvents)
        }

        if (needSponsor.length !== 0) {
            body['do_you_need_sponser'] = needSponsor
        }

        if (needSponsor && budgetNeed.length !== 0) {
            body['how_much_budget_you_need'] = budgetNeed
        }

        if (needCECredit.length !== 0) {
            body['do_you_provide_ce_credits'] = needCECredit
        }

        const updateData = await updateOrganizerProfileAPI(token, body)

        if (updateData.error === null) {
            toast.success('Organizer data updated!')
            setLoading(false)
            window.location.reload()
        } else {
            toast.error('Unexpected error occured!')
            setLoading(false)
        }

        clearField()
    }
    return (
        <div>
            <form onSubmit={submit}>
                <Input
                    name="companyName"
                    label="Company Name"
                    placeHolder="Company Name"
                    value={companyName}
                    setValue={setCompanyName}
                    key={key1}
                />
                <Input
                    name="organizationName"
                    label="Organization Name"
                    placeHolder="Organization Name"
                    value={orgName}
                    setValue={setOrgName}
                    key={key2}
                />
                <Input
                    name="organizationEmail"
                    label="Organization Email"
                    placeHolder="Organization Email"
                    value={orgEmail}
                    setValue={setOrgEmail}
                    key={key3}
                />
                <Input
                    name="perYearOrganizeEvents"
                    label="Per Year Organize Events"
                    placeHolder="Per Year Organize Events"
                    value={perYearOrganizeEvents}
                    setValue={setPerYearOrganizeEvents}
                    key={key4}
                />
                <LoveToKnow
                    needSponsor={needSponsor}
                    setNeedSponsor={setNeedSponsor}
                    provideCeCredits={needCECredit}
                    setProvideCeCredits={setNeedCECredit}
                    budget={budgetNeed}
                    setBudget={setBudgetNeed}
                    isUpdate={true}
                />
                {/* <Input
                    name="needSponsor"
                    label="Need Sponsor"
                    placeHolder="Need Sponsor"
                    value={needSponsor}
                    setValue={setNeedSponsor}
                    key={key5}
                />
                <Input
                    name="budgetNeed"
                    label="Budget Need"
                    placeHolder="Budget Need"
                    value={budgetNeed}
                    setValue={setBudgetNeed}
                    key={key6}
                /> */}

                {/* <Input
                    name="needCECredit"
                    label="Need CE Credit"
                    placeHolder="Need CE Credit"
                    value={needCECredit}
                    setValue={setNeedCECredit}
                    key={key7}
                /> */}
                <Button loading={loading}>Update</Button>
            </form>
        </div>
    )
}

export default OrganizerSettings
