import { Link } from 'react-router-dom'
import { Pagination } from '../../atoms'
import { EventCard } from '../../atoms/events'

const OrgEvents = ({ data, setPage, title = 'Events' }) => {
    return (
        <div className="relative mt-6">
            <h2 className="mb-4 mt-12">{title}</h2>
            <Link
                to="/event-add"
                className="absolute right-0 top-0 text-primary-golden-6 bg-primary-green-1 rounded-md px-4 py-2">
                Add event
            </Link>
            <div className="grid grid-cols-12 gap-4 py-4">
                {data.data.map((v, i) => {
                    return (
                        <div key={i} className="col-span-12 md:col-span-6 xl:col-span-4">
                            <EventCard data={v} />
                        </div>
                    )
                })}
            </div>
            <div>
                <Pagination
                    prevPageCount={data.prev_page_count}
                    currentPageProp={data.current_page}
                    nextPageCount={data.next_page_count}
                    setPage={setPage}
                />
            </div>
        </div>
    )
}

export default OrgEvents
