import { useState, useEffect } from 'react'
import { getOwnEventsAPI } from '@api/index'
import { LocalVar } from '@utils/variables'
import { Loading } from '@components/atoms/index'
import { OrgGetStarted, OrgEvents } from '@components/molecules/index'

const OwnEvents = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({ data: [] })
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(9)

    useEffect(() => {
        const updateLimitBasedOnWidth = () => {
            if (window.innerWidth < 1280) {
                setLimit(6)
            } else {
                setLimit(9)
            }
        }

        updateLimitBasedOnWidth()

        window.addEventListener('resize', updateLimitBasedOnWidth)
        return () => window.removeEventListener('resize', updateLimitBasedOnWidth)
    }, [])

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const getEvents = async (token, page, limit) => {
            const eventsData = await getOwnEventsAPI(token, page, limit)
            if (eventsData.error === null) {
                setData({ ...eventsData.data.data, data: eventsData.data.data.data })
                setLoading(false)
            } else {
                setLoading(false)
            }
        }

        getEvents(token, page, limit)
    }, [page, limit])

    return (
        <div>
            {loading ? (
                <div className="h-full flex justify-center items-center">
                    <Loading />
                </div>
            ) : (
                <div>
                    {data?.data?.length === 0 ? (
                        <OrgGetStarted />
                    ) : (
                        <OrgEvents data={data} setPage={setPage} title="Your events" />
                    )}
                </div>
            )}
        </div>
    )
}

export default OwnEvents
