import { Header } from '@components/organisms'
import { MainTemplate } from '@components/templates/index'

const MainPage = () => {
    return (
        <div>
            <Header description="DentiCE | A Roadmap to Career Growth and Personal Fulfillment." />
            <MainTemplate />
        </div>
    )
}
export default MainPage
