const CeCard = ({ title, ceClass, ceWrapperClass, txt }) => {
    return (
        <div>
            <h4 className="text-gray-700 text-lg mb-4 ml-2">{title}</h4>
            <div className="flex items-center justify-start bg-white rounded-lg p-8">
                <span className={ceWrapperClass}>
                    <h3 className={ceClass}>CE</h3>
                </span>

                <h4 className="text-lg md:text-xl text-gray-800">{txt}</h4>
            </div>
        </div>
    )
}

export default CeCard
