import { useState, useEffect } from 'react'
import { Loading, Modal, Pagination } from '@components/atoms/index'
import { LocalVar } from '@utils/index'
import { getUsersAdminAPI } from '@api/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faFileDownload, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import AdminSeeUserDetail from './AdminSeeUserDetail'
import { updateUserVerifyAPI } from '@api/index'
import { writeFile, utils } from 'xlsx'
import { getFullUsersListAPI } from '@api/adminApi'

const AdminUsersTable = () => {
    const [data, setData] = useState({ data: [] })
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(null)
    const [page, setPage] = useState(0)
    const [verified, setVerified] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)

    useEffect(() => {
        setLoading(true)
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const getUs = async (token, page, verified) => {
            const getDtata = await getUsersAdminAPI(token, page, 10, verified)
            if (getDtata.error === null) {
                setData(getDtata.data.data)
                setLoading(false)
            } else {
                setErr(getDtata.error)
                setLoading(false)
            }
        }

        getUs(token, page, verified)
    }, [page, verified])

    const updateVerify = async (id, val) => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
        const click = await updateUserVerifyAPI(token, id, val)
        if (click.error === null) {
            window.location.reload()
        }
    }

    if (loading) {
        return (
            <div className="h-full flex justify-center items-center bg-white">
                <Loading />
            </div>
        )
    }

    if (err !== null) {
        return (
            <div className="bg-white rounded-md p-8">
                <h3 className="text-primary-green-1">Users</h3>
                <h3 className="text-center text-gray-400 mt-8">
                    <FontAwesomeIcon icon={faBan} className="mr-2" />
                    Unexpected error occurred!
                </h3>
            </div>
        )
    }

    const handleDownload = async () => {
        setIsDownloading(true)
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
        let data

        let usersDateRangeData = await getFullUsersListAPI(token)
        if (usersDateRangeData.error === null) {
            data = usersDateRangeData?.data?.data?.data || []
            setIsDownloading(false)
        } else {
            setIsDownloading(false)
        }
        let preparedData = data?.map((dt) => {
            return {
                'Full name': dt?.full_name || '',
                Email: dt?.email_address || '',
                Phone: dt?.phone_country_prefix && dt?.phone_number ? dt?.phone_country_prefix + dt?.phone_number : '',
                Country: dt?.country || '',
                Province: dt?.province || '',
                City: dt?.city || '',
                Status: dt?.status ? 'Active' : 'Deactivated',
            }
        })
        let wb = utils.book_new()
        let ws = utils.json_to_sheet(preparedData || {})
        utils.book_append_sheet(wb, ws, 'Users')
        writeFile(wb, `users-list.xlsx`)
        setIsDownloading(false)
    }

    return (
        <div className="bg-white rounded-md p-8">
            <div className="flex justify-between">
                <h3 className="text-primary-green-1">
                    Users <span className="text-gray-400 text-lg">(total: {data.total_data_count})</span>
                    <span
                        onClick={() => setVerified(!verified)}
                        className="ml-4 text-sm font-medium rounded-full bg-slate-300 py-2 cursor-pointer">
                        <span
                            className={
                                verified
                                    ? 'px-4 py-2 rounded-full text-primary-golden-6 bg-primary-green-1'
                                    : 'px-4 py-2 rounded-full'
                            }>
                            Verified
                        </span>
                        <span
                            className={
                                verified
                                    ? 'px-4 py-2 rounded-full'
                                    : 'px-4 py-2 rounded-full text-primary-golden-6 bg-primary-green-1'
                            }>
                            Non Verified
                        </span>
                    </span>
                </h3>
                <div className="flex justify-end px-6">
                    <button
                        className={`py-1 px-4 text-sm rounded-md mx-1 bg-primary-green-1 text-white border-2 border-primary-green-1 ${
                            isDownloading ? 'cursor-not-allowed' : ''
                        }`}
                        disabled={isDownloading}
                        onClick={() => handleDownload()}>
                        Download <FontAwesomeIcon icon={faFileDownload} />
                    </button>
                </div>
            </div>
            <div className="mt-6 overflow-y-auto min-h-[40vh] max-h-[60vh]">
                <table>
                    <thead>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold min-w-[180px] bg-slate-100">
                            Full name
                        </th>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold min-w-[180px] bg-slate-100">
                            Email
                        </th>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold min-w-[180px] bg-slate-100">
                            Phone
                        </th>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold min-w-[180px] bg-slate-100">
                            Roles
                        </th>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold bg-slate-100">
                            View profile
                        </th>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold bg-slate-100">
                            Status
                        </th>
                        <th className="border-2 border-gray-200 p-2 text-gray-600 font-semibold bg-slate-100">
                            Verification
                        </th>
                    </thead>
                    <tbody>
                        {data?.data?.map((v, i) => {
                            return (
                                <tr key={i}>
                                    <td className="p-2 text-sm border-2 border-gray-200 min-w-[180px]">
                                        {v.full_name}
                                    </td>
                                    <td className="p-2 text-sm border-2 border-gray-200 min-w-[180px]">
                                        {v.email_address}
                                    </td>
                                    <td className="p-2 text-sm border-2 border-gray-200 min-w-[180px]">
                                        {v.phone_country_prefix}
                                        {v.phone_number}
                                    </td>

                                    <td className="p-2 text-sm border-2 border-gray-200 min-w-[180px]">
                                        {v.role.map((v, i) => (
                                            <span key={i} className="bg-slate-200 p-1 mr-1">
                                                {v}
                                            </span>
                                        ))}
                                    </td>

                                    <td className="p-2 text-sm border-2 border-gray-200 min-w-[180px] text-center">
                                        <span
                                            className="text-blue-500 underline cursor-pointer mx-auto"
                                            // onClick={() => setOpenModal(v.id)}>
                                            onClick={() => setOpenModal(false)}>
                                            View
                                        </span>
                                    </td>
                                    <td className="p-2 text-sm border-2 border-gray-200 text-center">
                                        <p
                                            className={
                                                v.verified
                                                    ? 'text-green-400 font-semibold'
                                                    : 'text-red-400 font-semibold'
                                            }>
                                            <FontAwesomeIcon
                                                icon={v.verified ? faToggleOn : faToggleOff}
                                                className="text-base mr-2"
                                            />
                                            {v.verified ? 'Verified' : 'Not verified'}
                                        </p>
                                    </td>
                                    <td className="p-2 text-sm border-2 border-gray-200 text-center">
                                        <span
                                            className="text-white bg-blue-400 px-2 py-1 rounded-sm shadow-sm cursor-pointer"
                                            onClick={() => updateVerify(v.id, !v.verified)}>
                                            {v.verified ? 'Undo Verification' : 'Verify'}
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <Pagination
                    prevPageCount={data.prev_page_count}
                    currentPageProp={data.current_page}
                    nextPageCount={data.next_page_count}
                    setPage={setPage}
                />
                {openModal !== null && openModal !== false && (
                    <Modal title="User detail" setValue={setOpenModal}>
                        <AdminSeeUserDetail entity_id={openModal} />
                    </Modal>
                )}
            </div>
        </div>
    )
}

export default AdminUsersTable
