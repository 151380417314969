import { useContext } from 'react'
import { Header } from '../organisms'
import { EventsAddTemplate } from '../templates'
import { Auth } from '../../context'
import { ProfileVar } from '../../utils'
import NotFound from './NotFound'

const EventAddPage = () => {
    const { state } = useContext(Auth)
    return (
        <div>
            <Header title="Add Event" description="DentiCE Event page" />
            {state.auth.role === ProfileVar.ADMIN || state.auth.role === ProfileVar.ORGANIZER ? (
                <EventsAddTemplate />
            ) : (
                <NotFound warningTxt="You may not have the correct permissions to view the page" />
            )}
        </div>
    )
}

export default EventAddPage
