import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import KeyLogin from '../../assets/img/key_login.png'
import axios from 'axios'
import toast from 'react-hot-toast'
import { LocalVar } from '@utils/variables'
import { settings } from '@utils/Settings'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import ExperienceForm from '@components/molecules/Forms/SpeakerCreate/ExperienceForm'

const ProfileSpeakerCreateTemplate = () => {
    const [yearsOfExperience, setYearsOfExperience] = useState(0)
    const [specializeIn, setSpecializeIn] = useState('')

    const onSubmit = (e) => {
        e.preventDefault()
        // if (!isLastStep) return next()

        // here start my code
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        let body = {
            experience_year: yearsOfExperience,
            specialization: specializeIn,
        }

        const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
        fetchAPI
            .post('/profile-speakers/', { ...body }, { headers: { Authorization: `Bearer ${token.session_id}` } })
            .then((data) => {
                toast('Profile successfully created!', {
                    icon: '👋',
                })
                window.location.reload()
            })
            .catch((err) => toast.error('Failed to create profile.'))
    }

    return (
        <div className="relative">
            <div className="w-full md:w-7/12 min-h-screen flex justify-center items-center">
                <form
                    onSubmit={onSubmit}
                    className="w-[600px] bg-gray-100 py-12 px-6 md:py-20 md:px-12 rounded-lg m-4 relative">
                    <div>
                        <ExperienceForm
                            yearsOfExperience={yearsOfExperience}
                            setYearsOfExperience={setYearsOfExperience}
                            specializeIn={specializeIn}
                            setSpecializeIn={setSpecializeIn}
                        />
                        <button
                            type="submit"
                            className="text-white font-semibold text-xl bg-primary-green-1 py-2 px-12 block w-full rounded-md mt-8">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
            <div className="fixed w-full md:w-5/12 h-screen right-0 top-0 bg-green-100 md:flex justify-center items-center p-4 hidden">
                <Link to="/" className="absolute right-8 top-8 text-primary-green-1 text-lg font-semibold">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to Main page
                </Link>
                <img src={KeyLogin} alt="goal_image" />
            </div>
        </div>
    )
}

export default ProfileSpeakerCreateTemplate
