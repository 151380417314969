import { MainNav } from '@components/molecules'
import { Footer, Header } from '@components/organisms'
import React from 'react'
import { Link } from 'react-router-dom'

export default function PrivacyPolicyPage() {
    return (
        <div>
            <Header title="Dentice Privacy" />
            <MainNav />
            <div className="md:container mx-auto p-4 md:p-8 bg-light-main-green">
                <div className="pb-2">
                    <div className="flex flex-col justify-center items-center pb-4">
                        <h1>Privacy Policy for DentiCE</h1>
                        <p>
                            <b>Effective Date: </b>September 6, 2024{' '}
                        </p>
                    </div>
                    <p>
                        DentiCE ("we," "us," or "our") is committed to respecting your privacy and ensuring that your
                        personal information is handled in a safe and responsible manner. This Privacy Policy outlines
                        our practices regarding the collection, use, disclosure, and protection of your information when
                        you use our mobile application, DentiCE. Please read this policy carefully. If you do not agree
                        with the terms of this policy, please do not access the application.{' '}
                    </p>
                </div>

                <div className="py-2">
                    <h3>Information We Collect </h3>
                    <p className="pl-2">We may collect information about you in various ways, including:</p>
                    <p className="pl-4">
                        <b>1. Personal Data: </b>While using our app, we may ask you to provide us with certain
                        personally identifiable information, including but not limited to your name, email address,
                        phone number, and professional background.{' '}
                    </p>

                    <p className="pl-4">
                        <b>2. Usage Data: </b>
                        We may collect information about how you access and use the app, including your device's
                        Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our
                        app that you visit, the time and date of your visit, the time spent on those pages, and other
                        diagnostic data.{' '}
                    </p>
                    <p className="pl-4">
                        <b>3. Cookies and Tracking Technologies: </b>
                        We may use cookies and similar tracking technologies to monitor activity on our app and store
                        certain information. You can manage your cookie preferences through your browser settings.{' '}
                    </p>
                </div>

                <div className="py-2">
                    <h3>How We Use Your Information </h3>
                    <p className="pl-2">We use the information we collect in various ways, including to: </p>
                    <p className="pl-4">1. Provide, operate, and maintain our app </p>
                    <p className="pl-4">2. Improve, personalize, and expand our app </p>
                    <p className="pl-4">3. Understand and analyze how you use our app </p>
                    <p className="pl-4">
                        4. Communicate with you, either directly or through one of our partners, including for customer
                        service, to provide you with updates and other information relating to the app
                    </p>
                    <p className="pl-4">
                        5. Process your transactions and send you transaction-related notifications Find and prevent
                        fraud
                    </p>
                </div>

                <div className="py-2">
                    <h3>Disclosure of Your Information </h3>
                    <p className="pl-2">
                        We may share information we have collected about you in certain situations. Your information may
                        be disclosed as follows:{' '}
                    </p>

                    <p className="pl-4">
                        <b>1. By Law or to Protect Rights: </b> If we believe the release of information about you is
                        necessary to respond to legal process, to investigate or remedy potential violations of our
                        policies, or to protect the rights, property, and safety of others, we may disclose your
                        information as required by applicable law.{' '}
                    </p>
                    <p className="pl-4">
                        <b>2. Third-Party Service Providers:</b> We may share your information with third parties that
                        perform services for us or on our behalf, including payment processing, data analysis, email
                        delivery, customer service, and marketing assistance.{' '}
                    </p>
                    <p className="pl-4">
                        <b>3. Business Transfers:</b> We may share or transfer your information in connection with any
                        merger, sale of company assets, financing, or acquisition of our business to another company.
                    </p>
                </div>
                <div className="py-2">
                    <h3>Your Privacy Rights (North American Users) </h3>
                    <p className="pl-2">
                        If you are a resident of California or other jurisdictions with similar laws, you may have the
                        following rights regarding your personal information:{' '}
                    </p>
                    <p className="pl-4">
                        <b>1. Right to Know:</b> You have the right to request information about the categories and
                        specific pieces of personal information we have collected about you.{' '}
                    </p>
                    <p className="pl-4">
                        <b>2. Right to Delete:</b> You have the right to request the deletion of your personal
                        information that we have collected from you, subject to certain exceptions (e.g., if we need to
                        retain it to comply with legal obligations).{' '}
                    </p>
                    <p className="pl-4">
                        <b>3. Right to Opt-Out:</b> You have the right to opt-out of the sale of your personal
                        information to third parties (if applicable).{' '}
                    </p>
                    <p className="pl-4">
                        <b>4. Right to Non-Discrimination:</b> You have the right not to receive discriminatory
                        treatment if you exercise your privacy rights.
                    </p>
                    <p className="pl-2">
                        To exercise any of these rights, please contact us using the information provided in the Contact
                        Us section below.{' '}
                    </p>
                </div>

                <div className="py-2">
                    <h3>Security of Your Information </h3>
                    <p className="pl-2">
                        We implement a variety of security measures to help protect your personal information. While we
                        strive to use commercially acceptable methods to protect your personal information, no method of
                        transmission over the Internet or method of electronic storage is 100% secure, and we cannot
                        guarantee its absolute security.{' '}
                    </p>
                </div>

                <div className="py-2">
                    <h3>Changes to This Privacy Policy </h3>
                    <p className="pl-2">
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting
                        the new Privacy Policy on this page and updating the effective date at the top of this Privacy
                        Policy. You are advised to review this Privacy Policy periodically for any changes.{' '}
                    </p>
                </div>

                <div className="py-2">
                    <h3>Contact Us </h3>
                    <p className="pl-2">If you have any questions about this Privacy Policy, please contact us: </p>
                    <p
                        className="pl-4"
                        // onClick={(e) => {
                        //     window.location.href = 'mailto:admin@worldaigroup.com'
                        // }}
                    >
                        <b>Email:</b> admin@worldaigroup.com
                    </p>
                    <p className="pl-4">
                        <b>Website:</b>{' '}
                        <Link to={'https://dentice.ai'} target="_blank">
                            DentiCE.ai
                        </Link>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}
