const CalendarLogoLink = ({ children, setValue = () => {} }) => {
    return (
        <div
            className="bg-white p-3 md:px-6 md:py-2 text-md font-medium text-primary-green-1 rounded-lg cursor-pointer"
            onClick={() => setValue(children)}>
            <img
                src="/assets/logo/calendar.png"
                alt="calendar-logo"
                width="30px"
                className="inline mr-4 bg-primary-light"
            />
            <span className="text-primary-green-1">{children}</span>
        </div>
    )
}

export default CalendarLogoLink
