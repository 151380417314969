import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { ProjectVar } from '../../utils'
import { FooterNav } from '@components/molecules/index'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="block w-full bg-dark-green">
            <div className="max-w-[1000px] mx-auto py-[30px] grid grid-cols-12 p-4">
                <div className="col-span-12 md:col-span-6">
                    <Link>
                        <img src={ProjectVar.PROJECT_LOGO_WHITE} alt={ProjectVar.PROJECT_NAME} />
                    </Link>
                    <p className="mb-4 leading-8 text-white text-md text-left tracking-wide">
                        DentiCE's AI matches you with the best events, saving you time and ensuring you never miss out
                    </p>
                    <Link to="https://www.instagram.com/dentice.ai/" className="golden-txt text-4xl flex items-center">
                        Follow us
                        <FontAwesomeIcon icon={faInstagram} className="text-primary-golden-5 text-4xl ml-4" />
                    </Link>
                </div>
                <div className="col-span-12 md:col-span-6 flex justify-start md:justify-center items-center mt-8 md:mt-0">
                    <FooterNav />
                </div>
            </div>
            <div className="bg-primary-green-1">
                <div className="md:container mx-auto">
                    <p className="text-white text-sm p-2 text-center">
                        @2024 {ProjectVar.PROJECT_NAME}. All Rights Reserved.
                    </p>
                </div>
            </div>
        </div>
    )
}
export default Footer
