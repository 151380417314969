import { ProjectVar } from '@utils/variables'
import { Link } from 'react-router-dom'

const WhyChooseUs = () => {
    return (
        <div className="bg-dark-green py-12">
            <div className="md:container mx-auto p-4">
                <div className="flex justify-center">
                    <h1 className="text-cente golden-txt mb-8">Why Choose {ProjectVar.PROJECT_NAME}</h1>
                </div>
                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 md:col-span-6 rounded-xl border-2 border-light-main-green p-6 bg-dark-green shadow sm">
                        <h2 className="golden-txt py-2">Tailored Learning Journeys</h2>
                        <p className="text-white leading-8 tracking-wide">
                            No matter your role in the dental community, whether you're a hygienist, assistant or
                            pursuing another path in dentistry, DentiCE is your guide to crafting a customized learning
                            journey. Whatever your goal within the realm of dentistry, DentiCE connects you with the
                            perfect opportunities to achieve it.
                        </p>
                    </div>
                    <div className="col-span-12 md:col-span-6 rounded-xl border-2 border-light-main-green p-6 bg-dark-green shadow sm">
                        <h2 className="golden-txt py-2">Optimize Your CE Credits</h2>
                        <p className="text-white leading-8 tracking-wide">
                            We understand the importance of Continuing Education (CE) credits for your career
                            advancement. DentiCE helps you optimize the number of CE credits you earn by directing you
                            towards events and courses that align with your career objectives.
                        </p>
                    </div>
                    <div className="col-span-12 md:col-span-6 rounded-xl border-2 border-light-main-green p-6 bg-dark-green shadow sm">
                        <h2 className="golden-txt py-2">Comprehensive Resources</h2>
                        <p className="text-white leading-8 tracking-wide">
                            Explore a diverse range of educational opportunities, from seminars and workshops to online
                            courses and conferences, all curated to meet the unique needs of dental professionals like
                            you.
                        </p>
                    </div>
                    <div className="col-span-12 md:col-span-6 rounded-xl border-2 border-light-main-green p-6 bg-dark-green shadow sm">
                        <h2 className="golden-txt py-2">Informed Decision-Making</h2>
                        <p className="text-white leading-8 tracking-wide">
                            Access comprehensive feedback and reviews on event organizers, sponsored courses, and events
                            with DentiCE. Make informed decisions for maximum professional growth and development based
                            on insights from fellow dental professionals.
                        </p>
                    </div>
                </div>
                <h1 className="golden-txt mt-24 text-center">Get Started Today</h1>
                <h4 className="my-4 text-center">
                    <b className="golden-txt">
                        <Link to="/signup">Sign up</Link>
                    </b>{' '}
                    <span className='text-white'>for free and start your journey towards professional excellence with</span>{' '}
                    <b className="golden-txt">
                        <Link to="/">{ProjectVar.PROJECT_NAME}</Link>
                    </b>
                </h4>
            </div>
        </div>
    )
}

export default WhyChooseUs
