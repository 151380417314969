export class ProjectVar {
    static PROJECT_NAME = 'DentiCE'
    static PROJECT_LOGO_GREEN = '/assets/logo/dent-logo-green.png'
    static PROJECT_LOGO_WHITE = '/assets/logo/dent-logo-white.png'
}

export class ProfileVar {
    static ADMIN = 'admin'
    static PRACTITIONER = 'practitioner'
    static ORGANIZER = 'organizer'
    static SPONSOR = 'sponsor'
    static SPEAKER = 'speaker'
    static EDUCATOR = 'educator'
}

export class LocalVar {
    static AUTH_DATA = 'DentAuthData'
}

export class EventCategory {
    static HANDS_ON_TRAINING = {
        value: 'Hands-on Training',
        text: 'Hands-on Training',
        details: 'Hands-on, Live Demo, Course',
    }
    static CONFERENCE = { value: 'Conference', text: 'Conference', details: 'Hands-on, Live Demo, Course' }
    static CONGRESS = { value: 'Congress', text: 'Congress', details: 'Hands-on, Live Demo, Course' }
    static WEBINARS = { value: 'Webinar', text: 'Webinar', details: 'Hands-on, Live Demo, Course' }
    static SEMINARS = { value: 'Seminar', text: 'Seminar', details: 'Hands-on, Live Demo, Course' }
    static WORKSHOPS = { value: 'Workshoop', text: 'Workshoop', details: 'Hands-on, Live Demo, Course' }
    static SYMPOSIA = { value: 'Symposia', text: 'Symposia', details: 'Hands-on, Live Demo, Course' }
    static CEC = {
        value: 'Continuing Education Courses',
        text: 'Cont. Edu Courses',
        details: 'Hands-on, Live Demo, Course',
    }
    static EXPOS = { value: 'Expos', text: 'Expos', details: 'Hands-on, Live Demo, Course' }
    static FORUMS = { value: 'Forum', text: 'Forum', details: 'Hands-on, Live Demo, Course' }
    static MENTORSHIP = { value: 'Mentorship', text: 'Mentorship', details: '1:1 mentorship or Group Mentorship' }
    static HANDS_ON = { value: 'Hands On', text: 'Hands On', details: 'Hands-on, Live Demo, Course' }
    static LIVE_DEMO = { value: 'Live Demo', text: 'Live Demo', details: 'Hands-on, Live Demo, Course' }
    static LIVE_PRACTICE = { value: 'Live Practice', text: 'Live Practice', details: 'Hands-on, Live Demo, Course' }
    static GALA = { value: 'Gala', text: 'Gala', details: 'Hands-on, Live Demo, Course' }
    static RETREAT = { value: 'Retreat', text: 'Retreat', details: 'Hands-on, Live Demo, Course' }
    static LECTURE = { value: 'Lecture', text: 'Lecture', details: 'Hands-on, Live Demo, Course' }
    static COURSE = { value: 'Courses', text: 'Courses', details: 'Hands-on, Live Demo, Course' }
    // static DENTAL_GETWAY = {
    //     value: 'dental-getway',
    //     text: 'Dental Getaway & Entertainment',
    //     details: 'Hands-on, Live Demo, Course',
    // }
    // static MENTORSHIP = { value: 'mentorship', text: 'Mentorship', details: '1:1 mentorship or Group Mentorship' }
    // static CONGRESS_EXHIBITIONS = {
    //     value: 'congress-exhibitions',
    //     text: 'Congress & Exhibitions',
    //     details: 'Seminars, Conferences, Exhibitions workshops',
    // }
    // static VOLUNTEER = { value: 'volunteer', text: 'Volunteer', details: 'Volunteering Hands-on, Live Demo, Course' }
}

export const EventCategoryArray = Object.entries(EventCategory).map(([key, value]) => ({
    key,
    value,
}))

export const virtualOptions = [
    { text: 'Virtual', value: 'virtual' },
    { text: 'In Person', value: 'person' },
    { text: 'Person and Virtual', value: 'person and virtual' },
]

export const timeZones = [
    { offset: '+14:00', cities: 'Kiritimati' },
    { offset: '+13:00', cities: 'Auckland, Suva' },
    { offset: '+12:00', cities: 'Fiji, Kamchatka, Wellington' },
    { offset: '+11:00', cities: 'Magadan, New Caledonia, Solomon Is.' },
    { offset: '+10:00', cities: 'Brisbane, Guam, Vladivostok' },
    { offset: '+09:30', cities: 'Adelaide, Darwin' },
    { offset: '+09:00', cities: 'Osaka, Seoul, Tokyo' },
    { offset: '+08:00', cities: 'Beijing, Hong Kong, Singapore' },
    { offset: '+07:00', cities: 'Bangkok, Hanoi, Jakarta' },
    { offset: '+06:30', cities: 'Yangon, Rangoon' },
    { offset: '+06:00', cities: 'Almaty, Dhaka' },
    { offset: '+05:45', cities: 'Kathmandu' },
    { offset: '+05:30', cities: 'Chennai, Kolkata, Mumbai, New Delhi' },
    { offset: '+05:00', cities: 'Islamabad, Karachi, Tashkent' },
    { offset: '+04:30', cities: 'Kabul' },
    { offset: '+04:00', cities: 'Abu Dhabi, Muscat, Baku' },
    { offset: '+03:30', cities: 'Tehran' },
    { offset: '+03:00', cities: 'Baghdad, Moscow, Nairobi' },
    { offset: '+02:00', cities: 'Athens, Cairo, Helsinki' },
    { offset: '+01:00', cities: 'Berlin, Paris, Rome' },
    { offset: '+00:00', cities: 'Dublin, London, Lisbon' },
    { offset: '-01:00', cities: 'Azores, Cape Verde Is.' },
    { offset: '-02:00', cities: 'Mid-Atlantic' },
    { offset: '-03:00', cities: 'Buenos Aires, Sao Paulo' },
    { offset: '-04:00', cities: 'Eastern Time (Canada), Toronto, Caracas, La Paz' },
    { offset: '-05:00', cities: 'Eastern Time (US & Canada), New York, Bogota' },
    { offset: '-06:00', cities: 'Central Time (US & Canada), Chicago, Mexico City' },
    { offset: '-07:00', cities: 'Mountain Time (US & Canada), Denver, Edmonton' },
    { offset: '-08:00', cities: 'Pacific Time (US & Canada), Los Angeles, Vancouver' },
    { offset: '-09:00', cities: 'Alaska, Anchorage' },
    { offset: '-10:00', cities: 'Hawaii, Honolulu' },
    { offset: '-11:00', cities: 'Midway Island, Samoa' },
    { offset: '-12:00', cities: 'International Date Line West' },
]

export const dentalCategoryOptions = [
    { text: 'Prosthodontics', value: 'Prosthodontics' },
    { text: 'Oral Pathology', value: 'Oral Pathology' },
    { text: 'Orthodontics', value: 'Orthodontics' },
    { text: 'Oral Radiology', value: 'Oral Radiology' },
    { text: 'Periodontics', value: 'Periodontics' },
    { text: 'Pediatric Dentistry', value: 'Pediatric Dentistry' },
    { text: 'All Dentistry', value: 'All Dentistry' },
    { text: 'Public Health Dentistry', value: 'Public Health Dentistry' },
    { text: 'Congress', value: 'Congress' },
    { text: 'Implant Dentistry', value: 'Implant Dentistry' },
    { text: 'Endodontics', value: 'Endodontics' },
    { text: 'General Dentistry', value: 'General Dentistry' },
    { text: 'Multiple Fields', value: 'Multiple Fields' },
    { text: 'Restorative Dentistry', value: 'Restorative Dentistry' },
    { text: 'Oral Medicine', value: 'Oral Medicine' },
    { text: 'Oral Surgery', value: 'Oral Surgery' },
    { text: 'Dental Implants', value: 'Dental Implants' },
    { text: 'Laser Dentistry', value: 'Laser Dentistry' },
    { text: 'Seminar', value: 'Seminar' },
    { text: 'Orofacial Pain', value: 'Orofacial Pain' },
    { text: 'Dental Law', value: 'Dental Law' },
    { text: 'Cosmetic Dentistry', value: 'Cosmetic Dentistry' },
]

export const findByOptions = [
    { text: 'All', value: 'all' },
    { text: 'Event Name', value: 'event_name' },    
    { text: 'Country', value: 'country' },
    { text: 'City', value: 'city' },
    { text: 'Organizer name', value: 'organizer_name' },
    { text: 'Speakers', value: 'speakers_with_cv' },
]

export const sortByOptions = [
    { text: 'Date', value: 'event_start_date' },
    { text: 'Price', value: 'early_birds_price' },    
    // { text: 'Speakers Rating', value: 'speakers_rating' },
    // { text: 'Organizers Rating', value: 'organizers_rating' },
]

export const eventsByOptions = [
    { text: 'All', value: 'all' },
    { text: 'Upcoming', value: 'upcoming' },
    { text: 'Previous', value: 'previous' },
    { text: 'This Week', value: 'thisWeek' },
    { text: 'This Weekend', value: 'thisWeekend' },
    { text: 'This Month', value: 'thisMonth' },
    { text: 'Custom', value: 'custom' },
]

export const ratingFilterOptions = [
    { text: 'All', value: 'all' },
    { text: 'One', value: 'one' },
    { text: 'Two', value: 'two' },
    { text: 'Three', value: 'three' },
    { text: 'Four', value: 'four' },
    { text: 'Five', value: 'five' },
]

export const priceFilterOptions = [
    { text: 'All', value: 'all' },
    { text: '0-50', value: '0-50' },
    { text: '51-100', value: '51-100' },
    { text: '101-250', value: '251-500' },
    { text: '501-1000', value: '501-1000' },
    { text: '1001-5000', value: '1001-5000' },
    { text: '5001-10000', value: '5001-10000' },
    { text: '>10000', value: '10001-1000000' },
]
