import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Input, PhoneNumber, Button } from '@components/atoms/index'
import { createAdmin } from '@api/index'
import toast from 'react-hot-toast'

const SignupAdminForm = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [phonePrefix, setPhonePrefix] = useState('')
    const [password, setPassword] = useState('')
    const [cnfPassword, setCnfPassword] = useState('')
    const [done, setDone] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (done === true) {
            navigate('/login')
        }
    }, [done, navigate])

    const submit = async (e) => {
        e.preventDefault()

        if (loading === true) return
        setLoading(true)

        if (password !== cnfPassword) {
            toast.error('Password not confirmed!')
            setLoading(false)
            return
        }
        if (password.length < 8) {
            toast.error('Your password too short.')
            setLoading(false)
            return
        }

        let body = {
            email_address: email,
            secret: password,
            full_name: name,
            phone_number: phone,
            phone_country_prefix: phonePrefix,
        }

        const signupUser = await createAdmin(body)
        if (signupUser.error === null) {
            toast.success('Your account created successfully!')
            setLoading(false)
            setDone(true)
        } else {
            const pop = signupUser.error.response.data.errors[0].message || 'Failed to create your account'
            toast.error(pop)
            setLoading(false)
        }
    }

    return (
        <div className="h-full w-full flex justify-center overflow-auto">
            <div className="w-[400px] m-auto py-8">
                <h2 className="font-w6 text-center mb-4">
                    Create your account as <span className="text-primary-green-1">Admin</span>
                </h2>
                <form onSubmit={submit}>
                    <Input
                        name="name"
                        label="Name"
                        labelClass="text-md font-semibold"
                        inputClass="block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100"
                        placeHolder="Your name"
                        required={true}
                        setValue={setName}
                    />
                    <Input
                        name="email"
                        type="email"
                        label="Email"
                        labelClass="text-md font-semibold"
                        inputClass="block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100"
                        placeHolder="Your Email"
                        required={true}
                        setValue={setEmail}
                    />
                    <PhoneNumber
                        name="phone"
                        label="Phone"
                        labelClass="text-md font-semibold"
                        inputClass="block w-full p-2 pl-16 my-4 border-2 border-gray-300 rounded-md focus:outline-0 rounded-md bg-gray-100"
                        placeHolder="Your phone number"
                        required={true}
                        setValue={setPhone}
                        setPrefixValue={setPhonePrefix}
                    />
                    <Input
                        name="password"
                        label="Password"
                        labelClass="text-md font-semibold"
                        type="password"
                        wrapperClass="relative"
                        eyeIconClass="cursor-pointer absolute right-4 top-12"
                        inputClass="block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100"
                        placeHolder="Your Password"
                        required={true}
                        setValue={setPassword}
                    />
                    <p className="text-gray-500 text-sm mb-4">Must be 8 character at least.</p>
                    <Input
                        name="cnf-password"
                        label="Confirm Password"
                        labelClass="text-md font-semibold"
                        type="password"
                        wrapperClass="relative"
                        eyeIconClass="cursor-pointer absolute right-4 top-12"
                        inputClass="block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100"
                        placeHolder="Retype your password"
                        required={true}
                        setValue={setCnfPassword}
                    />
                    <Button
                        btnClass="bg-primary-green-1 text-white p-4 w-full block mt-6 cursor-pointer rounded-md active:bg-primary-green-10 active:text-primary-green-1 border-2 border-primary-green-1 hover:bg-primary-green-2"
                        loading={loading}>
                        Signup
                    </Button>
                </form>

                <p className="font-semibold text-gray-400 text-center mt-8">
                    Already have an account?{' '}
                    <Link to="/login" className="text-primary-green-1">
                        Sign In
                    </Link>
                </p>
            </div>
        </div>
    )
}
export default SignupAdminForm
