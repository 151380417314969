import { Header } from '@components/organisms/index'
import { ProjectVar } from '@utils/index'
import { FavoriteEventsTemplate } from '@components/templates/index'

const FavoriteEventsPage = () => {
    return (
        <div>
            <Header title="Favorite events" description={ProjectVar.PROJECT_NAME + ' favorite events page'} />
            <FavoriteEventsTemplate />
        </div>
    )
}

export default FavoriteEventsPage
