import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { ClickAwayListener } from '@mui/base/ClickAwayListener'

const HeroSearchBar = ({
    name = 'hero-search-bar',
    type = 'text',
    setValue = () => {},
    placeHolder = 'Search here',
    data = [],
}) => {
    const [localValue, setLocalValue] = useState('')
    const [isEnter, setIsEnter] = useState(false)
    const [open, setOpen] = useState(true)

    const filteredData = [...new Set(data)]

    const handleChange = (e) => {
        setIsEnter(false)
        setLocalValue(e.target.value)
        setValue(e.target.value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setIsEnter(true)
        }
    }

    const handleClickAway = () => {
        setOpen(false)
    }

    return (
        <div className="relative">
            {/* logo label  */}
            <label htmlFor={name} className="absolute top-[17px] left-4 text-gray-500">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </label>
            <div className="flex gap-4">
                {/* input section  */}
                <input
                    type={type}
                    id={name}
                    value={localValue}
                    onChange={handleChange}
                    className="block w-full p-4 pl-10 rounded-lg border-2 border-gray-200 outline-0"
                    placeholder={placeHolder}
                    onKeyDown={handleKeyDown}
                    onClick={() => setOpen(true)}
                />
            </div>

            {/* Data dropdown  */}
            {filteredData.length !== 0 &&
                filteredData[0] !== localValue &&
                localValue.length !== 0 &&
                isEnter === false &&
                open && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <div className="min-w-[90%] bg-white rounded-md absolute top-[62px] shadow-sm border-2 border-slate-200 z-50">
                            <ul className="list-none py-2">
                                {filteredData.map((v, i) => {
                                    return (
                                        open && (
                                            <li
                                                className="px-4 py-1 hover:bg-slate-100 cursor-pointer"
                                                key={i}
                                                onClick={() => {
                                                    setLocalValue(v)
                                                    setValue(v)
                                                }}>
                                                {v}
                                            </li>
                                        )
                                    )
                                })}
                            </ul>
                        </div>
                    </ClickAwayListener>
                )}
        </div>
    )
}

export default HeroSearchBar
