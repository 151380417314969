import { getEventFilterAPI } from '@api/eventsApi'
import { Pagination } from '@components/atoms'
import { EventCard } from '@components/atoms/events'
import { SideBar } from '@components/molecules'
import { faBan, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

export default function AllEventsTemplate() {
    const { eventsType } = useParams()
    const { state } = useLocation()
    const [data, setData] = useState({ data: state?.data?.data ? state?.data?.data : [] })
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const limit = 12
    const by = state?.by || 'event_name'
    const keyword = state?.keyword || ''
    const upcoming = state?.upcoming || false
    const featured = state?.featured || false
    const category = state?.category || 'all'

    useEffect(() => {
        const eventFilter = async (by, keyword, limit, page, upcoming, featured, category) => {
            let eventData = await getEventFilterAPI(by, keyword, limit, page, upcoming, featured, category)
            if (eventData.error === null) {
                setData({ ...eventData.data.data, data: [...eventData.data.data.data] })
                setLoading(false)
            } else {
                setLoading(false)
            }
        }

        eventFilter(by, keyword, limit, page, upcoming, featured, category)
    }, [page, by, keyword, upcoming, featured, category])

    return (
        <div className="h-screen">
            <SideBar>
                <div className="pt-6 px-4">
                    <h2 className="text-primary-green-1">All {eventsType !== "all" ? eventsType : ""} events</h2>
                </div>
                <div className="pt-6 px-4">
                    {loading ? (
                        <div className="flex justify-center items-center min-h-[200px]">
                            <FontAwesomeIcon
                                icon={faSpinner}
                                className="motion-safe:animate-spin text-4xl text-primary-green-2"
                            />
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                            {data?.data?.length !== 0
                                ? data?.data?.map((v, i) => {
                                      return (
                                          <div key={i} className="col-span-12 md:col-span-6 xl:col-span-4">
                                              <EventCard data={v} />
                                          </div>
                                      )
                                  })
                                : null}
                        </div>
                    )}
                    {data?.data?.length === 0 && (
                        <div className="flex justify-center items-center bg-white rounded-md border-2 border-slate-200 py-8">
                            <h3 className="text-gray-400 text-center font-medium">
                                <FontAwesomeIcon icon={faBan} /> No events found
                            </h3>
                        </div>
                    )}
                    {state?.data?.data && state?.data?.total_data_count > 3 ? (
                        <Pagination
                            prevPageCount={data.prev_page_count}
                            currentPageProp={data.current_page}
                            nextPageCount={data.next_page_count}
                            setPage={setPage}
                        />
                    ) : null}
                </div>
            </SideBar>
        </div>
    )
}
