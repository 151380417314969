import { useContext, useEffect, useState } from 'react'
import { Auth } from '@context/index'
import { Header } from '@components/organisms/index'
import { Loading } from '@components/atoms/index'
import { ProfileVar, ProjectVar, LocalVar } from '@utils/index'
import {
    AdminProfileTemplate,
    OrganizerProfileTemplate,
    PractitionerProfileTemplate,
    SponsorProfileTemplate,
    SpeakerProfileTemplate
} from '@components/templates/index'
import { getProfileAPI, getUserAPI } from '@api/index'
import NotFound from './NotFound'

const ProfilePage = () => {
    const { state } = useContext(Auth)
    const user = state.auth

    const [userData, setUserData] = useState(null)
    const [profileData, setProfileData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [err, setErr] = useState(null)

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        const getUser = async (token, user_id) => {
            let getUserData = await getUserAPI(token, user_id)
            if (getUserData.error === null) {
                setUserData(getUserData.data.data)
            } else {
                window.location.reload()
                setErr(getUserData.error)
            }
        }

        const getProfile = async (token, user_id) => {
            let data = await getProfileAPI(token, user_id)
            if (data.error === null) {
                setProfileData(data.data.data[user.role])
                setLoading(false)
            } else {
                setErr(data.error)
                setLoading(false)
            }
        }

        getUser(token, user.entity_id)
        if (token !== null && ProfileVar.ADMIN !== user.role) {
            if (err === null) {
                getProfile(token, user.entity_id)
            } else {
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }, [user, err])

    if (loading) return <Loading />

    if (err !== null) return <NotFound warningTxt="Unexpected error occurred!" />

    const views = {
        [ProfileVar.ADMIN]: AdminProfileTemplate,
        [ProfileVar.PRACTITIONER]: PractitionerProfileTemplate,
        [ProfileVar.ORGANIZER]: OrganizerProfileTemplate,
        [ProfileVar.SPONSOR]: SponsorProfileTemplate,
        [ProfileVar.SPEAKER]: SpeakerProfileTemplate,
    }

    const ProfileViewer = views[user.role]

    return (
        <div>
            <Header title="Home" description={ProjectVar.PROJECT_NAME + ' profile page'} />

            {user.role === ProfileVar.ADMIN ? (
                <ProfileViewer userData={userData} />
            ) : (
                <ProfileViewer userData={userData} profileData={profileData} />
            )}
        </div>
    )
}

export default ProfilePage
