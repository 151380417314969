import { SideBar } from '@components/molecules/index'
import { EventCards } from '@components/molecules/index'

const SponsorHomeTemplate = () => {
    return (
        <div className="h-screen">
            <SideBar>
                <div className="px-8">
                    <h2 className="px-2 text-primary-green-1">Sponsor Home page</h2>
                    <EventCards
                        title={'Upcoming events'}
                        by="event_name"
                        keyword=""
                        upcoming={true}
                        featured={null}
                        limitPerPage={6}
                        container={false}
                    />
                </div>
            </SideBar>
        </div>
    )
}

export default SponsorHomeTemplate
