import axios from 'axios'
import { settings } from './Settings'
import toast from 'react-hot-toast'

export const logout = (token) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    fetchAPI
        .delete('/sessions/', { headers: { Authorization: `Bearer ${token.session_id}` } })
        .then((data) => {
            toast('Come back soon', {
                icon: '👋',
            })
        })
        .catch((err) => {
            toast.error('Failled remove your session.')
        })
}
