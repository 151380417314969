import { useState, useContext } from 'react'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    SideBar,
    ChangePassword,
    UpdateUserInfo,
    PractitionerSettings,
    OrganizerSettings,
    CreateRole,
} from '@components/molecules/index'
import { Auth } from '@context/authContext'
import { ProfileVar } from '@utils/index'

const SettingSection = ({ children, title, name, value, setValue = () => {} }) => {
    return (
        <div className="bg-white rounded-md px-8 py-4 mt-4">
            <h4
                className="text-primary-green-1 font-semibold inline-block cursor-pointer"
                onClick={() => setValue(name)}>
                {title} <FontAwesomeIcon icon={faSort} />
            </h4>
            {value === name && <div>{children}</div>}
        </div>
    )
}

const SettingsTemplate = () => {
    const [isOpen, setIsOpen] = useState('user')

    const { state } = useContext(Auth)
    const user = state.auth

    const views = {
        [ProfileVar.PRACTITIONER]: PractitionerSettings,
        [ProfileVar.ORGANIZER]: OrganizerSettings,
    }

    const ProfileSettings = views[user.role]

    return (
        <div className="h-screen">
            <SideBar>
                <div className="px-8 pt-12">
                    <h2>Settings</h2>
                    <SettingSection title="Update user information" name="user" value={isOpen} setValue={setIsOpen}>
                        <div className="py-8 grid grid-cols-3">
                            <UpdateUserInfo />
                        </div>
                    </SettingSection>
                    <SettingSection title="Change password" name="password" value={isOpen} setValue={setIsOpen}>
                        <div className="py-8 grid grid-cols-3">
                            <ChangePassword />
                        </div>
                    </SettingSection>
                    <SettingSection title="Create Role" name="role" value={isOpen} setValue={setIsOpen}>
                        <div className="py-8 grid grid-cols-3">
                            <CreateRole />
                        </div>
                    </SettingSection>
                    {/* {user.role !== ProfileVar.ADMIN && (
                        <SettingSection
                            title="Update your profile information"
                            name="profile"
                            value={isOpen}
                            setValue={setIsOpen}>
                            <div className="py-8 grid grid-cols-3">
                                <ProfileSettings />
                            </div>
                        </SettingSection>
                    )} */}
                </div>
            </SideBar>
        </div>
    )
}

export default SettingsTemplate
