import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Loading = () => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
            <FontAwesomeIcon
                icon={faSpinner}
                className="motion-safe:animate-spin text-4xl text-primary-green-2 text-opacity-50"
            />
        </div>
    )
}

export default Loading
