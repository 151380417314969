import axios from 'axios'
import { resolve } from './resolve'
import { settings } from '@utils/index'

export const createUser = async (body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(fetchAPI.post('/accounts/', { ...body }).then((res) => res.data))
}

export const getUserAPI = async (token, user_id) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/accounts/${user_id}`, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const getUserRolesAPI = async (token) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/accounts/get-role`, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const updateUserAPI = async (token, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .patch(`/accounts/`, body, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const updateUserPasswordAPI = async (token, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .post(`/accounts/change-password`, body, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const forgotPasswordAPI = async (body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(fetchAPI.post(`/accounts/forgot-password`, body).then((res) => res.data))
}

export const resetPasswordAPI = async (body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(fetchAPI.post(`/accounts/reset-password`, body).then((res) => res.data))
}

export const verifyEmailAPI = async (body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(fetchAPI.post(`/accounts/signup-verify`, body).then((res) => res.data))
}

export const resendVerifyEmailAPI = async (body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(fetchAPI.post(`/accounts/resend-verification`, body).then((res) => res.data))
}

export const setRoleAPI = async (token, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .patch(`/accounts/set-role`, body, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const selectRoleAPI = async (token, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .post(`/accounts/select-role`, body, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const uploadProPicAPI = async (token, body) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .patch(`/accounts/profile-pic/`, body, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}
