import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import Input from './Input'

const DropDownWithField = ({ data, value = '', setValue = () => {}, label, labelClass }) => {
    const [localValue, setLocalValue] = useState(value ? value : 'Select')
    const [isActive, setIsActive] = useState(false)

    const handleChange = (text, value) => {
        setLocalValue(text)
        setValue(value)
        setIsActive(false)
    }
    return (
        <div>
            <label className={labelClass}>{label}</label>
            <div className="relative mt-2">
                <p
                    className="py-2 px-4 border-2 bg-white border-gray-200 rounded-md mb-1 cursor-pointer text-gray-600 font-medium relative"
                    onClick={() => setIsActive(!isActive)}>
                    {localValue}
                    <span className="absolute right-4">
                        {isActive ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
                    </span>
                </p>
                {isActive ? (
                    <div className="pt-2 px-4 border-2 border-gray-200 rounded-md bg-white absolute top-12 block w-full shadow-sm z-10">
                        {data.map((v, i) => {
                            return (
                                <span
                                    key={i}
                                    className="block cursor-pointer hover:bg-gray-100 py-2 px-4 text-gray-600 text-sm font-semibold"
                                    onClick={() => {
                                        handleChange(v.text, v.value)
                                    }}>
                                    {v.text}
                                </span>
                            )
                        })}
                        <span className="px-4">
                            <Input
                                setValue={setValue}
                                placeHolder="Others"
                                inputClass="block w-full border-2 border-gray-300 p-2 focus:outline-0 rounded-md bg-gray-100"
                            />
                        </span>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default DropDownWithField
