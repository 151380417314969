import { useState, useEffect } from 'react'

const TextArea = ({
    name,
    wrapperClass = 'relative',
    label,
    labelClass = 'text-md font-semibold',
    inputClass = 'block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100',
    value = '',
    setValue = () => {},
}) => {
    const [row, setRow] = useState(3)
    const [localState, setLocalState] = useState('')

    useEffect(() => {
        setLocalState(value)
    }, [value])

    useEffect(() => {
        let rowNum = localState?.split('\n').length
        if (rowNum >= 3) {
            setRow(rowNum)
        }
        setValue(localState)
    }, [localState, setValue])

    return (
        <div className={wrapperClass}>
            <label htmlFor={name} className={labelClass}>
                {label}
            </label>
            <textarea
                className={inputClass}
                name={name}
                id={name}
                rows={row}
                value={localState}
                onChange={(e) => setLocalState(e.target.value)}></textarea>
        </div>
    )
}

export default TextArea
