import axios from 'axios'
import { resolve } from './resolve'
import { settings } from '@utils/index'

export const getEventRatings = async (token, event_id, user_id) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .get(`/ratings/rate-event/?event_id=${event_id}&user_id=${user_id}`, {
                headers: { Authorization: `Bearer ${token.session_id}` },
            })
            .then((res) => res.data),
    )
}

export const postEventRatings = async (token, event_id, rating) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .post(
                `/ratings/rate-event/`,
                { event_id: event_id, rating: rating },
                {
                    headers: { Authorization: `Bearer ${token.session_id}` },
                },
            )
            .then((res) => res.data),
    )
}

export const updateEventRatings = async (token, event_id, rating) => {
    const fetchAPI = axios.create({ baseURL: settings.api_url_v1() })
    return await resolve(
        fetchAPI
            .patch(
                `/ratings/rate-event/`,
                { event_id: event_id, rating: rating },
                {
                    headers: { Authorization: `Bearer ${token.session_id}` },
                },
            )
            .then((res) => res.data),
    )
}
