import React, { useContext, useEffect, useState } from 'react'
import { SideBar, UserProfile } from '@components/molecules'
import { getSpeakerProfileAPI, updateSpeakerProfileAPI } from '@api/speakerApi'
import toast from 'react-hot-toast'
import { v4 as uuidv4 } from 'uuid'
import { LocalVar } from '@utils/variables'
import { Button, Input } from '@components/atoms'
import { Auth } from '@context/authContext'

const SpeakerProfileTemplate = ({ userData, profileData }) => {
    const { state } = useContext(Auth)
    const [loading, setLoading] = useState(false)
    const [speakerData, setSpeakerData] = useState({})
    const [isEditable, setIsEditable] = useState(false)
    const [yearsOfExperience, setYearsOfExperience] = useState(0)
    const [specializeIn, setSpecializeIn] = useState('')
    const [key1, setKey1] = useState(uuidv4())
    const [key2, setKey2] = useState(uuidv4())

    const clearField = () => {
        setYearsOfExperience(0)
        setSpecializeIn('')
        setKey1(uuidv4())
        setKey2(uuidv4())
    }

    useEffect(() => {
        ;(async () => {
            const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))
            let getSpeakerData = await getSpeakerProfileAPI(token, state?.auth?.entity_id)
            if (getSpeakerData.error === null) {
                setSpeakerData(getSpeakerData?.data?.data)
                setYearsOfExperience(getSpeakerData?.data?.data?.experience_year || 0)
                setSpecializeIn(getSpeakerData?.data?.data?.specialization || '')
            }
        })()
    }, [state])

    const submit = async (e) => {
        e.preventDefault()

        setLoading(true)

        const token = JSON.parse(localStorage.getItem(LocalVar.AUTH_DATA))

        let body = {
            experience_year: speakerData?.experience_year,
            specialization: speakerData?.specialization,
        }

        if (yearsOfExperience.length !== 0) {
            body['experience_year'] = yearsOfExperience
        }

        if (specializeIn.length !== 0) {
            body['specialization'] = specializeIn
        }

        const updateData = await updateSpeakerProfileAPI(token, body)

        if (updateData.error === null) {
            toast.success('Speaker data updated!')
            setLoading(false)
            window.location.reload()
        } else {
            toast.error('Unexpected error occured!')
            setLoading(false)
        }

        clearField()
    }

    return (
        <SideBar>
            <div className="pt-12 px-4">
                <h2 className="mb-6">Profile</h2>
                <div className="bg-white rounded-md p-8 shadow-sm">
                    <UserProfile
                        data={{ ...userData, completeness: profileData?.completeness || 0 }}
                        isEditable={isEditable}
                        setIsEditable={setIsEditable}
                    />
                </div>
                {!isEditable && (
                    <div className="bg-white rounded-md p-8 shadow-sm mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <p className="font-semibold">
                                Profile active:{' '}
                                <span
                                    className={profileData?.profile?.active ? 'text-primary-green-2' : 'text-red-500'}>
                                    {profileData?.profile?.active ? 'Yes' : 'No'}
                                </span>
                            </p>
                            <p className="text-gray-600 font-semibold py-2">
                                Years Of Experience:{' '}
                                <span className="font-medium">{profileData?.profile?.experience_year}</span>
                            </p>
                            <p className="text-gray-600 font-semibold py-2">
                                Specialize In:{' '}
                                <span className="font-medium">{profileData?.profile?.specialization}</span>
                            </p>
                        </div>
                    </div>
                )}
                {isEditable && (
                    <form onSubmit={submit}>
                        <div className="bg-white rounded-md p-8 shadow-sm mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <Input
                                    name="experience"
                                    label="Years Of Experience"
                                    placeHolder="Years Of Experience"
                                    value={yearsOfExperience}
                                    setValue={setYearsOfExperience}
                                />
                                <Input
                                    name="specialize"
                                    label="Specialize In"
                                    placeHolder="Specialize In"
                                    value={specializeIn}
                                    setValue={setSpecializeIn}
                                />
                                <Button type="submit" loading={loading}>
                                    Update
                                </Button>
                            </div>
                            <div></div>
                        </div>
                    </form>
                )}
            </div>
        </SideBar>
    )
}

export default SpeakerProfileTemplate
