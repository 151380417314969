import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Input, PhoneNumber, Button } from '@components/atoms/index'
// import SigInGoogle from '@assets/icon/sign-in-google.png'
// import SigInApple from '@assets/icon/sign-in-apple-id.png'
import { createUser } from '@api/index'
import toast from 'react-hot-toast'

const SignupForm = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [phonePrefix, setPhonePrefix] = useState('+1')
    const [country, setCountry] = useState('')
    const [province, setProvince] = useState('')
    const [city, setCity] = useState('')
    const [password, setPassword] = useState('')
    const [cnfPassword, setCnfPassword] = useState('')
    const [done, setDone] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (done === true) {
            // navigate(`/verify/${email}`)
            navigate(`/login`, { state: { data: { email, password } } })
        }
    }, [done, navigate, email])

    const submit = async (e) => {
        e.preventDefault()

        if (loading === true) return
        setLoading(true)

        if (password !== cnfPassword) {
            toast.error('Password not confirmed!')
            setLoading(false)
            return
        }
        if (password.length < 8) {
            toast.error('Your password too short.')
            setLoading(false)
            return
        }

        let body = {
            email_address: email,
            secret: password,
            full_name: name,
            phone_number: phone,
            phone_country_prefix: phonePrefix,
            country,
            province,
            city,
        }

        const signupUser = await createUser(body)
        if (signupUser.error === null) {
            toast.success('Your account created successfully!')
            setLoading(false)
            setDone(true)
        } else {
            const pop = signupUser.error.response.data.errors[0].message || 'Failed to create your account'
            toast.error(pop)
            setLoading(false)
        }
    }

    return (
        <div className="h-full w-full flex justify-center overflow-auto">
            <div className="w-[400px] m-auto py-8">
                <h2 className="font-w6">Create your account</h2>
                <p className="text-gray-700 py-4">It’s seamless and easy</p>
                <form onSubmit={submit}>
                    <Input
                        name="name"
                        label="Name"
                        labelClass="text-md font-semibold"
                        inputClass="block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100"
                        placeHolder="Your name"
                        required={true}
                        setValue={setName}
                    />
                    <Input
                        name="email"
                        type="email"
                        label="Email"
                        labelClass="text-md font-semibold"
                        inputClass="block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100"
                        placeHolder="Your Email"
                        required={true}
                        setValue={setEmail}
                    />
                    <PhoneNumber
                        name="phone"
                        label="Phone"
                        placeHolder="Your phone number"
                        prefixValue={phonePrefix}
                        value={phone}
                        required={true}
                        setValue={setPhone}
                        setPrefixValue={setPhonePrefix}
                    />
                    <Input
                        name="country"
                        label="Country"
                        labelClass="text-md font-semibold"
                        inputClass="block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100"
                        placeHolder="Your country"
                        required={true}
                        setValue={setCountry}
                    />
                    <Input
                        name="province"
                        label="Province"
                        labelClass="text-md font-semibold"
                        inputClass="block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100"
                        placeHolder="Your province"
                        required={true}
                        setValue={setProvince}
                    />
                    <Input
                        name="city"
                        label="City"
                        labelClass="text-md font-semibold"
                        inputClass="block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100"
                        placeHolder="Your city"
                        required={true}
                        setValue={setCity}
                    />
                    <Input
                        name="password"
                        label="Password"
                        labelClass="text-md font-semibold"
                        type="password"
                        wrapperClass="relative"
                        eyeIconClass="cursor-pointer absolute right-4 top-12"
                        inputClass="block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100"
                        placeHolder="Your Password"
                        required={true}
                        setValue={setPassword}
                    />
                    <p className="text-gray-500 text-sm mb-4">Must be 8 character at least.</p>
                    <Input
                        name="cnf-password"
                        label="Confirm Password"
                        labelClass="text-md font-semibold"
                        type="password"
                        wrapperClass="relative"
                        eyeIconClass="cursor-pointer absolute right-4 top-12"
                        inputClass="block w-full border-2 border-gray-300 p-2 my-4 focus:outline-0 rounded-md bg-gray-100"
                        placeHolder="Retype your password"
                        required={true}
                        setValue={setCnfPassword}
                    />
                    <Button
                        btnClass="bg-primary-green-1 text-white p-4 w-full block mt-6 cursor-pointer rounded-md active:bg-primary-green-10 active:text-primary-green-1 border-2 border-primary-green-1 hover:bg-primary-green-2"
                        loading={loading}>
                        Signup
                    </Button>
                </form>
                {/* <button className="font-semibold block w-full text-center p-4 border-2 border-gray-300 rounded-md mt-6">
                    <img className="w-6 inline" src={SigInGoogle} alt="Google-Sign-In" /> Signup with google
                </button>
                <button className="font-semibold block w-full text-center p-4 border-2 border-gray-300 rounded-md mt-6">
                    <img className="w-6 inline" src={SigInApple} alt="Apple-Sign-In" /> Signup with apple
                </button> */}
                <p className="font-semibold text-gray-400 text-center my-8">OR</p>
                <p className="font-semibold text-gray-400 text-center mt-8">
                    Already have an account?{' '}
                    <Link to="/login" className="text-primary-green-1">
                        Sign In
                    </Link>
                </p>
                <p className="text-center text-sm text-gray-500 mt-6">
                    By Signing up I agree to the Privacy Policy & Terms and Conditions
                </p>
            </div>
        </div>
    )
}
export default SignupForm
